import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import NotificationArea from 'components/NotificationArea'
import './SubscriptionAssignment.css'
import { useDispatch, useSelector } from 'react-redux'
import { subscriptionActions, toastActions } from 'actions'
import useAssets from '../../../hooks/useAssets'
import SimpleReactValidator from 'simple-react-validator'

const SubscriptionAssignment = () => {
  const assets = useAssets()
  const [studentEmail, setStudentEmail] = useState('')
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.loginUser.user)
  const error = useSelector((state) => state.ownedSubscriptions.error)

  const selectedSubscription = useSelector((state) =>
    state.ownedSubscriptions.subscriptions.find((sub) => sub._id === id)
  )

  const isAssigned = selectedSubscription?.forUser && selectedSubscription.forUser.length > 0
  const initialEmail = isAssigned ? selectedSubscription.forUser[0].email : ''

  useEffect(() => {
    if (isAssigned) {
      setStudentEmail(initialEmail)
    }
  }, [isAssigned, initialEmail])

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => <div style={{ color: 'tomato' }}>{message}</div>,
      messages: {
        required: assets.SERVER_MESSAGES.ERRORS.ERR_REQUIRED_EMAIL,
        email: assets.SERVER_MESSAGES.ERRORS.ERR_INVALID_EMAIL,
      },
    })
  )

  useEffect(() => {
    if (error && error.response) {
      dispatch(
        toastActions.pushNotification({
          text: error.response?.data?.message || 'An error occurred',
          navigationTarget: null,
          position: 'BOTTOM',
          color: 'NEGATIVE',
          life: 3000,
        })
      )
    }
  }, [error])

  const handleNextClick = async () => {
    if (error && error.response) {
      return
    }

    if (isAssigned || validator.current.allValid()) {
      const knowledgeMaps = selectedSubscription.subscriptionPlan[0].highStakesTestDefinition[0].knowledgeMaps
      const maxSelectableMaps = selectedSubscription.subscriptionPlan[0].highStakesTestDefinition[0].maxSelectableMaps

      if (knowledgeMaps.length > maxSelectableMaps) {
        navigate(`/parent-dashboard/subscriptions/${id}/knowledge-maps-selection`, {
          state: { studentEmail },
        })
      } else {
        if (!isAssigned) {
          dispatch(
            subscriptionActions.addStudentToSubscription(
              id,
              studentEmail,
              user._id,
              knowledgeMaps.map((km) => km._id)
            )
          )
        }
        navigate(`/parent-dashboard/subscriptions/${id}/graders`)
      }
    } else {
      validator.current.showMessages()
      dispatch(
        toastActions.pushNotification({
          text: assets.SERVER_MESSAGES.ERRORS.ERR_INVALID_EMAIL,
          navigationTarget: null,
          position: 'BOTTOM',
          color: 'WARNING',
          life: 3000,
        })
      )
    }
  }

  return (
    <>
      <NotificationArea
        defaultText={selectedSubscription?.subscriptionPlan[0].highStakesTestDefinition[0].description.toUpperCase()}
      />
      <div className='subscription-assignment'>
        <div className='subscription-assignment-container'>
          <div className='student-email-input'>
            <label htmlFor='studentEmail'>{assets.SUBSCRIPTIONS.SUBSCRIPTION_EMAIL}</label>
            <InputText
              id='studentEmail'
              value={studentEmail}
              onChange={(e) => {
                setStudentEmail(e.target.value)
                validator.current.showMessageFor('studentEmail')
              }}
              className='email-input-text'
              disabled={isAssigned}
            />
            {isAssigned && (
              <div className='note'>
                <i className='pi pi-exclamation-circle icon' />
                <p>{assets.SUBSCRIPTIONS.ALREADY_ASSIGNED_NOTE}</p>
              </div>
            )}
            {!isAssigned && validator.current.message('studentEmail', studentEmail, 'required|email')}
          </div>
          <Button label='Next' onClick={handleNextClick} className='p-button-primary next-button' />
        </div>
      </div>
    </>
  )
}

export default SubscriptionAssignment
