/* global btoa */
/**
 * @module loginActions
 * @description actions for classic and code login
 * @category actions
 */
import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const LOGIN = 'LOGIN'
export const CHECK_CODE = 'CHECK_CODE'
export const LOGIN_CODE = 'LOGIN_CODE'
export const GET_LAST_LOGGED_USER = 'GET_LAST_LOGGED_USER'
export const LOGOUT = 'LOGOUT'
export const COMPLETE_OAUTH2_LOGIN = 'COMPLETE_OAUTH2_LOGIN'
export const SEND_QR_CODE_EMAIL = 'SEND_QR_CODE_EMAIL'
/**
 * @description GET to login in the traditional fashion, with username and password
 * @action login
 * @dispatches LOGIN
 * @param {String} username - the username for the account
 * @param {String} password - the password for the account
 */
export function login (payload = {}, step = 0) {
  switch (step) {
    case 0:
      return {
        type: LOGIN,
        payload: axios.post(`${SERVER}/auth-api/login?step=${step}`, { activationCode: payload.activationCode }, { headers: { Authorization: 'Basic ' + btoa(encodeURI(payload.username + ':' + payload.password)) } })
      }
    case 1:
      return {
        type: LOGIN,
        payload: axios.post(`${SERVER}/auth-api/login?step=${step}`, {}, { headers: { Authorization: btoa(encodeURI(store.getState().loginUser.user.multiFactorToken + ':' + payload.username + ':' + payload.code)) } })
      }
  }
}

/**
 * @description POST to complete OAUTH2 authentication process
 * @action completeOauthLogin
 * @dispatches COMPLETE_OAUTH2_LOGIN
 * @param {String} user - the user authenticated with username and password
 */
export function completeOauth2Login (code) {
  return {
    type: COMPLETE_OAUTH2_LOGIN,
    payload: axios.post(`${SERVER}/auth-api/complete-login`, { code })
  }
}

/**
 * @description GET to send 2fa QR code through mail
 * @action sendQRCodeMail
 * @dispatches SEND_QR_CODE_EMAIL
 * @param {String} user - the user authenticated with username and password
 */
export function sendQRCodeMail (user) {
  return {
    type: SEND_QR_CODE_EMAIL,
    payload: axios.get(`${SERVER}/login/users/${user._id}/send-qr`, { headers: { Authorization: btoa(encodeURI(store.getState().loginUser.user.multiFactorToken + ':' + user.username + ':' + '')) } })
  }
}

/**
 * @description GET the last logged user
 * @action getLastLoggedUser
 * @dispatches GET_LAST_LOGGED_USER
 * @param {String} token - the last logged user's token
 */
export function getLastLoggedUser (token) {
  return {
    type: GET_LAST_LOGGED_USER,
    payload: axios.get(`${SERVER}/enrollment-api/users/${token}/last-logged-user`)
  }
}

/**
 * @description POST to login with a code
 * @action loginCode
 * @dispatches LOGIN_CODE
 * @param {String} code - the access code for a particular instance
 * @param {String} fullName - the name or pseudonym to be used
 * @param {String} userId - the user id to be user, if any
 */
export function loginCode (code, fullName, userId, { email = '', username = '', password = '' } = {}) {
  return {
    type: LOGIN_CODE,
    payload: axios.post(`${SERVER}/login/code?generateToken=true`, { token: code, fullName: fullName, uid: userId, email, username, password })
  }
}

/**
 * @description POST to login with a code
 * @action checkCode
 * @dispatches CHECK_CODE
 * @param {String} code - the access code for a particular instance
 */
export function checkCode (code) {
  return {
    type: CHECK_CODE,
    payload: axios.post(`${SERVER}/login/instance/code`, { token: code })
  }
}

export function setInitialState (token) {
  return {
    type: 'SET_INITIAL_STATE',
    payload: axios.put(`${SERVER}/login/user`, { token }, { headers: { Authorization: token } })
  }
}

/**
 * @description DELETE to logout to explicitly logout
 * @action logout
 * @dispatches LOGOUT
 */
export function logout () {
  const { token } = store.getState().loginUser.user
  return {
    type: LOGOUT,
    payload: axios.delete(`${SERVER}/auth-api/login/token`, { headers: { Authorization: token }})
  }
}
