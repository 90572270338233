const INITIAL_STATE = {
  error: null,
  fetching: false,
  fetched: false,
  isEmailValid: null,
  isRecoveryMailSent: null

}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'RECOVER_PASSWORD_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'RECOVER_PASSWORD_FULFILLED':
      return { ...state, error: null, fetched: true, fetching: false }
    case 'RECOVER_PASSWORD_REJECTED':
      return { ...state, error: action.payload.response.data, fetching: false, fetched: false }
    case 'RECOVER_USERS_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'RECOVER_USERS_FULFILLED':
      return { ...state, error: null, isEmailValid: true, fetched: true, fetching: false }
    case 'RECOVER_USERS_REJECTED':
      return { ...state, error: null, isEmailValid: false, fetched: true, fetching: false }
    case 'SEND_RECOVERY_TOKEN_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'SEND_RECOVERY_TOKEN_FULFILLED':
      return { ...state, error: null, isRecoveryMailSent: true, fetched: true, fetching: false }
    case 'SEND_RECOVERY_TOKEN_REJECTED':
      return { ...state, error: null, isRecoveryMailSent: true, fetched: true, fetching: false }
    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}
