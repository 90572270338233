import './Camera.css'
import React, { useState, useRef, useEffect } from 'react'

const Camera = (props) => {
  const { facingMode, height } = props
  const [ stream, setStream ] = useState(null)
  const [ constraints, setConstraints ] = useState({
    audio: false,
    video: {
      facingMode: facingMode ? facingMode : 'environment',
      width: { ideal: 4320 },   
      height: { ideal: 7680 }   
    }
  });
  const [ error, setError ] = useState(null)
  const videoRef = useRef(null)

  useEffect(() => {
    if (facingMode && facingMode !== constraints.video.facingMode) {
      setConstraints({
        audio: false,
        video: {
          facingMode: facingMode
        }
      })
    }
  }, [facingMode])

  useEffect(() => {
    if (stream) {
      stream.getTracks().forEach(track => {
        track.stop()
      })
    }
    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        setStream(stream)
        videoRef.current.srcObject = stream
      })
      .catch((error) => {
        setError(error)
      })
  }, [constraints])

  return (
    <div className='camera-container'>
      <video ref={videoRef} playsInline autoPlay style={{ height: height }}/>
      { 
        error ? (
          <div>{error.message}</div>
        ) : null
      }
    </div>
  )
}

export default Camera