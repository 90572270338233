/**
 * @module registrationReducer
 * @description state manipulation for sidebar collapse
 * @category reducers
 */

import { loadUserState } from 'stores/user-storage'
import { COLLAPSE_SIDEBAR } from '../actions/sidebar-actions'

const INITIAL_STATE = {
  isSidebarCollapsed: false
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case COLLAPSE_SIDEBAR:
      return { ...state, isSidebarCollapsed: action.payload }
    case 'LOGIN_FULFILLED': {
      const userState = loadUserState(action.payload.data.username)
      return { ...INITIAL_STATE, ...userState?.sidebar }
    }
    case 'LOGIN_CODE_FULFILLED': {
      const userState = loadUserState(action.payload.data.user.username)
      return { ...INITIAL_STATE, ...userState?.sidebar }
    }
    default:
      return { ...state }
  }
}
