// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* KnowledgeMapsSelection.css */
.knowledge-maps-selection {
  padding: 2em;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.knowledge-maps-selection h1 {
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.5em;
  color: #333;
}

.knowledge-maps-selection p {
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.1em;
  color: #666;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 1em;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.checkbox-item label {
  margin-left: 0.5em;
  font-size: 1.1em;
}

.next-button {
  margin-top: 2em;
  width: 100%;
  padding: 1em;
  font-size: 1.2em;
}
`, "",{"version":3,"sources":["webpack://./src/components/Subscriptions/KnowledgeMapsSelection/KnowledgeMapsSelection.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;EACE,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,yCAAyC;EACzC,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["/* KnowledgeMapsSelection.css */\n.knowledge-maps-selection {\n  padding: 2em;\n  max-width: 600px;\n  margin: 0 auto;\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  background-color: #fff;\n}\n\n.knowledge-maps-selection h1 {\n  text-align: center;\n  margin-bottom: 1em;\n  font-size: 1.5em;\n  color: #333;\n}\n\n.knowledge-maps-selection p {\n  text-align: center;\n  margin-bottom: 1em;\n  font-size: 1.1em;\n  color: #666;\n}\n\n.checkbox-group {\n  display: flex;\n  flex-direction: column;\n  gap: 1em;\n  margin-top: 1em;\n}\n\n.checkbox-item {\n  display: flex;\n  align-items: center;\n}\n\n.checkbox-item label {\n  margin-left: 0.5em;\n  font-size: 1.1em;\n}\n\n.next-button {\n  margin-top: 2em;\n  width: 100%;\n  padding: 1em;\n  font-size: 1.2em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
