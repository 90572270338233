/**
 * @module loginUserReducer
 * @description state manipulation the current user and current user profile
 * @category reducers
 */
const INITIAL_STATE = {
  lastLoggedUsers: [],
  lastLoggedUser: null,
  isQrCodeEmailSent: null,
  user: null,
  error: null,
  fetching: false,
  fetched: false
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOGIN_PENDING':
    case 'LOGIN_CODE_PENDING':
    case 'MODIFY_PROFILE_PENDING':
    case 'MODIFY_PASSWORD_PENDING':
    case 'SEND_QR_CODE_EMAIL_PENDING':
    case 'CONFIRM_TWO_FACTOR_AUTHENTICATION_PENDING':
    case 'DEACTIVATE_TWO_FACTOR_AUTHENTICATION_PENDING':
    case 'SET_INITIAL_STATE_PENDING':
    case 'COMPLETE_OAUTH2_LOGIN_PENDING':
    case 'REGISTER_FCM_TOKEN_PENDING':
    case 'PICK_USER_FACETS_PENDING':
    case 'PUT_SHOW_PLATFORM_INSTRUCTIONS_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'LOGIN_FULFILLED':
    case 'SET_INITIAL_STATE_FULFILLED':
    case 'MODIFY_PROFILE_FULFILLED':
    case 'MODIFY_PASSWORD_FULFILLED':
    case 'CONFIRM_TWO_FACTOR_AUTHENTICATION_FULFILLED':
    case 'DEACTIVATE_TWO_FACTOR_AUTHENTICATION_FULFILLED':
    case 'COMPLETE_OAUTH2_LOGIN_FULFILLED':
    case 'REGISTER_FCM_TOKEN_FULFILLED':
    case 'PICK_USER_FACETS_FULFILLED':
      return { ...state, user: action.payload.data, lastLoggedUser: action.payload.data, isTokenValid: true, error: null, fetched: true, fetching: false }
    case 'LOGIN_CODE_FULFILLED':
      return { ...state, user: action.payload.data.user, lastLoggedUser: action.payload.data.user, isTokenValid: true, error: null, fetched: true, fetching: false }
    case 'PUT_SHOW_PLATFORM_INSTRUCTIONS_FULFILLED':
      return { ...state, user: action.payload.data, error: null, fetched: true, fetching: false }
    case 'SEND_QR_CODE_EMAIL_FULFILLED':
      return { ...state, isQrCodeEmailSent: action.payload.data, error: null, fetched: true, fetching: false }
    case 'LOGIN_REJECTED':
    case 'SET_INITIAL_STATE_REJECTED':
    case 'LOGIN_CODE_REJECTED':
    case 'MODIFY_PROFILE_REJECTED':
    case 'MODIFY_PASSWORD_REJECTED':
    case 'SEND_QR_CODE_EMAIL_REJECTED':
    case 'CONFIRM_TWO_FACTOR_AUTHENTICATION_REJECTED':
    case 'DEACTIVATE_TWO_FACTOR_AUTHENTICATION_REJECTED':
    case 'COMPLETE_OAUTH2_LOGIN_REJECTED':
    case 'REGISTER_FCM_TOKEN_REJECTED':
    case 'PICK_USER_FACETS_REJECTED':
    case 'PUT_SHOW_PLATFORM_INSTRUCTIONS_REJECTED':
      return {
        ...state,
        // user: null,
        error: action.payload.response.data,
        fetching: false,
        fetched: false
      }
    case 'RESET':
    case 'RESET_TOKEN_STATE':
    case 'LOGOUT_FULFILLED':
      if (state.lastLoggedUsers && state.user && (state.lastLoggedUsers.some(e => e && e.username === state.user.username) || state.user.isGeneratedUser)) {
        return { ...state, isTokenValid: false, lastLoggedUsers: state.lastLoggedUsers, lastLoggedUser: state.lastLoggedUser, user: null }
      } else {
        if (state.lastLoggedUsers && state.lastLoggedUsers.length === 5) {
          state.lastLoggedUsers.pop(state.lastLoggedUsers)
          state.lastLoggedUsers.unshift(state.user)
        } else {
          if (state.lastLoggedUsers) {
            state.lastLoggedUsers.unshift(state.user)
          }
        }
        return { ...state, isTokenValid: false, lastLoggedUsers: state.lastLoggedUsers ? state.lastLoggedUsers : [], lastLoggedUser: state.lastLoggedUser, user: null }
      }
    default:
      break
  }
  return state
}

// function reorderFacets (user) {
//   if (user.facets.indexOf('teacher') > 0) {
//     user.facets.splice(user.facets.indexOf('teacher'), 1)
//     user.facets.unshift('teacher')
//   }
//   return user
// }
