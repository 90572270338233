
import './ParentActivityFeed.css'
import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom'
import { activityActions } from 'actions'
import useAssets from 'hooks/useAssets'
import TestraxLogoV1 from 'assets/icons/testrax_v1.webp'
import ParentTestingEvent from './ParentTestingEvent'
import BurgerMenu from 'components/BurgerMenu'
import NotificationArea from 'components/NotificationArea'
import IconAnalytics from 'assets/icons/analysis-left-svgrepo.svg'
import IconSwot from 'assets/icons/graph-pie-svgrepo.svg'

const activitySelector = state => state.activity.activityFeedItems
const activityCountSelector = state => state.activity.count
const fetchingSelector = state => state.activity.fetching

const userSelector = state => state.loginUser.user
const ownedSubscriptionsSelector = state => state.ownedSubscriptions.subscriptions

function ParentActivityFeed () {
  const dispatch = useDispatch()
  const { subscriptionId } = useParams()
  const lastInfiniScrollElement = useRef()
  const assets = useAssets()
  const activity = useSelector(activitySelector, shallowEqual)
  const activityCount = useSelector(activityCountSelector, shallowEqual)
  const fetching = useSelector(fetchingSelector, shallowEqual)
  const user = useSelector(userSelector, shallowEqual)
  const ownedSubscriptions = useSelector(ownedSubscriptionsSelector , shallowEqual)
  const selectedSubscription = ownedSubscriptions.find(sub => sub._id === subscriptionId)
  const studentId = selectedSubscription.forUser[0]?._id
  const studentFullName = selectedSubscription?.forUser[0]?.fullName?.toUpperCase() || selectedSubscription?.forUser[0]?.username?.toUpperCase() 

  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    dispatch(activityActions.resetActivity())
    dispatch(activityActions.getParentFeedActivity(studentId))
  }, [user])

  const handleNext = () => {
    dispatch(activityActions.getParentFeedActivity(studentId,currentPage + 1))
    setCurrentPage(currentPage + 1)
  }

  const checkVisible = (elm) => {
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  const infiniScrollHandler = () => {
    if (!fetching && checkVisible(lastInfiniScrollElement.current) && activity.length < activityCount) {
      handleNext()
    }
  }

  const getEventColor = (eventType) => {
    switch (eventType) {
      case 'SUBMISSION_UPLOADED':
        return 'var(--grading-event-color-submission-uploded)';
      case 'SUBMISSION_GRADED':
        return 'var(--grading-event-color-submission-graded)';
      case 'TEST_ASSIGNED':
        return 'var(--grading-event-color-test-assigned)';
      case 'TROPHY_ROAD_ITEM_COMPLETE':
        return 'var(--grading-event-color-trophy-road-item-complete)';
      default:
        return '';
    }
  };

  const menuModel = [{
    url: `/parent-dashboard/students/${studentId}/knowledge-map-reports`,
    label: assets.TESTRAX_V2.KNOWELDGE_MAPS_REPORTS,
    icon: IconAnalytics,
    alt: 'icon-analytics'
  },{
    url: `/parent-dashboard/subscriptions/${subscriptionId}/swot`,
    label: assets.TESTRAX_V2.SWOT_ANALYSIS,
    icon: IconSwot,
    alt: 'icon-swot'
  }]

  return (
      <div className='parent-activity-feed' onScroll={infiniScrollHandler} >
        <NotificationArea defaultText={studentFullName.toUpperCase()} />
        {activity.length > 0 &&
          activity.map((e, i) => {
            const isLastElement = i === activity.length - 1
            return (
              <div
                key={i}
                ref={isLastElement ? lastInfiniScrollElement : null} 
                className={`parent-activity-item ${i % 2 === 0 ? 'even' : 'odd'}`}
              >
                <ParentTestingEvent 
                  item={e} 
                  background={getEventColor(e.eventType)} 
                  itemNumber={activityCount - i} 
                />
              </div>
            )
          })
        }
      {activity.length === 0 && (
        <div className='no-activity-parent-container'>
          <div className='no-activity-parent-logo'>
            <img src={TestraxLogoV1} />
          </div>
          <div className='no-activity-parent-div'>
            <span>{assets.TESTRAX_V2.NO_ACTIVITY_PARENT}</span>
          </div>
        </div>
      )}
      <BurgerMenu menuItems={menuModel} />
    </div>
  )
}

export default ParentActivityFeed

