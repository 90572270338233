import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { useDispatch, useSelector } from 'react-redux'
import { subscriptionActions } from 'actions'
import NotificationArea from 'components/NotificationArea'
import SimpleReactValidator from 'simple-react-validator'
import TrophyRoadItem from './TrophyRoadItem'
import './TrophyRoad.css'
import useAssets from '../../../hooks/useAssets'

const TrophyRoad = () => {
  const assets = useAssets()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.loginUser.user)
  const selectedSubscription = useSelector((state) =>
    state.ownedSubscriptions.subscriptions.find(sub => sub._id === id)
  )
  const parentId = user._id

  const studentEmail = selectedSubscription?.forUser?.[0]?.email || ''
  const highStakesTestDefinitionDescription = selectedSubscription?.subscriptionPlan?.[0]?.highStakesTestDefinition?.[0]?.description || ''
  const knowledgeMaps = selectedSubscription?.subscriptionPlan?.[0]?.highStakesTestDefinition?.[0]?.knowledgeMaps || []

  const [trophyRoadItems, setTrophyRoadItems] = useState([])
  const [editingItem, setEditingItem] = useState(null)
  const [originalValues, setOriginalValues] = useState({ link: '', condition: '' })

  const validator = useRef(new SimpleReactValidator({
    validators: {
      exceedsQuota: {
        message: assets.SUBSCRIPTIONS.EXCEEDS_QUOTA, 
        rule: (val, params) => parseInt(val, 10) <= params,
      },
    },
    element: message => <div className="trophy-road-generator-warning-message">{message}</div>,
    messages: {
      required: assets.SUBSCRIPTIONS.REQUIRED_FIELD, 
      numeric: assets.SUBSCRIPTIONS.NUMERIC_FIELD 
    }
  }))

  useEffect(() => {
    if (selectedSubscription) {
      const pagesQuota = selectedSubscription.subscriptionPlan[0].quotas.pages
      const milestones = []
      const increment = Math.floor(pagesQuota / 5)

      for (let i = 1; i <= 5; i++) {
        milestones.push({
          position: i,
          condition: i * increment,
          link: '',
          isCompleted: false,
          description: `You submitted ${i * increment} pages`
        })
      }
      setTrophyRoadItems(milestones)
    }
  }, [selectedSubscription])

  const handleEditClick = (index, item) => {
    setEditingItem({ index, link: item.link || '', condition: item.condition || '' })
    setOriginalValues({ link: item.link || '', condition: item.condition || '' })
    validator.current.showMessages()
  }

  const handleSaveClick = () => {
    if (!validator.current.allValid()) {
      validator.current.showMessages()
      return
    }

    if (editingItem.index === null) {
      const newItem = {
        position: trophyRoadItems.length + 1,
        condition: parseInt(editingItem.condition, 10),
        link: editingItem.link,
        isCompleted: false,
        description: `You submitted ${editingItem.condition} pages`
      }

      const updatedItems = [...trophyRoadItems, newItem]
      const sortedItems = updatedItems.sort((a, b) => a.condition - b.condition)

      setTrophyRoadItems(sortedItems)
    } else {
      const updatedItems = trophyRoadItems.map((item, index) => {
        if (index === editingItem.index) {
          return {
            ...item,
            condition: parseInt(editingItem.condition, 10),
            link: editingItem.link,
            description: `You submitted ${editingItem.condition} pages`
          }
        }
        return item
      })
      const sortedItems = [...updatedItems].sort((a, b) => a.condition - b.condition)

      setTrophyRoadItems(sortedItems)
    }

    setEditingItem(null)
  }

  const handleAddNewItem = () => {
    setEditingItem({
      index: null,
      condition: '',
      link: ''
    })
    validator.current.showMessages()
  }

  const handleFinishClick = () => {
    dispatch(subscriptionActions.assignTrophyRoadToSubscription(id, trophyRoadItems, parentId));
    navigate('/parent-dashboard')
  }

  const pagesQuota = selectedSubscription?.subscriptionPlan?.[0]?.quotas?.pages || 0

  return (
    <>
      <NotificationArea defaultText={highStakesTestDefinitionDescription.toUpperCase()} />
      <div className='trophy-road-generator'>
        <h1 className="trophy-road-generator-header">{assets.SUBSCRIPTIONS.TROPHY_ROAD_HEADER}</h1>
        {trophyRoadItems.map((item, index) => (
          <TrophyRoadItem
            key={index}
            item={item}
            index={index}
            isEditing={editingItem?.index === index}
            onEditClick={() => handleEditClick(index, item)}
            onSaveClick={handleSaveClick}
            editLink={editingItem?.link || ''}
            editConditionValue={editingItem?.condition || ''}
            handleConditionChange={(e) => {
              setEditingItem(prev => ({ ...prev, condition: e.target.value }))
              validator.current.showMessageFor(`condition-${index}`)
            }}
            handleLinkChange={(e) => setEditingItem(prev => ({ ...prev, link: e.target.value }))}
            validator={validator}
            pagesQuota={pagesQuota}
          />
        ))}

        {editingItem && editingItem.index === null && (
          <TrophyRoadItem
            key="new-item"
            item={{ condition: '', link: '' }}
            index="new-item"
            isEditing={true}
            onSaveClick={handleSaveClick}
            editLink={editingItem.link}
            editConditionValue={editingItem.condition}
            handleConditionChange={(e) => {
              setEditingItem(prev => ({ ...prev, condition: e.target.value }))
              validator.current.showMessageFor('condition-new')
            }}
            handleLinkChange={(e) => setEditingItem(prev => ({ ...prev, link: e.target.value }))}
            validator={validator}
            pagesQuota={pagesQuota}
          />
        )}

        <div className='trophy-road-generator-add-item' onClick={handleAddNewItem}>
          <i className='pi pi-plus trophy-road-generator-plus-icon' />
        </div>

        <Button label={assets.SUBSCRIPTIONS.FINISH_ASSIGNATION} onClick={handleFinishClick} className='trophy-road-generator-next-button' />
      </div>
    </>
  )
}

export default TrophyRoad
