import './LoginProvider.css'
import React from 'react'
import { SERVER } from 'config/global'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { loginActions } from 'actions'

import useAssets from 'hooks/useAssets'

import logo from 'assets/icons/testrax_v1.webp'
import googleIcon from '../../assets/login-buttons/google.svg'
import appleIcon from '../../assets/login-buttons/apple.svg'
import facebookIcon from '../../assets/login-buttons/facebook.svg'

import InvalidAccountLogout from '../InvalidAccountLogout/InvalidAccountLogout'

const loginUserSelector = (state) => state.loginUser.user

const LoginProvider = () => {
  const assets = useAssets()
  const dispatch = useDispatch()
  const user = useSelector(loginUserSelector, shallowEqual)

  const handleLoginMessage = (event) => {
    // dispatch action to get user info via event.data
    if (SERVER.startsWith(event.origin) && event?.data?.code) {
      dispatch(loginActions.completeOauth2Login(event.data.code))
    }
  }

  const handleLoginMessageFile = (event) => {
    // dispatch action to get user info via event.data
    if (event.origin === 'file://') {
      const messageContent = JSON.parse(event.data)
      dispatch(loginActions.completeOauth2Login(messageContent.code))
    } else {
      // console.warn('strange message')
      // console.warn(event)
    }
  }

  const handleGoogleLogin = async () => {
    try {
      if (window.cordova) {
        const ref = window.open(`${SERVER}/auth-api/google/login`, 'oauth:_blank', '')
        ref.addEventListener('loadstop', (event) => {
          if (event.url.startsWith(`${SERVER}/auth-api/auth-token`)) {
            const url = new URL(event.url)
            const code = url.searchParams.get('code')
            window.postMessage(JSON.stringify({ code }), '*')
            ref.close()
          }
        })
        window.onmessage = handleLoginMessageFile
      } else {
        window.open(`${SERVER}/auth-api/google/login`, 'oauth:_blank', '')
        window.onmessage = handleLoginMessage
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const handleFacebookLogin = () => {

  }

  const handleAppleLogin = () => {

  }

  const DashboardSelector = () => {
    if (user.facets.length === 0) {
      return <Navigate to='/facet-picker' />
    } else {
      if (user.facets.length !== 1) {
        return <InvalidAccountLogout />
      } else {
        if (user.facets.includes('student')) {
          return <Navigate to='/student-dashboard' />
        }
        if (user.facets.includes('parent')) {
          return <Navigate to='/parent-dashboard' />
        }
        if (user.facets.includes('grader')) {
          return <InvalidAccountLogout />
        }
        return <InvalidAccountLogout />
      }
    }
  }

  return (
    <>
      {
        user?.token ? (
          <DashboardSelector />
        ) : (
          <div className='login-provider-container'>
            <div className='login-provider-header'>
              <img className='login-provider-logo' src={logo} />
            </div>
            <div className='login-provider-moto'>
              <span>{assets.LOGIN_PROVIDER.MOTO}</span>
            </div>
            <div className='login-provider-content'>
              <div className='login-button google' onClick={handleGoogleLogin}>
                <img className='icon' src={googleIcon} />
                <span className='label unselectable-text'>{assets.LOGIN_PROVIDER.CONTINUE_GOOGLE}</span>
              </div>
              <div className='login-button apple'>
                <img className='icon' src={appleIcon} />
                <span className='label unselectable-text'>{assets.LOGIN_PROVIDER.CONTINUE_APPLE}</span>
              </div>
              <div className='login-button facebook'>
                <img className='icon' src={facebookIcon} />
                <span className='label unselectable-text'>{assets.LOGIN_PROVIDER.CONTINUE_FACEBOOK}</span>
              </div>
            </div>
           	<div className='rights-links'>
							<a href={assets.LOGIN_PROVIDER.LINKS.TERMS_LINK}>{assets.LOGIN_PROVIDER.LINKS.TERMS_TEXT}</a>
							<a href={assets.LOGIN_PROVIDER.LINKS.GDPR_LINK}>{assets.LOGIN_PROVIDER.LINKS.GDPR_TEXT}</a>
							<a href={assets.LOGIN_PROVIDER.LINKS.COOKIES_LINK}>{assets.LOGIN_PROVIDER.LINKS.COOKIES_TEXT}</a>
						</div>
          </div>
        )
      }
    </>
  )
}

export default LoginProvider
