// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.points-viewer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 5em;
	height: 5em;
	position: fixed;
	right: 1vw;
	top: calc(var(--notification-area-height) * 2 + 2vh);
}

.points-viewer img {
	max-height: 5em;
}

.points-viewer .image-container {
	background: white;
  position: relative;
	display: inline-block
}	

.points-viewer .points {
	font-weight: bold;
}

.points-viewer .overlay {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: var(--positive-color);
	mix-blend-mode: screen; /* Try changing to 'difference' */
	pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/TrophyRoadViewer/PointsViewer/PointsViewer.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,uBAAuB;CACvB,UAAU;CACV,WAAW;CACX,eAAe;CACf,UAAU;CACV,oDAAoD;AACrD;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,iBAAiB;EAChB,kBAAkB;CACnB;AACD;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,WAAW;CACX,kBAAkB;CAClB,SAAS;CACT,OAAO;CACP,WAAW;CACX,iCAAiC;CACjC,sBAAsB,EAAE,iCAAiC;CACzD,oBAAoB;AACrB","sourcesContent":[".points-viewer {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\twidth: 5em;\n\theight: 5em;\n\tposition: fixed;\n\tright: 1vw;\n\ttop: calc(var(--notification-area-height) * 2 + 2vh);\n}\n\n.points-viewer img {\n\tmax-height: 5em;\n}\n\n.points-viewer .image-container {\n\tbackground: white;\n  position: relative;\n\tdisplay: inline-block\n}\t\n\n.points-viewer .points {\n\tfont-weight: bold;\n}\n\n.points-viewer .overlay {\n\tcontent: \"\";\n\tposition: absolute;\n\tbottom: 0;\n\tleft: 0;\n\twidth: 100%;\n\tbackground: var(--positive-color);\n\tmix-blend-mode: screen; /* Try changing to 'difference' */\n\tpointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
