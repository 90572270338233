import React from 'react'
import { connect } from 'react-redux'
import errorRobot from 'assets/error-robot.webp'
import './ErrorBoundary.css'

const mapStateToProps = function (state) {
  return {
    assets: state.language.assets
  }
}

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    console.error('error :>> ', error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    console.error('error :>> ', error)
    console.error('errorInfo :>> ', errorInfo)
  }

  render () {
    const assets = this.props.assets
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='network-error-display'>
          <div className='container'>
            {assets.NETWORK_ERROR.START_SECONDARY}
          </div>
          <img className='robot' src={errorRobot} alt='Error' />
          <div className='container'>
            {assets.NETWORK_ERROR.ACTION_SECONDARY}
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default connect(mapStateToProps)(ErrorBoundary)
