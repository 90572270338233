/**
 * @module languageReducer
 * @description state manipulation for internationalization and locale selection
 * @category reducers
 */
import defaultsDeep from 'lodash/defaultsDeep'

const INITIAL_STATE = {
  // lang : 'EN_uk',
  // calendarLocale : 'en-gb'
  lang: 'RO_ro',
  calendarLocale: 'ro',
  networkLocale: 'ro-RO,ro;q=0.9',
  assets: require('assets/texts_RO_ro').default
}

const DEFAULT_ASSETS = require('assets/texts_EN_uk').default

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SELECT_LANGUAGE': {
      let calendarLocale = ''
      let networkLocale = ''
      switch (action.payload) {
        case 'RO_ro':
          calendarLocale = 'ro'
          networkLocale = 'ro-RO,ro;q=0.9'
          break
        case 'EN_uk':
          calendarLocale = 'en-gb'
          networkLocale = 'en-GB,en;q=0.9'
          break
        case 'DE_de':
          calendarLocale = 'de'
          networkLocale = 'de-DE,de;q=0.9'
          break
        case 'FR_fr':
          calendarLocale = 'fr'
          networkLocale = 'fr-FR,fr;q=0.9'
          break
        default:
          calendarLocale = 'en-gb'
          networkLocale = 'en-GB,en;q=0.9'
      }
      const rawAssets = require(`assets/texts_${action.payload}`).default
      return { ...state, lang: action.payload, calendarLocale: calendarLocale, networkLocale: networkLocale, assets: defaultsDeep({}, rawAssets, DEFAULT_ASSETS) }
    }
    // case 'RESET':
    //   return INITIAL_STATE
    default:
      return state
  }
}
