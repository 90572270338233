import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { submissionsActions, toastActions } from '../../actions'
import ContentRenderer from 'components/ContentRenderer'
import NotificationArea from 'components/NotificationArea'
import { Tag } from 'primereact/tag'
import './SubmissionGrading.css'
import AuthorizedImage from '../AuthorizedImage/AuthorizedImage'
import { SERVER } from 'config/global'

const submissionGradingSelector = (state) => state.submissionGrading.submissionGrading
const assetSelector = (state) => state.language.assets
const userSelector = (state) => state.loginUser.user

const SubmissionGrading = () => {
  const { submissionId, userKnowledgeMapId, submissionGradingId } = useParams()
  const dispatch = useDispatch()
  const [authorizationMessageVisible, setAuthorizationMessageVisible] = useState(true)

  const submissionGrading = useSelector(submissionGradingSelector, shallowEqual)
  const assets = useSelector(assetSelector, shallowEqual)
  const user = useSelector(userSelector, shallowEqual)

  useEffect(() => {
    if (submissionGradingId) {
      dispatch(submissionsActions.getSubmissionGrading(submissionGradingId))
    }
  }, [submissionGradingId])

  const getStatusSeverity = (status) => {
    switch (status) {
      case 'CORRECT':
        return 'success'
      case 'INCORRECT':
        return 'danger'
      case 'PARTIALLY_CORRECT':
        return 'warning'
      case 'NOT_ATTEMPTED':
        return 'info'
      case 'UNGRADED':
        return 'info'
      default:
        return null
    }
  }

  const formatStatus = (status) => {
    return assets.TESTRAX_V2.SUBMISSION_STATUS_TYPE[status] || status.split('_').join(' ');
  }

  const renderGradingItemsForImage = (imageMeta) => {
    const isGradable = submissionGrading.gradablePages.includes(imageMeta._id)
    const gradingItems = submissionGrading.gradingItems.filter((item) => item.imageRef === imageMeta._id)

    if (!isGradable) return null;
    if (gradingItems.length === 0) {
      return <p className="no-grading-items-message">{assets.TESTRAX_V2.NO_GRADING_ITEMS_MESSAGE}</p>;
    }
  
    return submissionGrading.gradingItems
      .filter((item) => item.imageRef === imageMeta._id)
      .map((item) => {
        return (
          <div key={item._id} className="grading-item">
            <div className="question-container">
              <div className="question-text">
                {item.testItem ? (
                  <ContentRenderer content={item.testItem.content} />
                ) : (
                  item.itemContent?.questionText || ''
                )}
              </div>
            </div>  
            <div className="status-container">
              <div className="status-tag">
                <Tag value={formatStatus(item.status)} severity={getStatusSeverity(item.status)} />
              </div>
            </div>
          </div>
        )
      })
  }
  

  const handleAccept = () => {
    dispatch(submissionsActions.updateSubmissionGradingStatus(submissionId, userKnowledgeMapId, submissionGradingId, 'ACCEPTED'))
    setAuthorizationMessageVisible(false)
    dispatch(toastActions.pushNotification({
      text: assets.GENERAL.SUBMISSION_GRADING_ACCEPTED,
      navigationTarget: null,
      position: 'BOTTOM',
      color: 'POSITIVE',
      life: 3000
    }))
  }

  const handleReject = () => {
    dispatch(submissionsActions.updateSubmissionGradingStatus(submissionId, userKnowledgeMapId, submissionGradingId, 'REJECTED'))
    setAuthorizationMessageVisible(false)
    dispatch(toastActions.pushNotification({
      text: assets.GENERAL.SUBMISSION_GRADING_REJECTED,
      navigationTarget: null,
      position: 'BOTTOM',
      color: 'NEUTRAL',
      life: 3000
    }))
  }

  return (
    <div className='submission-grading-container'>
      <NotificationArea defaultText={assets.TESTRAX_V2.HOME_TITLE.toUpperCase()} />
      <h1 className='submission-header'>{assets.GENERAL.SUBMISSION_GRADING}</h1>
      <div className='grading-items-container'>
        {submissionGrading && submissionGrading.images.map((image, index) => (
          <div key={index}>
            <div className='image-container-section'>
            <div className='image-container'>
              <AuthorizedImage
                thumbnail={`${SERVER}/media-api/submission/${submissionId}/thumbnail/${image.uuid}`}
                src={`${SERVER}/media-api/submission/${submissionId}/image/${image.uuid}`}
                authorizationToken={user.token}
                isGradeable={submissionGrading.gradablePages.find(e => e === image._id)}
              />
            </div>
            </div>
            {!submissionGrading.gradablePages.find(e => e === image._id) && (
                <p className="not-gradable-message">{assets.TESTRAX_V2.UNGRADABLE_PAGE_MESSAGE}</p>
              )}
            {renderGradingItemsForImage(image)}
          </div>
        ))}

        {authorizationMessageVisible && user.facets.includes('parent') && submissionGrading && submissionGrading.isExternalGrader && submissionGrading.status === 'PENDING' && (
          <div className='authorization-message'>
            <p>{assets.TESTRAX_V2.EXTERNAL_GRADER_MESSAGE}</p>
            <div className='authorization-buttons'>
              <button onClick={handleAccept} className='accept-button'>{assets.GENERAL.ACCEPT}</button>
              <button onClick={handleReject} className='reject-button'>{assets.GENERAL.REJECT}</button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SubmissionGrading
