import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import NotificationArea from 'components/NotificationArea';
import './CorrectorsAllocation.css';
import { useDispatch, useSelector } from 'react-redux';
import { gradingActions } from 'actions';
import useAssets from '../../../hooks/useAssets';

const CorrectorsAllocation = () => {
  const assets = useAssets()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector((state) => state.loginUser.user);

  const selectedSubscription = useSelector((state) =>
    state.ownedSubscriptions.subscriptions.find(sub => sub._id === id)
  );

  const studentEmail = selectedSubscription?.forUser?.[0]?.email || '';
  const highStakesTestDefinition = selectedSubscription?.subscriptionPlan?.[0]?.highStakesTestDefinition?.[0];
  const highStakesTestDefinitionDescription = highStakesTestDefinition?.description || '';
  const knowledgeMaps = highStakesTestDefinition?.knowledgeMaps?.map((map) => ({
    ...map,
    manualAllocate: true,
    email: ''
  })) || [];

  const [updatedKnowledgeMaps, setUpdatedKnowledgeMaps] = useState(knowledgeMaps);

  const handleEmailChange = (index, email) => {
    const newKnowledgeMaps = [...updatedKnowledgeMaps];
    newKnowledgeMaps[index].email = email;
    setUpdatedKnowledgeMaps(newKnowledgeMaps);
  };

  const handleNextClick = async () => {
    const userKnowledgeMaps = selectedSubscription?.userKnowledgeMaps || [];

    for (const km of updatedKnowledgeMaps) {
      const email = km.email.trim();
      if (email) {
        const userKnowledgeMap = userKnowledgeMaps.find(ukm => ukm.knowledgeMap._id === km._id);
        if (userKnowledgeMap) {
          dispatch(gradingActions.sendGradingInvite(id, user._id, userKnowledgeMap._id, email));
        }
      }
    }

    navigate(`/parent-dashboard/subscriptions/${id}/trophy-road`);
  };

  return (
    <>
      <NotificationArea defaultText={highStakesTestDefinitionDescription.toUpperCase()} />
      <div className='correctors-allocation-container'>
        <h1>{assets.SUBSCRIPTIONS.CORRECTORS_ALLOCATION}</h1>
        <div className='note'>
          <i className='pi pi-exclamation-circle icon'></i>
          <p>{assets.SUBSCRIPTIONS.EMAIL_SPECIFICATION_WARNING}</p>
        </div>
        {selectedSubscription?.userKnowledgeMaps?.map((ukm, index) => (
          <div key={index} className='knowledge-map-section'>
            <h3>{ukm.knowledgeMap.description}</h3>
            <div className='email-input'>
              <label htmlFor={`correctorEmail${index}`}>{assets.SUBSCRIPTIONS.CORRECTOR_EMAIL}</label>
              <InputText
                id={`correctorEmail${index}`}
                value={updatedKnowledgeMaps[index]?.email || ''}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                className='enabled-email-input'
                disabled={ukm.gradingInviteSent} 
              />
              {ukm.gradingInviteSent && (
                <p className='grading-invite-message'>Grading invite sent</p>
              )}
            </div>
          </div>
        ))}
        <Button
          label='Next'
          className='next-button'
          onClick={handleNextClick}
        />
      </div>
    </>
  );
};

export default CorrectorsAllocation;
