/**
 * @module activityActions
 * @description actions for activity elements
 * @category actions
 */
import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const GET_ACTIVITY = 'GET_ACTIVITY'
export const RESET_ACTIVITY = 'RESET_ACTIVITY'
export const GET_TROPHY_ROAD_ITEM_ACTIVITY = 'GET_TROPHY_ROAD_ITEM_ACTIVITY'
export const RESET_TROPHY_ROAD_ITEM_ACTIVITY = 'RESET_TROPHY_ROAD_ITEM_ACTIVITY'

/**
 * @description GET activity
 * @action getActivity
 * @dispatches GET_ACTIVITY
 */
export function getActivity (page = 0) {
    const user = store.getState().loginUser.user

    return {
      type: GET_ACTIVITY,
      payload: axios.get(`${SERVER}/student-api/activity-feed?page=${page}`, { headers: { Authorization: user.token } })
    }
  }

  export function getParentFeedActivity (studentId, page = 0) {
    const user = store.getState().loginUser.user

    return {
      type: GET_ACTIVITY,
      payload: axios.get(`${SERVER}/parent-api/students/${studentId}/activity-feed?page=${page}`, { headers: { Authorization: user.token } })
    }
  }

  export function resetActivity () {
    return {
      type: RESET_ACTIVITY,
      payload: {}
    }
  }

  export function getTrophyRoadItemActivity (trid, triid, page = 0) {
    const user = store.getState().loginUser.user
    return {
      type: GET_TROPHY_ROAD_ITEM_ACTIVITY,
      payload: axios.get(
        `${SERVER}/student-api/trophy-roads/${trid}/trophy-road-items/${triid}/trophy-road-activity-feed?page=${page}`,
        { headers: { Authorization: user.token } }
      )
    }
  }
  
  export function resetTrophyRoadItemActivity () {
    return {
      type: RESET_TROPHY_ROAD_ITEM_ACTIVITY,
      payload: {}
    }
  }