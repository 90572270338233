/**
 * @module languageActions
 * @description actions for locale management
 * @category actions
 */

export const SELECT_LANGUAGE = 'SELECT_LANGUAGE'

/**
 * @description Select a language/locale
 * @action selectLanguage
 * @dispatches SELECT_LANGUAGE
 * @param {String} languageSelector - a ISO language selector in a slightly modified format e.g. RO_ro
 */
export function selectLanguage (languageSelector) {
  return {
    type: SELECT_LANGUAGE,
    payload: languageSelector
  }
}
