/**
 * @module toastActions
 * @description actions for toast content
 * @category actions
 */

export const TOAST_QUEUE_PUSH = 'TOAST_QUEUE_PUSH'
export const TOAST_QUEUE_POP = 'TOAST_QUEUE_POP'

/*
a notification object should look like this:
{
  text: 'a text notification',
  navigationTarget: 'path',
  position: 'TOP',
  color: 'POSITIVE',
  life: 3000
}
*/

/**
 * @description Push a notification to the toast queue
 * @action pushNotification
 * @dispatches TOAST_QUEUE_PUSH
 * @param {Object} notification -a notification object
 */
export function pushNotification (notification) {
  return {
    type: TOAST_QUEUE_PUSH,
    payload: notification
  }
}

/**
 * @description Pop a notification from the toast queue
 * @action popNotification
 * @dispatches TOAST_QUEUE_POP
 */
export function popNotification () {
  return {
    type: TOAST_QUEUE_POP,
    payload: {}
  }
}

