import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { loginActions } from 'actions'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

const fetchingSelector = state => state.loginUser.fetching
const fetchedSelector = state => state.loginUser.fetched
const userSelector = state => state.loginUser.user
const LoginAuthorization = () => {
  const fetching = useSelector(fetchingSelector, shallowEqual)
  const fetched = useSelector(fetchedSelector, shallowEqual)
  const user = useSelector(userSelector, shallowEqual)

  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const { code } = params

  useEffect(() => {
    if (code) {
      dispatch(loginActions.completeOauth2Login(code))
    }
  }, [code])

  useEffect(() => {
    if (user && fetched && !fetching) {
      navigate('/#/')
    }
  }, [fetched, fetching, user])

  return (
    <></>
  )
}

export default LoginAuthorization