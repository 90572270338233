const INITIAL_STATE = {
    testingEvents: [],
    count: 0,
    error: null,
    fetching: false,
    fetched: false
}

const testingEventsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_TESTING_EVENTS_PENDING':
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: null
            }

        case 'GET_TESTING_EVENTS_FULFILLED':
            return {
                ...state,
                testingEvents: action.payload.data.testingEvents,
                count: action.payload.data.count,
                error: null,
                fetching: false,
                fetched: true
            }
        case 'GET_TESTING_EVENTS_REJECTED':
            return {
                ...state,
                error: action.payload.response.data,
                fetching: false,
                fetched: false
              }
        
        case 'RESET':
              return INITIAL_STATE
        default:
              break
    }
    return state
}

export default testingEventsReducer