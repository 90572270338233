import './InvalidAccountLogout.css'
import useAssets from 'hooks/useAssets'
import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { loginActions } from 'actions'
import { useNavigate } from 'react-router-dom'
import NotificationArea from '../NotificationArea/NotificationArea'

const loginUserSelector = (state) => state.loginUser

function InvalidAccountLogout () {
  const assets = useAssets()
  const loginUser = useSelector(loginUserSelector, shallowEqual)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  function logoutClickHandler () {
    dispatch(loginActions.logout())
    dispatch(stateActions.resetState())
  }

  useEffect(() => {
    if (!loginUser.isTokenValid) {
      navigate('/login')
    }   
  })


  return (
    <>
    <NotificationArea defaultText={assets.TESTRAX_V2.HOME_TITLE.toUpperCase()} />
    <div className='invalid-account-logout'>
      <div className='warning-message'>
        { 
          loginUser.user.facets.length !== 1 ? (
            assets.LOGIN_PROVIDER.INVALID_ACCOUNT_CONTACT_SUPPORT
          ) : (
            loginUser.user.facets.includes('grader') ? (
              assets.LOGIN_PROVIDER.INVALID_ACCOUNT_CONTACT_SUPPORT
            ) : (
              assets.LOGIN_PROVIDER.ACCOUNT_NOT_USEABLE
              )
            )
          }
      </div>
      <div className='logout-container'>
        <button className='logout-button' onClick={logoutClickHandler}>
          {assets.LOGIN_PROVIDER.LOGOUT}
        </button>
      </div>
    </div>
    </>
  )
}

export default InvalidAccountLogout