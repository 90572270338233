import React from 'react'
import './NetworkErrorDisplay.css'
import { useSelector, shallowEqual } from 'react-redux'
import errorRobot from 'assets/network_error_robot.webp'

const networkErrorSelector = state => state.networkError
const assetSelector = state => state.language.assets

const NetworkErrorDisplay = (props) => {
  const networkError = useSelector(networkErrorSelector, shallowEqual)
  const assets = useSelector(assetSelector, shallowEqual)

  if (networkError.networkErrorOccured) {
    return (
      <div className='network-error-display'>
        <div className='container'>
          {assets.NETWORK_ERROR.START}
        </div>
        <img className='robot' src={errorRobot} alt='Error' />
        <div className='container'>
          {assets.NETWORK_ERROR.ACTION}
        </div>
      </div>
    )
  } else {
    return (
      <>
        {props.children}
      </>
    )
  }
}

export default NetworkErrorDisplay
