/**
 * @module gradingActions
 * @description actions for subscriptions
 * @category actions
 */
import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const ADD_GRADING_INVITE = 'ADD_GRADING_INVITE'

export function sendGradingInvite(studentId, parentId, userKnowledgeMapId, email, page = 0, sortField = '', sortOrder = 1, filterString = '') {
  const token = store.getState().loginUser.user.token
  const facet = store.getState().loginUser.user.facets.includes('parent') ? 'parent' : 'teacher'

  return {
    type: ADD_GRADING_INVITE,
    payload: async () => {
        await axios.post(`${SERVER}/parent-api/students/${studentId}/user-knowledge-maps/${userKnowledgeMapId}/grading-invite`, { email }, {
        headers: {
          Authorization: token
        }
      })
      const response = axios.get(`${SERVER}/${facet}-api/users/${parentId}/subscriptions?page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&${filterString}`, {
        headers: {
          Authorization: token
        }
      })
      return response
    }
    }
}






