const INITIAL_STATE = {
  knowledgeMapsSuggestions: [],
  count: 0,
  error: null,
  fetching: false,
  fetched: false
}

const knowledgeMapsSuggestionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_TEST_DEFINITION_KNOWLEDGE_MAPS_PENDING':
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      }
    case 'GET_TEST_DEFINITION_KNOWLEDGE_MAPS_FULFILLED':
      return {
        ...state,
        knowledgeMapsSuggestions: action.payload.data.knowledgeMaps,
        count: action.payload.data.count,
        error: null,
        fetching: false,
        fetched: true
      }
    case 'GET_TEST_DEFINITION_KNOWLEDGE_MAPS_REJECTED':
      return {
        ...state,
        error: action.payload.response.data,
        fetching: false,
        fetched: false
      }

    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}

export default knowledgeMapsSuggestionsReducer
