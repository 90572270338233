/**
 * @module subscriptionActions
 * @description actions for subscriptions
 * @category actions
 */
import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const GET_ACTIVE_SUBSCRIPTION = 'GET_ACTIVE_SUBSCRIPTION'
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS'
export const GET_TEST_DEFINITION_KNOWLEDGE_MAPS = 'GET_TEST_DEFINITION_KNOWLEDGE_MAPS'
export const ADD_STUDENT_TO_SUBSCRIPTION = 'ADD_STUDENT_TO_SUBSCRIPTION'
export const ASSIGN_TROPHY_ROAD_TO_SUBSCRIPTION = 'ASSIGN_TROPHY_ROAD_TO_SUBSCRIPTION'
export const GET_USER_STATISTICS = 'GET_USER_STATISTICS'

/**
 * @description GET active subscription
 * @action getActiveSubscription
 * @dispatches GET_ACTIVE_SUBSCRIPTION
 */
export function getActiveSubscription () {
    const user = store.getState().loginUser.user
    return {
      type: GET_ACTIVE_SUBSCRIPTION,
      payload: axios.get(`${SERVER}/student-api/active-subscription`, { headers: { Authorization: user.token } })
    }
  }


  export function getOwnedSubscriptions (parentId, page = 0, sortField = '', sortOrder = 1, filterString = '') {
    const token = store.getState().loginUser.user.token
    const facet = store.getState().loginUser.user.facets.includes('parent') ? 'parent' : 'teacher'
  
    return {
      type: GET_SUBSCRIPTIONS,
      payload: axios.get(`${SERVER}/${facet}-api/users/${parentId}/subscriptions?page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&${filterString}`, {
        headers: {
          Authorization: token
        }
      })
    }
  }
  
  export function getHighStakesTestDefinitionKnowledgeMaps (highStakesTestDefinitionId) {
    const token = store.getState().loginUser.user.token
    const facet = store.getState().loginUser.user.facets.includes('parent') ? 'parent' : 'teacher'
    return {
      type: GET_TEST_DEFINITION_KNOWLEDGE_MAPS,
      payload: axios.get(`${SERVER}/${facet}-api/high-stakes-test-definitions/${highStakesTestDefinitionId}/knowledge-maps`, {
        headers: {
          Authorization: token
        }
      })
    }
  }
  
  export function addStudentToSubscription (subscriptionId, studentEmail, parentId, knowledgeMapsIds, page = 0, sortField = '', sortOrder = 1, filterString = '') {
    const token = store.getState().loginUser.user.token
    const facet = store.getState().loginUser.user.facets.includes('parent') ? 'parent' : 'teacher'
  
    return {
      type: ADD_STUDENT_TO_SUBSCRIPTION,
      payload: async () => {
        await axios.post(`${SERVER}/${facet}-api/subscriptions/${subscriptionId}/students`, { studentEmail, knowledgeMapsIds }, {
          headers: {
            Authorization: token
          }
        })
        const response = axios.get(`${SERVER}/${facet}-api/users/${parentId}/subscriptions?page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&${filterString}`, {
          headers: {
            Authorization: token
          }
        })
        return response
      }
    }
  }
  
  export function assignTrophyRoadToSubscription(subscriptionId, trophyRoadItems, parentId, page = 0, sortField = '', sortOrder = 1, filterString = '') {
    const token = store.getState().loginUser.user.token;
  
    return {
      type: ASSIGN_TROPHY_ROAD_TO_SUBSCRIPTION,
      payload: async () => {
        await axios.post(`${SERVER}/parent-api/subscriptions/${subscriptionId}/trophy-road`, { trophyRoadItems }, {
          headers: {
            Authorization: token
          }
        });
  
        const response = axios.get(`${SERVER}/parent-api/users/${parentId}/subscriptions?page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&${filterString}`, {
          headers: {
            Authorization: token
          }
        });
  
        return response;
      }
    };
  }

  export function getStudentStatistics () {
    const user = store.getState().loginUser.user
    return {
      type: GET_USER_STATISTICS,
      payload: axios.get(`${SERVER}/student-api/statistics`, { headers: { Authorization: user.token } })
    }
  }