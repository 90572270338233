import React, { useEffect, useRef } from 'react'
import './LanguageSelector.css'
import NotificationArea from 'components/NotificationArea'
import { useDispatch, useSelector } from 'react-redux'
import { languageActions, toastActions } from 'actions'
import useAssets from 'hooks/useAssets'
import ROFlag from '../../../assets/flags/RO_ro.svg'
import ENFlag from '../../../assets/flags/EN_uk.svg'

const languageSelector = (state) => state.language.lang

const LanguageSelector = () => {
  const dispatch = useDispatch()
  const assets = useAssets()
  const language = useSelector(languageSelector)
  const prevLanguageRef = useRef(language) 

  const handleLanguageSelect = (language) => {
    dispatch(languageActions.selectLanguage(language))
  }

  useEffect(() => {
    if (prevLanguageRef.current && prevLanguageRef.current !== language) {
      dispatch(
        toastActions.pushNotification({
          text: assets.TESTRAX_V2.SETTINGS.LANGUAGE_CHANGED_SUCCESS,
          navigationTarget: null,
          position: 'BOTTOM',
          color: 'POSITIVE',
          life: 3000,
        })
      )
    }
    prevLanguageRef.current = language 
  }, [language, dispatch])

  return (
    <div className='language-selector-container'>
      <NotificationArea defaultText={assets.TESTRAX_V2.SETTINGS.SELECT_LANGUAGE.toUpperCase()} />
      <div className='language-selector-list'>
        <div
          className='language-selector-item'
          onClick={() => handleLanguageSelect('RO_ro')}
        >
          <img src={ROFlag} alt='Romanian Flag' className='language-selector-flag' />
          <span className='language-selector-label'>{assets.TESTRAX_V2.SETTINGS.LANGUAGE_RO}</span>
        </div>
        <div
          className='language-selector-item'
          onClick={() => handleLanguageSelect('EN_uk')}
        >
          <img src={ENFlag} alt='English Flag' className='language-selector-flag' />
          <span className='language-selector-label'>{assets.TESTRAX_V2.SETTINGS.LANGUAGE_EN}</span>
        </div>
      </div>
    </div>
  )
}

export default LanguageSelector
