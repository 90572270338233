import './LoginClassic.css'
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

import { loginActions, recoveryActions } from 'actions'
import { Card } from 'primereact/card'

const assetSelector = state => state.language.assets
const userSelector = state => state.loginUser.user
const isRecoverEmailValidSelector = state => state.passwordRecovery.isEmailValid
const isRecoveryMailSentSelector = state => state.passwordRecovery.isRecoveryMailSent
const recoveryIsFetchingSelector = state => state.passwordRecovery.fetching
const isFetchingSelector = state => state.loginUser.fetching

const LoginClassic = (props) => {
  const assets = useSelector(assetSelector, shallowEqual)
  const user = useSelector(userSelector, shallowEqual)
  const isRecoverEmailValid = useSelector(isRecoverEmailValidSelector, shallowEqual)
  const isRecoveryMailSent = useSelector(isRecoveryMailSentSelector, shallowEqual)
  const recoveryIsFetching = useSelector(recoveryIsFetchingSelector, shallowEqual)
  const isFetching = useSelector(isFetchingSelector, shallowEqual)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [activationCode, setActivationCode] = useState('')
  const [isRecoverUserShown, setIsRecoverUserShown] = useState(false)
  const [isRecoverPasswordShown, setIsRecoverPasswordShown] = useState(false)
  const [recoverEmail, setRecoverEmail] = useState('')
  const [recoverUsername, setRecoverUsername] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const validator = useRef(new SimpleReactValidator({
    element: message => <div style={{ color: 'tomato' }}>{message}</div>,
    messages: {
      required: assets.GENERAL.VALIDATION.FIELD_REQUIRED
    }
  }))

  useEffect(() => {
    if (params.activationCode) {
      setActivationCode(params.activationCode)
    }
  }, [])

  useEffect(() => {
    if (user) {
      if (props.destination) {
        navigate(props.destination)
      } else {
        if (location && location.state && location.state.from) {
          navigate(location.state.from)
        } else {
          const route = window.localStorage.getItem('protected-route-history')
          if (route) {
            navigate(route)
            window.localStorage.removeItem('protected-route-history')
          } else {
            if(user.facets.includes('parent')) {
              navigate('/parent-dashboard')
            } else if (user.facets.includes('student')) {
              navigate('/student-dashboard')
            }
          }
        }
      }
    }
  }, [user])

  useEffect(() => {
    setUsername(props.username)
  }, [props.username])

  useEffect(() => {
    const usernameField = document.getElementById('username-login-field')
    const passwordField = document.getElementById('password-login-field')
    const loginButton = document.getElementById('login-button')
    usernameField.addEventListener('keyup', function (event) {
      if (event.key === 'Enter' && passwordField) {
        passwordField.focus()
      }
    })
    passwordField.addEventListener('keyup', function (event) {
      if (event.key === 'Enter' && loginButton) {
        loginButton.focus()
      }
    })
  }, [])

  const handleLogin = () => {
    dispatch(loginActions.login({ username: username.trim(), password, activationCode }, 0))
  }

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      if (username && password) {
        handleLogin()
      }
    }
  }

  const handleShowRecoverUsers = (evt) => {
    setIsRecoverUserShown(true)
  }

  const handleHideRecoverUsers = () => {
    dispatch(recoveryActions.resetRecovery())
    setIsRecoverUserShown(false)
    setRecoverEmail('')
  }

  const handleShowRecoverPassword = (evt) => {
    setIsRecoverPasswordShown(true)
  }

  const handleHideRecoverPassword = () => {
    setIsRecoverPasswordShown(false)
    setRecoverEmail('')
    setRecoverUsername('')
    dispatch(recoveryActions.resetRecovery())
  }

  const handleSendRecoverUsers = (evt) => {
    dispatch(recoveryActions.recoverUsers(recoverEmail))
  }

  const handleSendRecoverPassword = (evt) => {
    dispatch(recoveryActions.sendPasswordRecoveryToken(recoverUsername, recoverEmail))
  }

  return (
    <div className='login-classic-container' onKeyDown={!isFetching ? handleKeyDown : undefined}>
      <Card className='login-text-inputs-container'>
        <div style={{ margin: 0, fontWeight: 'bold', fontSize: '1.25em' }}>{assets.GENERAL.LOGIN}</div>

        <div className='login-input-container'>
          <input type='text' id='username-login-field' placeholder={assets.LOGIN.USERNAME_PLACEHOLDER} className='login-input' value={username} onChange={(e) => setUsername(e.target.value)} />
          <div className='login-input-icon'><i className='pi pi-user' /></div>
        </div>

        <div className='login-input-container'>
          <input type='password' id='password-login-field' placeholder={assets.LOGIN.PASSWORD_PLACEHOLDER} className='login-input' value={password} onChange={(e) => setPassword(e.target.value)} />
          <div className='login-input-icon'><i className='pi pi-lock' /></div>
        </div>

        <Button id='login-button' tabIndex='0' icon='pi pi-sign-in' label={assets.LOGIN.LOGIN} className='action-button' onClick={() => handleLogin()} data-test-id='login-button' disabled={isFetching} />

        <div className='login-links-container'>

          <div className='link'>
            <span href='about:blank' onClick={() => handleShowRecoverUsers()}>{assets.LOGIN.RECOVER_USERS}</span>
          </div>

          <div className='link'>
            <span href='about:blank' onClick={() => handleShowRecoverPassword()}>{assets.LOGIN.RECOVER_PASSWORD}</span>
          </div>

        </div>

      </Card>

      <div className='recover-user-dialog-container'>
        <Dialog
          header={assets.LOGIN.RECOVER_USERS} visible={isRecoverUserShown} className='p-col-10 p-lg-4 p-md-8 p-sm-10' onHide={() => handleHideRecoverUsers()}
          contentStyle={{ overflowY: 'auto' }}
        >
          {!isRecoverEmailValid &&
            <div className='content-container'>
              <h4>{assets.LOGIN.RECOVER_USERS_EXPLANATION}</h4>

              <div className='login-input-container' style={{ marginBottom: '0.5em' }}>
                <input type='text' className='login-input' onChange={(e) => setRecoverEmail(e.target.value)} name='recoverEmail' placeholder={assets.REGISTER.GENERAL.USER_EMAIL} onBlur={validator.current.showMessageFor('userRecoveryEmail')} />
                <div className='login-input-icon'><i className='pi pi-envelope' /></div>
              </div>

              {validator.current.message('userRecoveryEmail', recoverEmail, 'required')}
              <label style={{ color: isRecoverEmailValid === false ? '#f44336' : '' }} className={isRecoverEmailValid === false ? '' : 'hidden'}>{assets.LOGIN.USER_NOT_FOUND_FOR_EMAIL}</label>
              <Button disabled={!recoverEmail || recoveryIsFetching} onClick={() => handleSendRecoverUsers()} label={assets.LOGIN.RECOVER_SEND} className='p-button-custom-primary p-mt-4' />
            </div>}
          {isRecoverEmailValid &&
            <h4>{assets.LOGIN.USER_RECOVERY_EMAIL_SENT}</h4>}
        </Dialog>
      </div>

      <div className='recover-user-dialog-container'>
        <Dialog
          header={assets.LOGIN.RECOVER_PASSWORD} visible={isRecoverPasswordShown} className='p-col-10 p-lg-4 p-md-8 p-sm-10' onHide={() => handleHideRecoverPassword()}
          contentStyle={{ overflowY: 'auto' }}
        >
          {!isRecoveryMailSent &&
            <div className='content-container'>
              <h4>{assets.LOGIN.RECOVER_PASSWORD_EXPLANATION}</h4>

              <div className='login-input-container' style={{ marginTop: '1em', marginBottom: '0.5em' }}>
                <input type='text' className='login-input' onChange={(e) => setRecoverEmail(e.target.value)} name='recoverEmail' placeholder={assets.REGISTER.GENERAL.USER_EMAIL} onBlur={validator.current.showMessageFor('userRecoveryEmail')} />
                <div className='login-input-icon'><i className='pi pi-envelope' /></div>
              </div>
              {validator.current.message('userRecoveryEmail', recoverEmail, 'required')}

              <div className='login-input-container' style={{ marginTop: '1em', marginBottom: '0.5em' }}>
                <input type='text' className='login-input' onChange={(e) => setRecoverUsername(e.target.value)} name='recoverUsername' placeholder={assets.LOGIN.USERNAME_PLACEHOLDER} onBlur={validator.current.showMessageFor('userRecoveryUsername')} />
                <div className='login-input-icon'><i className='pi pi-user' /></div>
              </div>

              {validator.current.message('userRecoveryUsername', recoverUsername, 'required')}
              <h5>{assets.LOGIN.PASSWORD_NO_MATCH_EXPLANATION}</h5>
              <Button disabled={!recoverEmail || !recoverUsername || recoveryIsFetching} onClick={() => handleSendRecoverPassword()} label={assets.LOGIN.RECOVER_SEND} className='p-button-custom-primary' />
            </div>}
          {isRecoveryMailSent &&
            <div>
              <h4>{assets.LOGIN.PASSWORD_RECOVERY_EMAIL_SENT}</h4>
              <h5>{assets.LOGIN.PASSWORD_NO_MATCH_EXPLANATION}</h5>
            </div>}
        </Dialog>
      </div>
    </div>
  )
}

export default LoginClassic
