import { networkErrorActions } from 'actions'

const networkErrorDispatchMiddleware = store => next => action => {
  if (action.type.endsWith('_REJECTED')) {
    if (action.payload.data) {
      return next(action)
    } else {
      if (action?.payload?.response?.data) {
        return next(action)
      } else {
        store.dispatch(networkErrorActions.setNetworkError())
      }
    }
  } else {
    return next(action)
  }
}

export default {
  networkErrorDispatchMiddleware
}
