/**
 * @module licensedSubjectsReducer
 * @description state manipulation for a licensed subject
 * @category reducers
 */
const INITIAL_STATE = {
  licensedSubjects: [],
  licensedSubjectsCount: 0,
  error: null,
  fetching: false,
  fetched: false
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_LICENSED_SUBJECTS_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'GET_LICENSED_SUBJECTS_FULFILLED':
      return { ...state, licensedSubjects: action.payload.data.licensedSubjects, licensedSubjectsCount: action.payload.data.licensedSubjectsCount, error: null, fetching: false, fetched: true }
    case 'GET_LICENSED_SUBJECTS_REJECTED':
      return { ...state, error: action.payload.response.data, fetching: false, fetched: false }
    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}
