
import './Cam.css'
import React, { useState, useRef } from 'react'
import Camera from '../Camera'
import IconCycle from 'assets/icons/icon-cycle.svg'
import IconGallerySend from 'assets/icons/icon-gallery-send.svg'
import { useParams } from 'react-router-dom'
import useQuery from 'hooks/useQuery'

function Cam(props) {
  const { onPhotoCapture, onDone } = props
  const { trophyroadId, trophyroaditemId } = useParams()
  const query = useQuery()
  const knowledgeMap = query.get("knowledgeMap");
  const eventType = query.get("eventType");
  const cameraRef = useRef(null)
  const [facingMode, setFacingMode] = useState('environment')
  const [error, setError] = useState(null)

  const onTakePhoto = () => {
    const videoElement = cameraRef.current.querySelector('video');

    const videoWidth = videoElement.videoWidth;
    const videoHeight = videoElement.videoHeight;

    const displayWidth = videoElement.offsetWidth;
    const displayHeight = videoElement.offsetHeight;

    const videoAspectRatio = videoWidth / videoHeight;
    const displayAspectRatio = displayWidth / displayHeight;

    const outputHeight = 1080;
    const outputWidth = Math.round(outputHeight * displayAspectRatio);

    // cropping parameters to match displayed content
    let sx, sy, sWidth, sHeight;

    if (displayAspectRatio > videoAspectRatio) {
        // crop top and bottom
        sWidth = videoWidth;
        sHeight = videoWidth / displayAspectRatio;
        sx = 0;
        sy = (videoHeight - sHeight) / 2;
    } else {
        // crop sides
        sWidth = videoHeight * displayAspectRatio;
        sHeight = videoHeight;
        sx = (videoWidth - sWidth) / 2;
        sy = 0;
    }

    const canvas = document.createElement('canvas');
    canvas.width = outputWidth;
    canvas.height = outputHeight;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(videoElement, sx, sy, sWidth, sHeight, 0, 0, outputWidth, outputHeight);

    const photo = canvas.toDataURL('image/jpeg', 1);

    const flaps = document.querySelectorAll('.flap');
    flaps.forEach((flap) => {
        flap.style.animation = 'click 0.9s cubic-bezier(0.5, 0, 0.5, 1) 0.1s';
        flap.onanimationend = () => {
            flap.style.animation = '';
        };
    });

    onPhotoCapture(photo, { trophyroadId, trophyroaditemId, knowledgeMap, eventType });
};

  const onSwitchCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'environment' ? 'user' : 'environment'))
  }

  return (
    <div className='cam-container'>
      <div className='cam' ref={cameraRef}>
        <Camera facingMode={facingMode} height='85vh' />
        {error && <div className="error">{error.message}</div>}
        
        <div className="shutter">
          <div className="flap" id="f0"></div>
          <div className="flap" id="f1"></div>
          <div className="flap" id="f2"></div>
          <div className="flap" id="f3"></div>
          <div className="flap" id="f4"></div>
          <div className="flap" id="f5"></div>
        </div>
      </div>
      <div className='cam-controls'>
        <button className='cam-switch-btn' onClick={onSwitchCamera}>
          <img src={IconCycle} alt='Swap camera' className='icon' />
        </button>
        <button onClick={onTakePhoto} className='cam-take-btn'> </button>
        <button onClick={() => onDone({ trophyroadId, trophyroaditemId, knowledgeMap, eventType })} className='cam-back-btn'>
          <img src={IconGallerySend} alt='Gallery' className='icon' />
        </button>
      </div>
    </div>
  )
}

export default Cam