/* global localStorage */
export const loadUserState = (username) => {
  try {
    if (!username) {
      return undefined
    }
    const serializedState = localStorage.getItem(username)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

export const saveUserState = (username, state) => {
  if (username) {
    try {
      const serializedState = JSON.stringify({
        infoGrowl: state.infoGrowl,
        sidebar: state.sidebar
      })
      localStorage.setItem(username, serializedState)
    } catch (e) {
      console.warn(e)
    }
  }
}
