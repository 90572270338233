import './Statistics.css'
import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { subscriptionActions } from '../../actions'

import NotificationArea from 'components/NotificationArea'
import useAssets from 'hooks/useAssets'
import MonthlyPageChart from './MonthlyPageChart'

const userSelector = (state) => state.loginUser.user
const subscriptionIdSelector = (state) => state.subscription.subscriptionId
const subscriptionStatusSelector = (state) => state.subscription.status
const subscriptionPageStatsSelector = (state) => state.subscription.subscriptionPageStats
const montlyPagesCountsSelector = (state) => state.statistics.montlyPagesCounts

const Statistics = () => {
  const assets = useAssets()
  const user = useSelector(userSelector, shallowEqual)
  const subscriptionId = useSelector(subscriptionIdSelector, shallowEqual)
  const subscriptionStatus = useSelector(subscriptionStatusSelector, shallowEqual)
  const subscriptionPageStats = useSelector(subscriptionPageStatsSelector, shallowEqual)
  const montlyPagesCounts = useSelector(montlyPagesCountsSelector, shallowEqual)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(subscriptionActions.getActiveSubscription())
    dispatch(subscriptionActions.getStudentStatistics())
  }, [user])

  return (
    <div className='statistics-container'>
      <NotificationArea defaultText={assets.TESTRAX_V2.STATISTICS.HEADER.toUpperCase()} />
      <div className='statistics-content'>
        <div className="statistics-text">
          {subscriptionPageStats !== undefined && subscriptionPageStats !== null && (
            <p>{assets.TESTRAX_V2.STATISTICS.TOTAL_PAGES}: {subscriptionPageStats}</p>
          )}
        </div>
        {montlyPagesCounts && (
          <MonthlyPageChart monthlyPageCounts={montlyPagesCounts} />
        )}
      </div>
    </div>
  )
}

export default Statistics
