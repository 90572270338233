/**
 * @module knowledgeMapMetricsActions
 * @description actions for knowledge map metrics
 * @category actions
 */
import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const GET_STUDENT_SWOT = 'GET_STUDENT_SWOT'
export const GET_STUDENT_KNOWLEDGE_MAP_METRICS = 'GET_STUDENT_KNOWLEDGE_MAP_METRICS'

export function getStudentSwot (studentId) {
  const token = store.getState().loginUser.user.token

  return {
    type: GET_STUDENT_SWOT,
    payload: axios.get(`${SERVER}/parent-api/students/${studentId}/swot`, {
      headers: {
        Authorization: token
      }
    })
  }
}

export function getStudentKnowledgeMapMetrics (studentId) {
  const token = store.getState().loginUser.user.token

  return {
    type: GET_STUDENT_KNOWLEDGE_MAP_METRICS,
    payload: axios.get(`${SERVER}/parent-api/students/${studentId}/knowledge-maps`, {
      headers: {
        Authorization: token
      }
    })
  }
}