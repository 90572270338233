/**
 * @module activityReducer
 * @description state manipulation for activity elements
 * @category reducers
 */
const INITIAL_STATE = {
  activityFeedItems: [],
  count: 0,
  error: null,
  fetching: false,
  fetched: false
  }
  
  export default function reducer (state = INITIAL_STATE, action) {
    switch (action.type) {
      case 'RESET_ACTIVITY':
        return { ...state, activityFeedItems: [], count: 0, error: null, fetching: false, fetched: false }
      case 'GET_ACTIVITY_PENDING':
        return { ...state, error: null, fetching: true, fetched: false }
      case 'GET_ACTIVITY_FULFILLED':
        return { ...state, activityFeedItems: concatActivity(state.activityFeedItems, action.payload.data.activityFeedItems), count: action.payload.data.count, error: null, fetching: false, fetched: true }
      case 'GET_ACTIVITY_REJECTED':
        return { ...state, error: action.payload.response.data, fetching: false, fetched: false }
      case 'RESET':
        return INITIAL_STATE
      default:
        break
    }
    return state
  }

const concatActivity = (oldActivity, newActivity) => {
  const activity = [...oldActivity]
  activity.push(...newActivity)
  return activity
}
