/**
 * @module testScanReducer
 * @description state manipulation for test scan
 * @category reducers
 */
const INITIAL_STATE = {
  testScans: [],
  testScanCount: 0,
  error: null,
  fetching: false,
  fetched: false
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SUBMIT_PICTURES_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'GET_TEST_SCANS_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'SUBMIT_PICTURES_FULFILLED':
      return { ...state, error: null, fetched: true, fetching: false }
    case 'GET_TEST_SCANS_FULFILLED':
      return { ...state, testScans: action.payload.data.testScans, testScanCount: action.payload.data.testScanCount, error: null, fetching: false, fetched: true }
    case 'SUBMIT_PICTURES_REJECTED':
      return { ...state, error: action.payload.response.data, fetching: false, fetched: false }
    case 'GET_TEST_SCANS_REJECTED':
      return { ...state, error: action.payload.response.data, fetching: false, fetched: false }
    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}
