import { combineReducers } from 'redux'

import activity from './activity-reducer'
import gallery from './gallery-reducer'
import helpOverlay from './help-overlay-reducer'
import lastLoggedUser from './last-logged-user-reducer'
import language from './language-reducer'
import licensedSubjects from './licensed-subjects-reducer'
import loginUser from './login-user-reducer'
import knowledgeMapMetrics from './knowledge-map-metrics-reducer'
import networkError from './network-error-reducer'
import ownedSubscriptions from './owned-subscriptions-reducer'
import paymentFlow from './payment-flow-reducer'
import passwordRecovery from './password-recovery-reducer'
import sidebar from './sidebar-reducer'
import statistics from './statistics-reducer'
import subscription from './subscription-reducer'
import submissionPictures from './submission-pictures-reducer'
import submissionGrading from './submission-grading-reducer'
import test from './test-reducer'
import testDefinitionKnowledgeMaps from './test-definition-knowledge-maps-reducer'
import testingEvents from './testing-events-reducer'
import testScan from './test-scan-reducer'
import toast from './toast-reducer'
import trophyRoad from './trophy-road-reducer'
import trophyRoadItemActivity from './trophy-road-item-activity-reducer'
import tokenValidity from './token-reducer'
import user from './user-reducer'

export default combineReducers({
  activity,
  gallery,
  helpOverlay,
  lastLoggedUser,
  language,
  licensedSubjects,
  loginUser,
  knowledgeMapMetrics,
  networkError,
  ownedSubscriptions,
  paymentFlow,
  passwordRecovery,
  sidebar,
  statistics,
  subscription,
  submissionPictures,
  submissionGrading,
  test,
  testDefinitionKnowledgeMaps,
  testingEvents,
  testScan,
  toast,
  trophyRoad,
  trophyRoadItemActivity,
  tokenValidity,
  user
})
