/**
 * @module lastLoggedUserReducer
 * @description state manipulation the last logged user
 * @category reducers
 */
const INITIAL_STATE = {
  lastLoggedUser: null,
  error: null,
  fetching: false,
  fetched: false
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_LAST_LOGGED_USER_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'GET_LAST_LOGGED_USER_FULFILLED':
      return { ...state, lastLoggedUser: action.payload.data.user, error: null, fetched: true, fetching: false }
    case 'GET_LAST_LOGGED_USER_REJECTED':
      return { ...state, lastLoggedUser: null, error: action.payload.response, fetching: false, fetched: false }
    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}
