import React,{ useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useUser from '../../hooks/useUser'

import { firebaseNotifActions } from '../../actions'

const RegisterFCM = () => {
  const user = useUser()
  const dispatch = useDispatch()

  const push = PushNotification.init({
    android: {
    },
    browser: {
        pushServiceURL: 'http://push.api.phonegap.com/v1/push'
    },
    ios: {
        alert: "true",
        badge: "true",
        sound: "true"
    }
  })

  useEffect(() => {
    const platform = window.localStorage.getItem('PLATFORM')

    if (platform === 'mobile') {
      if (user && !user.FCMToken) {
        push.on('registration', (data) => {
            console.warn('REGISTRATION')  
            if (data.registrationId !== user.FCMToken) {
              dispatch(firebaseNotifActions.registerFCMToken(user._id, data.registrationId))
            }
        })
      }
    } else {
      dispatch(firebaseNotifActions.registerFCMToken(user._id, null))
    }
  }, [user])

  push.on('notification', (data) => {
      console.warn(data)

      if (data.additionalData.foreground === true) { 
          console.warn('FOREGROUND NOTIFICATION')
      }
      if (data.additionalData.foreground === false) {
          if (data.additionalData.coldstart === true) {
              console.warn('COLDSTART NOTIFICATION')
          }
          if (data.additionalData.coldstart === false) {
              console.warn('BACKGROUND NOTIFICATION')
          }
      }
      console.warn('NOTIFICATION')
      console.warn(data.additionalData)
  })
  
  push.on('error', (e) => {
      console.warn('ERROR') 
      console.log(e)
  })

  return (
    <></>
  )
}

export default RegisterFCM
