import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './components/App'
import 'primereact/resources/themes/md-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'index.css'
// import * as serviceWorker from './serviceWorker'
import store from './stores/store'
import { Provider } from 'react-redux'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
// import swConfig from 'swConfig'

const container = document.getElementById('root')
const root = createRoot(container)

const renderReactDom = () => {
  root.render(<Provider store={store}><ErrorBoundary><App /></ErrorBoundary></Provider>)
}

window.onload = () => {   
  if (window.cordova) {  
    // cordova setup 
    document.addEventListener('deviceready', () => {
      const permissions = window.cordova.plugins.permissions
      const list = [
        permissions.CAMERA,
        permissions.READ_MEDIA_IMAGES,
        permissions.INTERNET
      ]

      // monkey patch window.open to use InAppBrowser
      window.open = window.cordova.InAppBrowser.open
      
      if (window.cordova) {
        if (!window.cordova?.platformId || window.cordova?.platformId === 'browser') {
          window.localStorage.setItem('PLATFORM', 'browser')
        } else {
          window.localStorage.setItem('PLATFORM', 'mobile')
        }
      } else {
        window.localStorage.setItem('PLATFORM', 'browser')
      }

      if (!window.cordova?.platformId || window.cordova?.platformId === 'browser') {
        window.localStorage.setItem('PLATFORM', 'browser')
      } else {
        window.localStorage.setItem('PLATFORM', 'mobile')
      }

      // get permissions for camera, read media images, and internet
      list.forEach((permission) => {
        permissions.checkPermission(permission, (result) => {
          if (!result.hasPermission) {
            permissions.requestPermission(permission, (result) => {
              // No reason to debug
            }, (err) => {
              console.warn('requestPermission error')
              console.warn(err)
            })
          }
        }, (err) => {
          console.warn('checkPermission error')
          console.warn(err)
        })
      })

      const push = PushNotification.init({
        android: {
        },
        browser: {
            pushServiceURL: 'http://push.api.phonegap.com/v1/push'
        },
        ios: {
            alert: "true",
            badge: "true",
            sound: "true"
        }
    });
    
      push.on('registration', (data) => {
          console.warn('REGISTRATION')  
          console.log(data)
      });
      
      push.on('notification', (data) => {
          console.warn(data)
  
          if (data.additionalData.foreground === true) { 
              console.warn('FOREGROUND NOTIFICATION')
          }
          if (data.additionalData.foreground === false) {
              if (data.additionalData.coldstart === true) {
                  console.warn('COLDSTART NOTIFICATION')
              }
              if (data.additionalData.coldstart === false) {
                  console.warn('BACKGROUND NOTIFICATION')
              }
          }
          console.warn('NOTIFICATION')
          console.warn(data.additionalData)
      });
      
      push.on('error', (e) => {
          console.warn('ERROR') 
          console.log(e)
      });

      
      renderReactDom()
    }, false)
  } else {
    if (window.cordova) {
      if (!window.cordova?.platformId || window.cordova?.platformId === 'browser') {
        window.localStorage.setItem('PLATFORM', 'browser')
      } else {
        window.localStorage.setItem('PLATFORM', 'mobile')
      }
    } else {
      window.localStorage.setItem('PLATFORM', 'browser')
    }
    console.warn('cordova is not defined here')
    renderReactDom()
  }
}


