import './StudentTestingEvent.css'
import React, { useRef } from 'react'
import { SplitButton } from 'primereact/splitbutton'
import { useNavigate, useParams } from 'react-router-dom'
import useAssets from 'hooks/useAssets'
import dayjs from 'dayjs'
import { shallowEqual, useSelector } from 'react-redux'

const subscriptionIdSelector = state => state.subscription.subscriptionId
const trophyRoadSelector = state => state.trophyRoad.trophyRoadId

const StudentTestingEvent = ({ item, background, itemNumber }) => {
  const navigate = useNavigate()
  const trophyRoadId = useSelector(trophyRoadSelector, shallowEqual)
  const assets = useAssets()
  const subscriptionId = useSelector(subscriptionIdSelector, shallowEqual)
  const splitButtonRef = useRef(null)

  const menuItems = () => {
    switch (item.eventType) {
      case 'TEST_ASSIGNED':
        return [
          {
            label: assets.GENERAL.VIEW,
            icon: 'pi pi-eye',
            command: () => {
              if (trophyRoadId && item.trophyRoadItemId) {
                navigate(`/student-dashboard/trophyroads/${trophyRoadId}/trophyroaditems/${item.trophyRoadItemId}/tests/${item.testId}/content`, { state: { item } })
              }
            }
          },
          // {
          //   label: assets.TESTRAX_V2.SEND_ANSWERS,
          //   icon: 'pi pi-send',
          //   command: () => {
          //     if (trophyroadId && trophyroaditemId) {
          //       navigate(
          //         `/student-dashboard/trophyroads/${trophyroadId}/trophyroaditems/${trophyroaditemId}/camera?knowledgeMap=${item.knowledgeMapId}&eventType=${item.eventType}`,
          //         { state: { eventType: item.eventType, testId: item.testId } }
          //       )
          //     } else {
          //       navigate(`/student-dashboard/camera?knowledgeMap=${item.knowledgeMapId}&eventType=${item.eventType}`)
          //     }
          //   }
          // }
        ]
      case 'SUBMISSION_UPLOADED':
        return [
          {
            label: assets.GENERAL.VIEW,
            icon: 'pi pi-eye',
            command: () => {
              navigate(`/student-dashboard/submissions/${item.submission}`, { state: { item } })
            }
          }
        ]
      case 'SUBMISSION_GRADED':
        return [
          {
            label: assets.GENERAL.REVIEW,
            icon: 'pi pi-file',
            command: () => {
              navigate(`/student-dashboard/submission-gradings/${item.submissionGrading}`, {
                state: { item }
              })
            }
          }
        ]
      case 'TROPHY_ROAD_ITEM_COMPLETE':
        return [
          {
            label: assets.TESTRAX_V2.TROPHY_ROAD,
            icon: 'pi pi-chart-line',
            command: () => {
              // navigate(
              //   `/student-dashboard/subscriptions/${subscriptionId}/trophyroads/${item.trophyRoad}/completedtrophyroaditems/${item.trophyRoadItem}`,
              //   { state: { item } }
              // )
            }
          }
        ]
      default:
        return []
    }
  }

  const items = menuItems()
  const isDisabled = items.length === 0

  const handleContainerClick = (event) => {
    if (event.isTrusted === false) {
      return
    }

    if (item.eventType === 'TROPHY_ROAD_ITEM_COMPLETE') {
      return
    }
    
    if (items.length === 1) {
      items[0].command()
    } else if (splitButtonRef.current && typeof splitButtonRef.current.show === 'function') {
      const dropdownButton = splitButtonRef.current.getElement().querySelector(
        '.p-splitbutton-menubutton.p-button.p-component.p-button-icon-only'
      )
      if (dropdownButton) {
        const syntheticEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
        dropdownButton.dispatchEvent(syntheticEvent)
      }
    }
  }

  const activityTemplate = (item) => {
    return (
      <div className='activity-item-title'>
        <span className='event-description'>
          {assets.TESTRAX_V2.EVENT_TYPE[item.eventType]}
        </span>
        {
          item.eventType === 'TEST_ASSIGNED' &&
            <span> "{item.testTitle}" {assets.TESTRAX_V2.ALLOCATED_FOR}</span>
        }
        <span className='description-separator'>{assets.TESTRAX_V2.FOR_OBJECTIVE}</span>
        <span>"{item.trophyRoadItemDescription}"</span>
      </div>
    )
  }

  return (
    <div className='student-testing-event-container'>
      <div className='line-marker' style={{ backgroundColor: background }} />
      <div className='item-number-column'>
        <span className='item-number'>#{itemNumber}</span>
      </div>
      <div className='activity-content' onClick={handleContainerClick}>
        {activityTemplate(item)}
        <div className='timestamp'>
          {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
        </div>
      </div>
      <div className='activity-controls'>
        {!isDisabled && items.length > 1 && (
          <SplitButton
            ref={splitButtonRef}
            icon='pi pi-ellipsis-v'
            model={items}
            className='p-button-secondary'
            dropdownIcon='pi pi-ellipsis-v'
          />
        )}
      </div>
    </div>
  )
}

export default StudentTestingEvent
