import React, { useEffect } from 'react'
import { useLocation, useParams} from 'react-router-dom'
import NotificationArea from 'components/NotificationArea'
import { trophyRoadActions } from '../../actions'
import './TrophyRoadItemCompleted.css'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useAssets from 'hooks/useAssets'

const ownedSubscriptionsSelector = state => state.ownedSubscriptions.subscriptions

const TrophyRoadItemCompleted = () => {
  const { subscriptionId, trophyroaditemId } = useParams()
  const dispatch = useDispatch()
  const assets = useAssets()

  const { trophyRoadItems, fetching, error } = useSelector(state => state.trophyRoad)
  const ownedSubscriptions = useSelector(ownedSubscriptionsSelector , shallowEqual)
  const selectedSubscription = ownedSubscriptions.find(sub => sub._id === subscriptionId)
  const studentFullName = selectedSubscription?.forUser[0]?.fullName

  const trophyRoadItem = trophyRoadItems.find(item => item._id === trophyroaditemId)

  useEffect(() => {
    dispatch(trophyRoadActions.getTrophyRoad(subscriptionId))
  }, [subscriptionId])

  if (!trophyRoadItem) {
    return <div>{assets.TESTRAX_V2.NO_TROPHY_ROAD_ITEM_FOUND}</div>
  }

  return (
    <div className="trophy-road-completed">
      <NotificationArea defaultText={studentFullName?.toUpperCase() || assets.TESTRAX_V2.EVENT_TYPE.TROPHY_ROAD_ITEM_COMPLETE.toUpperCase()} />
      <div className="trophy-road-completed-container">
        <div className="completed-header">
          <h1>{assets.TESTRAX_V2.EVENT_TYPE.TROPHY_ROAD_ITEM_COMPLETE}</h1>
          <span role="img" aria-label="trophy" className="trophy-emoji">🏆</span>
        </div>
        <div className="trophy-road-item-container">
          <div className="trophy-road-item">
            <div className='trophy-road-header'>
              <div className='trophy-road-description'>
                {trophyRoadItem.description} 
              </div>
            </div>
            <div className='trophy-road-image'>
              {trophyRoadItem.link ? (
                <div className='trophy-road-image-container'>
                  <img src={trophyRoadItem.link} alt='Trophy' />
                </div>
              ) : (
                <div className='trophy-road-placeholder'>
                  <i className='pi pi-image plus-icon' />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrophyRoadItemCompleted
