
import React, { useEffect } from 'react'

const CatchDotInUrl = () => {
  const { origin } = window.location

  useEffect(() => {
    if (origin.endsWith('.')) {
      const newUrl = `${origin.slice(0, -1)}/${window.location.hash}`
      window.location.replace(newUrl)
    }
  }, [origin])

  return (
    <>
    </>
  )
}

export default CatchDotInUrl
