import html2canvas from 'html2canvas'
import QRCode from 'qrcode'
import jsPDF from 'jspdf'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import robotoFontNormal from '../assets/fonts/pdf-fonts/Roboto/Roboto-normal'
import robotoFontBold from '../assets/fonts/pdf-fonts/Roboto/Roboto-bold'
import logo from '../assets/icons/testrax_v1.jpg'

const renderKaTeX = (node) => {
  const inlineMaths = node.querySelectorAll('.latex-inline')
  inlineMaths.forEach((inlineMath) => {
  const math = inlineMath.textContent.replace(/\\\\/g, '\\')
  try {
      inlineMath.innerHTML = katex.renderToString(math, { throwOnError: false })
  } catch {
      inlineMath.innerHTML = `<span style="color:red">[invalid inline math]</span>`
  }
  })

  const blockMaths = node.querySelectorAll('.latex-block')
  blockMaths.forEach((blockMath) => {
    const math = blockMath.textContent
    try {
      blockMath.innerHTML = katex.renderToString(math, { throwOnError: false, displayMode: true })
    } catch {
      blockMath.innerHTML = `<span style="color:red">[invalid block math]</span>`
    }
  })
}

const createPDFRenderContainer = () => {
  const container = document.createElement('div')
  container.style.position = 'absolute'
  container.style.top = '-9999px'
  container.style.width = '1000px'
  container.className = 'pdf-render-container'
  document.body.appendChild(container)
  return container
}

const createExerciseElement = (item, index) => {
  const exerciseDiv = document.createElement('div')
  exerciseDiv.style.fontFamily = 'Roboto, sans-serif'

  exerciseDiv.innerHTML = `
    <div class="exercise">
      <h3 style="font-size: 24px;">Exercițiul ${index + 1}</h3>
      <div class="content-renderer">${item.content}</div>
    </div>
  `

  const styleTag = document.createElement('style')
  styleTag.innerHTML = `
    .pdf-render-container .content-renderer {
      font-size: 20px !important;
      line-height: 1.6;
      max-width: 920px;
    }
    .pdf-render-container .content-renderer .katex {
      font-size: 1.4em !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  `

  exerciseDiv.appendChild(styleTag)
  return exerciseDiv
}

export const generateTestPDF = async (testContent) => {
    const { title, content, description, knowledgeMap } = testContent
    const doc = new jsPDF()

    doc.addFileToVFS('Roboto.ttf', robotoFontNormal)
    doc.addFileToVFS('Roboto-bold.ttf', robotoFontBold)
    doc.addFont('Roboto.ttf', 'Roboto', 'normal')
    doc.addFont('Roboto-bold.ttf', 'Roboto', 'bold')

    const infoLink = `${window.location.hostname}/#/student-dashboard/tests/${testContent._id}/content`
    const qrCodeDataURL = await new Promise((resolve, reject) => {
        QRCode.toDataURL(infoLink, { width: 250 }, (err, url) => {
            if (err) reject(err)
            resolve(url)
        })
    })

    const topMargin = 40
    let yPosition = topMargin

    const pageWidth = doc.internal.pageSize.getWidth()
    const logoX = pageWidth - 100
    const imageWidth = 95

    doc.addImage(logo, 'JPG', pageWidth / 2 - imageWidth / 2, 5, imageWidth, 0)
    yPosition += 4

    doc.setFont('Roboto', 'bold')
    doc.setFontSize(18)
    doc.text(title, 105, yPosition, { align: 'center' })

    yPosition += 10

    doc.addImage(qrCodeDataURL, 'PNG', 16, yPosition, 40, 40)

    yPosition += 8


    doc.setFontSize(12)
    doc.setFont('Roboto', 'normal')
    doc.text(`Obiectiv: Test inițial`, 61, yPosition)
    yPosition += 5
    doc.text(`Materie: ${knowledgeMap?.description || 'N/A'}`, 61, yPosition)
    yPosition += 9


    doc.text('Nume: __________________________', 61, yPosition)
    yPosition += 6
    doc.text('Prenume: _______________________', 61, yPosition)
    yPosition += 6
    doc.text('Clasă: _________________________', 61, yPosition)
    yPosition += 10

    doc.setFont('Roboto', 'normal')
    doc.text('Indicații:', 20, yPosition)
    yPosition += 8
    doc.text('Rezolvă exercițiile pe hârtie și fotografiază soluțiile.', 20, yPosition)
    yPosition += 8
    doc.text('Încarcă soluțiile folosind aplicația TESTRAX.', 20, yPosition)
    yPosition += 8

    const container = createPDFRenderContainer()


  for (const [index, item] of content.entries()) {
    const exerciseDiv = createExerciseElement(item, index)
    container.appendChild(exerciseDiv)

    renderKaTeX(exerciseDiv)

    const canvas = await html2canvas(exerciseDiv, { scale: 1 })
    const imgData = canvas.toDataURL('image/png')

    const imgProps = doc.getImageProperties(imgData)
    const pdfWidth = 185
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

    const rezolvareHeight = 10 + 70
    const fullExerciseHeight = pdfHeight + rezolvareHeight + 10

    const pageHeight = doc.internal.pageSize.getHeight()
    const remainingSpace = pageHeight - yPosition

    if (remainingSpace < fullExerciseHeight) {
      doc.addPage()
      yPosition = topMargin
    }

    doc.addImage(imgData, 'PNG', 20, yPosition, pdfWidth, pdfHeight)
    yPosition += pdfHeight + 10

    doc.setFont('Roboto', 'normal')
    doc.text('Rezolvare:', 20, yPosition)
    doc.rect(20, yPosition + 8, 170, 70)
    yPosition += rezolvareHeight + 10
  }

  document.body.removeChild(container)

  doc.save(`${testContent.title}.pdf`)
}
