/**
 * @module stateActions
 * @description actions for state reset under various circumstances
 * @category actions
 */
import store from 'stores/store'

export const RESET = 'RESET'
export const RESET_TOKEN_STATE = 'RESET_TOKEN_STATE'
export const RESET_CHAT_MESSAGES = 'RESET_CHAT_MESSAGES'
export const CANCEL_AUTO_FORWARD = 'CANCEL_AUTO_FORWARD'
export const RESET_SELECT_FLOW_RESULT = 'RESET_SELECT_FLOW_RESULT'

/**
 * @description Trigger state reset in reducers
 * @action resetState
 * @dispatches RESET
 */
export function resetState () {
// export function resetState(questionId, answerId){
  return {
    type: RESET,
    payload: {}
  }
}

/**
* @description Trigger state reset in reducers
* @action resetTokenState
* @dispatches RESET_TOKEN_STATE
*/
export function resetTokenState () {
// export function resetState(questionId, answerId){
  return {
    type: RESET_TOKEN_STATE,
    payload: {}
  }
}

/**
 * @description Trigger partial state reset (reset the selected flow)
 * @action resetSelectFlowResult
 * @dispatches RESET_SELECT_FLOW_RESULT
 */
export function resetSelectFlowResult () {
  const token = store.getState()?.loginUser?.user?.token
  return {
    type: RESET_SELECT_FLOW_RESULT,
    payload: {
      token: token
    }
  }
}

/**
 * @description Trigger partial state reset
 * @action resetChatMessages
 * @dispatches RESET_CHAT_MESSAGES
 */
export function resetChatMessages () {
  return {
    type: RESET_CHAT_MESSAGES,
    payload: {}
  }
}

/**
 * @description Set shouldAutoForward to false manually
 * @action cancelAutoForward
 * @dispatches CANCEL_AUTO_FORWARD
 */
export function cancelAutoForward () {
  return {
    type: CANCEL_AUTO_FORWARD,
    payload: {}
  }
}
