/**
 * @module galleryActions
 * @description actions for gallery management
 * @category actions
 */

export const ADD_PICTURE = 'ADD_PICTURE'
export const REMOVE_PICTURE = 'REMOVE_PICTURE'
export const RESET_PICTURE_SEND_STATUS = 'RESET_PICTURE_SEND_STATUS'

/**
 * @description Add a picture to the gallery
 * @action addPicture
 * @dispatches ADD_PICTURE
 * @param {String} picture -a picture base64 encoded
 */
export function addPicture (picture) {
  return {
    type: ADD_PICTURE,
    payload: picture
  }
}

/**
 * @description Remove a picture from the gallery
 * @action removePicture
 * @dispatches REMOVE_PICTURE
 * @param {String} pictureIndex -a picture's index in the gallery picture array
 */
export function removePicture (pictureIndex) {
  return {
    type: REMOVE_PICTURE,
    payload: pictureIndex
  }
}

/**
 * @description Reset pictures send status
 * @action resetPicturesSendStatus
 * @dispatches RESET_PICTURE_SEND_STATUS
 */
export function resetPicturesSendStatus () {
  return {
    type: RESET_PICTURE_SEND_STATUS
  }
}
