const assets = {
  CLIENT_SIDE_ERRORS: {
    REQUIRED: 'Trebuie completat',
    POSITIVE_INTEGER: 'Trebuie sa fie un număr pozitiv'
  },
  CLAIM: {
    STUDENT_EMAIL: 'Adresa de mail înregistrată',
    ADD_OWN_STUDENT: 'Adăugare elev',
    STUDENT_ACCOUNT: 'Cont elev'
  },
  CREDIT: {
    EXISTING: 'Adaugă credit la un cont existent',
    NEW: 'Creează un cont nou'
  },
  DASHBOARD: {
    ADD_GROUP: 'Creează grupă',
    ADD_INSTANCE: 'Deschide o sesiune',
    CALENDAR: 'Programarea activităților',
    GROUPS: 'Grupe',
    NO_FLOW: 'Niciun flux selectat',
    NO_INSTANCE: 'Nicio sesiune deschisă',
    PATH_EDITOR: 'Editor conținut educațional',
    SELECTED_FLOW: 'Fluxul selectat',
    USERS: 'Utilizatori'
  },
  EVENT: {
    ACTIVE_PARTICIPANTS: 'Participanți activi',
    PARTICIPANTS: 'Participanți',
    ADD_EVENT: 'Activitate',
    ADD_NEW_EVENT: 'Ajouter un événement',
    ASSIGN_EVENT: 'Alocă activitate',
    APPROVE_EVENT: 'Aprobă activitatea',
    ONLINE_MEET: 'Conferință online?',
    CONFERENCE_LINK: 'Link conferință online',
    CONFIRM_EVENT: 'Confirmă',
    COPY_TOKEN_SUCCESS: 'Cod copiat',
    DELETE_INSTANCE: 'Șterge',
    DESCRIPTION: 'Descriere activitate',
    DURATION: 'Durata (în minute)',
    EVENT: 'Activitate',
    EDIT_EVENT_INSTANCE: 'Editare activitate',
    EDIT_EVENT_APPLY_ALL: 'Aplică schimbările la activitățile viitoare',
    EVENT_FREQUENCY_DAILY: 'Zi',
    EVENT_FREQUENCY_WEEKLY: 'Săp.',
    EVENT_FREQUENCY_MONTHLY: 'Luna',
    EVENT_CALENDAR: 'Calendrier des événements',
    EVENT_TYPE_COURSE: 'Curs/Lecţie',
    EVENT_TYPE_NONE: 'Altele',
    EVENT_TYPE_HACKATHON: 'Hackathon educaţional',
    EVENT_TYPE_GROUP: 'Cerc şcolar',
    EVENT_TYPE_TEST: 'Test',
    FEEDBACK: 'Motivație',
    GROUP: 'Grupă asociată',
    INVITED: 'Invitaţi',
    INVITE_TRAINERS: 'Invită',
    IS_PUBLIC: 'Activitate publică?',
    LAST_EVENTS: 'Ultimele activități',
    LOCATION: 'Locație',
    MANAGE_PARTICIPANTS: 'Participanți',
    NO_ACTIVE_PARTICIPANTS: 'Nu există participanți activi',
    PARTICIPANT_EXPIRY: 'Participanții pot expira',
    PARTICIPATE: 'Participă',
    PAYMENT_MESSAGE: 'Costul fluxului pentru această activitate este: ',
    POINTS: ' puncte',
    REJECTED: 'Respins',
    REQUESTED: 'Cerut',
    REPEATABLE: 'Repetabil?',
    SELECT_FLOW: 'Flux',
    SELECT_LOCATION: 'Selectați o locație',
    SELECT_TYPE: 'Tipul de eveniniment',
    SEND_INVITES: 'Trimite invitațiile',
    STATE: 'Stare activitate',
    START_DATE: 'Data și ora de început',
    STUDENT_NUMBER: 'Număr maxim de copii',
    TIMES_REPEATED: 'Număr de sesiuni',
    TOOLTIP_ADD_PARTICIPANTS: 'Adaugă participanți',
    TOOLTIP_ACCEPT_EVENT: 'Confirmă',
    TOOLTIP_CHOOSE_FLOW: 'Alocă lecție',
    TOOLTIP_SEE_TOKEN: 'Vezi codul',
    TOOLTIP_COPY_TOKEN: 'Copiază link-ul',
    TOOLTIP_EDIT_EVENT: 'Editează',
    TOOLTIP_DELETE_EVENT: 'Șterge',
    TRAINER_NUMBER: 'Număr de traineri',
    VALIDATE_ATTENDANCE: 'Validare prezență',
    NO_FLOW: 'Niciun flux',
    SHOW_CODE: 'Cod de acces',
    SHOW_CODE_SHORT: 'Cod',
    EVENT_NEEDS_DESCRIPTION: 'Activitatea trebuie să aibă o descriere!',
    EVENT_NEEDS_NR_REPEATS: 'Trebuie specificat numărul de întâlniri!',
    EVENT_NEEDS_PARTICIPANTS: 'Trebuie specificat un număr de participanti!',
    EVENT_NEEDS_TRAINERS: 'Trebuie sa existe minim 1 trainer!',
    EVENT_COMPLETE_NECESSARY_FIELDS: 'Vă rugăm să completați toate câmpurile obligatorii, cu valori valide!',
    EVENT_NEEDS_FLOW: 'Trebuie selectat un flux!',
    EVENT_NEEDS_LOCATION: 'Trebuie selectata o locație!'
  },
  FACETS: {
    PARENT: 'părinte',
    SELLER: 'coordonator',
    TEACHER: 'profesor',
    VOLUNTEER: 'voluntar'
  },
  FORM_TEMPLATE: {
    NAME: 'Nume formular',
    ELEMENT_LABEL: 'Etichetă',
    FILTER_PLACEHOLDER: 'Filtrează',
    DETAILS_TITLE: 'Detalii despre formular',
    FORM_PLACEHOLDER: 'Trage o componentă aici',
    FORM_SUBMISSION_VIEWER: 'Răspunsuri formulare',
    SEND: 'Trimite',
    TITLE: 'Titlu formular',
    NO_FORM_SUBMISSIONS: 'Nu există răspunsuri încă.'
  },
  GENERAL: {
    ACTIVATE: 'Activează',
    ASSIGN_EVENT: 'Alocă unei activități',
    ASSIGN_EVENT_SUCCESS: 'Alocare efectuată cu succes',
    ASSOCIATED_STUDENTS: 'Elevi asociați',
    ADD: 'Adaugă',
    ADD_CHILD: 'Adaugă un descendent',
    ADD_BLANK_FLOW: 'Adaugă șablon de lecție cu 10 pași',
    AVAILABLE: 'disponibile',
    BOT_NAME: 'Ioana',
    CANCEL: 'Anulează',
    CHANGE_CODE: 'Schimbă codul',
    CLAIM: 'Adaugă elev',
    CONFIRMED: 'Confirmat?',
    CONFIGURE_ACCOUNT: 'Configurare cont',
    CONFIRM: 'Confirmare',
    CONTINUE: 'Continuă',
    CORRECT_QUESTION: 'Corect!',
    CREATED_EVENTS: 'Activități create',
    CREATED_FLOWS: 'Lecții create',
    INCORRECT_QUESTION: 'Greșit!',
    THANKS: 'Mulțumesc',
    CONTINUE_TYPE_DONE: 'Scrie gata să treci mai departe',
    LETS_CONTINUE: 'Hai să continuăm.',
    COPY: 'Copiază',
    COPY_LINK: 'Copiază link-ul',
    CURRENT_FLOW: 'Lecţia curentă',
    CUT: 'Taie',
    DELETE: 'Șterge',
    DESCRIPTION: 'Descriere',
    DOWNLOAD: 'Descarcă',
    EDIT: 'Modifier',
    EXPAND: 'Expandează',
    EXPORT: 'Exportă',
    EXPORT_FLOW: 'Exportă lecția',
    EXPORT_PATH: 'Exportă traiectoria',
    FLOW: 'Fluxul',
    UNLOCK_FLOW_CONFIRMATION: 'Nu ai deblocat acest flow.\nDeblocarea costă 1 punct.',
    IMPORT: 'Importă',
    IMPORT_FLOW: 'Importă o lecție',
    IMPORT_PATH: 'Importă o traiectorie',
    IN: 'în',
    GREETING: 'Bună ziua',
    LOGOUT: 'Sortie',
    MESSAGE: 'Message',
    NO: 'Nu',
    NO_FLUX_COVERED: 'Nu există lecții parcurse cu acest cont',
    NEW_VERSION_AVAILABLE: 'Nextlab are o nouă versiune!',
    OR: 'Sau',
    PATH: 'Traiectoria',
    POINTS: 'puncte',
    PRINT: 'Tipărește',
    RELOAD: 'Reîncarcă aplicația',
    RELOAD_TABLE: 'Reîncarcă tabelul',
    REORDER: 'Reordonează',
    SAVE: 'Salvează',
    SELECT: 'Selectează',
    SEND: 'Envoyer',
    SELECT_OPTION: 'Selectați o opțiune',
    SHOW_FORM: 'Afișează formularul',
    TEACHER: 'Professeur',
    TEMPORARY_USER: 'utilizator temporar',
    TIMEZONE: 'Europe/Bucharest',
    UPLOAD: 'Încarcă',
    USE_THIS_ACCOUNT: 'Folosește acest cont',
    PASTE: 'Lipește',
    ROLE: 'Rol',
    SEE_CALENDAR: 'vezi tot Calendarul',
    SEE_EDITOR: 'vezi toate Lecțiile',
    SEE_STUDENTS: 'vezi toți Elevii',
    STUDENT: 'Étudiant',
    YES: 'Da',
    YOUR_ACCOUNTS: 'Conturile tale'
  },
  GROUP_LIST: {
    ADD_CLASS: 'Adaugă clasă',
    ADD_EVENT: 'Ajouter un événement',
    ADD_GROUP: 'Adaugă comunitate',
    ADD_MEMBERS: 'Adaugă membri existenți',
    ADD_STUDENTS: 'Adaugă elevi existenți',
    ADD_SUBGROUP: 'Adaugă subgrup',
    COORDINATOR: 'Coordonator',
    CLASS_NAME: 'Numele clasei*',
    CLASS: 'Clasă',
    CREATE_MEMBERS: 'Creează membri noi',
    CREATE_STUDENTS: 'Creează elevi noi',
    REGISTER_MEMBER: 'Înregistrare',
    COMMUNITIES: 'Comunităţi virtuale',
    COMMUNITY_NAME: 'Numele comunităţii*',
    DELETE_GROUP: 'Şterge',
    DELETE_GROUP_MEMBERS: 'Ştergere membru',
    DETAILS_TITLE: 'Detalii despre comunitate',
    DESCRIPTION: 'Descriere',
    EDIT_GROUP: 'Editează comunitatea',
    EDIT_GROUP_OPTIONS: 'Opţiuni editare',
    EDIT_GROUP_TITLE: 'Editare comunitate',
    EDIT_CLASS_TITLE: 'Editare clasă',
    ERROR_NAME_REQUIRED: 'Numele este obligatoriu',
    GROUP_FILTER_PLACEHOLDER: 'Caută după numele comunităţii',
    GROUP_LIST: 'Lista comunităţilor',
    MEMBER_FILTER_PLACEHOLDER: 'Caută după nume',
    MEMBERS: 'Membrii comunităţii',
    FULLNAME: 'Numele și prenumele*',
    EMAIL: 'Adresa de e-mail',
    PHONE: 'Numărul de telefon (e.g. +40712345678)',
    NAME: 'Nume',
    NO_EVENT_AVAILABLE: 'Nu există activități programate',
    LINK: 'Link',
    SEE_GROUP_MEMBERS: 'Vizualizare membri',
    SELECT_USER_PLACEHOLDER: 'Selectați un utilizator',
    SCHOOL: 'Școala',
    STUDENTS: 'Elevi',
    STUDENT_NAME: 'Numele și prenumele elevului*',
    STUDENT_EMAIL: 'Adresa de e-mail a elevului/părintelui',
    STUDENT_SCHOOL: 'Școala elevului',
    STUDENT_CLASS: 'Clasa elevului',
    TYPE: 'Tip',
    TYPE_CLASS: 'Clasă',
    TYPE_SCIENTIFIC_GATHERING: 'Cerc științific',
    TYPE_GENERIC_GROUP: 'Grup',
    TYPE_OTHERS: 'Altele',
    USERS: 'Membri',
    USER_LIST: 'Lista utilizatorilor',
    FIELDS_REQUIRED: 'Campurile marcate cu "*" sunt obligatorii',
    USERNAME_WILL_BE_GENERATED: 'Va fi generat'
  },
  INSPECTOR_DASHBOARD: {
    ACCESS_COUNT: 'Număr de accesări',
    ACCESS_STATS: 'Statistici de acces pentru:',
    CITY: 'Orașul',
    CITY_CHILD: 'Școala',
    CLASS: 'Clasa',
    CONCEPT_COVERAGE: 'Acoperirea conceptelor pentru: ',
    CONCEPT_STATS: 'Statistici de acoperire a conceptelor pentru: ',
    CONVERSATION_COUNT: 'Numărul de mesaje',
    CONVERSATION_STATS: 'Statistici conversaționale pentru: ',
    COVERAGE: 'Nivel de acoperire',
    COUNTY: 'Județul',
    COUNTY_CHILD: 'Orașul',
    GLOBAL_STATS: 'Statistici globale',
    SCHOOL: 'Școala',
    SCHOOL_CHILD: 'Clasa',
    SEARCH_ACCESS: 'Statistici de acces',
    SEARCH_CONCEPTS: 'Acoperirea conceptelor',
    SEARCH_USAGE: 'Statistici conversaționale',
    SEARCH_TYPE: 'Tip de statistici'
  },
  KNOWLEDGE_MAP: {
    KNOWLEDGE_MAP_TITLE: 'Harta personală de cunoștințe',
    INCLUDE_IN_IMPORT: 'Include harta de cunoștințe',
    EXPORT_WITH_KM: 'Cu hartă de cunoștințe',
    EXPORT_WITHOUT_KM: 'Fără hartă de cunoștințe'
  },
  KNOWLEDGE_MAP_EDITOR: {
    COMPETENCY_ELEMENT: {
      DESCRIPTION: 'Descriere',
      EXPLANATORY_LINK: 'Link explicativ',
      CODE: 'Cod'
    },
    COMPETENCIES: 'Competențe',
    CONTENT_DOMAINS: 'Domenii de conținut',
    CONTENT_DOMAIN_TITLE: 'Domeniu de conținut',
    CONTENT_DOMAIN: {
      DESCRIPTION: 'Descriere',
      CODE: 'Cod'
    },
    CONTENT_ITEM_TITLE: 'Element de conținut',
    CONTENT_ITEM: {
      DESCRIPTION: 'Descriere',
      CODE: 'Cod'
    },
    CONCEPT_TITLE: 'Concept',
    CONCEPT: {
      DESCRIPTION: 'Concept',
      RESOURCE_LINK: 'Link la resursă',
      RESOURCE_TYPE: 'Tip de resursă',
      EXISTING: 'Concept existent',
      NEW: 'Concept nou',
      CODE: 'Cod'
    },
    GENERAL_COMPETENCY_TITLE: 'Competențe generale',
    GENERAL_COMPETENCY: {
      DESCRIPTION: 'Descriere',
      CODE: 'Cod'
    },
    KNOWLEDGE_MAP_PLACEHOLDER: 'Dă click dreapta pentru a adăuga o hartă de învățare',
    KNOWLEDGE_MAP: {
      DESCRIPTION: 'Descriere',
      CODE: 'Cod'
    },
    KNOWLEDGE_MAP_TITLE: 'Hartă de cunoștințe',
    SPECIFIC_COMPETENCY: {
      DESCRIPTION: 'Descriere',
      CODE: 'Cod'
    },
    SPECIFIC_COMPETENCY_TITLE: 'Competență specifică'
  },
  NOTIFICATIONS: {
    INCOMPLETE_PROFILE_TITLE: 'Completare profil',
    INCOMPLETE_PROFILE_CONTENT: 'Pentru a primi rezultatele la teste trebuie să completați în profil adresa de email și numărul de telefon',
    NAVIGATE_PROFILE: 'Mergi la profil'
  },
  MAKERSPACE_OWNER_LIST: {
    DETAILS_TITLE: 'Detalii despre persoană',
    FILTER_PLACEHOLDER: 'Filtrează',
    MAKERSPACE_ADDRESS: 'Adresă',
    MAKERSPACE_NAME: 'Nume',
    NO_PASSWORD: 'Lasați câmpul necompletat pentru a păstra parola',
    USER_EMAIL: 'Email',
    USER_FULLNAME: 'Nume',
    USER_NAME: 'Utilizator',
    USER_PASSWORD: 'Parola',
    USER_PHONE: 'Telefon'
  },
  LOCATION_LIST: {
    DETAILS_TITLE: 'Detalii despre locație',
    LOCATION_DESCRIPTION: 'Descriere',
    LOCATION_WELCOME_MESSAGE: 'Mesaj de primire',
    LOCATION_LAT: 'Latitudine',
    LOCATION_LONG: 'Longitudine',
    FILTER_PLACEHOLDER: 'Filtrează',
    LOCATION_COORDINATES: 'Coordonate',
    OWNER: 'Makerspacer'
  },
  LOGIN: {
    CONTINUE_AS: 'Continuă ca',
    CONFIRM_PASSWORD: 'Confirmă parola',
    CHANGE_ACCOUNT: 'Schimbă contul',
    GO_TO_LOGIN: 'Mergi la pagina de logare',
    INSERT_USERNAME: 'Introdu numele de utilizator',
    INSERT_PASSWORD: 'Introdu parola',
    LANDING_MESSAGE_FIRST: 'Platforma cu lecții gratuite de informatică și robotică',
    LANDING_MESSAGE_FIRST_BNR: 'Platforma cu lecții gratuite de educaţie financiară.',
    LANDING_MESSAGE_SECOND: 'pentru studiul de acasă și pentru concursul Nextlab.tech',
    LANDING_MESSAGE_SECOND_BNR: 'pentru studiul de acasă',
    LANDING_MESSAGE_THIRD: 'Pentru lecții de clasa a V-a click',
    LANDING_MESSAGE_THIRD_BNR: 'Pentru lecții de clasa a VIII-a click',
    LOGIN: 'Intră',
    LOGIN_DETAILED: 'Intră în aplicație',
    NEW_PASSWORD_PLACEHOLDER: 'Parola nouă',
    NOT_YOUR_ACCOUNT: 'Nu ești',
    PASSWORD_PLACEHOLDER: 'Parola',
    CODE_PLACEHOLDER: 'Codul de acces',
    FULLNAME_PLACEHOLDER: 'Nume',
    RECOVER_CHANGE: 'Schimbă parola',
    RECOVER_PASSWORD: 'Mi-am uitat parola',
    RECOVER_SEND: 'Trimite',
    RECOVER_USERS: 'Mi-am uitat utilizatorul',
    SESSION_EXPIRED: 'Sesiunea curentă a expirat',
    USERNAME_PLACEHOLDER: 'Numele de utilizator',
    WELCOME_BACK: 'Bine ai revenit,'
  },
  ADDITIONAL_KNOWLEDGE_BASE_EDITOR: {
    FILTER_PLACEHOLDER: 'Filtrează',
    ADDITIONAL_KNOWLEDGE_BASE: 'Baza de cunoștințe',
    ADDITIONAL_KNOWLEDGE_BASE_ENTITY: 'Entitate concept',
    ADDITIONAL_KNOWLEDGE_BASE_UPPER_LIMIT: 'Max',
    ADDITIONAL_KNOWLEDGE_BASE_CHARACTERS: 'caractere',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_RESPONSE: 'Asistentul spune',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_EXPLANATION: 'Intentiile simple permit asistentului sa clasifice textul introdus de utilizator, oferind raspunsuri. Ex:',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_EXAMPLE_1: 'Copil: "Nu se învârt roțile"',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_EXAMPLE_2: 'Asistent: "Lansează fluxul de verificare a motoarelor din traiectoria \'Asamblare robot\'. "',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_RECOGNITION_TEXT: 'Exemple',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY: 'Intentie simpla',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_TABLE_TITLE: 'Intenții simple (utilizatori avansati)',
    ADDITIONAL_KNOWLEDGE_BASE_DESCRIPTION: 'Numele bazei',
    ADDITIONAL_KNOWLEDGE_BASE_INFORMATION: 'O bază de cunoștințe reprezintă un set de concepte din programa școlară asociate cu definiții și resurse educaționale deschise sau materiale educaționale convenționale. Pe lângă bazele de cunoștinte implicite ale sistemului, cadrele didactice pot schimba definițiile și asocierile cu resurse educaționale. Aici poți configura răspunsurile pe care le dau asistenții virtuali la întrebări de forma "Ce este ...?". Bazele de cunoștinte reprezintă domenii sau subdomenii ale ariilor de învățare precum: Matematică clasa a-V-a, Fizică clasa a-VI-a, Informatică și TIC clasa a-V-a, Robotică, etc.',
    ADDITIONAL_KNOWLEDGE_BASE_ENTITIES: 'Entitățile și seturile de antrenare',
    ADDITIONAL_KNOWLEDGE_BASE_SAMPLES: 'Seturile de antrenare',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT: 'Concept',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT_EXAMPLE_1: 'Copil: "Ce este algoritmul?"',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT_EXAMPLE_2: 'Asistentul: "Algoritmul este un set de pași...."',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT_EXPLANATION: 'Conceptele vor fi recunoscute de catre asistent in cadrul intrebarilor de tipul "Ce este ...?" pentru a fi explicate. Ex:',
    ADDITIONAL_KNOWLEDGE_BASE_NEW_CONCEPT: 'Concept_nou',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS: 'Conceptele bazei',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS_DEFINITIONS: 'Asistentul răspunde',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS_LINK: 'Link',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS_NOTES: 'Observații',
    ADDITIONAL_KNOWLEDGE_BASE_JSON_FORMAT: 'JSON',
    DETAILS_TITLE: 'Detaliile bazei de cunoștințe',
    JSON_INVALID: 'Format invalid, respectați standardul JSON!',
    TOOLTIP_VARIANT: 'Numele entității',
    TOOLTIP_DEFINITION: 'ex: Algoritmul este un set de pași...',
    TOOLTIP_LINK: 'Link-ul către resursa educațională (videoclip, document pdf, etc)',
    TOOLTIP_NOTES: 'Note explicative pentru resursa educațională (ex: Capitolul 2 al manualului, Minutul 3 al videoclipului, etc)',
    TOOLTIP_KEY: 'Numele intenției',
    TOOLTIP_CONCEPT_CHIPS: 'Cuvinte cheie care ar putea fi folosite pentru a descrie entitatea (ex: robot, roboțel, robo, robotul, tecnicus, MIRO, etc)',
    TOOLTIP_SIMPLE_ENTITY_CHIPS: 'Frazele specifice care trebuie sa declanseze raspunsul'
  },
  PATH_EDITOR: {
    PATH_EXPORT: 'Traiectorie',
    FLOW_EXPORT: 'Lecție',
    ANSWER: {
      IDEA: 'Explicație',
      IS_CORRECT: 'Este corect?',
      TEXT: 'Răspuns'
    },
    COPY_LINK_SUCCESS: 'Link copiat',
    ANSWER_TITLE: 'Răspunsul',
    FLOW: {
      ASSISTANT_SELECTOR: 'Asistent de învățare (i.e. ioana, testrax)',
      WELCOME: 'Mesaj introductiv',
      DESCRIPTION: 'Descriere',
      KNOWLEDGE_SELECTOR: 'Baza de cunoștințe (i.e. ioana-default, testrax-default)',
      ADDITIONAL_KNOWLEDGE_BASE_SELECTOR: 'Baze de cunoștințe adiționale',
      LANGUAGE_SELECTOR: 'Limba (i.e. ro, en, es, fr)',
      FPUBLIC_CHECKBOX: 'Disponibil public',
      SPECIFIC_COMPETENCIES: 'Competențe',
      CONTENT_ITEMS: 'Elemente de conținut',
      ADDITIONAL_OERS: 'Lecturi suplimentare'
    },
    FLOW_TITLE: 'Fluxul de învățare',
    PATH: {
      DESCRIPTION: 'Descriere'
    },
    PATH_PLACEHOLDER: 'Dă click dreapta pentru a adăuga un path',
    PATH_TITLE: 'Traiectoria de învățare',
    TAGS: 'Etichete',
    KNOWLEDGE_MAP: 'Harta de cunoştinţe',
    CHOOSE_KNOWLEDGE_MAP: 'Alege harta de cunoştinţe',
    QUESTION: {
      FORM_TEMPLATE: 'Model formular',
      SELECTOR: 'Selector (testrax)',
      QUESTION_TYPE: 'Tip de întrebare',
      TEXT: 'Întrebare'
    },
    QUESTION_TITLE: 'Întrebarea',
    STEP: {
      CONCEPTS: 'Concepte',
      DESCRIPTION: 'Descriere',
      HELP: 'Text de ajutor',
      HINT: 'Alte indicații',
      IS_QUESTION_STEP: 'Conține întrebări?',
      IMAGE: 'Link la imagine',
      KEY: 'Cheie de regăsire',
      META: 'Metainformații (JSON)',
      SOLUTION: 'Soluție',
      OER_LINK: 'Link către resursa educațională'
    },
    STEP_TITLE: 'Pasul de învățare'
  },
  PACKAGE_EDITOR: {
    PACKAGE: {
      DESCRIPTION: 'Descriere',
      NAME: 'Nume'
    },
    PACKAGE_TITLE: 'Detalii despre pachet',
    SELECT_PATHS: 'Care traiectorii fac parte din pachet?'
  },
  REGISTER: {
    CHILD_REGISTERING_SELF: 'TEMP-Je suis un enfant',
    GENERAL: {
      CHILD_NAME: 'TEMP-Nom et prénom de l\'enfant',
      CONFIRM_ACCOUNT: 'TEMP-Confirmer email / téléphone',
      CONFIRM_EMAIL: 'TEMP-Code de confirmation reçu par email',
      CONFIRM_PHONE: 'TEMP-Code de confirmation reçu par SMS',
      ESTIMATED_STUDENTS_NUMBER: 'TEMP-Le nombre estimé d\'étudiants que vous inscrirez',
      FIELD_REQUIRED: 'TEMP-Ce champ est requis!',
      INSERT_EMAIL: 'TEMP-Adresse e-mail (parent / enseignant)',
      PERFORM_CONFIRM: 'TEMP-Confirmer',
      PERFORM_REGISTER: 'TEMP-S\'inscrire',
      PARENT_EMAIL: 'TEMP-Adresse email des parents',
      PARENT_PHONE: 'TEMP-Numéro de téléphone du parent (par exemple +40712345678)',
      REGISTER_INTENT_STUDENT: 'TEMP-Inscription des étudiants',
      REGISTER_INTENT_TEACHER: 'TEMP-Inscription des enseignants',
      SAVE_AND_ENTER_APP: 'TEMP-Enregistrez et entrez dans l\'application',
      USER_CITY: 'Ville',
      USER_CLASS: 'Classe',
      USER_COUNTRY: 'Pays',
      USER_COUNTY: 'Comté',
      USER_EMAIL: 'E-mail',
      USER_EMAIL_INVITED: 'E-mail',
      USER_NAME: 'Nom et surnom',
      USER_OLD_PASSWORD: 'TEMP-L\'ancien mot de passe',
      USER_PASSWORD: 'Choisissez un mot de passe',
      USER_CONFIRM_PASSWORD: 'TEMP-Confirmer votre mot de passe',
      USER_PHONE: 'Numéro de téléphone (e.g. +33 1 23 45 67 89)',
      USER_PHONE_INVITED: 'Numéro de téléphone',
      USER_REGISTRATION: 'TEMP-Inscription des étudiants',
      USER_SCHOOL: 'École',
      USER_USERNAME: 'TEMP-Choisissez un nom d\'utilisateur'
    },
    LOGIN: 'TEMP-Je veux entrer dans l\'application',
    PARENT: {
      CHILDREN: 'TEMP-GARÇONS',
      MY_DATA: 'TEMP-Mes données',
      PERFORM_CONFIRM: 'TEMP-Confirmation'
    },
    PARENT_REGISTERING_CHILD: 'TEMP-Je suis parent',
    REGISTER_PARENT: 'TEMP-Je veux inscrire un enfant',
    REGISTER_SELLER: 'TEMP-Je veux organiser des cours',
    REGISTER_TRAINER: 'TEMP-Je veux être entraîneur',
    PARENT_REGISTRATION: 'TEMP-Inscription des parents',
    PARENT_REGISTRATION_FORM_DETAILS: 'TEMP-Créez un compte parent en utilisant le formulaire ci-dessous',
    TEACHER_REGISTERING_CHILD: 'TEMP-Je suis un enseignant',
    TEACHER_REGISTRATION: 'TEMP-Inscription des enseignantsi',
    TEACHER_REGISTRATION_FORM_DETAILS: 'TEMP-Créez un compte enseignant en utilisant le formulaire ci-dessous',
    STUDENT_REGISTRATION: 'TEMP-Inscription étudiante',
    STUDENT_REGISTRATION_FORM_DETAILS: 'TEMP-Créez un compte étudiant en utilisant le formulaire ci-dessous',
    TERMS_CONDITIONS: 'TEMP-Termes et conditions',
    TERMS_CONDITIONS_ONE: 'TEMP-Je suis d\'accord avec ',
    TERMS_CONDITIONS_TWO: 'TEMP- utilisation de la plateforme',
    PRIVACY_COOKIES_ONE: 'TEMP-J\'accepte ',
    PRIVACY: 'TEMP-Politique de confidentialité',
    COOKIES: 'Cookies',
    AND: ' et '
  },
  REPORTS: {
    DESCRIPTION_LEARNING_REPORT: 'Raport de învățare pentru:',
    DESCRIPTION_SIMPLE_TEST_REPORT: 'Status susținere teste',
    NO_STATS_FOR_USER: 'Nu există statistici pentru utilizatorul',
    QUESTIONS_CORRECT: 'Întrabări cu răspuns corect',
    CORRECT: 'Corecte',
    STARTED: 'Început',
    FINISHED: 'Terminat',
    INCORRECT: 'Incorecte',
    UNASWERED: 'Întrebări fără răspuns',
    LOADING: 'Se încarcă...',
    NO_STATS: 'Nu există statistici pentru acest flux',
    NO_QUESTIONS: 'Acest pas nu conține întrebări',
    REPORT_FREQUENCY: 'Primește rapoarte',
    REPORT_FREQUENCY_WEEKLY: 'Hebdomadaire',
    REPORT_FREQUENCY_MONTHLY: 'Mensuel',
    REPORT_FREQUENCY_NONE: 'Jamais',
    ADDITIONAL_OERS: 'Lecturi suplimentare'
  },
  SERVER_MESSAGES: {
    ACCEPTED: 'Operația a fost făcută',
    CREATED: 'Entitatea a fost creată',
    ERR: 'Eroare',
    ERRORS: {
      ERR_CLAIM_INCOMPLETE: 'Nu poți asocia un cont fără email și numele de utilizator',
      ERR_CODE_INVALID: 'Codul de activare este invalid',
      ERR_CODE_USED: 'Codul de activare a fost deja utilizat',
      ERR_EXISTS: 'Acest nume este deja utilizat',
      ERR_NOT_AUTHORIZED: 'Nu ești autorizat să faci acestă operație',
      ERR_NO_CREDENTIALS: 'Credențialele sunt invalide',
      ERR_OVER_CAPACITY: 'Numărul de utilizatori depășește numărul maxim',
      ERR_INCORRECT_TOKEN: 'Încearcă să te reconectezi la aplicație',
      ERR_INVALID_IMPORT_FLOW: 'Ai încercat să încarci o traiectorie, nu o lecție',
      ERR_INVALID_IMPORT_PATH: 'Ai încercat să încarci o lecție, nu o traiectorie',
      ERR_MATCH_TOKEN: 'Încearcă să te reconectezi la aplicație',
      ERR_NO_EXISTING_PATH: 'Nu puteți adăuga un path deja existent',
      ERR_NO_FLOW_SELECTED: 'Niciun flux de învățare selectat',
      ERR_NOT_FOUND: 'Nu am găsit',
      ERR_NOT_IMPLEMENTED: 'Operația nu este încă implementată',
      ERR_SERVER_ERROR: 'S-a întâmplat o eroare pe server',
      ERR_TIME_EXPIRED: 'Timpul permis pentru această operație a expirat',
      ERR_DUPLICATE_USER: 'Există deja un cont cu acest utilizator',
      ERR_ONLY_STUDENT_CLAIM: 'Se poate asocia doar un cont de student',
      ERR_REQUIRED_EMAIL: 'Adresa de email este obligatorie',
      ERR_REQUIRED_PHONE: 'Numărul de telefon este obligatoriu',
      ERR_INSUFFICIENT_FUNDS: 'Puncte insuficiente',
      ERR_INVALID_EMAIL: 'Adresa de email este invalidă',
      ERR_INVALID_PHONE: 'Numărul de telefon este invalid',
      ERR_INVALID_VALUE: 'Valoare invalidă!',
      ERR_EXISTS_MANY: 'Unul dintre utilizatori există deja',
      ERR_REQUIRED_STUDENT_CAPACITY: 'Numărul maxim de copii este obligatoriu',
      ERR_REQUIRED_DURATION: 'Durata este obligatorie',
      ERR_REQUIRED_LOCATION: 'Locația este obligatorie',
      ERR_TOKEN_EXPIRED: 'Sesiunea curentă a expirat!',
      ERR_JSON_INVALID: 'Structura invalida!'
    }
  },
  TAB_TITLES: {
    ADDITIONAL_KNOWLEDGE_BASE_EDITOR: 'Bases de savoir',
    FORM_TEMPLATE_ADMINISTRATION: 'Forme',
    INSPECTOR_DASHBOARD: 'Statistiques globales',
    SELLER_EVENT_ADMINISTRATION: 'Calendrier',
    TRAINER_EVENT_ADMINISTRATION: 'Calendrier - entraîneur',
    KNOWLEDGE_MAP: 'Carte de savoir',
    KNOWLEDGE_MAP_EDITOR: 'Éditeur de carte de savoir',
    LEARNING_ASSISTANT: "Assistant d'apprentissage",
    LEARNING_PROGRESS: 'Suivi des étudiants',
    LEARNING_STATS: "Rapport d'apprentissage",
    LOCATION_EDITOR: 'Locații',
    MAKERSPACE_EVENT_ADMINISTRATION: 'Calendrier - makerspace',
    PARENT_DASHBOARD: 'Dashboard părinte',
    PATH_EDITOR: 'Editor traiectorii de învățare',
    PRIMARY_DASHBOARD: 'Dashboard',
    PROFILE_EDITOR: 'Profil',
    SELLER_COMMUNITY_ADMINISTRATION: 'Comunităţi virtuale',
    STUDENT_DASHBOARD: 'Bibliothèque',
    TAG_DEFINITION_EDITOR: 'Taguri',
    TEACHER_DASHBOARD: 'Elevi',
    TEACHER_PATH_EDITOR: 'Editor traiectorii personale',
    TEST_STATS: 'Raport teste',
    TRAINER_DASHBOARD: 'Dashboard trainer',
    USER_EDITOR: 'Utilizatori',
    SCAFFOLD: '?!?',
    TEST_LIST_TEACHERS: 'Teste',
    TEST_LIST_STUDENTS: 'Résultats des tests'

  },
  TAG_DEFINITION_LIST: {
    DETAILS_TITLE: 'Detalii tag',
    TAG_FACETS: 'Aplicabil fațetelor',
    TAG_NAME: 'Eticheta',
    TAG_DESCRIPTION: 'Descrierea etichetei'
  },
  TEACHER_DASHBOARD: {
    TESTS_TAKEN: 'Teste susținute',
    CHOOSE_FLOW: 'Alege fluxul de învăţare',
    CHOOSE_PATH: 'Alege o traiectorie de învăţare parcursă',
    NO_ASSOCIATED_STUDENTS: 'Nu există elevi asociați',
    PATH: 'Traiectoria de învăţare',
    TEACHER_PATHS: 'Traiectorii personale'
  },
  TEACHER_LIST: {
    DETAILS_TITLE: 'Detalii despre profesor',
    FILTER_PLACEHOLDER: 'Filtrează',
    NO_PASSWORD: 'Lasați câmpul necompletat pentru a păstra parola',
    USER_EMAIL: 'Email',
    USER_FULLNAME: 'Nume',
    USER_NAME: 'Utilisateur',
    USER_PASSWORD: 'Parola',
    USER_PHONE: 'Telefon'
  },
  TEST_LIST_TEACHERS: {
    ANSWERS: 'Răspunsurile',
    ANSWER: 'Răspuns',
    SIMILARITY: 'Similitudine',
    STUDENT: 'Elev:',
    FOR_THE_TEST: 'pentru: ',
    SELECT_TEST: 'Selecteză testul pe care vrei să îl vizualizezi :',
    NAME_AND_SURNAME: 'Nume și prenume',
    TEST_RESULTS: 'Situație întrebări',
    TEST_STATISTICS: 'Statistici test',
    TOTAL_POINTS_EARNED: 'Total puncte obținute : ',
    TOTAL_POINTS: 'Total puncte :',
    TOTAL_STEPS_TEST: 'Total de pași pe test : ',
    TOTAL_QUESTIONS_TEST: 'Total de întrebări pe test : ',
    TOTAL_CORECT_ANSWERS: 'Total răspunsuri corecte : ',
    TOTAL_INCORECT_ANSWERS: 'Total răspunsuri incorecte : ',
    TOTAL_UNASWERED_QUESTIONS: 'Total întrebări la care nu s-a înregistrat nici un răspuns : ',
    STATUS_QUESTIONS: 'Status întrebări',
    DATE_ANSWERED: ' Răspuns dat în data de : ',
    NO_ANSWER_TO_QUESTION: 'La acestă întrebare nu s-a răspuns',
    ANSWER_GIVEN: 'Răspunsul dat : ',
    ANSWER_CONSIDERED_CORECT: 'Răspunsul este considerat : Corect',
    ANSWER_CONSIDERED_INCORECT: 'Răspunsul este considerat : Incorect',
    NO_OBSERVATIONS: 'Fără observații',
    OBSERVATIONS: 'Observații :',
    DETAILS: 'Detalii',
    POINTS: 'Punctaj întrebari cu corectare automata',
    POINTS_DONT_INCLUDE_FORMS: 'punctajul nu include intrebarile de tip formular',
    MAXIMUM_POINTS: 'Punctaj maxim',
    OBTAINED_POINTS: 'Punctaj obținut',
    TEST_LIST: 'Teste',
    TEST_LIST_DESCRIPTION: 'Aici poți să vezi rezultatele testelor organizate.',
    SEARCH: 'Caută',
    ADVANCED_SEARCH: 'Căutare avansată',
    TAB_DESCRIPTION_UNFINISHED_TESTS: 'Aici pot fi vizualizate testele necorectate',
    TAB_DESCRIPTION_FINISHED_TESTS: 'Aici pot fi vizualizate testele corectate',
    LIST_OF_UNFINISHED_TESTS: 'Lista testelor necorectate',
    LIST_OF_FINISHED_TESTS: 'Lista testelor corectate',
    FINALIZED: 'Finalizat',
    EXCEL_EXPORT_ALL: 'Exportă raport complet',
    EXCEL_EXPORT_PAGE: 'Exportă raport',
    SAVE: 'Salvează',
    SAVE_AND_FINALIZE: 'Salvează și finalizează',
    DETAILS_LAST_EDIT: 'Ultima editare a punctajul a avut loc la data de :',
    SELECT_TIME_INTERVAL: 'Intervalul de timp în care s-a desfășurat testul',
    INTERVAL_START_DATE: 'De la',
    INTERVAL_END_DATE: 'Până la',
    TEST_DATE: 'Data testului',
    EVENT_START_DATE: 'Data de început a testului',
    FILTER_PLACEHOLDER: 'Căutare dupa numele elevului',
    SELECT_EVENT: 'Selectează test',
    EVENT_FILTER_PLACEHOLDER: 'Codul întâlnirii',
    ACCESS_TOKEN: 'Codul întâlnirii',
    EVENT_DURATION: 'Durata testului',
    SELECT: 'Selectează',
    SELECT_EVENT_INSTANCE: 'Sélectionnez un rendez-vous',
    EVENT_INSTANCE_FILTER: 'Datele testului',
    TEST_FLOW: 'Fluxul de test',
    NO_EVENTS_TO_SHOW: 'Nu au fost găsite teste.',
    LAUNCH_A_TEST: 'Creează un test nou',
    ADJUST_FILTERS_OR_MAKE_TEST: 'Creați un test folosind butonul albastru "Creează un test nou"!',
    EVENT_NAME: 'Numele testului',
    BACK_TO_EVENT_LIST: 'Înapoi',
    NO_TESTS_TO_SHOW: 'Nu există rezultate pentru această întâlnire.',
    TABPANEL_UNFINALIZED_TESTS: 'Teste necorectate',
    TABPANEL_FINALIZED_TESTS: 'Teste corectate',
    TABPANEL_NO_UNFINALIZED_TESTS: 'Nu există teste necorectate pentru această întâlnire.',
    TABPANEL_NO_FINALIZED_TESTS: 'Nu există teste corectate pentru această întâlnire.',
    TABPANEL_BACK: 'Întoarcere la lista de întâlniri.',
    SHOW_ONLY_ASSOCIATED_STUDENTS: 'Afișează doar elevii asociați',
    RESPONSE_IS: 'Răspunsul este:',
    MODIFY_VERDICT: 'Modify mark',
    CORRECT: 'Corect',
    INCORRECT: 'Incorect',
    TEST_STATUS: 'Starea testului',
    TEST_STATUS_SCHEDULED: 'Programat',
    TEST_STATUS_ONGOING: 'In derulare',
    TEST_STATUS_FINISHED: 'Finalizat',
    DISABLED_UNTIL_TEST_BEGINS: 'Puteți monitoriza progresul dupa inceperea testului',
    MODEL_ANSWER: 'Răspunsul așteptat :',
    SEE_SIMILAR_ANSWERS: 'Vezi răspunsuri similare',
    HIDE_SIMILAR_ANSWER: 'Ascunde răspunsuri similare',
    SIMILAR_ANSWERS: 'Răspunsuri similare',
    FOUND: 'Au fost gasite',
    SIMILAR_ANSWERS_LOWERCASE: 'răspunsuri similare',
    USER: "Nom d'utilisateur"
  },
  USER_LIST: {
    ASSOCIATED_TEACHERS: 'Profesori asociaţi',
    ASSOCIATED_USERS: 'Utilizatori asociaţi',
    ASSOCIATED_STUDENTS: 'Elevi asociaţi',
    BILLING_MAKERSPACE: 'Makerspace original',
    CONVERSATION_HISTORY: 'Istoric conversaţii',
    CURRENT_MAKERSPACE: 'Makerspace curent',
    GROUP: 'Grupa',
    DETAILS_TITLE: 'Detalii despre student',
    KNOWLEDGE_MAP: 'Hartă de cunoştinţe',
    LEARNING_REPORT: 'Raport de învăţare',
    MAKERSPACES: 'Makerspace-uri',
    NO_PASSWORD: 'Lasați câmpul necompletat pentru a păstra parola',
    PAYMENT_TYPE: 'Tip abonament',
    FILTER_PLACEHOLDER: 'Căutare după numele de utilizator',
    USER_CURRENT_STEP: 'Pasul curent',
    USER_STEPS_TAKEN: 'Pași parcurși',
    USER_CITY: 'Ville',
    USER_CLASS: 'Classe',
    USER_COUNTRY: 'Pays',
    USER_COUNTY: 'Comté',
    USER_EMAIL: 'E-mail',
    USER_ROLE: 'rôle',
    USER_FACETS: 'Choisir le rôle',
    USER_FULLNAME: 'Nume',
    USER_LEARNING_STATS: 'Raport de învățare',
    USER_NAME: "Nom d'utilisateur",
    USER_PARENT: 'Associez vos parents',
    USER_PASSWORD: 'Parola',
    USER_PHONE: 'Telefon',
    USER_SCHOOL: 'Şcoală',
    USER_STATUS: 'Status',
    USER_TEACHER: 'Associez vos professeurs',
    USER_USERNAME: 'Nume de utilizator',
    WALLET: 'Portofel electronic',
    WALLET_BALANCE: 'Puncte',
    WALLET_EXPIRY: 'Data de expirare'
  },
  WORKING_GROUP_LIST: {
    ACTIVATE_FLOW: 'Activează un flux',
    ADD_FLOW_TO_GROUP: 'Adaugă un flux la grupă',
    DESCRIPTION: 'Descriere',
    CLASSROOM: 'Locație',
    GROUP: 'Grupa',
    DETAILS_TITLE: 'Detalii despre grupă',
    SELECT_FLOW: 'Selectează un flux',
    SELECT_PATH: 'Selectează o traiectorie',
    IMPORT_STUDENTS: 'Importă studenți'
  },
  PROFILE_LIST: {
    ACCOUNT_DETAILS: 'Détails du compte',
    ASSOCIATIONS: 'Associations enseignants / parents',
    REPORTS_FREQUENCY: 'Fréquence des rapports',
    MODIFY_PASSWORD: 'Changer le mot de passe',
    MODIFY_SUCCESS_GROWL: 'Modifié!',
    PASSWORD_CONFIRMATION: 'Confirmez le mot de passe',
    PASSWORD_CHANGED_SUCCESS: 'Mot de passe changé',
    PASSWORD_CHANGE_CONFIRMATION: 'Voulez-vous vraiment changer votre mot de passe?',
    PASSWORD_MATCH_ERROR: 'Les mots de passe doivent être égaux',
    ADVANCED_SETTINGS: 'Réglages avancés'
  },
  LEARNING_PROGRESS: {
    EVENT: 'Activitate',
    FLOW: 'Flux',
    DATE: 'Data',
    START_HOUR: 'Ora început',
    SEARCH_EVENT: 'Caută după codul sau link-ul activități',
    SEARCH_INPUT_PLACEHOLDER: 'Codul sau link-ul activități',
    NOT_STARTED: 'Neînceput',
    RESTARTED: 'Reînceput',
    STARTED: 'În curs',
    FINISHED: 'Terminat'
  }
}

export default assets
