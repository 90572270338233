// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-width {
  display: block;
  width : 100vw;
}

.centered {
  text-align: center;
}

.padding-top {
  padding-top: 2vh !important;
}

.info-dialog .p-dialog-content {
  overflow: hidden;
}

.no-padding {
  padding: 0 !important;
}

.not-shown {
  display: none !important;
}

.p-dialog-maximized {
  width: 100vw !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}
.p-dialog-maximized .p-dialog-content{
  width: 100vw !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}

.app-header {
  padding-top: 0.5em;
  display: grid;
  height: 4em;
}

.nextlab-logo-link {  
  display: flex;
  align-items: center;
}

.logo-container {
  grid-column: 3/5;
  grid-row: 1;
  align-items: center;
}

.logo {
  max-height: 3em;
}`, "",{"version":3,"sources":["webpack://./src/components/App/App.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,uBAAuB;EACvB,6CAA6C;AAC/C;AACA;EACE,uBAAuB;EACvB,6CAA6C;AAC/C;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".full-width {\n  display: block;\n  width : 100vw;\n}\n\n.centered {\n  text-align: center;\n}\n\n.padding-top {\n  padding-top: 2vh !important;\n}\n\n.info-dialog .p-dialog-content {\n  overflow: hidden;\n}\n\n.no-padding {\n  padding: 0 !important;\n}\n\n.not-shown {\n  display: none !important;\n}\n\n.p-dialog-maximized {\n  width: 100vw !important;\n  height: calc(var(--vh, 1vh) * 100) !important;\n}\n.p-dialog-maximized .p-dialog-content{\n  width: 100vw !important;\n  height: calc(var(--vh, 1vh) * 100) !important;\n}\n\n.app-header {\n  padding-top: 0.5em;\n  display: grid;\n  height: 4em;\n}\n\n.nextlab-logo-link {  \n  display: flex;\n  align-items: center;\n}\n\n.logo-container {\n  grid-column: 3/5;\n  grid-row: 1;\n  align-items: center;\n}\n\n.logo {\n  max-height: 3em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
