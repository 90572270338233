import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import './LoadingSpinnerDisplay.css';

const LoadingSpinnerDisplay = () => {
  const [isVisible, setIsVisible] = useState(false);

  const isFetching = useSelector((state) => 
    Object.values(state).some(reducer => reducer && reducer.fetching === true)
  );

  useEffect(() => {
    setIsVisible(isFetching);
  }, [isFetching]);

  if (!isVisible) {
    return null; 
  }

  return (
    <div className="loading-spinner-overlay">
      <ProgressSpinner />
    </div>
  );
};

export default LoadingSpinnerDisplay;
