import React, { useEffect } from 'react'

import './ProtectedRoute.css'
import { Navigate, useLocation } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

const isAuthenticatedSelector = state => state.loginUser.user

const ProtectedRoute = (props) => {
  const { element } = props

  const isAuthenticated = useSelector(isAuthenticatedSelector, shallowEqual)
  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated) {
      window.localStorage.setItem('protected-route-history', location.pathname)
    }
  }, [])

  return (
    isAuthenticated ? element : <Navigate to={{ pathname: '/login', state: { from: location } }} />
  )
}

export default ProtectedRoute
