import React, { useRef, useEffect } from 'react'
import 'katex/dist/katex.min.css'
import katex from 'katex'

const ContentRenderer = ({ content }) => {
  const contentRef = useRef(null)

  // console.warn(content)

  useEffect(() => {
    if (contentRef.current) {
      const inlineMaths = contentRef.current.querySelectorAll('.latex-inline')
      inlineMaths.forEach((inlineMath) => {
        const math = inlineMath.textContent.replace(/\\\\/g, '\\')
        inlineMath.innerHTML = katex.renderToString(math, { throwOnError: false })
      })

      const blockMaths = contentRef.current.querySelectorAll('.latex-block')
      blockMaths.forEach((blockMath) => {
        const math = blockMath.textContent
        blockMath.innerHTML = katex.renderToString(math, { throwOnError: false })
      })
    }
  }, [content])

  return (
    <div className='content-renderer' dangerouslySetInnerHTML={{ __html: content }} ref={contentRef} />
  )
}

export default ContentRenderer