import './PointsViewer.css'
import React from 'react'
import iconGift from 'assets/icons/robot.svg'

const PointsViewer = (props) => {
	const { current, total } = props

	return (
		<div className='points-viewer'>
			<div className='image-container'>
				<img src={ iconGift } alt='gift' />
				<div className='overlay' style={{
					height: `${10 + (current / total) * 90}%`
				}}></div>
			</div>
			<div className='points'>
				{ current } / { total }
			</div>
		</div>
	)
}

export default PointsViewer
