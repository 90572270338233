/**
 * @module trophyRoadReducer
 * @description state manipulation for trophy road
 * @category reducers
 */
const INITIAL_STATE = {
    trophyRoadItems: [],
    trophyRoadId: ''
  }
  
  export default function reducer (state = INITIAL_STATE, action) {
    switch (action.type) {
      case 'GET_TROPHY_ROAD_PENDING':
        return { ...state, error: null, fetching: true, fetched: false }
      case 'GET_TROPHY_ROAD_FULFILLED':
        return { ...state, trophyRoadItems: action.payload.data.levels, trophyRoadId: action.payload.data._id, error: null, fetching: false, fetched: true }
      case 'GET_TROPHY_ROAD_REJECTED':
        return { ...state, error: action.payload.response.data, fetching: false, fetched: false }
      case 'RESET':
        return INITIAL_STATE
      default:
        break
    }
    return state
  }
