const assets = {
  ASSISTANT_BUILDER: {
    ACTIONS: 'Aktionen',
    ASSISTANT_BUILDER: 'Assistant editor',
    ASSISTANT_EDITOR: 'Assistant Editor',
    ASSISTANT_IMPORT: 'Importassistent',
    EXPORT: 'Export',
    FILTER_PLACEHOLDER: 'Der Name des Assistenten, den Sie suchen', // DE VALIDAT
    LANGUAGE: 'Sprache',
    LANGUAGE_FILTER_PLACEHOLDER: 'Die gesuchte Sprache',
    NAME: 'Name',
    NAME_FILTER_PLACEHOLDER: 'Der Name des gesuchten Assistenten',
    RULES: 'Regeln',
    TYPE_RULE: 'Regeltyp'
  },
  ASSISTANT_RULE_EDITOR: {
    BEHAVIOUR: 'Verhalten',
    COMMENT: 'Kommentar',
    MATCHING_RULE: 'Matching rule',
    NAME: 'Name',
    RULE_EDITOR: 'Editorregeln',
    SELECT_BEHAVIOUR: 'Verhalten auswählen',
    SELECT_TYPE: 'Typ auswählen',
    TYPE: 'Typ',
    NAME_FILTER_PLACEHOLDER: 'Der Name der gesuchten Regel',
    TYPE_FILTER_PLACEHOLDER: 'Der Name des gesuchten Typs'
  },
  ASSISTANT_BEHAVIOR_EDITOR: {
    BEHAVIOR_EDITOR: 'Verhaltenseditor',
    CODE_MODULE: 'Modulcode',
    NAME: 'Name',
    NAME_FILTER_PLACEHOLDER: 'Der Name des gesuchten Verhaltens',
    CODE_FILTER_PLACEHOLDER: 'Der Name des gesuchten Moduls'
  },
  CLASSIFIER_BUILDER: {
    CLASS: 'Klasse',
    CLASSIFIER_EDITOR: 'Klassifikator-Editor',
    EXPORT: 'Export',
    LANGUAGE: 'Sprache',
    LANGUAGE_FILTER_PLACEHOLDER: 'Die Sprache des Klassifikators',
    NAME: 'Name',
    NAME_FILTER_PLACEHOLDER: 'Klassifikatorname',
    STANDARD_ANSWER: 'Standardantwort',
    STANDARD_ANSWER_PLACEHOLDER: 'Standardantwort',
    TEST_SAMPLE: 'Trainingsbeispiel'
  },
  KNOWLEDGE_BASE_BUILDER: {
    ADD_KNOWLEDGE_BASE: 'Wissensdatenbank hinzufügen', // DE VALIDAT
    ADD_TOOLTIP: 'Fügen Sie eine Wissensdatenbank hinzu',
    ANSWERS: 'Antworten',
    ANSWERS_TOOLTIP: 'Bearbeiten Sie die grundlegenden Antworten',
    DELETE_TOOLTIP: 'Wissensdatenbank löschen',
    EDIT_TOOLTIP: 'Bearbeiten Sie die Wissensdatenbank',
    ENTITIES: 'Entität',
    ENTITIES_TOOLTIP: 'Bearbeitet Wissensdatenbankentitäten',
    EXPORT: 'Export',
    EXPORT_TOOLTIP: 'Exportieren Sie die Wissensdatenbank als JSON',
    FILTER_PLACEHOLDER: 'Der Name der gesuchten Wissensbasis',
    IMPORT_KNOWLEDGE_BASE: 'Importieren Sie eine Wissensdatenbank',
    IMPORT_TOOLTIP: 'Importieren Sie eine JSON-Wissensdatenbank',
    INTENTS: 'Intentionen',
    INTENTS_TOOLTIP: 'Bearbeiten Sie die grundlegenden Absichten',
    KNOWLEDGE_BASE_NAME: 'Name',
    KNOWLEDGE_BASE_DESCRIPTION: 'Beschreibung', // DE VALIDAT
    KNOWLEDGE_BASE_DIALOG_TITLE: 'Wissensbasis',
    KNOWLEDGE_BASE_LANGUAGE: 'Sprache',
    LANGUAGE_FILTER_PLACEHOLDER: 'Die Sprache der gesuchten Wissensdatenbank',
    NAME_FILTER_PLACEHOLDER: 'Die Sprache der gesuchten Wissensdatenbank',
    NO_DATA: 'Es gibt keine Wissensbasis',
    PUBLIC: 'Öffentlich',
    TITLE: 'Wissensdatenbank-Editor'
  },
  KNOWLEDGE_BASE_BUILDER_INTENT_EDITOR: {
    TITLE: 'Absichtseditor',
    NAME: 'Name',
    NAME_FILTER_PLACEHOLDER: 'Der Name der gesuchten Absicht',
    SAMPLE: 'Beispiel'
  },
  KNOWLEDGE_BASE_BUILDER_ENTITY_EDITOR: {
    NAME: 'Name',
    NAME_FILTER_PLACEHOLDER: 'Der Name der gesuchten Entität', // DE VALIDAT
    TITLE: 'Entity Editor',
    SAMPLE: 'Beispiel',
    VARIANT: 'Alternative',
    VARIANT_FILTER_PLACEHOLDER: 'Der Name der gesuchten Variante' // DE VALIDAT
  },
  KNOWLEDGE_BASE_BUILDER_ANSWER_EDITOR: {
    TITLE: 'Assistent antwortet Editor',
    NAME: 'Name',
    NAME_FILTER_PLACEHOLDER: 'Der Name der gesuchten Antwort',
    ANSWER_VARIANTS: 'Antwortvarianten'
  },
  SIMPLE_KNOWLEDGE_BASE_BUILDER: {
    ADD: 'Wissensdatenbank hinzufügen', // DE VALIDAT
    ANSWER: 'Antwort',
    DELETE_CONFIRMATION_DESCRIPTION: 'Bestätigen Sie das Löschen der Wissensdatenbank?', // DE VALIDAT
    DELETE_CONFIRMATION_YES: 'Ja',
    DELETE_CONFIRMATION_NO: 'Nein',
    DESCRIPTION: 'Hier können Sie die auf maschinellem Lernen basierenden Assistentenantworten konfigurieren. Hinzufügen / Importieren einer Wissensdatenbank oder Konfigurieren einer vorhandenen. ', // DE VALIDAT
    DESCRIPTION_NO_TABEL: 'Hier können Sie die Antworten des Assistenten auf der Basis des maschinellen Lernens konfigurieren. Hinzufügen / Importieren einer Wissensdatenbank.', // DE VALIDAT
    EXPORT: 'Export',
    FILTER_PLACEHOLDER: 'Der Name der gesuchten Wissensbasis', // DE VALIDAT
    IMPORT: 'Importieren Sie eine Wissensdatenbank', // DE VALIDAT
    IMPORT_KNOWLEDGE_BASE: 'Importieren Sie eine Wissensdatenbank', // DE VALIDAT
    INTENTS: 'Aufbau', // DE VALIDAT
    LANGUAGE: 'Sprache',
    NAME: 'Name',
    NAME_DESCRIPTION: 'Gibt den Datenbanknamen an', // DE VALIDAT
    SIMPLE_KNOWLEDGE_BASE_BUILDER_NAME_TABLE: 'Name der Wissensbasis', // DE VALIDAT
    SIMPLE_KNOWLEDGE_BASE_BUILDER_LANGUAGE_TABLE: 'Ausgewählte Sprache', // DE VALIDAT
    TITLE: 'Wissensdatenbanken'
  },
  SIMPLE_KNOWLEDGE_BASE_BUILDER_INTENT_EDITOR: {
    ADD: 'Absicht hinzufügen', // DE VALIDAT
    ADVANCED_SEARCH: 'Erweiterte Suche', // DE VALIDAT
    ANSWER: 'Der Assistent antwortet',
    ANSWER_DESCRIPTION: 'Gibt an, was der Assistent antwortet', // DE VALIDAT
    ANSWER_TYPE: 'Antworten',
    BACK: 'Zurück',
    DELETE_CONFIRMATION_DESCRIPTION: 'Möchten Sie die Antwort wirklich löschen?', // DE VALIDAT
    DELETE_CONFIRMATION_NO: 'Nein',
    DELETE_CONFIRMATION_YES: 'Ja',
    DESCRIPTION: 'Richten Sie automatische Gespräche zwischen Assistent und Schüler ein. Sie können Beispiele definieren, um die Absicht des Schülers zu identifizieren und die Antworten automatisch zu konfigurieren. Für jede Absicht müssen Sie mithilfe der Option eine Reihe von Schulungsbeispielen hinzufügen "Konfig. Beispiel".', // DE VALIDAT
    DESCRIPTION_NO_TABLE: 'Richten Sie automatische Gespräche zwischen Assistent und Schüler ein. Sie können Beispiele definieren, um die Absicht der Schüler zu identifizieren und Antworten automatisch zu konfigurieren. Für jede Absicht müssen Sie eine Reihe von Trainingsbeispielen hinzufügen. ', // DE VALIDAT
    EDIT: 'Konfig. Beispiel', // DE VALIDAT
    FILTER_PLACEHOLDER: 'Der Name der Absicht', // DE VALIDAT
    INTENT_ADVANCED_SEARCH: 'Erweiterte Suche', // DE VALIDAT
    INTENT_NAME: 'Absicht',
    INTENT_TYPE: 'Absicht',
    LIST_SAMPLES: 'Liste der Beispiele :',
    LIST_SAMPLES_TOOLTIP: 'Schreiben Sie hier ähnliche Texte wie der Schüler, um sie als Trainingssatz für den Algorithmus für maschinelles Lernen zu verwenden.', // DE VALIDAT
    NAME: 'Name Antwort',
    NAME_DESCRIPTION: 'Geben Sie eine Kennung der Antwort an, die Sie definieren möchten', // DE VALIDAT
    PERFORM_SEARCH: 'Suchen nach',
    RESULT_TYPE: 'Art des Ergebnisses', // DE VALIDAT
    RESULTS_PLACEHOLDER: 'Keine Ergebnisse. Schreiben Sie den Namen der Absicht, des Beispiels oder der Antwort, nach der gesucht werden soll', // DE VALIDAT
    SAMPLE_TYPE: 'Beispiel',
    SAMPLES: 'Geben Sie ein Beispiel an', // DE VALIDAT
    SEARCH_PLACEHOLDER: 'Name der Absicht, Beispiel oder Antwort', // DE VALIDAT
    TITLE: 'Der Herausgeber antwortet' // DE VALIDAT
  },
  CLIENT_SIDE_ERRORS: {
    REQUIRED: 'Muss ausgefüllt werden',
    POSITIVE_INTEGER: 'Muss eine positive Zahl sein'
  },
  CLAIM: {
    STUDENT_EMAIL: 'Registrierte E-Mail Adresse',
    ADD_OWN_STUDENT: 'Student hinzufügen',
    STUDENT_ACCOUNT: 'Studentenkonto'
  },
  CREDIT: {
    EXISTING: 'Guthaben zu einem vorhandenen Konto hinzufügen',
    NEW: 'Neues Konto erstellen'
  },
  DASHBOARD: {
    ADD_GROUP: 'Gruppe erstellen',
    ADD_INSTANCE: 'Anmelden',
    CALENDAR: 'Planung von Aktivitäten',
    GROUPS: 'Gruppen',
    NO_FLOW: 'Kein Stream ausgewählt',
    NO_INSTANCE: 'Keine offene Sitzung',
    PATH_EDITOR: 'Editor für Bildungsinhalte',
    SELECTED_FLOW: 'Ausgewählter Stream',
    USERS: 'Benutzer'
  },
  EVENT_TYPES: {
    TEST: 'Test',
    COURSE: 'Kurs / Lektion',
    HACKATHON: 'Ausbildung Hackathon',
    GROUP: 'Gruppe',
    OTHERS: 'Andere',
    UNSPECIFIED: 'Keine Angabe'
  },
  EVENT: {
    ACTIVE_PARTICIPANTS: 'Aktive Teilnehmer',
    AGE: 'Jahre',
    AGE_RANGE: 'Alterskategorie',
    PARTICIPANTS: 'Teilnehmer',
    ADD_COMMUNITY: 'Gemeinschaftszuweisung', // DE VALIDAT
    ADD_EVENT: 'Ereignis',
    ADD_NEW_EVENT: 'Fügen Sie ein neues Ereignis hinzu', // DE VALIDAT
    ASSIGN_EVENT: 'Ereignis zuordnen',
    ASSIGN_EVENT_SUCCESSFUL: 'Zugewiesenes Ereignis!', // DE VALIDAT
    APPROVE_EVENT: 'Ereignis genehmigen',
    ONLINE_MEET: 'Online-Konferenz?',
    CONFERENCE_LINK: 'Link zur Online-Konferenz',
    CLICK_FOR_ASYNCHRONOUS_FLOW: 'Klicken Sie auf die Schaltfläche unten, um den Ablauf außerhalb der Kalenderaktivitäten zu durchsuchen.',
    CODE: 'Zugangscode',
    CONFIRM_EVENT: 'Bestätigen',
    COPY_TOKEN_SUCCESS: 'Kopierter Code',
    CONTINUE_AS: 'Weiter als', // DE VALIDAT
    COORDINATOR: 'Koordinator',
    DATE: 'Auf',
    DELETE_INSTANCE: 'Löschen',
    DESCRIPTION: 'Ereignisbeschreibung',
    DURATION: 'Dauer (in Minuten)',
    EVENT: 'Veranstaltung',
    EVENT_DETAILS: 'Aktivitätsdetails',
    EVENT_FLOW: 'Der Fluss',
    EVENT_GROUP: 'Die Gemeinde',
    EVENT_HOST: 'unterstützt durch ', // DE VALIDAT
    EDIT_EVENT_INSTANCE: 'Ereignis bearbeiten', // DE VALIDAT
    EDIT_EVENT_APPLY_ALL: 'Übernehmen Sie Änderungen auf alle zukünftigen Ereignisse', // DE VALIDAT
    EVENT_EXPIRED: 'Die Aktivität ist beendet.',
    EVENT_FREQUENCY_DAILY: 'Tag',
    EVENT_FREQUENCY_WEEKLY: 'Woche',
    EVENT_FREQUENCY_MONTHLY: 'Monat',
    EVENT_CALENDAR: 'Eventkalender',
    EVENT_TYPE_COURSE: 'Kurs / Lektion',
    EVENT_TYPE_NONE: 'Andere', // DE VALIDAT
    EVENT_TYPE_HACKATHON: 'Pädagogischer Hackathon', // DE VALIDAT
    EVENT_TYPE_GROUP: 'Gruppe',
    EVENT_TYPE_TEST: 'Prüfung',
    FEEDBACK: 'Motivation',
    FINISHED: 'Fertig',
    GROUP: 'Assoziierte Grupp',
    HOUR: 'Die Stunde',
    IN_PROGRESS: 'Im Laufenden',
    INVITED: 'Eingeladen',
    INVITE_TRAINERS: 'Einladen',
    IS_PUBLIC: 'Öffentliche Veranstaltung?',
    LAST_EVENTS: 'Die folgenden Ereignisse',
    LOCATION: 'Ort',
    MANAGE_PARTICIPANTS: 'Teilnehmer',
    NO_ACTIVE_PARTICIPANTS: 'Es gibt keine aktiven Teilnehmer',
    NO_EVENTS: 'Es sind keine Aktivitäten zum Anzeigen vorhanden',
    NO_COMMUNITY: 'Nicht definiert',
    NOT_STARTED: 'Die Aktivität hat noch nicht begonnen. Bitte komm zurück!',
    PARTICIPANT_EXPIRY: 'Teilnehmer können ablaufen',
    PARTICIPATE: 'Mitmachen',
    PAYMENT_MESSAGE: 'Die Flusskosten für dieses Ereignis betragen: ',
    POINTS: ' Punkte',
    REJECTED: 'Antworten',
    REQUESTED: 'Angefordert',
    REPEATABLE: 'Wiederholbar?',
    SCHEDULED: 'Programmiert',
    SELECT_FLOW: 'Stream',
    SELECT_LOCATION: 'Wählen Sie einen Ort',
    SELECT_TYPE: 'Ereignistyp', // DE VALIDAT
    SEND_INVITES: 'Einladungen senden',
    SETTINGS_ADVANCED: 'Erweiterte Einstellungen',
    SELECT_FLUX: 'Wähle einen Stream',
    STATE: 'Ereignisstatus',
    START_DATE: 'Startdatum und -zeit',
    START_HOUR: 'Startzeit', // DE VALIDAT
    STUDENT_NUMBER: 'Maximale Anzahl von Kindern',
    TIMES_REPEATED: 'Anzahl der Sitzungen',
    TOOLTIP_ADD_PARTICIPANTS: 'Teilnehmer hinzufügen',
    TOOLTIP_ACCEPT_EVENT: 'Bestätigen',
    TOOLTIP_CHOOSE_FLOW: 'Weisen Sie eine Lektion zu',
    TOOLTIP_COPY_TOKEN: 'Kopieren Sie den Link',
    TOOLTIP_EDIT_EVENT: 'Bearbeiten',
    TOOLTIP_DELETE_EVENT: 'Entfernen',
    TOOLTIP_INVITE: 'Einladen',
    TOOLTIP_SEE_TOKEN: 'Siehe den Code',
    TRAINER_NUMBER: 'Anzahl der Trainer',
    TYPE_EVENT: 'Ereignistyp',
    VALIDATE_ATTENDANCE: 'Anwesenheitsüberprüfung',
    NO_FLOW: 'Kein Stream',
    SHOW_CODE: 'Zugangscode',
    SHOW_CODE_SHORT: 'Code',
    EVENT_NEEDS_DESCRIPTION: 'Die Veranstaltung muss eine Beschreibung haben!',
    EVENT_NEEDS_NR_REPEATS: 'Die Anzahl der Sitzungen muss angegeben werden!',
    EVENT_NEEDS_PARTICIPANTS: 'Eine Anzahl von Teilnehmern muss angegeben werden!',
    EVENT_NEEDS_TRAINERS: 'Es muss mindestens 1 Trainer sein!',
    EVENT_COMPLETE_NECESSARY_FIELDS: 'Bitte füllen Sie alle erforderlichen Felder mit gültigen Werten aus!',
    EVENT_NEEDS_FLOW: 'Ein Stream muss ausgewählt werden!',
    EVENT_NEEDS_LOCATION: 'Ein Ort muss ausgewählt werden!',
    EVENT_INTERFACE_EXPLANATION_1: 'In dieser Oberfläche können Sie Schülerklassen hinzufügen, Sie können Studentenkonten erstellen und virtuelle Communitys erstellen.',
    EVENT_INTERFACE_EXPLANATION_2: 'Eine virtuelle Community ist eine Gruppe von Benutzern, die gemeinsam an Lernaktivitäten teilnehmen (Hackathons, Kurse, Prüfung, wissenschaftliche Kreise, etc). '
  },
  FACETS: {
    PARENT: 'Elternteil',
    SELLER: 'Koordinator',
    STUDENT: 'Schüler',
    TEACHER: 'Léhrer',
    VOLUNTEER: 'freiwillig'
  },
  FLOW_EDITOR: {
    ASSISTANT: 'Lernassistent',
    ASSISTANT_FILTER_PLACEHOLDER: 'Assistent',
    DEFAULT_KNOWLEDGE_BASE: 'Wissensbasis',
    DEFAULT_KNOWLEDGE_BASE_FILTER_PLACEHOLDER: 'Die Basis',
    DESCRIPTION: 'Beschreibung',
    DESCRIPTION_FILTER_PLACEHOLDER: 'Beschreibung',
    FLOW_EDITOR: 'Flow-Liste',
    END_AGE: 'Höchstalter',
    END_AGE_FILTER_PLACEHOLDER: 'Alter',
    LANGUAGE: 'Sprache',
    LANGUAGE_FILTER_PLACEHOLDER: 'Sprache',
    NO_DATA: 'Es gibt keine Flüsse in dieser Flugbahn',
    START_AGE: 'Mindestalter',
    START_AGE_FILTER_PLACEHOLDER: 'Alter',
    STEPS: 'Treten',
    WELCOME: 'Einleitende Nachricht',
    WELCOME_FILTER_PLACEHOLDER: 'Nachricht'
  },
  FORM_TEMPLATE: {
    BACK: 'Zurück zu Verwendungen',
    DETAILS_TITLE: 'Formulardetails',
    DROPDOWN: 'Dropdown-Element', // DE VALIDAT
    ELEMENT_LABEL: 'Label',
    ELEMENT_UPDATED: 'Artikel aktualisiert', // DE VALIDAT
    ENTRIES: 'Aufzeichnungen', // DE VALIDAT
    FIELD_UNDEFINED: 'Nicht definiert',
    FILE_UPLOAD: 'Datei hochladen', // DE VALIDAT
    FILTER_PLACEHOLDER: 'Filter',
    FORM_APPLY_ALL: 'Aktualisiert die Formularstruktur in vorhandenen Feeds',
    FORM_EDITOR_INSTRUCTIONS: 'Verwenden Sie das Feld unten, um das Formular zu bearbeiten. Sie können neue Felder per "Drag & Drop" aus dem rechten Bereich hinzufügen.',
    FORM_FIELDS: 'Formularfelder',
    FORM_PLACEHOLDER: 'Eine Komponente hierher ziehen',
    FORM_SUBMISSION_VIEWER: 'Form Antworten', // DE VALIDAT
    FORM_INSTANCE_VIEWER: 'Verwendungen des Formulars:',
    FORM_INSTANCE_VIEWER_SUBTITLE: 'Hier sehen Sie, wo das Formular verwendet wurde:',
    FORM_VIEW: 'Sicht',
    FORM_VIEW_SUBMISSIONS: 'Datensätze anzeigen',
    FORM_DATE: 'Datum der letzten Änderung der Formularstruktur',
    FORM_FLOW: 'Lektion',
    FORM_QUESTION: 'Frage',
    FORM_STEP: 'Schritt',
    NAME: 'Formularname',
    NO_FORM_SUBMISSIONS: 'Es gibt noch keine Antworten.',
    PLACEHOLDER_ATTRIBUTE: 'Platzhalterattribut', // DE VALIDAT
    SEE_DATA: 'Siehe Daten',
    SEND: 'Senden',
    SUBMISSIONS: 'Aufzeichnungen',
    TEXT_AREA: 'Mehrzeiliger Text', // DE VALIDAT
    TEXT_INPUT: 'Kurzer Text', // DE VALIDAT
    TITLE: 'Titelformular',
    THE_FIELD: 'Feld:', // DE VALIDAT
    THE_FORM: 'Form: ',
    UPDATE_ELEMENT: 'Aktualisieren' // DE VALIDAT
  },
  GENERAL: {
    ACTIVATE: 'Aktivieren',
    DMIN: 'Administrator',
    ASSIGN_EVENT: 'Einem Ereignis zuordnen',
    ASSIGN_EVENT_SUCCESS: 'Zuordnung erfolgreich abgeschlossen',
    ASSOCIATE_WITH_TEACHER: 'Vergessen Sie nicht, sich mit Ihren Lehrern im Abschnitt Profil -> Lehrerverbände zu verbinden.',
    ASSOCIATED_STUDENTS: 'Assoziierte Studenten',
    ADD: 'Hinzufügen',
    ADD_CHILD: 'Nachkommen hinzufügen',
    ADD_BLANK_FLOW: 'Fügen Sie eine 10-stufige Unterrichtsvorlage hinzu',
    AVAILABLE: 'verfügbar',
    BACK: 'Zurück',
    BOT_NAME: 'Ioana',
    CANCEL: 'Stornieren', // DE VALIDAT
    CHANGE_CODE: 'Ändern Sie den Code', // DE VALIDAT
    CLAIM: 'Schüler hinzufügen',
    CLOSE: 'Auflegen',
    CONFIRMED: 'Bestätigt?',
    CONFIGURE_ACCOUNT: 'Kontoeinrichtung',
    CONFIRM: 'Bestätigen',
    CONTINUE: 'Weiter',
    CORRECT_QUESTION: 'Richtig!',
    CORRECT_QUESTION_EN: 'Correct!',
    CREATED_EVENTS: 'Ereignisse erstellt',
    CREATED_FLOWS: 'Lektionen erstellt',
    INCORRECT_QUESTION: 'Falsch!',
    INCORRECT_QUESTION_EN: 'Wrong!',
    THANKS: 'Vielen Dank',
    THANKS_EN: 'Thank you!',
    CONTINUE_TYPE_DONE: 'Schreiben Sie bereit, um fortzufahren',
    LETS_CONTINUE: 'Lass uns weitermachen.',
    COPY: 'Kopieren',
    COPY_LINK: 'Link kopieren', // DE VALIDAT
    CONTINUE_TO_ASSOCIATIONS: 'Gehe zu Assoziationen',
    CURRENT_FLOW: 'Die aktuelle Lektion',
    CUT: 'Schnit',
    DELETE: 'Löschen',
    DESCRIPTION: 'Beschreibung',
    DOWNLOAD: 'Herunterladen', // DE VALIDAT.
    DOCUMENTATION: 'Dokumentation',
    EDIT: 'Ändern',
    EXPAND: 'Erweitern',
    EXPORT: 'Export',
    EXPORT_FLOW: 'Exportieren Sie die Lektion',
    EXPORT_PATH: 'Exportieren Sie die Flugbahn',
    FILTER_BY_NAME: 'Nach Benutzer filtern', // DE VALIDAT
    FILTER_BY_FLOW: 'Nach Lektion filtern', // DE VALIDAT
    FINISHED_EVENTS: 'Abgeschlossene Events', // DE VALIDAT
    FINISHED_FLOWS: 'Gewonnene Erkenntnisse', // DE VALIDAT
    FINISHED_TESTS: 'Tests abgeschlossen', // DE VALIDAT
    FLOW: 'Stream',
    FORM_FIELD_NO_ANSWER: 'Keine Antwort',
    UNLOCK_FLOW_CONFIRMATION: 'Sie haben diesen Flow nicht freigeschaltet.\nDas Entsperren kostet 1 Punkt.',
    APPLE_DETECTED: 'Wir haben festgestellt, dass Sie ein iOS-Gerät verwenden. Einige Funktionen wie die Smart Assistant-Stimme sind standardmäßig blockiert, Bitte entsperren Sie die Autoplay-Option.',
    IMPORT: 'Import',
    IMPORT_FLOW: 'Importieren Sie eine Lektion',
    IMPORT_PATH: 'Eine Flugbahn importieren',
    IN: 'in',
    GREETING: 'Hallo',
    LOGOUT: 'Beenden',
    MESSAGE: 'Botschaft',
    MONITORING: 'Überwachung', // DE VALIDAT
    NO: 'Nein',
    NO_FLUX_COVERED: 'Es gibt keine Feeds mit diesem Konto',
    NEW_VERSION_AVAILABLE: 'Nextlab hat eine neue Version!',
    OR: 'Oder',
    PATH: 'Flugbahn',
    POINTS: 'Punkte',
    PRINT: 'Druken',
    RELOAD: 'Anwendung neu laden',
    RELOAD_APP_ONE: 'Hier können Sie die Anwendung aktualisieren.', // DE VALIDAT
    RELOAD_APP_TWO: 'Klicken Sie hier, um die Webanwendung zu synchronisieren.', // DE VALIDAT
    RELOAD_TABLE: 'Laden Sie die Tabelle neu', // DE VALIDAT
    REPORT_BUG: 'Ein problem melden', // DE VALIDAT
    REORDER: 'Reorder',
    SAVE: 'Speichern',
    SELECT: 'Select',
    SELLER: 'Verkäufer',
    SELLER_MANAGER: 'Verkäufer-Manager',
    SEND: 'Senden', // DE VALIDAT
    SELECT_OPTION: 'Option auswählen',
    SHOW_FORM: 'Formular anzeigen',
    SHOW_FORM_SUBMISSIONS: 'Registrierung anzeigen', // DE VALIDAT
    START: 'Start',
    STEP: 'Schritt', // DE VALIDAT
    STUDENT: 'Schüler',
    TEACHER: 'Lehrer',
    TEMPORARY_USER: 'temporärer Benutzer',
    TESTS_TAKEN: 'Prüfungen bestanden',
    TIMEZONE: 'Europe/Bucharest',
    TIMESTAMP: 'Datum',
    UPDATE: 'Aktualisieren Sie die Anwendung', // DE VALIDAT
    UPLOAD: 'Hochladen',
    USE_THIS_ACCOUNT: 'Verwenden Sie dieses Konto',
    PASTE: 'Einfügen',
    ROLE: 'Rolle',
    USER_CLASS: 'Klasse',
    RULES: 'Details und Vorschriften', // DE VALIDAT
    SEE_CALENDAR: 'Alle Kalender anzeigen',
    SEE_EDITOR: 'Alle Lektionen anzeigen',
    SEE_LEARNING_STATS: 'siehe den ganzen Bericht',
    SEE_STUDENTS: 'Alle Schüler sehen',
    SEE_RESULTS: 'Alle Ergebnisse anzeigen',
    UPLOAD_LIMIT: 'Maximal 10 MB',
    VALIDATION: {
      FIELD_REQUIRED: 'Dieses Feld ist erforderlich!',
      NO_NULL_EXAMPLES: 'Es können keine leeren Beispiele hinzugefügt werden' // DE VALIDAT
    },
    VIDEO: 'Video',
    VIDEO_PRESENTATION: 'Video Präsentation',
    WELCOME_DIALOG_HEADER: 'Willkommen',
    WELCOME_DIALOG_MESSAGE_STUDENT: 'Besuchen Sie den Abschnitt Aktivitäten! Hier können Sie an den interessantesten Online-Lektionen und lehrreichen Hackathons teilnehmen!',
    WELCOME_DIALOG_MESSAGE_TEACHER: 'Bitte verknüpfen Sie die Konten Ihrer Schüler. Sie können dies tun, indem Sie ihnen Ihren Benutzernamen geben, oder wenn Sie ihren Benutzernamen und ihre E-Mail-Adresse kennen, können Sie im Abschnitt Profil Verknüpfungen herstellen.',
    YES: 'Ja',
    YOUR_ACCOUNTS: 'Ihre Konten'
  },
  GROUP_LIST: {
    ADD_MEMBER: 'Hinzufügen',
    ADD_CLASS: 'Klasse hinzufügen',
    ADD_EVENT: 'Ereignis hinzufügen', // DE VALIDAT
    ADD_GROUP: 'Gruppe hinzufügen', // DE VALIDAT
    ADD_MEMBERS: 'Fügen Sie Mitglieder hinzu', // DE VALIDAT
    ADD_STUDENTS: 'Fügen Sie vorhandene Schüler hinzu', // DE VALIDAT
    ADD_SUBGROUP: 'Untergruppe hinzufügen', // DE VALIDAT
    ASSOCIATE_EXISTING_STUDENTS: 'Wenn Sie die Konten Ihrer Schüler kennen, können Sie sie jetzt über die Schaltfläche hinzufügen', // DE VALIDAT
    ASSOCIATE_STUDENTS_REVERSE_1: 'Sie können sie auch bitten, sich mit Ihrem Konto zu verknüpfen', // DE VALIDAT
    ASSOCIATE_STUDENTS_REVERSE_2: 'oder assoziieren Sie sie später.', // DE VALIDAT
    COORDINATOR: 'Koordinator', // DE VALIDAT
    CLASS_NAME: 'Klassenname',
    CLASS: 'Klasse',
    CITY: 'Stadt*',
    COUNTY: 'Bezirk*',
    COUNTRY: 'Das Land*',
    CREATE_MEMBERS: 'Erstellen Sie neue Mitglieder',
    CREATE_STUDENTS: 'Erstellen Sie neue Schüler',
    CREATE_VOLUNTEERS: 'Erstellen Sie ein freiwilliges Konto', // DE VALIDAT
    DELETE_CONFIRMATION_DESCRIPTION: 'Löschen der Community bestätigen',
    DELETE_CONFIRMATION_YES: 'Yes',
    DELETE_CONFIRMATION_NO: 'No',
    EDIT_VIRTUAL_COMMUNITY: 'Editor der virtuellen Community',
    EDIT_CLASS: 'Klasseneditor',
    EDIT_COMMUNITY: 'Community-Editor',
    REGISTER_MEMBER: 'Anmeldung',
    COMMUNITIES: 'Gruppe',
    COMMUNITY_NAME: 'Name der Community',
    DELETE_GROUP: 'Löschen', // DE VALIDAT
    DELETE_GROUP_MEMBERS: 'Mitglied löschen', // DE VALIDAT
    DETAILS_TITLE: 'Gruppendetails',
    DESCRIPTION: 'Beschreibung',
    EDIT_GROUP: 'Gruppe bearbeiten', // DE VALIDAT
    EDIT_GROUP_OPTIONS: 'Optionen bearbeiten', // DE VALIDAT
    EDIT_GROUP_TITLE: 'Gruppe bearbeiten', // DE VALIDAT
    EDIT_CLASS_TITLE: 'Klassenbearbeitung',
    EMAIL: 'E-Mail-Addresse',
    CHILD_EMAIL: 'Die E-Mail-Adresse des Kindes*',
    PARENT_EMAIL: 'Emailadresse der Eltern*',
    EMAIL_VALID: 'E-Mail-Adresse muss gültig sein',
    ERROR_NAME_REQUIRED: 'Der Name ist erforderlich',
    EXPORT: 'Export',
    EXPORT_COMMUNITY_TOOTLIP: 'Exportieren Sie eine Liste der Community-Mitglieder.',
    FILTER_PLACEHOLDER: 'Suche mit Name', // DE VALIDAT
    FULLNAME: 'Name und Nachname*',
    LINK: 'Link',
    GROUP_FILTER_PLACEHOLDER: 'Suche nach Gruppe', // DE VALIDAT
    GROUP_LIST: 'Liste der Gruppen',
    GROUP_DIALOG: 'Gruppe',
    MEMBER_FILTER_PLACEHOLDER: 'Suche mit Name', // DE VALIDAT
    MEMBERS: 'Gruppenmitglieder', // DE VALIDAT
    MEMBERS_DIALOG: 'Zustände',
    NAME: 'Name',
    NEW_CLASS: 'Neue Klasse',
    NEW_COMMUNITY: 'Neue Gemeinschaft',
    NO_EVENT_AVAILABLE: 'Es sind keine Veranstaltungen geplant',
    NO_MEMBER_RECORDS: 'Es gibt keine Mitglieder in dieser Community', // DE VALIDAT
    NO_MEMBER_CLASS: 'Diese Klasse hat keine Mitglieder. Über die Schaltflächen unten können Sie Mitglieder hinzufügen.',
    NO_MEMBER_COMMUNITY: 'Diese Gemeinschaft hat keine Mitglieder. Über die Schaltflächen unten können Sie Mitglieder hinzufügen.',
    PASSWORD: 'Passwort*',
    PHONE: 'Telefonnummer (e.g. +40712345678)',
    PHONE_VALID: 'Telefonnummer muss gültig sein',
    RETURN: 'Rückkehr', // DE VALIDAT
    SAVE_CLASS: 'Klasse speichern', // DE VALIDAT
    SAVE_COMMUNITY: 'Speichern Sie die Community', // DE VALIDAT
    SEE_GROUP_MEMBERS: 'Mitglieder anzeigen', // DE VALIDAT
    SELECT_USER_PLACEHOLDER: 'Benutzer auswählen',
    SCHOOL: 'Schule',
    SCHOOL_VOLUNTEER: 'Schule (optional)',
    SKU: 'Code sku',
    CLASS_VOLUNTEER: 'Klasse (optional)',
    STUDENTS: 'Studenten',
    STUDENT_NAME: 'Vor- und Nachname des Schülers *',
    STUDENT_EMAIL: 'E-Mail-Adresse des Schülers / Elternteils',
    STUDENT_SCHOOL: 'Die Schule des Schülers',
    STUDENT_CLASS: 'Die Klasse des Schülers',
    SUCCESS: 'Erfolg',
    TYPE: 'Typ',
    TYPE_CLASS: 'Klasse',
    TYPE_SCIENTIFIC_GATHERING: 'Wissenschaftlicher Kreis',
    TYPE_GENERIC_GROUP: 'Gruppe',
    TYPE_ALL_USERS: 'Standardgruppe', // DE VALIDAT
    TYPE_NEW_COMMUNITY: 'Typ',
    TYPE_OTHERS: 'Andere',
    USERS: 'Mitglieder',
    USER_ADDED: 'Benutzer hinzugefügt',
    USER_LIST: 'Benutzerliste', // FIELDS_REQUIRED: 'Campurile marcate cu "*" sunt obligatorii',
    USERNAME_WILL_BE_GENERATED: 'Es wird generiert',
    LIST_CLASS_MEMBERS: 'Liste der Klassenmitglieder',
    LIST_COMMUNITY_MEMBERS: 'Liste der Community-Mitglieder'
  },
  INSPECTOR_DASHBOARD: {
    ACCESS_COUNT: 'Anzahl der Treffer',
    ACCESS_STATS: 'Zugriff auf Statistiken für:',
    CITY: 'Stadt',
    CITY_CHILD: 'Schule',
    CLASS: 'Klasse',
    CONCEPT_COVERAGE: 'Abdeckung der Konzepte für:',
    CONCEPT_STATS: 'Konzeptabdeckungsstatistik für: ',
    CONVERSATION_COUNT: 'Anzahl der Nachrichten',
    CONVERSATION_STATS: 'Gesprächsstatistik für: ',
    COVERAGE: 'Abdeckungsgrad',
    COUNTY: 'Bezirk', // DE VALIDAT
    COUNTY_CHILD: 'Die Stadt',
    GLOBAL_STATS: 'Globale Statistiken', // DE VALIDAT
    SCHOOL: 'Schule', // DE VALIDAT
    SCHOOL_CHILD: 'Klasse',
    SEARCH_ACCESS: 'Zugriff auf Statistiken', // DE VALIDAT
    SEARCH_CONCEPTS: 'Konzeptabdeckung', // DE VALIDAT
    SEARCH_USAGE: 'Gesprächsstatistik', // DE VALIDAT
    SEARCH_TYPE: 'Statistiktyp' // DE VALIDAT
  },
  KNOWLEDGE_MAP: {
    KNOWLEDGE_MAP_TITLE: 'Persönliche Wissenskarte', // DE VALIDAT
    INCLUDE_IN_IMPORT: 'Beinhaltet eine Wissenskarte',
    EXPORT_WITH_KM: 'Mit Wissenskarte',
    EXPORT_WITHOUT_KM: 'Keine Wissenskarte',
    NO_KNOWLEDGE_MAP: 'Es gibt keine Daten für die Wissenskarte' // DE VALIDAT
  },
  KNOWLEDGE_MAP_EDITOR: {
    COMPETENCY_ELEMENT: {
      DESCRIPTION: 'Beschreibung', // DE VALIDAT
      EXPLANATORY_LINK: 'Erklärender Link', // DE VALIDAT
      CODE: 'Code'
    },
    COMPETENCIES: 'Kompetenzen',
    CONTENT_DOMAINS: 'Inhaltsdomänen',
    CONTENT_DOMAIN_TITLE: 'Inhaltsdomäne',
    CONTENT_DOMAIN: {
      DESCRIPTION: 'Beschreibung', // DE VALIDAT
      CODE: 'Code'
    },
    CONTENT_ITEM_TITLE: 'Inhaltselement',
    CONTENT_ITEM: {
      DESCRIPTION: 'Beschreibung', // DE VALIDAT
      CODE: 'Code'
    },
    CONCEPT_TITLE: 'Konzept',
    CONCEPT: {
      DESCRIPTION: 'Konzept', // DE VALIDAT
      RESOURCE_LINK: 'Ressourcenlink', // DE VALIDAT
      RESOURCE_TYPE: 'Ressourcentyp', // DE VALIDAT
      EXISTING: 'Bestehendes Konzept', // DE VALIDAT
      NEW: 'Neues Konzept', // DE VALIDAT
      CODE: 'Code'
    },
    GENERAL_COMPETENCY_TITLE: 'Allgemeine Kompetenzen',
    GENERAL_COMPETENCY: {
      DESCRIPTION: 'Beschreibung', // DE VALIDAT
      CODE: 'Code'
    },
    KNOWLEDGE_MAP_PLACEHOLDER: 'Klicken Sie mit der rechten Maustaste, um eine Wissenskarte hinzuzufügen',
    KNOWLEDGE_MAP: {
      DESCRIPTION: 'Beschreibung', // DE VALIDAT
      CODE: 'Code'
    },
    KNOWLEDGE_MAP_TITLE: 'Wissenskarte',
    SPECIFIC_COMPETENCY: {
      DESCRIPTION: 'Beschreibung', // DE VALIDAT
      CODE: 'Code'
    },
    SPECIFIC_COMPETENCY_TITLE: 'Spezifische Kompetenz'
  },
  NOTIFICATIONS: {
    INCOMPLETE_PROFILE_TITLE: 'Profil vervollständigen',
    INCOMPLETE_PROFILE_CONTENT: 'Um Testergebnisse zu erhalten, müssen Sie Ihre Profil-E-Mail-Adresse und Telefonnummer eingeben',
    NAVIGATE_PROFILE: 'Zum Profil gehen'
  },
  MAKERSPACE_OWNER_LIST: {
    DETAILS_TITLE: 'Personendetails',
    FILTER_PLACEHOLDER: 'Filter',
    MAKERSPACE_ADDRESS: 'Adresse',
    MAKERSPACE_NAME: 'Name',
    NO_PASSWORD: 'Leeres Feld lassen, um das Passwort zu behalten',
    USER_EMAIL: 'Email',
    USER_FULLNAME: 'Name',
    USER_NAME: 'Benutzer',
    USER_PASSWORD: 'Word',
    USER_PHONE: 'Telefon'
  },
  LOCATION_LIST: {
    DETAILS_TITLE: 'Standortdetails',
    LOCATION_DESCRIPTION: 'Beschreibung',
    LOCATION_WELCOME_MESSAGE: 'Posteingang',
    LOCATION_LAT: 'Latitude',
    LOCATION_LONG: 'Länge',
    FILTER_PLACEHOLDER: 'Filter',
    LOCATION_COORDINATES: 'Koordinaten',
    OWNER: 'Makerspacer'
  },
  LOGIN: {
    BACK_TO_LOGIN: ' Zurück zum Login',
    CONTINUE_AS: 'Weiter wie',
    CONFIRM_PASSWORD: 'Bestätigen Sie Ihr Passwort',
    CHANGE_ACCOUNT: 'Account wechseln',
    FULLNAME_PLACEHOLDER: 'Vollständiger Name', // DE VALIDAT
    GO_TO_LOGIN: 'Gehen Sie zur Anmeldeseite',
    INSERT_USERNAME: 'Benutzername eingeben',
    INSERT_PASSWORD: 'Passwort eingeben',
    LANDING_MESSAGE_FIRST: 'Plattform mit kostenlosem Computer- und Robotikunterricht',
    LANDING_MESSAGE_FIRST_BNR: 'Plattform mit kostenlosem Finanzunterricht',
    LANDING_MESSAGE_SECOND: 'für das Heimstudium und den Nextlab.tech-Wettbewerb',
    LANDING_MESSAGE_SECOND_BNR: 'für das Heimstudium',
    LANDING_MESSAGE_THIRD: 'Einzelheiten und Zeitplan finden Sie unter ',
    LANDING_MESSAGE_CONTEST_ANNOUNCEMENT: 'Die Registrierung für das Nextlab.tech RoboHackaton beginnt am 1. März 2021 um 18:00 Uhr', // DE VALIDAT
    LANDING_MESSAGE_THIRD_BNR: 'Für Lektionen der 8. Klasse klicken Sie auf',
    LOGIN: 'Enter',
    LOGIN_DETAILED: 'Anmelden',
    NEW_PASSWORD_PLACEHOLDER: 'Neues Passwort',
    NOT_YOUR_ACCOUNT: 'NICHT IHR KONTO',
    PASSWORD_PLACEHOLDER: 'Word',
    PASSWORD_NO_MATCH_EXPLANATION: 'Wenn Sie die E-Mail nicht erhalten, überprüfen Sie den Abschnitt SPAM der E-Mail und stellen Sie sicher, dass Sie die richtige Adresse und den richtigen Benutzer eingegeben haben.',
    PASSWORD_RECOVERY_EMAIL_SENT: 'Wenn Ihre E-Mail-Adresse und Ihr Benutzername korrekt sind, erhalten Sie in Kürze eine E-Mail.',
    CODE_PLACEHOLDER: 'Zugangscode',
    RECOVER_CHANGE: 'Passwort ändern',
    RECOVER_PASSWORD: 'Ich habe mein Passwort vergessen',
    RECOVER_PASSWORD_EXPLANATION: 'Geben Sie Ihren Benutzernamen und Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen des Passworts in Ihrer E-Mail zu erhalten.',
    RECOVER_SEND: 'Senden',
    RECOVER_USERS: 'Ich habe meinen Benutzername vergessen',
    RECOVER_USERS_EXPLANATION: 'Geben Sie Ihre E-Mail-Adresse ein, um eine Liste der zugeordneten Benutzer zu erhalten.',
    SESSION_EXPIRED: 'Die aktuelle Sitzung ist abgelaufen',
    USERNAME_PLACEHOLDER: 'Benutzername',
    WELCOME_BACK: 'Willkommen zurück,',
    CONFIGURE_TEMPORAR_ACCOUNT: 'Dies ist ein temporäres Konto. Um fortzufahren, füllen Sie bitte die folgenden Felder aus:',
    USER_NOT_FOUND_FOR_EMAIL: 'Mit dieser E-Mail-Adresse wurden keine Benutzer gefunden!',
    USER_RECOVERY_EMAIL_SENT: 'Wiederherstellungs-E-Mail gesendet, es wird in wenigen Minuten ankommen! Wenn Sie es nicht finden können, schauen Sie im Abschnitt SPAM nach.',
    WELCOME_MESSAGE: 'Willkommen auf der Plattform',
    WELCOME_MESSAGE_DESCRIPTION_COL_TABEL: 'Um an dieser Aktivität teilzunehmen, müssen Sie Ihren Namen aus der Liste auf der linken Seite auswählen.',
    WELCOME_MESSAGE_DESCRIPTION_COL_ADD: 'Wenn Sie Ihren Namen nicht finden können, können Sie ihn hinzufügen, indem Sie ihn in das Feld rechts "Vor- und Nachname" schreiben.',
    WELCOME_MESSAGE_DESCRIPTION_NO_TABLE: 'Um an dieser Aktivität teilzunehmen, müssen Sie Ihren Namen in das Feld unten mit der Aufschrift "Name und Nachname" eingeben.',
    WELCOME_MESSAGE_DESCRIPTION_PHONE: 'Um an dieser Aktivität teilzunehmen, müssen Sie Ihren Namen aus der Liste auswählen. Wenn Sie Ihren Namen nicht finden können, können Sie ihn hinzufügen, indem Sie ihn in das Feld "Name und Nachname" schreiben.',
    NAME_AND_SURNAME: 'Name und Nachname',
    TEMPORARY_ACCOUNT: 'Temporärer Benutzer',
    COMMUNITIES: 'Gemeinschaft'
  },
  ADDITIONAL_KNOWLEDGE_BASE_EDITOR: {
    ADD_NEW_CONCEPT: 'Fügen Sie das neue Konzept hinzu',
    ADD_NEW_ENTITY: 'Neue Absicht hinzufügen',
    ADD_NEW_SAMPLE: 'Beispiel hinzufügen',
    ADD_KNOWLEDGE: 'Wissen hinzufügen', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASE: 'Wissensbasis', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASES: 'Wissensbasis', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASE_ENTITY: 'Konzeptentität', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASE_UPPER_LIMIT: 'Max',
    ADDITIONAL_KNOWLEDGE_BASE_CHARACTERS: 'Zeichen',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_RESPONSE: 'Der Assistent sagt',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_EXPLANATION: 'Mit einfachen Absichten kann der Assistent den vom Benutzer gesendeten Text klassifizieren und Antworten anbieten. Z.B:', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_EXAMPLE_1: 'Kind: "Die Räder funktionieren nicht"', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_EXAMPLE_2: 'Assistent: "Starten Sie den Motorprüfungsfluss über den Pfad \'Roboterbaugruppe\'.', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_RECOGNITION_TEXT: 'Beispiele',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY: 'Einfache Absicht',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_TABLE_TITLE: 'Einfache Absichten (fortgeschrittene Benutzer)', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASE_DESCRIPTION: 'Basisname',
    ADDITIONAL_KNOWLEDGE_BASE_INFORMATION: 'Eine Wissensbasis repräsentiert eine Reihe von Konzepten aus dem Lehrplan, die mit Definitionen und offenen Bildungsressourcen oder konventionellen Unterrichtsmaterialien verbunden sind. Neben den impliziten Wissensdatenbanken des Systems können Lehrer Definitionen und Assoziationen mit verschiedenen Bildungsressourcen ändern. Hier können Sie die Antworten der virutalen Assistenten auf Fragen wie "Was ist ...?" Konfigurieren. Wissensdatenbanken repräsentieren Bereiche oder Unterdomänen von Lernbereichen: Mathematik der fünften Klasse, Physik der sechsten Klasse, Informatik und IKT der fünften Klasse, Robotik usw.', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASE_ENTITIES: 'Entitäten und Trainingssets',
    ADDITIONAL_KNOWLEDGE_BASE_SAMPLES: 'Trainingssets',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT: 'Konzept',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT_EXAMPLE_1: 'Kind: "Was ist ein Algorithmus?"', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT_EXAMPLE_2: 'Assistent: "Der Algorithmus besteht aus einer Reihe elementarer Schritte ..."', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT_EXPLANATION: 'Konzepte werden vom Assistenten bei Fragen wie "Was ist ...?" erklärt werden. Z.B:', // DE VALIDAT
    ADDITIONAL_KNOWLEDGE_BASE_NEW_CONCEPT: 'Neues_Konzept',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS: 'Wissensdatenbankkonzepte',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS_DEFINITIONS: 'Der Assistent antwortet',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS_LINK: 'Link',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS_NOTES: 'Bemerkung',
    ADDITIONAL_KNOWLEDGE_BASE_JSON_FORMAT: 'JSON',
    DETAILS_TITLE: 'Details zur Wissensdatenbank', // DE VALIDAT
    FILTER_PLACEHOLDER: 'gefiltert',
    INVALID_INPUT_DIALOG_HEADER: 'Ungültiger Wert!',
    INVALID_INPUT_CONCEPT_NAME: 'Es gibt bereits ein Konzept mit diesem Namen! \n',
    INVALID_INPUT_ENTITY_NAME: 'Es gibt bereits eine Entität mit diesem Namen! \n',
    JSON_INVALID: 'Ungültiges Format, folgen Sie dem JSON-Standard!', // DE VALIDAT
    NEW_ENTITY: 'Neue Absicht',
    NEW_CONCEPT: 'Neues Konzept',
    NO_ADDITIONAL_KNOWLEDGE_BASES: 'Es gibt keine zusätzlichen Wissensdatenbanken', // DE VALIDAT
    TOOLTIP_VARIANT: 'Entitätsname', // DE VALIDAT
    TOOLTIP_DEFINITION: 'Beispiel: Der Algorithmus besteht aus einer Reihe von Elementarschritten ...', // DE VALIDAT
    TOOLTIP_LINK: 'Link zur Bildungsressource (Video, PDF-Dokument usw.)', // DE VALIDAT
    TOOLTIP_NOTES: 'Hinweise für die Bildungsressource (z. B. Kapitel 2, 3. Minute des Videos usw.)', // DE VALIDAT
    TOOLTIP_KEY: 'Absichtsname', // DE VALIDAT
    TOOLTIP_CONCEPT_CHIPS: 'Schlüsselwörter, die zur Beschreibung der Entität verwendet werden könnten (z. B. Roboter, Robo, Roboter, Tecnicus, MIRO usw.)', // DE VALIDAT
    TOOLTIP_SIMPLE_ENTITY_CHIPS: 'Spezifische Sätze, die die Antwort auslösen sollen' // DE VALIDAT
  },
  PATH_EDITOR: {
    PATH_TOTAL_COUNT: 'Gesamtzahl der Flugbahnen: ',
    PATH_EXPORT: 'Flugbahn',
    FLOW_EXPORT: 'Lektion',
    ANSWER: {
      IDEA: 'Erklärung',
      IS_CORRECT: 'Ist es richtig?',
      TEXT: 'Antwort'
    },
    COPY_LINK_SUCCESS: 'Link kopiert',
    ANSWER_TITLE: 'Antwort',
    FILTER_PLACEHOLDER: 'Suche nach Beschreibung', // DE VALIDAT
    INCLUDE_FORM: 'Enthält Formulare',
    FLOW: {
      ASSISTANT_SELECTOR: 'Lernassistent (d. H. Ioana, Testrax)',
      WELCOME: 'Willkommensnachricht', // DE VALIDAT
      DEFAULT_KNOWLEDGE_BASE: 'Standard-Wissensdatenbank',
      DEFAULT_ASSISTANT: 'Lernassistent',
      DESCRIPTION: 'Beschreibung',
      KNOWLEDGE_SELECTOR: 'Wissensdatenbank (d. H. Ioana-Standard, Testrax-Standard)',
      ADDITIONAL_KNOWLEDGE_BASE_SELECTOR: 'Zusätzliche Wissensdatenbanken', // DE VALIDAT
      LANGUAGE_SELECTOR: 'Sprache (d. H. Ro, en, es, fr',
      PUBLIC_CHECKBOX: 'Öffentlich verfügbar', // DE VALIDAT
      SPECIFIC_COMPETENCIES: 'Kompetenzen', // DE VALIDAT
      CONTENT_ITEMS: 'Inhaltselemente', // DE VALIDAT
      ADDITIONAL_OERS: 'Zusätzliche Lesungen',
      AGE_INTERVAL: 'Alterskategorie wählen (von 6 bis 18 Jahren)',
      AGE_FROM: 'Von',
      AGE_TO: 'Bis zu'
    },
    FLOW_TITLE: 'Lernfluss',
    FLOW_IN_TESTING: 'Zum Testen zugänglich',
    NO_DATA: 'Keine Streams erstellt.',
    NO_OWNED_PATHS_ONE: 'Es gibt keine persönlichen Flugbahnen', // DE VALIDAT
    NO_OWNED_PATHS_TWO: ' Click ',
    NO_OWNED_PATHS_THREE: ' eine Flugbahn hinzufügen.', // DE VALIDAT
    PATH: {
      DESCRIPTION: 'Beschreibung'
    },
    PATH_PLACEHOLDER: 'Klicken Sie mit der rechten Maustaste, um einen Pfad hinzuzufügen', // DE VALIDAT
    PATH_VALIDITY: 'Zur Veröffentlichung geprüft?', // DE VALIDAT
    PATH_TITLE: 'Lernpfad',
    TAGS: 'Etiketten',
    KNOWLEDGE_MAP: 'Wissenskarte',
    CHOOSE_KNOWLEDGE_MAP: 'Wählen Sie die Wissenskarte',
    QUESTION: {
      FORM_TEMPLATE: 'Formularvorlage', // DE VALIDAT
      SELECTOR: 'Selector (Testrax)',
      QUESTION_TYPE: 'Fragetyp',
      TEXT: 'Frage'
    },
    QUESTION_TITLE: 'Frage',
    SEE_FLOW_JSON: 'Stream anzeigen',
    SEE_PATH_JSON: 'Zeigen Sie die Flugbahn an',
    STEP: {
      CONCEPTS: 'Konzept',
      DESCRIPTION: 'Beschreibung',
      HELP: 'Hilfetext',
      HINT: 'Andere Indikationen',
      IS_QUESTION_STEP: 'Enthalt Fragen?',
      IMAGE: 'Link zum Bild',
      KEY: 'Schlüssel finden',
      META: 'Metadaten (JSON)',
      SOLUTION: 'Lösung',
      OER_LINK: 'Link zur Bildungsressource'
    },
    STEP_TITLE: 'Lernschritt',
    DESCRIPTION: 'Beschreibung',
    DESCRIPTION_FILTER_PLACEHOLDER: 'Beschreibung',
    EXPORT: 'Export',
    FLOWS: 'Lektionen',
    IS_VERIFIED: 'Zur Veröffentlichung bestätigt',
    VERIFIED: 'Verifiziert',
    PATH_EDITOR: 'Liste der Flugbahnen'
  },
  PACKAGE_EDITOR: {
    PACKAGE: {
      DESCRIPTION: 'Beschreibung',
      NAME: 'Name'
    },
    PACKAGE_TITLE: 'Paketdetails',
    SELECT_PATHS: 'Welche Pfade sind Teil des Pakets?'
  },
  REGISTER: {
    CHILD_REGISTERING_SELF: 'Ich bin ein Kind',
    GENERAL: {
      CHILD_NAME: 'Vor- und Nachname des Kindes',
      CONFIRM_ACCOUNT: 'E-Mail / Telefon bestätigen',
      CONFIRM_EMAIL: 'Bestätigungscode per E-Mail erhalten',
      CONFIRM_PHONE: 'Bestätigungscode per SMS empfangen',
      ESTIMATED_STUDENTS_NUMBER: 'Die geschätzte Anzahl der Studenten, die Sie einschreiben werden',
      FIELD_REQUIRED: 'Dieses Feld ist erforderlich!',
      INSERT_EMAIL: 'E-Mail-Adresse (Eltern / Lehrer)',
      PARENT_EMAIL: 'Emailadresse der Eltern',
      PARENT_PHONE: 'Telefonnummer der Eltern (e.g. +40712345678)',
      PERFORM_CONFIRM: 'Bestätigen',
      PERFORM_REGISTER: 'Registrieren',
      REGISTER_INTENT_STUDENT: 'Studentenkonto erstellen',
      REGISTER_INTENT_TEACHER: 'Lehrerkonto mit mehreren Schülern erstellen',
      SAVE_AND_ENTER_APP: 'Speichern Sie die Anwendung und geben Sie sie ein',
      USER_CITY: 'TEMP-Stadt',
      USER_CLASS: 'TEMP-Klasse',
      USER_COUNTRY: 'TEMP-Land',
      USER_COUNTY: 'TEMP-Bezirk',
      USER_EMAIL: 'Übergeordnete E-Mail-Adresse',
      USER_EMAIL_INVITED: 'E-Mail-Addresse',
      USER_NAME: 'Vor- und Nachname des Kindes',
      USER_OLD_PASSWORD: 'Altes Passwort',
      USER_PASSWORD: 'Passwort auswählen',
      USER_CONFIRM_PASSWORD: 'TEMP-Bestätigen Sie Ihr Passwort',
      USER_PHONE: 'Telefonnummer der Eltern (z. B. +40712345678)',
      USER_PHONE_INVITED: 'Telefonnummer (e.g. +40712345678)',
      USER_REGISTRATION: 'TEMP-Studentenregistrierung',
      USER_SCHOOL: 'Schule',
      USER_USERNAME: 'Wählen Sie einen Benutzernamen'
    },
    LOGIN: 'Ich möchte die Anwendung eingeben',
    PARENT: {
      CHILDREN: 'Kinder',
      MY_DATA: 'Meine Daten',
      PERFORM_CONFIRM: 'Bestätigung'
    },
    PARENT_REGISTERING_CHILD: 'Ich bin ein Elternteil',
    REGISTER_PARENT: 'Ich möchte ein Kind registrieren',
    REGISTER_SELLER: 'Ich möchte Unterricht organisieren',
    REGISTER_TRAINER: 'Ich möchte Trainer werden',
    PARENT_REGISTRATION: 'Elternregistrierung',
    PARENT_REGISTRATION_FORM_DETAILS: 'Erstellen Sie ein übergeordnetes Konto mit dem folgenden Formular',
    TEACHER_REGISTERING_CHILD: 'Ich bin ein Lehrer',
    TEACHER_REGISTRATION: 'Lehrerregistrierung',
    TEACHER_REGISTRATION_FORM_DETAILS: 'Erstellen Sie ein Lehrerkonto mit dem folgenden Formular',
    STUDENT_REGISTRATION: 'TEMP-Einschreibung von Studenten',
    STUDENT_REGISTRATION_FORM_DETAILS: 'TEMP-Erstellen Sie ein Studentenkonto mit dem folgenden Formular',
    TERMS_CONDITIONS: 'TEP-Geschäftsbedingungen',
    TERMS_CONDITIONS_ONE: 'TEMP-Ich bin einverstanden mit ',
    TERMS_CONDITIONS_TWO: 'TEMP- Nutzung der Plattform',
    PRIVACY_COOKIES_ONE: 'TEMP-Akzeptieren ',
    PRIVACY: 'TEMP-Datenschutz-Bestimmungen',
    COOKIES: 'Cookies',
    AND: ' und '
  },
  REPORTS: {
    ADD_TO_COMMUNITY: 'Zu einer Klasse / Community hinzufügen', // DE VALIDAT
    ADDITIONAL_OERS: 'Zusätzliche Messwerte', // DE VALIDAT
    AGGREGATE: 'Für alle Lektionen: ',
    ASSOCIATE_STUDENTS: 'Fügen Sie einer Klasse / Community Schüler hinzu', // DE VALIDAT
    CREATE_CLASS: 'Erstellen Sie eine neue Klasse', // DE VALIDAT
    CREATE_COMMUNITY: 'Erstelle eine neue Community', // DE VALIDAT
    COMMUNITY_DESCRIPTION: 'Klassenbeschreibung',
    COMMUNITY_NAME: 'Klassenname',
    CONFIRM_DEASSOCIATION: 'Bestätigen Sie die Trennung?', // DE VALIDAT
    DEASSOCIATE: 'Trennen Sie sich', // DE VALIDAT
    DEASSOCIATE_STUDENTS_CONFIRMATION: 'Trennen Sie die ausgewählten Schüler', // DE VALIDAT
    DESCRIPTION_LEARNING_REPORT: 'Lernbericht für:',
    DESCRIPTION_SIMPLE_TEST_REPORT: 'Support-Status testen',
    NO_STATS_FOR_USER: 'Keine Benutzerstatistik',
    QUESTIONS_CORRECT: 'Fragen richtig beantwortet',
    CORRECT: 'Richtig',
    STARTED: 'Start',
    FINISHED: 'Fertig',
    FLOW: 'Für den Strom',
    INCORRECT: 'Falsch',
    UNASWERED: 'Unbeantwortete Fragen',
    LAST_DATE: 'Zuletzt aufgerufen',
    LOADING: 'Laden ...',
    NO_ANSWERS: 'Zu diesem Zeitpunkt wurden keine Fragen beantwortet.',
    NO_GROUPS: 'Dieser Schüler hat keine verbundenen Communities',
    NO_QUESTIONS: 'Dieser Schritt enthält keine Fragen',
    NO_STATS: 'Keine Statistik für diesen Stream',
    NO_TESTS: 'Es gibt keine Tests', // DE VALIDAT
    RECLAIM_NOTICE: 'Um die Fortschritte der Schüler zu überwachen, müssen sie neu zugeordnet werden!', // DE VALIDAT
    REPORT_ANSWERS_PIECHART_TITLE: 'Die Struktur der gegebenen Antworten',
    REPORT_EXPLANATION: 'Der Bericht gibt die letzte Antwort auf jede Frage an.',
    REPORT_FREQUENCY: 'Berichte empfangen',
    REPORT_FREQUENCY_WEEKLY: 'Wöchentlich',
    REPORT_FREQUENCY_MONTHLY: 'Monatlich',
    REPORT_FREQUENCY_NONE: 'Niemals',
    REPORT_FORMS_SECTION_TITLE: 'Antworten auf Formulare',
    REPORT_QUESTIONS_TIMELINE: 'Zeitaufwand für Fragen',
    REMOVE_FROM_GROUP: 'Aus der Klasse löschen', // DE VALIDAT
    SEARCH_FOR_COMMUNITY: 'Suche nach einer Community', // DE VALIDAT
    USERS_WILL_BE_ADDED_TO_COMMUNITY: 'Benutzer werden der Klasse / Community hinzugefügt', // DE VALIDAT
    USER_ASSOCIATED_GROUPS: 'Zugewiesene Klassen', // DE VALIDAT
    TO_THE_USER: 'Nutzer', // DE VALIDAT
    SECONDS: 'Sekunden',
    TIME_TO_COMPLETION: 'Die Klärung der Frage hat lange gedauert'
  },
  SERVER_MESSAGES: {
    ACCEPTED: 'Die Operation wurde durchgeführt',
    CREATED: 'Entität erstellt',
    ERR: 'Fehler',
    ERRORS: {
      ERR_CLAIM_INCOMPLETE: 'Sie können ein Konto nicht ohne E-Mail und Benutzernamen verknüpfen',
      ERR_CODE_INVALID: 'Aktivierungscode ist ungültig',
      ERR_CODE_USED: 'Aktivierungscode bereits verwendet',
      ERR_EXISTS: 'Dieser Name wird bereits verwendet',
      ERR_FLOW_PRIVATE: 'Sie sind nicht berechtigt, auf diesen Stream zuzugreifen!',
      ERR_NOT_AUTHORIZED: 'Sie sind nicht berechtigt, diesen Vorgang auszuführen',
      ERR_NO_CREDENTIALS: 'Anmeldeinformationen sind ungültig',
      ERR_OVER_CAPACITY: 'Die Anzahl der Benutzer überschreitet die maximale Anzahl',
      ERR_INCORRECT_TOKEN: 'Versuchen Sie erneut, eine Verbindung zur App herzustellen',
      ERR_INVALID_IMPORT_FLOW: 'Sie haben versucht, eine Flugbahn hochzuladen, keine Lektion',
      ERR_INVALID_IMPORT_PATH: 'Sie haben versucht, eine Lektion hochzuladen, keine Flugbahn',
      ERR_MATCH_TOKEN: 'Versuchen Sie, die Verbindung zur Anwendung wiederherzustellen',
      ERR_NO_ASSISTANT: 'Kein Lernassistent ausgewählt', // DE VALIDAT
      ERR_NO_EXISTING_PATH: 'Sie können keinen vorhandenen Pfad hinzufügen',
      ERR_NO_FLOW_SELECTED: 'Kein Lernstrom ausgewählt',
      ERR_NO_KNOWLEDGEBASE: 'Keine Wissensdatenbank ausgewählt', // DE VALIDAT
      ERR_NO_LANGUAGE: 'Die Assistentensprache wurde nicht ausgewählt',
      ERR_NOT_FOUND: 'Nicht gefunden',
      ERR_NOT_IMPLEMENTED: 'Operation noch nicht implementiert',
      ERR_SERVER_ERROR: 'Es ist ein Serverfehler aufgetreten',
      ERR_TIME_EXPIRED: 'Die für diesen Vorgang zulässige Zeit ist abgelaufen',
      ERR_DUPLICATE_USER: 'Ein Benutzer mit diesem Namen existiert bereits',
      ERR_ONLY_STUDENT_CLAIM: 'Es kann nur ein Studentenkonto zugeordnet werden',
      ERR_REQUIRED_EMAIL: 'E-Mail-Adresse erforderlich',
      ERR_REQUIRED_PHONE: 'Telefonnummer erforderlich',
      ERR_INSUFFICIENT_FUNDS: 'Unzureichende Punkte',
      ERR_INVALID_EMAIL: 'E-Mail-Adresse ist ungültig',
      ERR_INVALID_PHONE: 'Telefonnummer ist ungültig',
      ERR_INVALID_VALUE: 'Ungültiger Wert!',
      ERR_EXISTS_MANY: 'Einer der Benutzer existiert bereits',
      ERR_REQUIRED_STUDENT_CAPACITY: 'Maximale Anzahl erforderlicher Kopien',
      ERR_REQUIRED_DURATION: 'Dauer erforderlich',
      ERR_REQUIRED_LOCATION: 'Standort erforderlich',
      ERR_TOKEN_EXPIRED: 'Link ist abgelaufen',
      ERR_JSON_INVALID: 'Ungültige Struktur!',
      ERR_DUPLICATE_KNOWLEDGE_BASE: 'Es gibt bereits eine Wissensbasis mit diesem Namen', // DE VALIDAT
      ERR_PHONE_LENGTH: 'Die Telefonnummer muss mindestens 10 Zeichen lang sein', // DE VALIDAT
      ERR_FILE_UPLOAD_LIMIT_EXCEEDED: 'Die ausgewählte Datei ist zu groß! Es sollte 10 MB nicht überschreiten.'
    }
  },
  STEP_EDITOR: {
    STEP_EDITOR: 'Liste der Schritte',
    NO_DATA: 'Es gibt keine Schritte für diese Lektion',
    DESCRIPTION: 'Beschreibung',
    DESCRIPTION_FILTER_PLACEHOLDER: 'Beschreibung',
    OER_LINK: 'OER link',
    OER_LINK_PLACEHOLDER: 'OER',
    HELP: 'Hilfstext',
    HELP_FILTER_PLACEHOLDER: 'Hilfe',
    HINT: 'Anregung',
    HINT_FILTER_PLACEHOLDER: 'Anregung',
    QUESTION_EDITOR: 'Frageneditor',
    QUESTION_EDITOR_GUIDE: {
      TITLE: 'Anleitung zur Verwendung des Frageneditors.',
      GENERAL_INIDCATION_ONE: 'Im Frageneditor repräsentiert jede Spalte ein Attribut der Frage oder Antwort der erweiterten Frage und die Zeilen repräsentieren die Daten zur Frage bzw. zur Antwort.',
      GENERAL_INIDCATION_TWO: 'Fragen mit Antworten stehen in der Spalte „Nein“. einen Pfeil, um die Antworten auf eine Frage anzuzeigen oder zu ändern, klicken Sie auf den Pfeil, der der Frage entspricht.',
      QUESTION_ACTIONS: {
        TITLE: 'Aktionen bei Fragen',
        ADD_QUESTION: {
          TITLE: 'Frage hinzufügen',
          STEP_ONE: 'Das Hinzufügen einer Frage erfolgt durch Klicken auf die PLUS-Schaltfläche am unteren Rand der Tabelle.',
          STEP_TWO: 'Es erscheint ein Formular, das mit den Informationen zu der hinzugefügten Frage ausgefüllt wird. Nach dem Ausfüllen des Formulars klicken Sie auf die Schaltfläche SPEICHERN.'
        },
        EDIT_QUESTION: {
          TITLE: 'Bearbeiten Sie eine vorhandene Frage',
          STEP_ONE: 'Das Bearbeiten einer bestehenden Frage erfolgt durch direktes Klicken auf die Fragezelle in der zu ändernden Tabelle.',
          STEP_TWO: 'Nach dem Anklicken der Zelle erscheint ein Eingabefeld, das mit den neuen Informationen ausgefüllt wird.',
          STEP_THREE: 'Das Speichern der Fragenänderung wird abgeschlossen, wenn die Schaltfläche mit der Diskette in der Zeile der bearbeiteten Frage angeklickt wird.'
        },
        DELETE_QUESTION: {
          TITLE: 'Frage löschen',
          STEP_ONE: 'Das Löschen einer Frage erfolgt durch Klicken auf die rote Schaltfläche in der Zeile, die der Frage entspricht'
        }
      },
      ANSWER_ACTIONS: {
        TITLE: 'Aktionen auf Antworten',
        ADD_ANSWER: {
          TITLE: 'Antwort hinzufügen',
          STEP_ONE: 'Das Hinzufügen einer Antwort erfolgt durch Klicken auf die PLUS-Schaltfläche neben der Frage, zu der Sie die Antwort hinzufügen möchten.',
          STEP_TWO: 'Unter der ausgewählten Frage erscheint in der Tabelle eine Zeile mit der Meldung "Hier klicken, um .."',
          STEP_THREE: 'Sobald die Schaltfläche zum Hinzufügen einer Antwort gedrückt wurde, können die Informationen in der Tabelle nicht mehr geändert werden.',
          STEP_FOUR: 'Um die Informationen erneut ändern zu können, ist es notwendig, den Vorgang des Hinzufügens einer Antwort abzuschließen oder den Vorgang abzubrechen, indem Sie die MINUS-Taste in der Zeile drücken, die die neue Frage enthält.',
          STEP_FIVE: 'Jede Zelle, die die Nachricht "Hier klicken, um .." enthält, wird angeklickt, und anstelle der Nachricht wird ein Feld angezeigt, in das die Informationen entsprechend der Spalte in der neuen Antwort eingegeben werden können.',
          STEP_SIX: 'Nach dem Ausfüllen der Felder mit den erforderlichen Informationen wird das Hinzufügen einer Antwort durch Drücken der entsprechenden PLUS-Schaltfläche neben der Zeile mit der neuen Antwort abgeschlossen.'
        },
        EDIT_ANSWER: {
          TITLE: 'Bearbeiten einer vorhandenen Antwort',
          STEP_ONE: 'Das Bearbeiten einer vorhandenen Antwort erfolgt durch direktes Klicken auf die Antwortzelle in der zu ändernden Tabelle.',
          STEP_TWO: 'Nach dem Anklicken der Zelle erscheint ein Eingabefeld, das mit den neuen Informationen ausgefüllt wird.',
          STEP_THREE: 'Das Speichern der Änderung der Antwort ist abgeschlossen, wenn in der Zeile der bearbeiteten Antwort auf die Schaltfläche mit der Diskette geklickt wird.'
        },
        DELETE_ANSWER: {
          TITLE: 'Antwort löschen',
          STEP_ONE: 'Das Löschen einer Antwort erfolgt durch Klicken auf die rote Schaltfläche in der Zeile, die der Antwort entspricht'
        }
      }
    },
    QUESTION_PREVIEW_TABLE: {
      QUESTION_TYPE: 'Typ der Frage',
      TEXT: 'Frage',
      ANSWERS: 'Antworten',
      NO_EXISTING_QUESTIONS: 'Der Schritt enthält keine Fragen'
    },
    SOLUTION: 'Lösung',
    SOLUTION_FILTER_PLACEHOLDER: 'Lösung',
    IMAGE: 'Bild',
    IS_QUESTION_STEP: 'Schritt mit Fragen'
  },
  STEP_STATS: {
    ANSWER: 'Antworten',
    ATTEMPT_NUMBER: 'versuchen',
    DATE: 'Datum',
    DURATION: 'Dauer der Fahrt',
    FIELD: 'Gebiet',
    FIELD_SUBMISSION: 'Antworten',
    FLOW: 'Lektion',
    IN_PROGRESS: 'Laufend',
    INTERVAL_START: 'Fing an bei',
    MINUTE: 'minute',
    MINUTES: 'minute',
    NO_ANSWER: 'Es wurde keine Antwort aufgezeichnet',
    NO_STEP_QUESTIONS: 'Dieser Schritt hat keine Fragen',
    QUESTION: 'Die Frage',
    STEP: 'Schritt',
    STEP_STATS_TITLE: 'Schrittbericht',
    TIME_INTERVAL: 'Zeitintervall'
  },
  STUDENT_DASHBOARD: {
    CREATED_BY: 'Hergestellt von:',
    DATE: 'Datum:',
    FLOW_PREVIEW: 'Flow-Vorschau',
    STEP_NO_FORMS: 'Der Schritt enthält keine Formulare.',
    STEP_FORM: 'Der Schritt enthält das Formular',
    STEP_FORMS: 'Der Schritt enthält die Formulare',
    FLOW_TITLE: 'Flow-Titel: ',
    NO_QUESTIONS_STEP: 'Der Schritt enthält keine Fragen.',
    ONE_QUESTIONS_STEP: 'Der Schritt enthält eine Frage.',
    QUESTIONS: 'Frage',
    THE_STEP: 'Schritt',
    THE_STEP_CONTAINS: 'Der Schritt enthält',
    RESUME_FLOW: 'Fahren Sie dort fort, wo Sie aufgehört haben',
    STEP_TAKEN: 'Route',
    PLATFORM_INSTRUCTIONS: 'Anleitung zur Plattform',
    INSTRUCTIONS: {
      FIRST: '1. Unterricht nehmen',
      SECOND: '2. Anzeigen von Berichten',
      THIRD: '3. Zusammenarbeit mit Lehrern',
      FOURTH: '4. Persönliche Daten bearbeiten',
      FIFTH: '5. Robohackaton von Nextlab.tech'
    }
  },
  TAB_TITLES: {
    ADDITIONAL_KNOWLEDGE_BASE_EDITOR: 'Wissensdatenbanken', // DE VALIDAT
    ASSISTANT_BUILDER: 'Schnittassistenz',
    CLASSIFIER_BUILDER: 'Klassifikatoren',
    FORM_TEMPLATE_ADMINISTRATION: 'Formularen',
    INSPECTOR_DASHBOARD: 'Globale Statistiken',
    SELLER_EVENT_ADMINISTRATION: 'Veranstaltung - seller',
    TRAINER_EVENT_ADMINISTRATION: 'Veranstaltung - trainer',
    KNOWLEDGE_BASE_BUILDER: 'Wissenseditor',
    KNOWLEDGE_MAP: 'Wissenskarte', // DE VALIDAT
    KNOWLEDGE_MAP_EDITOR: 'Knowledge Map Editor', // DE VALIDAT
    LEARNING_ASSISTANT: 'Lernassistent',
    LEARNING_PROGRESS: 'Schülerüberwachung',
    LEARNING_STATS: 'Lernbericht',
    LOCATION_EDITOR: 'Standorte',
    MAKERSPACE_EVENT_ADMINISTRATION: 'Veranstaltung - makerspace',
    OWNED_PATH_BUILDER: 'Editor für persönliche Flugbahnen',
    PARENT_DASHBOARD: 'Elternteil Dashboard',
    PATH_EDITOR: 'Lernpfad-Editor',
    PRIMARY_DASHBOARD: 'Dashboard',
    PRIVATE_LIBRARY: 'Private Bibliothek',
    PROFILE_EDITOR: 'Profil',
    SELLER_COMMUNITY_ADMINISTRATION: 'Virtuelle Gemeinschaften',
    STUDENT_DASHBOARD: 'Bibliothek',
    TAG_DEFINITION_EDITOR: 'Etiketten',
    TEACHER_DASHBOARD: 'Dashboard-Lehrer',
    TEACHER_PATH_EDITOR: 'Editor für persönliche Flugbahnen',
    TEST_STATS: 'Testbericht',
    TRAINER_DASHBOARD: 'Dashboard-Trainer',
    ROBOHACKATON_USERS: 'Anmeldungen 2021', // DE VALIDAT
    SIMPLE_KNOWLEDGE_BASE_BUILDER: 'Wissensdatenbanken',
    SCAFFOLD: '?!?',
    TEST_LIST_TEACHERS: 'Testliste', // DE VALIDAT
    TEST_LIST_STUDENTS: 'Testergebnisse',
    USER_EDITOR: 'Benutzer'
  },
  TAG_DEFINITION_LIST: {
    DETAILS_TITLE: 'Derails-Etikett',
    TAG_FACETS: 'Anwendbar auf Facetten',
    TAG_NAME: 'Etikett',
    TAG_DESCRIPTION: 'Etikettenbeschreibung'
  },
  TEACHER_DASHBOARD: {
    INTRO_ONE: 'Hier können Sie Ihre eigenen Lektionen erstellen. Diese können auf Lernereignisse zurückgeführt werden. Standardmäßig sind die Lektionen in der Bibliothek auf der Lernplattform nicht sichtbar. Um in der Bibliothek veröffentlicht zu werden, müssen Pfade von einem Administrator genehmigt werden.', // DE VALIDAT
    INTRO_TWO: 'Sie können dies im Support-Bereich anfordern: ', // DE VALIDAT
    FILTER_PLACEHOLDER: 'Suche nach Benutzer', // DE VALIDAT
    TESTS_TAKEN: 'Unterstützte Tests',
    CHOOSE_FLOW: 'Wählen Sie einen Lernfluss', // DE VALIDAT
    CHOOSE_PATH: 'Wählen Sie einen Lernpfad',
    NO_ASSOCIATED_STUDENTS_ONE: 'Es sind keine Studenten verbunden',
    NO_ASSOCIATED_STUDENTS_TWO: 'Bitten Sie die Schüler, sich mit Ihrem Benutzernamen mit Ihrem Konto zu verbinden.', // DE VALIDAT
    PATH: 'Lernpfad',
    TEACHER_PATHS: 'Persönliche Flugbahnen',
    INTENT: 'Die Absicht identifiziert', // DE VALIDAT
    DONT_UNDERSTAND: 'Ioana verstand nicht' // DE VALIDAT
  },
  TEACHER_LIST: {
    DETAILS_TITLE: 'Lehrerdetails',
    FILTER_PLACEHOLDER: 'Filter',
    NO_PASSWORD: 'Leeres Feld lassen, um das Passwort zu behalten',
    USER_EMAIL: 'E-Mail',
    USER_FULLNAME: 'Name',
    USER_NAME: 'Benutzer',
    USER_PASSWORD: 'Word',
    USER_PHONE: 'Telefonnummer'
  },
  TEST_LIST_TEACHERS: {
    ACCESS_TOKEN: 'Besprechungscode',
    ADJUST_FILTERS_OR_MAKE_TEST: 'Erstellen Sie einen Test über die blaue Schaltfläche "Neuen Test erstellen"!',
    ADVANCED_SEARCH: 'Erweiterte Suche',
    ANSWERS: 'Antworten',
    ANSWER: 'Antworten',
    ANSWER_GIVEN: 'Antwort gegeben: ',
    ANSWER_CONSIDERED_CORECT: 'Die Antwort wird berücksichtigt: Richtig',
    ANSWER_CONSIDERED_INCORECT: 'Die Antwort wird berücksichtigt: Falsch',
    BACK_TO_EVENT_LIST: 'Zurück',
    CONFIDENCE_LEVEL: 'mit einer Genauigkeit von: ',
    COORDINATOR: 'Koordinator',
    CORRECT: 'Richtig',
    DATE_ANSWERED: ' Antwort gegeben am : ',
    DATE_EDIT_ANSWER: 'Die Antwort wurde zuletzt bearbeitet am : ',
    DELETE: 'Streichung',
    DETAILS: 'Einzelheiten',
    DETAILS_LAST_EDIT: 'Die letzte Bearbeitung der Partitur erfolgte am:',
    DISABLED_UNTIL_TEST_BEGINS: 'Sie können Ihren Fortschritt nach dem Start des Tests überwachen',
    DISABLED_UNTIL_TEST_GRADED: 'Sie können die Ergebnisse anzeigen, nachdem Sie den Test korrigiert haben',
    EVENT_DURATION: 'Testdauer',
    EVENT_DURATION_FILTER_PLACEHOLDER: 'Suche nach Testdauer',
    EVENT_FILTER_PLACEHOLDER: 'Besprechungscode',
    EVENT_INSTANCE_FILTER: 'Testdaten',
    EVENT_NAME: 'Testname',
    EVENT_NAME_FILTER_PLACEHOLDER: 'Namenssuche testen',
    EVENT_START_DATE: 'Teststartdatum',
    EVENT_START_DATE_FILTER_PLACEHOLDER: 'Startdatum der Suche',
    EXCEL_EXPORT_ALL: 'Exportieren Sie alle Berichte',
    EXCEL_EXPORT_SELECTED: 'Auswahlberichte exportieren',
    EXPORT_SELECTED: 'Exportieren Sie die ausgewählten Benutzer',
    EXPORT_TESTS: 'Es wird exportiert aus: ',
    FILTER_PLACEHOLDER: 'Suche nach Schülernamen',
    FINALIZED: 'Abgeschlossen',
    FOR_THE_TEST: 'zum: ', // DE VALIDAT
    FOUND: 'Sie wurden gefunden',
    GRADING_IN_PROGRESS: 'In Auswertung',
    HIDE_SIMILAR_ANSWER: 'Ähnliche Antworten ausblenden',
    INCORRECT: 'Falsch',
    INTERVAL_START_DATE: 'Von',
    INTERVAL_END_DATE: 'Bis zu',
    INTRO_MESSAGE_ONE: 'Bitte verbinden Sie sich mit einem Lehrer, mit dem Sie arbeiten. Sie können dies im Abschnitt tun ',
    INTRO_MESSAGE_TWO: ', Angabe des Benutzernamens des Lehrers. Wenn Sie nicht mit einem Lehrer arbeiten, mit dem "Roboprof" -Nutzer der Plattform verbinden, der vom System angebotene Lehrer.',
    LAUNCH_A_TEST: 'Erstellen Sie einen neuen Test',
    LINK_VIDEO: 'Link zum Video : ',
    LIST_OF_UNFINISHED_TESTS: 'Unkorrigierte Tests',
    LIST_OF_FINISHED_TESTS: 'Korrigierte Tests',
    LIST_OF_DELETED_TESTS: 'Gelöschte Tests',
    MAXIMUM_POINTS: 'Max Punkte',
    NAME_AND_SURNAME: 'Name und Nachname',
    MODEL_ANSWER: 'Die erwartete Antwort :',
    MODIFY_VERDICT: 'Ändern Sie die Notiz',
    NO_ANSWER_TO_QUESTION: 'Diese Frage wurde nicht beantwortet',
    NO_EVENTS_TO_SHOW: 'Keine Tests gefunden.',
    NO_FORM_ANSWER: 'Dieser Schritt, der dynamische Form verwendet, wurde nicht beantwortet',
    NO_OBSERVATIONS: 'Keine Kommentare',
    NO_PARTICIPANTS: 'Zahl der Teilnehmer',
    NO_PARTICIPANTS_FILTER_PLACEHOLDER: 'Nummernsuche',
    NO_TESTS_TO_SHOW: 'Es gibt keine Ergebnisse für dieses Treffen.',
    NUMBER_TESTS_UNFINALIZED: 'Anzahl der nicht korrigierten Tests',
    NUMBER_TESTS_FINALIZED: 'Anzahl der korrigierten Testse : ',
    NUMBER_TESTS_DELETED: 'Anzahl der gelöschten Tests : ',
    OBSERVATIONS: 'Bemerkungen :',
    OBTAINED_POINTS: 'Punktzahl erhalten',
    POINTS: 'Bewerten Sie Fragen mit automatischer Korrektur',
    POINTS_DONT_INCLUDE_FORMS: 'Die Partitur enthält keine Formularfragen',
    REGENERATE_WARNING_DIALOG_HEADER: 'Vorsichtig!',
    REGENERATE_WARNING_DIALOG_CONTENT: 'Die automatische Korrektur führt zur Neuklassifizierung von Antworten aus unkorrigierten Tests unter Verwendung des bestehenden maschinellen Lernalgorithmus!',
    REGRADE_TESTS: 'Automatische Korrektur',
    RESPONSE_IS: 'Die Antwort ist:',
    SAVE: 'speichern',
    SAVE_AND_FINALIZE: 'Speichern und vervollständigen',
    SEARCH: 'Suchen nach',
    SEE_SIMILAR_ANSWERS: 'Siehe ähnliche Antworten',
    SELECT: 'Wählen',
    SELECT_EVENT: 'Test auswählen',
    SELECT_EVENT_INSTANCE: 'Wählen Sie einen Termin',
    SELECT_TEST: 'Wählen Sie den Test aus, den Sie anzeigen möchten:',
    SELECT_TIME_INTERVAL: 'Das Zeitintervall, in dem der Test durchgeführt wurde',
    SIMILAR_ANSWERS: 'Ähnliche Antworten',
    SIMILAR_ANSWERS_LOWERCASE: 'ähnliche Antworten',
    SIMILARITY: 'Ähnlichkeit',
    SHOW_ONLY_ASSOCIATED_STUDENTS: 'Nur assoziierte Schüler anzeigen',
    STATUS_QUESTIONS: 'Statusfragen',
    STUDENT: 'vom Studenten',
    TAB_DESCRIPTION_UNFINISHED_TESTS: 'Nicht korrigierte Tests können hier eingesehen werden',
    TAB_DESCRIPTION_FINISHED_TESTS: 'Die korrigierten Tests können hier eingesehen werden',
    TABPANEL_BACK: 'Kehren Sie zur Besprechungsliste zurück.',
    TABPANEL_DELETED_TESTS: 'Gelöschte Tests',
    TABPANEL_FINALIZED_TESTS: 'korrigierte Tests',
    TABPANEL_NO_DELETED_TESTS: 'Es gibt keine gelöschten Tests für dieses Meeting',
    TABPANEL_UNFINALIZED_TESTS: 'unkorrigierte Tests',
    TABPANEL_NO_UNFINALIZED_TESTS: 'Es gibt keine unkorrigierten Tests für dieses Treffen',
    TABPANEL_NO_FINALIZED_TESTS: 'Für dieses Meeting gibt es keine korrigierten Tests',
    TEST_DATE: 'Testdatum',
    TEST_FLOW: 'Testablauf',
    TEST_FLOW_FILTER_PLACEHOLDER: 'Testablaufsuche',
    TEST_LIST: 'Prüfung',
    TEST_LIST_DESCRIPTION: 'Hier sehen Sie die Ergebnisse der organisierten Tests.',
    TEST_LIST_STUDENTS_EXPLANATION: 'Die Tests werden mit einem maschinellen Lernalgorithmus korrigiert, die Ergebnisse werden gemäß dem vom Koordinator bekannt gegebenen Zeitplan verfügbar sein.',
    TEST_RESULTS: 'Situationsfragen',
    TEST_STATUS: 'Teststatus',
    TEST_STATUS_FILTER_PLACEHOLDER: 'Teststatussuche',
    TEST_STATUS_FINISHED: 'Abgeschlossen',
    TEST_STATUS_GRADING: 'In Korrektur',
    TEST_STATUS_GRADED: 'Uberarbeitet',
    TEST_STATUS_SCHEDULED: 'Programmierer',
    TEST_STATUS_ONGOING: 'Laufend',
    TEST_STATISTICS: 'Teststatistik',
    TOTAL_POINTS_EARNED: 'Gesamtpunktzahl: ',
    TOTAL_POINTS: 'Gesamtpunktzahl :',
    TOTAL_STEPS_TEST: 'Gesamttestschritte: ',
    TOTAL_QUESTIONS_TEST: 'Insgesamt Testfragen : ',
    TOTAL_CORECT_ANSWERS: 'Völlig richtige Antworten : ',
    TOTAL_INCORECT_ANSWERS: 'Völlig falsche Antworten : ',
    TOTAL_UNASWERED_QUESTIONS: 'Insgesamt unbeantwortete Fragen : ',
    USER: 'Nutzer',
    UNDELETE: 'Wiederherstellung',
    USERNAME: 'Benutzer',
    VIEW: 'Aussicht'
  },
  USER_LIST: {
    ALL_MEMBERS: 'Alle Mitglieder',
    ASSOCIATE_TEACHERS_EXPLANATION: 'Verbinden Sie sich mit Ihren Lehrern, indem Sie in der Auswahl unten nach ihnen suchen.',
    ASSOCIATE_TEACHERS_PLACEHOLDER: 'Nutzername',
    ASSOCIATED_TEACHERS: 'Assoziierte Lehrer', // DE VALIDAT
    ASSOCIATED_USERS: 'Zugehörige Benutzer', // DE VALIDAT
    ASSOCIATED_STUDENTS: 'Assoziierte Studenten',
    ASSOCIATION_SUCCESSFUL: 'Erfolgreicher Verein!', // DE VALIDAT
    BILLING_MAKERSPACE: 'Original Makerspace',
    CONVERSATION_HISTORY: 'Gesprächsgeschichte',
    CURRENT_MAKERSPACE: 'Aktuell Makerspace',
    DELETE_ASSOCIATION_CONFIRMATION_DESCRIPTION: 'Assoziation löschen?',
    DETAILS_TITLE: 'Details zum Studenten',
    FACETS: 'Facette', // DE VALIDAT
    GROUP: 'Gruppe',
    KNOWLEDGE_MAP: 'Wissenskarte',
    LEARNING_REPORT: 'Lernbericht', // DE VALIDAT
    MAKERSPACES: 'Makerspaces',
    NO_PASSWORD: 'Leeres Feld lassen, um das Passwort zu behalten',
    PAYMENT_TYPE: 'Abonnementtyp',
    FILTER_PLACEHOLDER: 'Suche nach Benutzername', // DE VALIDAT
    TEST_STATUS: 'Testen Sie den Support-Status', // DE VALIDAT
    USER_CURRENT_STEP: 'Aktueller Schritt', // DE VALIDAT
    USER_STEPS_TAKEN: 'Schritte gemacht',
    USER_CITY: 'Stadt',
    USER_CLASS: 'Klasse',
    USER_COUNTRY: 'Land',
    USER_COUNTY: 'Bezirk', // DE VALIDAT
    USER_EMAIL: 'E-Mail-Adresse',
    USER_ROLE: 'Rolle', // DE VALIDAT
    USER_FACETS: 'Facetten',
    USER_FULLNAME: 'Name',
    USER_LEARNING_STATS: 'Lernbericht',
    USER_NAME: 'Benutzer',
    USER_PARENT: 'Assoziierter Elternteil',
    USER_PASSWORD: 'Word',
    USER_PHONE: 'Telefonnummer',
    USER_SCHOOL: 'Schule', // DE VALIDAT
    USER_STATUS: 'Status',
    USER_TEACHER: 'Lehrer', // DE VALIDAT
    USER_USERNAME: 'Nutzername',
    WALLET: 'Elektronische Brieftasche',
    WALLET_BALANCE: 'Punkte',
    WALLET_EXPIRY: 'Ablaufdatum',
    FACET_FILTER_PLACEHOLDER: 'Die gesuchte Facette',
    FULLNAME_FILTER_PLACEHOLDER: 'Gesuchter Name', // DE VALIDAT
    EMAIL_FILTER_PLACEHOLDER: 'Gesuchte E-Mail', // DE VALIDAT
    PHONE_FILTER_PLACEHOLDER: 'Telefon gesucht', // DE VALIDAT
    USERNAME_FILTER_PLACEHOLDER: 'Benutzer gesucht' // DE VALIDAT
  },
  WORKING_GROUP_LIST: {
    ACTIVATE_FLOW: 'Stream aktivieren',
    ADD_FLOW_TO_GROUP: 'Feed zur Gruppe hinzufügen',
    DESCRIPTION: 'Beschreibung',
    CLASSROOM: 'Ort',
    GROUP: 'Gruppe',
    DETAILS_TITLE: 'Gruppendetails',
    SELECT_FLOW: 'Stream auswählen',
    SELECT_PATH: 'Pfad auswählen',
    IMPORT_STUDENTS: 'Schüler importieren'
  },
  PROFILE_LIST: {
    ACCOUNT_DETAILS: 'Kontodetails', // DE VALIDAT
    ADVANCED_SETTINGS: 'Erweiterte Einstellungen', // DE VALIDAT
    ASSOCIATIONS: 'Lehrer- / Elternverbände', // DE VALIDAT
    COMMUNITIES_ASSOCIATIONS: 'Gemeindeverbände',
    COMMUNITIES_ASSOCIATIONS_TITLE: 'Ihre Gemeinden',
    COMMUNITIES_ASSOCIATIONS_DESCRIPTION: 'In der folgenden Tabelle sehen Sie, zu welchen Communities Sie gehören:',
    COMMUNITY_OWNER_NAME: 'Professor',
    COMMUNITY_NAME: 'Community-Name',
    COMMUNITY_LINK: 'Community-Link',
    COMMUNITY_DESCRIPTION: 'Community-Beschreibung',
    COMMUNITY_TIMESTAMP_ADDED: 'Datum hinzugefügt',
    NO_COMMUNITY: 'Hier sehen Sie die Liste der Communities, denen Sie angehören, nachdem Sie von Ihren Lehrern hinzugefügt wurden.',
    REPORTS_FREQUENCY: 'Berichte Häufigkeit', // DE VALIDAT
    MODIFY_PASSWORD: 'Passwort ändern',
    MODIFY_SUCCESS_GROWL: 'Angepasst!',
    PASSWORD_CONFIRMATION: 'Bestätigen Sie Ihr Passwort',
    PASSWORD_CHANGED_SUCCESS: 'Passwort geändert',
    PASSWORD_CHANGE_CONFIRMATION: 'Möchten Sie Ihr Passwort wirklich ändern?',
    PASSWORD_LENGTH_ERROR: 'Das Passwort ist zu kurz!',
    PASSWORD_MATCH_ERROR: 'Passwörter müssen gleich sein',
    RELOAD_APP: 'Anwendungsaktualisierung', // DE VALIDAT
    STUDENT_ASSOCIATIONS: 'Studentenvereinigungen', // DE VALIDAT
    STUDENT_ASSOCIATIONS_NO_USERS_ASSOCIATED: 'Sie haben keine assoziierten Studenten. Verbinden Sie sich mit Ihren Schülern mithilfe ihres Benutzernamens und ihrer E-Mail-Adresse. Alternativ können Sie sie bitten, sich mit Ihrem Benutzernamen mit Ihnen zu verbinden.',
    NO_REPORTS: 'In der kostenlosen Version der Anwendung ist die automatische Berichterstellung inaktiv', // DE VALIDAT
    STUDENT: 'Schüler',
    TEACHER: 'Lehrer',
    TWO_FACTOR_AUTHENTICATION_MANAGER: 'Zwei-Faktor-Authentifizierung'
  },
  LEARNING_PROGRESS: {
    EVENT: 'Veranstaltung',
    FLOW: 'Fließen', // DE VALIDAT
    DATE: 'Datum',
    DURATION: 'Zeit',
    START_HOUR: 'Die Zeit begann',
    SEARCH_EVENT: 'Suche nach Ereigniscode oder Link',
    SEARCH_INPUT_PLACEHOLDER: 'Der Code oder Link des Ereignisses',
    NOT_STARTED: 'Jungfrau',
    RESTARTED: 'Neu gestartet',
    STARTED: 'Sein',
    FINISHED: 'Getan',
    CONFIRM_DELETE_PARTICIPANT: 'Bestätigen Sie die Löschung des Teilnehmers?',
    DELETE_CONFIRMATION_YES: 'Ja',
    DELETE_CONFIRMATION_NO: 'No'
  },
  LEARNING_ASSISTANT: {
    CLASSIC_VIEW: 'Chatbot',
    CLICK: 'Drücken Sie ',
    END_FLOW: 'Stream-Schließung bestätigen',
    EXPLANATORY_NOTE: 'Hinweis: Geben Sie "Bereit" ein, um fortzufahren',
    FOR_DEMO_FLUX: 'für einen Demonstrationsablauf.',
    FOR_LIBRARY: ' für den Bereich Bibliothek.',
    HERE: ' Hier ',
    HIDE_MESSAGES: 'Nachrichten ausblenden',
    LOADING: 'Wird geladen...',
    NAVIGATOR_EXPLANATION: 'Klicken Sie auf das ">"-Zeichen neben dem gewünschten Schritt, um dorthin zurückzukehren und die vorherige Antwort zu überschreiben, die letzte Antwort wird berücksichtigt.',
    NAME: 'Name',
    NO_PREVIOUS_ANSWERS: 'Keine vorherigen Antworten gefunden.',
    NO_SELECTED_FLOW: 'Kein ausgewählter Lernfluss.',
    PREVIOUS_ANSWERS: 'Deine Antworten',
    SLIDE_VIEW: 'Gleiten',
    SHOW_ANSWERS: 'Arată răspunsurile Präzedenzfall',
    SHOW_MESSAGES: 'Nachrichten anzeigen',
    THE_ANSWER: 'Die Antwort',
    THE_EVENT: 'Aktivität',
    THE_FLOW: 'Lektion',
    THE_STEP: 'Schritt ',
    THE_QUESTION: 'Die Frage'
  },
  STUDENT_FUTURE_EVENTS: {
    FUTURE_EVENTS: 'Kommende Veranstaltungen',
    NO_FUTURE_EVENTS: 'Derzeit sind keine Besprechungen / Veranstaltungen zugeordnet.',
    INTRO_MESSAGE_ONE: 'Besuchen Sie den Abschnitt ',
    INTRO_MESSAGE_TWO: ' den Unterricht durch individuelles Lernen durchlaufen. Da sind Sie zu Lerntreffen eingeladen, Hier sehen Sie die Links und Zugangscodes. Sie erhalten auch E-Mail-Benachrichtigungen. Wenn Sie keine E-Mail-Adresse angegeben haben, rufen Sie bitte den Abschnitt auf ',
    INTRO_MESSAGE_THREE: ' Hier können Sie die Kontaktinformationen eingeben.'
  },
  ROBOHACKATON_USERS: {
    EXPORT_ALL: 'Alle Benutzer exportieren',
    EXPORT_TEACHERS: 'Lehrer exportieren',
    EXPORT_STUDENTS: 'Schüler exportieren',
    START_DATE: 'Startdatum',
    END_DATE: 'Endgültiges Datum',
    DESCRIPTION: 'Robohackaton Nextlab.tech 2021 Benutzer',
    FROM: 'Von',
    TO: 'Bis zu'
  },
  SELLER_DASHBOARD: {
    ASSOCIATED_PRODUCTS: 'Dazugehörige Produkte',
    ASSIGN_SELLER: 'Partnerverkäufer',
    COMMUNITIES: 'Gemeinschaft',
    NEW_SALES: 'Neue Verkäufe',
    ORDERS: 'Aufträge',
    ORDER_ID: 'Is',
    ORDER_ID_FILTER_PLACEHOLDER: 'Gesuchte ID',
    ORDER_DATE: 'Datum',
    ORDER_DATE_FILTER_PLACEHOLDER: 'Datum gesucht',
    PRODUCT: 'Produkt',
    PRODUCTS: 'Produkte',
    PRODUCT_ID: 'Produkt ID',
    PRODUCT_ID_FILTER_PLACEHOLDER: 'Gesuchte ID',
    PRODUCT_DESCRIPTION: 'Produktbeschreibung',
    PRODUCT_DESCRIPTION_FILTER_PLACEHOLDER: 'Das gesuchte Produkt',
    SALES: 'Verkauf',
    SELLERS: 'Verkäuferi',
    SKU: 'sku',
    SKU_CODES: 'Code SKU',
    SKU_FILTER_PLACEHOLDER: 'die sku gesucht'
  }
}

export default assets
