/**
 * @module toastReducer
 * @description state manipulation for toast content
 * @category reducers
 */
const INITIAL_STATE = {
  notificationQueue: [],
  currentNotification: null
}
  
export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'TOAST_QUEUE_PUSH':
      return { ...state, notificationQueue: queuePush(state.notificationQueue, action.payload) }
    case 'TOAST_QUEUE_POP':
      {
        const data = queuePop(state.notificationQueue)
        return { ...state, notificationQueue: data.notificationQueue, currentNotification: data.currentNotification }
      }
    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}

const queuePush = (currentQueue, newElement) => {
  const queue = [...currentQueue]
  queue.push(newElement)
  return queue
}

const queuePop = (currentQueue) => {
  if (currentQueue.length > 0) {
    const queue = [...currentQueue]
    const notification = queue.shift()
    return { notificationQueue: queue, currentNotification: notification }
  } else {
    return { notificationQueue: [], currentNotification: null }
  }
}