// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-container {
  position: relative;
  padding-top: var(--notification-area-height);
  height: calc(var(--app-height) * 0.84);
  overflow-y: auto;
}

.settings-container .notification-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.settings-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.settings-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75em;
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
  height: 4em;
}

.settings-label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 1em;
}

.settings-icon-left {
  margin-right: 1em;
  font-size: 1.25rem;
  color: var(--primary-background-color);
}

.settings-navigate-button {
  font-size: 1.25rem;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
}

.settings-icon {
  font-size: 1.25rem;
  color: var(--primary-background-color);
}`, "",{"version":3,"sources":["webpack://./src/components/Settings/Settings.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,4CAA4C;EAC5C,sCAAsC;EACtC,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,+CAA+C;EAC/C,WAAW;EACX,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,sCAAsC;AACxC","sourcesContent":[".settings-container {\n  position: relative;\n  padding-top: var(--notification-area-height);\n  height: calc(var(--app-height) * 0.84);\n  overflow-y: auto;\n}\n\n.settings-container .notification-area {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  z-index: 1000;\n}\n\n.settings-list {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.settings-item {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0.75em;\n  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);\n  width: 100%;\n  box-sizing: border-box;\n  height: 4em;\n}\n\n.settings-label {\n  display: flex;\n  align-items: center;\n  font-size: 1rem;\n  font-weight: 500;\n  padding-left: 1em;\n}\n\n.settings-icon-left {\n  margin-right: 1em;\n  font-size: 1.25rem;\n  color: var(--primary-background-color);\n}\n\n.settings-navigate-button {\n  font-size: 1.25rem;\n  background: none;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 1em;\n}\n\n.settings-icon {\n  font-size: 1.25rem;\n  color: var(--primary-background-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
