import { SERVER } from 'config/global'
import axios from 'axios'

export const RECOVER_USERS = 'RECOVER_USERS'
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const SEND_RECOVERY_TOKEN = 'SEND_RECOVERY_TOKEN'
export const RESET = 'RESET'

export function recoverUsers (email) {
  return {
    type: RECOVER_USERS,
    payload: axios.post(`${SERVER}/enrollment-api/recover-users`, { email })
  }
}

export function resetRecovery () {
  return {
    type: RESET,
    payload: ''
  }
}

export function sendPasswordRecoveryToken (username, email) {
  return {
    type: SEND_RECOVERY_TOKEN,
    payload: axios.post(`${SERVER}/enrollment-api/recover-password/prepare`, { username, email })
  }
}

export function recoverPassword (token, password) {
  return {
    type: RECOVER_PASSWORD,
    payload: axios.post(`${SERVER}/enrollment-api/recover-password`, { token, password })
  }
}
