import React, { useState, useEffect, useRef } from 'react'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'
import { useNavigate, useParams } from 'react-router-dom'
import './KnowledgeMapsSelection.css'
import NotificationArea from 'components/NotificationArea'
import { useDispatch, useSelector } from 'react-redux'
import { subscriptionActions } from 'actions'
import useAssets from '../../../hooks/useAssets'
import { useLocation } from 'react-router-dom'
import { toastActions } from '../../../actions'

const KnowledgeMapsSelection = () => {
  const location = useLocation()
  const assets = useAssets()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toast = useRef(null)

  const user = useSelector(state => state.loginUser.user)
  const error = useSelector(state => state.ownedSubscriptions.error)
  const fetching = useSelector(state => state.ownedSubscriptions.fetching)
  const fetched = useSelector(state => state.ownedSubscriptions.fetched)


  const selectedSubscription = useSelector(state =>
    state.ownedSubscriptions.subscriptions.find(sub => sub._id === id)
  )

  const studentEmail = location.state?.studentEmail || '' 
  const highStakesTestDefinition = selectedSubscription?.subscriptionPlan?.[0]?.highStakesTestDefinition?.[0]
  const knowledgeMaps = highStakesTestDefinition?.knowledgeMaps || []
  const maxSelectableMaps = highStakesTestDefinition?.maxSelectableMaps || 0

  const [selectedMaps, setSelectedMaps] = useState({})
  const [selectedCount, setSelectedCount] = useState(0)

  useEffect(() => {
    const initialSelection = {}
    knowledgeMaps.forEach(map => {
      initialSelection[map._id] = false
    })
    setSelectedMaps(initialSelection)
  }, [knowledgeMaps])

  useEffect(() => {
    if (error && error.response) {
      dispatch(
        toastActions.pushNotification({
          text: error.response?.data?.message || 'An error occurred',
          navigationTarget: null,
          position: 'BOTTOM',
          color: 'NEGATIVE',
          life: 3000
        })
      )
    }
  }, [error, dispatch])

  useEffect(() => {
    if (fetched) {
      navigate(`/parent-dashboard/subscriptions/${id}/graders`)
    }
  }, [fetched, navigate, id])

  const handleCheckboxChange = mapId => {
    const newSelection = !selectedMaps[mapId]
    const newCount = selectedCount + (newSelection ? 1 : -1)

    if (newCount <= maxSelectableMaps) {
      setSelectedMaps(prevState => ({
        ...prevState,
        [mapId]: newSelection
      }))
      setSelectedCount(newCount)
    }
  }

 
  const handleNextClick = () => {
    const selectedKnowledgeMaps = knowledgeMaps.filter((map) => selectedMaps[map._id])
    if (selectedCount === maxSelectableMaps) {
      dispatch(
        subscriptionActions.addStudentToSubscription(
          id,
          studentEmail,
          user._id,
          selectedKnowledgeMaps.map((km) => km._id)
        )
      )
    } 
  }

  return (
    <>
      <NotificationArea
        defaultText={selectedSubscription?.subscriptionPlan[0].highStakesTestDefinition[0].description.toUpperCase()}
      />
      <div className='knowledge-maps-selection'>
        <h1>{assets.SUBSCRIPTIONS.KNOWLEDGE_MAP_SELECTION}</h1>
        <p>Please select exactly {maxSelectableMaps} knowledge maps:</p>
        <div className='checkbox-group'>
          {knowledgeMaps.map(map => (
            <div key={map._id} className='checkbox-item'>
              <Checkbox
                inputId={map._id}
                checked={selectedMaps[map._id]}
                onChange={() => handleCheckboxChange(map._id)}
                disabled={!selectedMaps[map._id] && selectedCount >= maxSelectableMaps}
              />
              <label htmlFor={map._id}>{map.description}</label>
            </div>
          ))}
        </div>
        <Button
          label='Next'
          onClick={handleNextClick}
          className='p-button-primary next-button'
          disabled={selectedCount !== maxSelectableMaps || fetching}
        />
      </div>
    </>
  )
}

export default KnowledgeMapsSelection
