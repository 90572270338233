import axios from 'axios'

// configure axios to send locale header
const networkLocaleMiddleware = store => next => action => {
  if (action.type === 'SELECT_LANGUAGE') {
    let networkLocale = ''
    switch (action.payload) {
      case 'RO_ro':
        networkLocale = 'ro-RO,ro;q=0.9'
        break
      case 'EN_uk':
        networkLocale = 'en-GB,en;q=0.9'
        break
      case 'DE_de':
        networkLocale = 'de-DE,de;q=0.9'
        break
      case 'FR_fr':
        networkLocale = 'fr-FR,fr;q=0.9'
        break
      default:
        networkLocale = 'en-GB,en;q=0.9'
    }
    axios.defaults.headers.common['Accept-Language'] = networkLocale
  }
  next(action)
}

export default networkLocaleMiddleware
