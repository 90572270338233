/**
 * @module knowledgeMapMetricsReducer
 * @description state manipulation for knowledge map metrics
 * @category reducers
 */
const INITIAL_STATE = {
  flattenedKnowledgeMapMetrics: [],
  knowledgeMapsMetrics: [],
  error: null,
  fetching: false,
  fetched: false
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_STUDENT_SWOT_PENDING':
    case 'GET_STUDENT_KNOWLEDGE_MAP_METRICS_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'GET_STUDENT_SWOT_FULFILLED':
      return { ...state, flattenedKnowledgeMapMetrics: action.payload.data, error: null, fetching: false, fetched: true }
    case 'GET_STUDENT_KNOWLEDGE_MAP_METRICS_FULFILLED':
      return { ...state, knowledgeMapsMetrics: action.payload.data, error: null, fetching: false, fetched: true }
    case 'GET_STUDENT_SWOT_REJECTED':
    case 'GET_STUDENT_KNOWLEDGE_MAP_METRICS_REJECTED':
      return { ...state, error: action.payload.response.data, fetching: false, fetched: false }
    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}