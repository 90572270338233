import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { submissionsActions } from 'actions'
import AuthorizedImage from '../AuthorizedImage/AuthorizedImage'
import NotificationArea from 'components/NotificationArea'
import useAssets from 'hooks/useAssets'
import './SubmissionContent.css'

const loginUserSelector = (state) => state.loginUser

const SubmissionContent = () => {
  const { submissionId } = useParams()
  const dispatch = useDispatch()

  const assets = useAssets()
  const pictures = useSelector((state) => state.submissionPictures.pictures)
  const loginUser = useSelector(loginUserSelector, shallowEqual)
  const authorizationToken = loginUser.user.token

  useEffect(() => {
    if (submissionId) {
      dispatch(submissionsActions.getSubmissionPictures(submissionId))
    }
  }, [dispatch, submissionId])

  return (
    <div className="submission-content-container">
      <NotificationArea defaultText={assets.GENERAL.SUBMISSION_CONTENT.toUpperCase()} />
      <div className="content-area">
        {pictures.length > 0 && (
          pictures.map((imageDetails, index) => (
            <div key={index} className="picture-container">
              <AuthorizedImage
                thumbnail={imageDetails.thumbnail} 
                src={imageDetails.src} 
                authorizationToken={authorizationToken}
                isGradeable={true}
              />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default SubmissionContent
