/**
 * @module userReducer
 * @description state manipulation for the list of users
 * @category reducers
 */
const INITIAL_STATE = {
  userList: [],
  error: null,
  fetching: false,
  fetched: false,
  count: 0
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_USERS_PENDING':
    case 'GET_SELLERS_PENDING':
    case 'GET_NEW_USERS_PENDING':
    case 'ADD_USER_PENDING':
    case 'UPDATE_USER_PENDING':
    case 'DELETE_USER_PENDING':
    case 'UPDATE_WALLET_PENDING':
    case 'BULK_UPLOAD_USERS_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'GET_USERS_FULFILLED':
    case 'GET_SELLERS_FULFILLED':
    case 'GET_NEW_USERS_FULFILLED':
    case 'ADD_USER_FULFILLED':
    case 'UPDATE_USER_FULFILLED':
    case 'DELETE_USER_FULFILLED':
    case 'UPDATE_WALLET_FULFILLED':
    case 'BULK_UPLOAD_USERS_FULFILLED':
      return { ...state, userList: action.payload.data.users, count: action.payload.data.count, error: null, fetched: true, fetching: false }
    case 'GET_USERS_REJECTED':
    case 'GET_SELLERS_REJECTED':
    case 'GET_NEW_USERS_REJECTED':
    case 'ADD_USER_REJECTED':
    case 'UPDATE_USER_REJECTED':
    case 'DELETE_USER_REJECTED':
    case 'UPDATE_WALLET_REJECTED':
    case 'BULK_UPLOAD_USERS_REJECTED':
      return { ...state, error: action.payload.response.data, fetching: false, fetched: false }
    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}
