/* global btoa */
/**
 * @module helpOverlayActions
 * @description actions for help overlay state
 * @category actions
 */



export const SHOW_HELP = 'SHOW_HELP'
export const HIDE_HELP = 'HIDE_HELP'

/**
 * @description show help overlay
 * @action show help overlay
 * @dispatches SHOW_HELP
 */
export function showHelp () {
    return { type: SHOW_HELP }
}

/**
 * @description hide help overlay
 * @action hide help overlay
 * @dispatches HIDE_HELP
 */
export function hideHelp () {
  return { type: HIDE_HELP }
}