import './ImageUpload.css'
import React, { useState, useEffect, useRef } from 'react'
import { Button } from 'primereact/button'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { galleryActions } from 'actions'

import IconTrash from 'assets/icons/icon-trash.svg'

const assetSelector = state => state.language.assets
export default function ImageUpload() {
  const [selectedImages, setSelectedImages] = useState([]) 
  const [platformSelect ,setPlatformSelect] = useState('')


  const assets = useSelector(assetSelector, shallowEqual)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const browserFileInputRef = useRef()

  useEffect(() => {
    const platform = window.localStorage.getItem('PLATFORM')
    setPlatformSelect(platform)
  }, [])

  const uint8ArrayToBase64 = (uint8Array, mediaType) => {
    const metadata = `data:${mediaType};base64,`
    let binary = '' 
    const len = uint8Array.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(uint8Array[i])
    }
    return metadata + window.btoa(binary)
  }

  const handleMobileSelectImage = async () => {
    try {
      const chooserObject = window.chooser || chooser;
      const file = await chooserObject.getFile()
      if (file) {
        setSelectedImages([...selectedImages, file])
      }
    } catch (e) {
      console.warn(e)
    }
  }

  const handleBrowserTriggerUpload = () => {
    browserFileInputRef.current.click()
  }

  const getBase64URI = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = function(event) {
          resolve(event.target.result)
      }
      
      reader.onerror = function(error) {
          reject(error)
      }
      
      reader.readAsDataURL(file)
    })
  }

  const handleBrowserSelectImage = async (evt) => {
     try {
      const result = evt.target.files[0]
      const file = {
        name: result.name,
        mediaType: result.type,
        dataURI: ''
      }

      file.dataURI = await getBase64URI(result)

      setSelectedImages([...selectedImages, file])
    } catch (e) {
      console.warn(e)
    }
  }

  const handleMobileAddImages = () => {
    for (const image of selectedImages) {
      const base64 = uint8ArrayToBase64(image.data, image.mediaType) 

      dispatch(galleryActions.addPicture(base64))
    }
    navigate('/student-dashboard/gallery')
  }

  const handleBrowserAddImages = () => {
    for (const image of selectedImages) {
      dispatch(galleryActions.addPicture(image.dataURI))
    }
    navigate('/student-dashboard/gallery')
  }


  const handleRemoveImage = (index) => {
    if (index === 0) {
      setSelectedImages([])
    } else {
      const updatedState = selectedImages.splice(index, 1)
      setSelectedImages(updatedState)
    }
  }

  const mobileSelectTemplate = () => {
    return (
      <div className='image-upload-container'>
        <div className='notification-area'>{assets.TESTRAX_V2.SELECT_IMAGES}</div>
        <div className='selected-images-container'>
          {selectedImages.length > 0 ? selectedImages.map((image, index) => {
            return (
              <div className='picture-container' key={index}>
                <div className='delete-btn' onClick={() => handleRemoveImage(index)}>
                  <img src={IconTrash} alt='icon-trash' />
                </div>
                <div>
                  <img key={index} src={image.dataURI}></img>
                </div>
              </div>
            )
          }) : <div style={{ textAlign: 'center' }}>{assets.TESTRAX_V2.NO_IMAGES_SELECTED}</div>}
        </div>
        <Button label='Select image' onClick={handleMobileSelectImage} />
        <Button label='Add images' onClick={handleMobileAddImages} disabled={!(selectedImages.length > 0)} />
      </div>
    )
  }

  const browserSelectTemplate = () => {
    return (
      <div className='image-upload-container'>
        <div className='notification-area'>{assets.TESTRAX_V2.SELECT_IMAGES}</div>
        <div className='selected-images-container'>
          {selectedImages.length > 0 ? selectedImages.map((image, index) => {
            return (
              <div className='picture-container' key={index}>
                <div className='delete-btn' onClick={() => handleRemoveImage(index)}>
                  <img src={IconTrash} alt='icon-trash' />
                </div>
                <div>
                  {console.warn({uri: image.dataURI})}
                  <img key={index} src={image.dataURI}></img>
                </div>
              </div>
            )
          }) : <div style={{ textAlign: 'center' }}>{assets.TESTRAX_V2.NO_IMAGES_SELECTED}</div>}
        </div>
        <Button
          label={'Select image'} icon='pi pi-plus'
          onClick={handleBrowserTriggerUpload}
        >
          <input
            type='file'
            accept='image/*'
            onChange={handleBrowserSelectImage}
            style={{ display: 'none' }}
            ref={browserFileInputRef}
            onClick={(event) => { event.target.value = null }}
          />
        </Button>
        <Button label='Add images' onClick={handleBrowserAddImages} disabled={!(selectedImages.length > 0)} />
      </div>
    )
  }

  return (
    <>
      {platformSelect === 'browser' ? browserSelectTemplate() : mobileSelectTemplate()}
    </>
  )
}