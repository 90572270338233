/**
 * @module trophyRoadItemActivityReducer
 * @description state manipulation for trophy road item activity elements
 * @category reducers
 */
const INITIAL_STATE = {
    trophyRoadItemFeedItems: [],
    count: 0,
    error: null,
    fetching: false,
    fetched: false
  }
  
  export default function trophyRoadItemActivityReducer (state = INITIAL_STATE, action) {
    switch (action.type) {
      case 'RESET_TROPHY_ROAD_ITEM_ACTIVITY':
        return {
          ...state,
          trophyRoadItemFeedItems: [],
          count: 0,
          error: null,
          fetching: false,
          fetched: false
        }
      case 'GET_TROPHY_ROAD_ITEM_ACTIVITY_PENDING':
        return {
          ...state,
          error: null,
          fetching: true,
          fetched: false
        }
      case 'GET_TROPHY_ROAD_ITEM_ACTIVITY_FULFILLED':
        return {
          ...state,
          trophyRoadItemFeedItems: concatActivity(state.trophyRoadItemFeedItems, action.payload.data.activityFeedItems),
          count: action.payload.data.count,
          error: null,
          fetching: false,
          fetched: true
        }
      case 'GET_TROPHY_ROAD_ITEM_ACTIVITY_REJECTED':
        return {
          ...state,
          error: action.payload.response.data,
          fetching: false,
          fetched: false
        }
      case 'RESET':
        return INITIAL_STATE
      default:
        break
    }
    return state
  }
  
  const concatActivity = (oldActivity, newActivity) => {
    const activity = [...oldActivity]
    activity.push(...newActivity)
    return activity
  }
  