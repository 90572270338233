import './ParentDashboard.css'
import React from 'react'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import Subscriptions from 'components/Subscriptions'
import SubscriptionAssignment from 'components/Subscriptions/SubscriptionAssignment'
import KnowledgeMapsSelection from 'components/Subscriptions/KnowledgeMapsSelection'
import CorrectorsAllocation from 'components/Subscriptions/CorrectorsAllocation'
import TrophyRoad from 'components/Subscriptions/TrophyRoad'
import ParentActivityFeed from 'components/ParentActivityFeed' 
import SubmissionContent from 'components/SubmissionContent'
import SubmissionGrading from 'components/SubmissionGrading'
import TestContent from 'components/TestContent'
import TrophyRoadItemCompleted from 'components/TrophyRoadItemCompleted'
import StudentSwot from 'components/StudentSwot'
import KnowledgeMapReports from 'components/KnowledgeMapReports'
import { shallowEqual, useSelector } from 'react-redux'

const userSelector = state => state.loginUser.user

function ParentDashboard() {

  const user = useSelector(userSelector, shallowEqual)


  if (user.facets.includes('student')) {
    return <Navigate to={{ pathname: '/student-dashboard' }} />
  } else {
    return (
      <div className="parent-dashboard">
        <Routes>
          <Route index element={<Subscriptions />} />
          <Route path='subscriptions/:id/assignment' element={<SubscriptionAssignment />} />
          <Route path='subscriptions/:id/knowledge-maps-selection' element={<KnowledgeMapsSelection />} />
          <Route path='subscriptions/:id/graders' element={<CorrectorsAllocation />} />
          <Route path='subscriptions/:id/trophy-road' element={<TrophyRoad />} />
          <Route path='subscriptions/:subscriptionId/swot' element={<StudentSwot />} /> 
          <Route path='subscriptions/:subscriptionId' element={<ParentActivityFeed />} />
          <Route path='subscriptions/:subscriptionId/trophyroads/:trophyroadId/trophyroaditems/:trophyroaditemId' element={<TrophyRoadItemCompleted />} />
          <Route path='submissions/:submissionId' element={<SubmissionContent />} />
          <Route path="submissions/:submissionId/user-knowledge-maps/:userKnowledgeMapId/submission-gradings/:submissionGradingId" element={<SubmissionGrading />} /> 
          <Route path='students/:studentId/knowledge-map-reports' element={<KnowledgeMapReports />} /> 
          <Route path="tests/:testId/content" element={<TestContent />} /> 
        </Routes>
      </div>
    )
  }  
}

export default ParentDashboard
