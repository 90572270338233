/* global localStorage */
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('tx-global-state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify({
      accountConfirmation: state.accountConfirmation,
      loginUser: {
        ...state.loginUser,
        fetching: false
      },
      user: state.user,
      ownedSubscriptions: state.ownedSubscriptions,
      subscription: state.subscription,
      trophyRoad: state.trophyRoad
      // wallet: state.wallet
    })
    localStorage.setItem('tx-global-state', serializedState)
  } catch (e) {
    console.warn(e)
  }
}
