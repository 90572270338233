// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invalid-account-logout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 85vh;
    text-align: center;
  }
  
  .warning-message {
    font-size: 1.2em;
    font-weight: 500;
    color: black;
    padding: 2em;
    line-height: 1.5;
    margin-bottom: 1em;
  }
  
  .logout-container {
    display: flex;
    justify-content: center;
  }
  
  .logout-button {
    padding: 0.9em 1.2em;
    font-size: 1em;
    font-weight: 600;
    background-color: var(--primary-background-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .logout-button:hover {
    background-color: #2d33b3; 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/InvalidAccountLogout/InvalidAccountLogout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,oBAAoB;IACpB,cAAc;IACd,gBAAgB;IAChB,iDAAiD;IACjD,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".invalid-account-logout {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    height: 85vh;\n    text-align: center;\n  }\n  \n  .warning-message {\n    font-size: 1.2em;\n    font-weight: 500;\n    color: black;\n    padding: 2em;\n    line-height: 1.5;\n    margin-bottom: 1em;\n  }\n  \n  .logout-container {\n    display: flex;\n    justify-content: center;\n  }\n  \n  .logout-button {\n    padding: 0.9em 1.2em;\n    font-size: 1em;\n    font-weight: 600;\n    background-color: var(--primary-background-color);\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .logout-button:hover {\n    background-color: #2d33b3; \n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
