const assets = {
  ANNOUNCEMENT_BUILDER: {
    HEADER: 'Anunțurile mele',
    TITLE: 'Titlu',
    CONTENT: 'Conținut',
    DIALOG_HEADER: 'Modifică anunțul',
    DATE_VALID_START: 'Valabil de la data',
    DATE_VALID_END: 'Valabil până la data',
    CREATE_ANNOUNCEMENT_CARD_HEADER: 'Adaugă un anunț',
    ANNOUNCEMENT_LIST_CARD_HEADER: 'Anunțuri create',
    NO_CREATED_ANNOUNCEMENTS: 'Nu există anunțuri create',
    SELECT_USERS: 'Selectează ulizatori',
    SELECT_GROUPS: 'Selectează comunități',
    STATE: 'Stare',
    RECEIVER_TYPE: 'Anunț pentru',
    FOR_RECEIVER_TYPE: {
      STUDENT: 'Studenți',
      TEACHER: 'Profesori',
      VOLUNTEER: 'Voluntari',
      PARENT: 'Părinți',
      COMMUNITY: 'Comunitățile selectate',
      USER: 'Utilizatorii selectați'
    },
    VISIBILITY: {
      HIDDEN: 'Ascuns',
      VISIBLE: 'Public'
    }
  },
  ASSISTANT_BUILDER: {
    ACTIONS: 'Acțiuni',
    ASSISTANT_BUILDER: 'Editor de asistenți',
    ASSISTANT_CONFIRM_DELETE: 'Confirmă ștergerea asistentului',
    ASSISTANT_EDITOR: 'Editor asistent',
    ASSISTANT_IMPORT: 'Importă asistent',
    CONFIDENCE_THRESHOLD: 'Confidence (%)',
    EXPORT: 'Exportă',
    FILTER_PLACEHOLDER: 'Numele asistentului căutat',
    IS_DEFAULT_ASSISTANT: 'Asistent implicit',
    IS_TEST_ASSISTANT: 'Asistent pentru teste',
    IS_TEST_FLOW_INVALID: 'Asistentul selectat trebuie să fie pentru teste',
    IS_NOT_TEST_FLOW_INVALID: 'Asistentul selectat NU trebuie să fie pentru teste',
    LANGUAGE: 'Limbă',
    LANGUAGE_FILTER_PLACEHOLDER: 'Limba căutată',
    NAME: 'Numele asistentului',
    NAME_FILTER_PLACEHOLDER: 'Numele asistentului căutat',
    PRIORITY: 'Prioritate',
    PRIORITY_UP: 'Mărește prioritatea',
    PRIORITY_DOWN: 'Scade prioritatea',
    RULES: 'Reguli',
    TYPE_RULE: 'Tip regulă'
  },
  ASSISTANT_RULE_EDITOR: {
    BEHAVIOUR: 'Comportament',
    BEHAVIOURS_REQUIRED: 'Adaugă mai întâi o acțiune pentru a putea adăuga reguli',
    COMMENT: 'Comentariu',
    MATCHING_RULE: 'Matching rule',
    NAME: 'Nume',
    NAME_FILTER_PLACEHOLDER: 'Numele regulei căutate',
    PRIORITY: 'Prioritate',
    PRIORITY_FILTER_PLACEHOLDER: 'Prioritatea regulii',
    RULE_CONFIRM_DELETE: 'Confirmă ștergerea regulii',
    RULE_EDITOR: 'Editor reguli',
    SELECT_BEHAVIOUR: 'Selectează comportament',
    SELECT_PRIORITY: 'Selectează prioritatea',
    SELECT_TYPE: 'Selectează tip',
    TYPE: 'Tip',
    TYPE_FILTER_PLACEHOLDER: 'Numele tipului căutat'
  },
  ASSISTANT_BEHAVIOR_EDITOR: {
    BEHAVIOR_EDITOR: 'Editor comportament',
    BEHAVIOR_CONFIRM_DELETE: 'Confirmă ștergerea comportamentului',
    CODE_MODULE: 'Cod modul',
    NAME: 'Nume',
    NAME_FILTER_PLACEHOLDER: 'Numele comportamentului căutat',
    CODE_FILTER_PLACEHOLDER: 'Numele modulului căutat'
  },
  CLASSIFIER_BUILDER: {
    ADD_AS: 'Clasifică răspunsul',
    CLASS: 'Clasa',
    CLASSIFIED_AS: 'Clasificat',
    CLASSIFIED_WITH_CONFIDENCE: 'Cu gradul de încredere',
    CLASSIFIER_CONFIRM_DELETE: 'Confirmă ștergerea clasificatorului',
    CLASSIFIER_DEFINITION: 'Definirea clasificatorului',
    CLASSIFIER_EDITOR: 'Editor de clasificatori',
    CLASSIFIER_IMPORT: 'Importă clasificator',
    CLASSIFIER_EXPORT: 'Exportă clasificator',
    CLASSIFIED_EXAMPLES: 'Clasificarea exemplelor de antrenare',
    EXPORT: 'Exportă',
    EXPORT_INCLUDE_OWNED_DERIVED_CLASSIFIERS: 'Include clasificatorii derivați propri',
    EXPORT_INCLUDE_SELECTED_USERS_DERIVED_CLASSIFIERS: 'Include clasificatorii derivați ai utilizatorilor selectați',
    EXPORT_INCLUDE_ALL_DERIVED_CLASSIFIERS: 'Include clasificatorii derivați ai tuturor utilizatorilor',
    LANGUAGE: 'Limbă',
    LANGUAGE_FILTER_PLACEHOLDER: 'Limba clasificatorului',
    MINIMUM_CLASSNAMES: 'Sunt necesare minim două clase asociate exemplelor de antrenare',
    NAME: 'Nume',
    NAME_FILTER_PLACEHOLDER: 'Numele clasificatorului',
    RESULT_FOR: 'Rezultat pentru',
    STANDARD_ANSWER: 'Răspuns standard',
    STANDARD_ANSWER_PLACEHOLDER: 'Răspuns standard',
    TEST: 'Testează',
    TEST_SAMPLE: 'Exemplu de antrenare',
    TEST_EXPLANATION: 'Testează un răspuns',
    USERS: 'Utilizatori'
  },
  COMPETENCY_MAP_METRICS: {
    CHART_HEADER: 'Acoperirea competențelor din programa analitică',
    CHART_HEADER_SECOND: 'Utilizator',
    COVERAGE_VALUE: 'Valoare de acoperire',
    DIALOG_HEADER: 'Hartă de competențe',
    FOOTNOTE_FIRST: 'Selectați o hartă de cunoștințe, în urmă selecției va fi generat un grafic ce reflectă o perspectivă în ansamblu a dobândirii competențelor cuprinse în harta de cunoștințe selectată;',
    FOOTNOTE_SECOND: 'Făcând click pe o celulă, automat acea celulă va deveni rădăcina graficului și va oferi o perspectiva asupra nodurilor inferioare;',
    FOOTNOTE_THIRD: 'Vizualizarea este reprezentarea grafică a dobândirii competențelor cuprinse într-o hărtă de cunoștințe;',
    FOOTNOTE_FORTH: 'Culoarea fiecărei celule este rezultatul agregării metricii de acoperire de la nivelul competențelor specifice până la rădăcină, aceasta fiind reprezentată de prima celulă de la stânga la dreapta;',
    FOOTNOTE_FIFTH: 'O competență specifică poate fi marcată ca fiind acoperită de către oricare dintre profesorii unui elev.',
    FOOTNOTE_HEADER: 'Notă de subsol',
    LEGEND: {
      HEADER: 'Legendă',
      LEGEND_INDICATION_FIRST: 'Roșu indică faptul că o competență nu a fost dobândită',
      LEGEND_INDICATION_SECOND: 'Roșu-portocaliu reprezintă faptul un număr mic de competențe specifice dintr-o competență generală au fost dobândite',
      LEGEND_INDICATION_THIRD: 'Portocaliu reprezintă faptul un număr mediu de competențe specifice dintr-o competență generală au fost dobândite',
      LEGEND_INDICATION_FORTH: 'Galben reprezintă faptul un număr mare de competențe specifice dintr-o competență generală au fost dobândite',
      LEGEND_INDICATION_FIFTH: 'Verde reprezintă faptul că un număr mic de competențe specifice dintr-o competență generală au fost dobândite',
      LEGEND_INDICATION_DESCRIPTION: 'Descrierea reprezintă numele programei analitice sau competenței generale sau specifice',
      LEGEND_INDICATION_VALUE: 'Valoarea de acoperire este 1 pentru competențele specifice dobândite, respectiv valoarea agregată a acoperirii de la nivelul descendenților direcți pentru competențele generale/programa analitică'
    },
    NO_DATA: 'Nu există date pentru a genera vizualizarea pentru programa analitică',
    PRINTABLE_COMPETENCY_MAP_METRICS: {
      ASTERISK_METRICS_EXPLANATION: '* Numarul de competențe obținute/numărul de competențe totale.',
      COMPETENCY_MAP: 'Harta de competențe',
      CONCEPT: 'Conceptul',
      CONTENT_DOMAIN: 'Domeniul de conținut',
      CONTENT_ITEM: 'Elementul de conținut',
      COVER_TITLE_FIRST: 'Harta de competențe',
      COVER_TITLE_SECOND: 'pentru',
      FOUNDATION_HEADER: 'Fundamentul metricilor calculate',
      DATA_AGREGATION: 'Agregarea datelor',
      DATA_CALCULATION_HEADER: 'Dobândirea competențelor',
      DATA_CALCULATION_INFO: 'O parte din întrebările dintr-un flux de învățare au rolul de a stabili dacă elevul a dobândit competența sau competențele asociate. Răspunsurile la astfel de întrebări sunt evaluate de profesori. O competență specifică este dobândită dacă există un răspuns evaluat pozitiv.',
      DATA_AGREGATION_INFO: 'Calcularea indicatorilor la nivelul competențelor generale și hărții de competențe în sine se face pornind de la indicatorii calculați la nivelul competențelor specifice. O competență generală va fi acoperită în măsura în care au fost dobândite competențele specifice pe care le conține. Harta de competențe este o reprezentare globală a acoperirii tuturor competențelor generale care sunt incluse în programa analitică respectivă.',
      FOR_THE_STUDENT: 'pentru elevul',
      FOUNDATION_INFO_FIRST: 'Harta de competențe este un raport care reprezintă detaliat acoperirea competențelor din cadrul unei programe analitice. Raportul conține metrici de acoperire calculate la nivelul competențelor generale, competențelor specifice și hărții de cunoștințe în sine.',
      FOUNDATION_INFO_FOURTH: 'Acoperirea unei competențe generale se calculează prin raportarea numărului de competențe specifice dobândite de elev, la numărul total de competențe specifice asociate competenței generale. Acoperirea programei analitice este calculată ca medie aritmetică a gradelor de acoperire ale competențelor generale componente.',
      FOUNDATION_INFO_SECOND: 'Metrica de acoperire a competențelor ia în calcul dobândirea competențelor specifice pe baza răspunsurilor date la întrebările de evaluare a acestora.',
      FOUNDATION_INFO_THIRD: 'Competențele specifice sunt considerate dobândite sau nedobândite, neavând acoperire. Acestea sunt marcate ca dobândite de către un profesor asociat cu elevul, care evaluează răspunsul elevului la oricare dintre întrebările care relevă dobândirea competenței.',
      GENERAL_COMPETENCY: 'Competența generală',
      KNOWLEDGE_MAP: 'Harta de cunoștințe',
      COMPETENCY_MAP_HEADER: 'Harta de competențe (programa analitică)',
      COMPETENCY_MAP_INFO_FIRST: 'Harta de competențe reprezintă structura unei programe analitice într-o manieră ierarhică. O hartă de competențe conține competențe generale. O competență generală conține competențe specifice.',
      COMPETENCY_MAP_INFO_SECOND: 'Elevul își însușește o serie de cunoștințe prin parcurgerea unor conținuturi educaționale, competențele asociate reprezintă abilitatea elevului de a utiliza aceste cunoștințe în rezolvarea unor probleme practice.',
      PRINT: 'Tipărește raportul',
      PROGRESS_REPORT: 'Raport de progres',
      SCOPE_HEADER: 'Scop',
      SCOPE_INFO_FIRST: 'Harta de competențe este construită pentru a reprezenta o metrică de acoperire, reflectând faptul că elevul și-a însușit competențele acoperite de o programă analitică și a răspuns corect la întrebările de verificare care evaluează dobândirea competențelor asociate.',
      SCOPE_INFO_SECOND: 'Acest raport își propune să ofere o perspectivă cu granularitate crescută asupra parcurgerii unei arii de conținut, permițând identificarea unor posibile zone problematice de conținut, care nu au fost acoperite sau pentru care acoperirea a fost doar formală.',
      SPECIFIC_COMPETENCY: 'Competența specifică',
      STRUCTURE_HEADER: 'Structură',
      STRUCURE_INFO_COMPONENTS: 'Componentele raportului',
      STRUCTURE_INFO_FIRST: 'Raportul de dobândire a competențelor din cadrul programei analitice',
      STRUCTURE_INFO_SECOND: 'pentru elevul',
      STRUCTURE_INFO_THIRD: 'reflectă ce competențe specifice/generale au fost dobândite de elev în decursul parcurgerii programei.',
      STRUCURE_INFO_COMPONENTS_FIRST: 'Scopul raportului',
      STRUCURE_INFO_COMPONENTS_SECOND: 'Fundamentul metricilor calculate',
      STRUCURE_INFO_COMPONENTS_THIRD: 'Harta de competențe (programa analitică)',
      STRUCURE_INFO_COMPONENTS_FOURTH: 'Dobândirea competențelor',
      STRUCURE_INFO_COMPONENTS_FIFTH: 'Agregarea datelor',
      STRUCURE_INFO_COMPONENTS_SIXTH: 'Metrica desfășurată pentru fiecare nivel al hărții de competențe',
      SUMMARY_HEADER: 'Raportul NEXTLAB.TECH al dobândirii competențelor din harta de cunoștințe (programa analitică)',
      SUMMARY_CONTENT: 'Metrica desfășurată pentru fiecare nivel al hărții de competențe',
      SUMMARY_CONTENT_INFO: 'Harta de competențe este reprezentată sub forma unui cuprins adnotat al programei analitice corespunzătoare. Indentarea reprezintă coborârea unui nivel în ierarhia de competențe. Culoarea fiecărui element indică gradul lui de acoperire.',
      SUMMARY_COCLUSION: 'Recomandări',
      SUMMARY_CONCLUSION: {
        KNOWLEDGE_METRIC_LEVEL_WEAK: 'slab',
        KNOWLEDGE_METRIC_LEVEL_INSUFFICIENT: 'insuficient',
        KNOWLEDGE_METRIC_LEVEL_MEDIUM: 'mediu',
        KNOWLEDGE_METRIC_LEVEL_SUFFICIENT: 'suficient',
        RECOMMENDATION_REDO: 'Recomandăm reparcurgerea lecțiilor',
        RECOMMENDATION_REDO_RESEARCH: 'Recomandăm reparcurgerea lecțiilor pentru aprofundarea conceptelor',
        RECOMMENDATION_REDO_OPTIONALLY: 'Recomandăm reparcurgerea lecțiilor la latitudinea elevului, pentru aprofundare.',
        STRONG_POINTS: 'Cele mai bune rezultate se află în cadrul domeniilor de conținut',
        WEAK_POINTS: 'Cele mai slabe rezultate se află în cadrul domeniilor de conținut',
        WEAK_POINTS_FLOWS_SUGGESTIONS: 'Ar trebui reparcurse cu prioritate domeniile la care scorul a fost mai mic. Pentru aprofundarea acestor concepte recomandăm parcurgerea următoarelor lecții'
      },
      THE_STUDENT: 'Elevul'
    },
    OPEN_DIALOG_MESSAGE_FIRST: 'Selectați o programă analitică parcursă de elevul',
    OPEN_DIALOG_MESSAGE_SECOND: 'Va fi desenat un grafic ce va reprezenta o perspectivă în ansamblu a competențelor dobândite de către elev;',
    OPEN_DIALOG_MESSAGE_THIRD: 'O competență specifică dobândită este marcată cu verde;',
    OPEN_DIALOG_MESSAGE_FOURTH: 'O competență generală este marcată cu o culoare care denotă procentul de comptențe specifice asociate acesteia care au fost dobândite de elev (dacă au fost dobândite toate competențele specifice culoarea va fi verde).',
    SELECT_PLACEHOLDER: 'Selectează o programă analitică'
  },
  COMPETITION: {
    ADD_EDITION_HEADER: 'Adaugă o ediție',
    ADD_HEADER: 'Adaugă o competiție',
    COMPETITIONS: 'Competiții',
    DISABLED_IS_EXPIRED: 'Ediția nu mai poate fi modificată după sfârșitul acesteia',
    EDITIONS: 'Ediții',
    EDITIONS_COUNT: 'Număr ediții',
    EDITION_DATE_START_BEFORE_END: 'Data de sfârșit a competiției trebuie să fie după data de început a acesteia',
    EDITION_END_DATE: 'Data de sfârșit',
    EDITION_REGISTRATION_DATE_START_BEFORE_END: 'Data de sfârșit a înregistrării trebuie să fie după data de început a acesteia',
    EDITION_REGISTRATION_END_BEFORE_EDITION_END: 'Data de sfârșit a înregistrării trebuie să fie înainte de data de sfârșit a ediției',
    EDITION_REGISTRATION_START_BEFORE_EDITION_START: 'Data de început a înregistrării trebuie să fie înainte de data de început a ediției',
    EDITION_START_DATE: 'Data de început',
    IS_BLOCKING: 'Este blocantă',
    NO_AVAILABLE_COMPETITIONS: 'Nu există concursuri active la momentul curent',
    REGISTRATIONS: 'Înscrieri',
    REGISTRATIONS_BY_COUNTY: 'Situația înscrierilor pe județe',
    REGISTRATIONS_COUNT: 'Număr de înscrieri',
    REGISTRATION_END_DATE: 'Sfârșit înregistrări',
    REGISTRATION_START_DATE: 'Start înregistrări',
    STATISTICS: 'Statistici',
    STUDENT_EDITIONS: {
      COMPETITION_DESCRIPTION: 'Concurs',
      EDITION_DESCRIPTION: 'Ediție',
      EDITION_FINISH_DATE: 'Sfârșitul concursului',
      EDITION_JOINED: 'Înscris',
      EDITION_START_DATE: 'Începutul concursului',
      HEADER: 'Concursuri în desfășurare',
      JOIN_EDITION: 'Înscrie-te',
      REGISTRATION_END_DATE: 'Sfârșit înscrieri',
      REGISTRATION_START_DATE: 'Început înscrieri'
    },
    UNSPECIFIED_COUNTY: 'Nespecificat',
    UPDATE_HEADER: 'Modifică o competiție',
    UPDATE__EDITION_HEADER: 'Modifică o ediție',
    WELCOME_MESSAGE: 'Mesaj de întâmpinare la înscriere'
  },
  FACETS_PICKER: {
    HEADER: 'Alegeți unul din rolurile de mai jos'
  },
  HELP_OVERLAY: {
  	TITLE: 'Bun venit în TESTRAX',
   	DETAILED_INSTRUCTIONS: {
    	LINE_1: 'Evaluare națională',
     	LINE_2: 'Olimpiada de matematică',
      LINE_3: 'Note mari'
    },
    INSTRUCTIONS_LINK_TEXT: 'INSTRUCȚIUNI',
    INSTRUCTIONS_LINK_URL: 'https://docs.google.com/document/d/114m6aLnEpTtY866ZMl6nBH9DzhAk6HYA_oz9ZxNC9l4',
    DO_NOT_SHOW_AGAIN: 'Nu mai arată instrucțiunile',
  },
  KNOWLEDGE_BASE_BUILDER: {
    ADD_KNOWLEDGE_BASE: 'Adaugă o bază de cunoștințe',
    ADD_TOOLTIP: 'Adaugă o bază de cunoștințe',
    ANSWERS: 'Răspunsuri',
    ANSWERS_TOOLTIP: 'Editează răspunsurile din bază',
    DELETE_TOOLTIP: 'Șterge baza de cunoștințe',
    EDIT_DISABLED_TOOLTIP: 'Această bază este de tip simplificat și poate fi editată doar din interfața de profesor',
    EDIT_TOOLTIP: 'Editează baza de cunoștințe',
    ENTITIES: 'Entități',
    ENTITIES_TOOLTIP: 'Editează entitățile din bază',
    EXPORT: 'Exportă',
    EXPORT_TOOLTIP: 'Exporta baza ca JSON',
    FILTER_PLACEHOLDER: 'Numele bazei de cunoștințe căutate',
    IMPORT_KNOWLEDGE_BASE: 'Importă o bază de cunoștințe',
    IMPORT_TOOLTIP: 'Importă o bază de cunoștințe JSON',
    INTENTS: 'Intenții',
    INTENTS_TOOLTIP: 'Editează intențiile din bază',
    IS_DEFAULT: 'Bază de cunoștințe implicită',
    KNOWLEDGE_BASE_CONFIRM_DELETE: 'Confirmă ștergerea bazei de cunoștințe',
    KNOWLEDGE_BASE_CONFIRM_SHARE_PERMISSION_DELETE: 'Confirmă anularea partajării către',
    KNOWLEDGE_BASE_DESCRIPTION: 'Descriere',
    KNOWLEDGE_BASE_DIALOG_TITLE: 'Baza de cunoștințe',
    KNOWLEDGE_BASE_LANGUAGE: 'Limbă',
    KNOWLEDGE_BASE_NAME: 'Nume',
    LANGUAGE_FILTER_PLACEHOLDER: 'Limba bazei de cunoștințe căutate',
    NAME_FILTER_PLACEHOLDER: 'Numele bazei de cunoștințe căutate',
    NO_DATA: 'Nu există baze de cunoștințe',
    PUBLIC: 'Public',
    TITLE: 'Editor de baze de cunoștințe'
  },
  KNOWLEDGE_BASE_BUILDER_INTENT_EDITOR: {
    ADD_EXAMPLES: 'Adăugați exemple de antrenare folosind câmpul de mai jos',
    ADD_EXAMPLES_OR_CHANGE_FILTER: 'Ajustați filtrul sau adăugați exemple de anternare folosind câmpul de mai jos',
    CONFIRM_DELETE: 'Confirmă ștergerea intenției',
    NAME: 'Nume',
    NAME_FILTER_PLACEHOLDER: 'Numele intenției căutate',
    SAMPLE: 'Specifică un exemplu',
    TITLE: 'Editor de intenții'
  },
  KNOWLEDGE_BASE_BUILDER_ENTITY_EDITOR: {
    CONFIRM_DELETE: 'Confirmă ștergerea entității',
    NAME: 'Nume',
    NAME_FILTER_PLACEHOLDER: 'Numele entității căutate',
    SAMPLE: 'Exemplu',
    TITLE: 'Editor de entități',
    VARIANT: 'Variantă',
    VARIANT_FILTER_PLACEHOLDER: 'Numele variantei căutate'
  },
  KNOWLEDGE_BASE_BUILDER_ANSWER_EDITOR: {
    ANSWER_VARIANTS: 'Variante răspuns',
    CONFIRM_DELETE: 'Confirmă ștergerea răspunsului',
    NAME: 'Nume',
    NAME_FILTER_PLACEHOLDER: 'Numele răspunsului căutat',
    TITLE: 'Editor răspunsuri asistent'
  },
  LOGIN_PROVIDER: {
    HEADER: 'Welcome back!',
    LOGIN_MESSAGE: 'Login using one of the providers below',
    CONTINUE_GOOGLE: 'Continue with Google',
    CONTINUE_APPLE: 'Continue with Apple',
    CONTINUE_FACEBOOK: 'Continue with Facebook',
  },
  MARKETING_INFO: {
    EXTENDED_DESCRIPTION: 'Descriere extinsă',
    FEATURES: 'Caracteristici',
    MARKETING_INFO: 'Informații de marketing',
    OBJECTIVES: 'Obiective',
    SAVE_MARKETING_INFO: 'Salvează informațiile',
    VIDEO_URL: 'URL video prezentare (YouTube)'
  },
  SCORM: {
    ASSETS: {
      FLOW: 'Flux de învățare',
      KNOWLEDGE_BASE: 'Bază de cunoștințe',
      SCORM_PACKAGE: 'Pachet scorm',
      TRANSCRIPTION_PACK: 'Transcrieri fonetice',
      TITLE: 'Încărcați o lecție în format JSON pentru a genera un pachet SCORM',
      UI_ASSETS: 'Asset-uri interfață'
    },
    DESCRIPTION: 'Titlu',
    ERROR_DIALOG: 'Detaliile erorii',
    ERROR_TITLE: '',
    FAILED_WITH_ERROR: 'a eșuat cu eroarea',
    FILE_STATUS: 'Status fișier pachet',
    FILE_STATUS_NOT_GENERATED: 'Negenerat',
    FILE_STATUS_GENERATED: 'Generat',
    FILE_STATUS_EXPIRED: 'Expirat',
    FILE_STATUS_UNPACKED: 'Pregătit pentru remix',
    JOB_STATUS: 'Status generare',
    JOB_STATUS_REJECTED: 'Eșuat',
    JOB_STATUS_PENDING: 'În așteptare',
    JOB_STATUS_FULFILLED: 'Procesat',
    JOB_STATUS_CANCELED: 'Anulat',
    NO_SCORM_JOBS: 'Nu există pachete SCORM',
    REGENERATE: 'Regenerare SCORM',
    REMIX: 'Remix',
    REMIX_FLOW: 'Fluxul ales pentru remix',
    REMIX_EXPLANATION: 'Folosiți editorul de flux de mai jos pentru a remixa o lecție în format SCORM',
    REMIX_WARNING: 'Atenție! Modificările nu se salvează între sesiuni de editare. Părăsirea editorului va duce la pierderea modificărilor.',
    REMIX_NOTICE: 'Pentru a genera o lecție SCORM pe baza fluxului modificat apăsați pe butonul',
    SCORM_TABLE_HEADER: 'Lecții în format SCORM',
    SCORM_TOTAL_COUNT: 'Lecții încărcate',
    SELECT_FLOW: 'Selectează o lecție',
    SEND_JOB: 'Trimite spre generare',
    SEND_JOB_EXPLANATION: 'Apăsați butonul de mai jos pentru a trimite lecția spre generare',
    SHOW_ADVANCED_SETTINGS: 'Arată setările avansate',
    THE_GENERATION_JOB: 'Generarea pachetului',
    TIMESTAMP: 'Adăugat la data',
    UPLOAD_ASSETS: 'Încarcă lecția',
    UPLOAD_SCORM: 'Încarcă scorm'
  },
  SIMPLE_KNOWLEDGE_BASE_BUILDER: {
    ADD: 'Adaugă bază de cunoștințe',
    ANSWER: 'Răspuns',
    DELETE_CONFIRMATION_DESCRIPTION: 'Confirmă ștergerea bazei de cunoștințe',
    DELETE_CONFIRMATION_YES: 'Da',
    DELETE_CONFIRMATION_NO: 'Nu',
    DESCRIPTION:
      'Aici poți configura răspunsurile asistentului bazat pe machine learning. Adaugă/Importă o bază de cunoștințe sau configurează una existentă. ',
    DESCRIPTION_NO_TABEL:
      'Aici poți configura răspunsurile asistentului bazat pe machine learning. Adaugă/Importă o bază de cunoștințe.',
    EXPORT: 'Exportă',
    FILTER_PLACEHOLDER: 'Numele bazei de cunoștințe căutate',
    IMPORT: 'Importă o bază de cunoștințe',
    IMPORT_KNOWLEDGE_BASE: 'Importă o bază de cunoștințe',
    INTENTS: 'Configurare',
    LANGUAGE: 'Limbă',
    NAME: 'Nume',
    NAME_DESCRIPTION: 'Specifică numele bazei de date',
    SHARE: 'Partajează',
    SHARE_KNOWLEDGE_BASE: 'Partajează baza de cunoștinte selectată',
    SHARE_KNOWLDEGE_BASE_NO_SHARED:
      'Baza de cunoștințe nu a fost partajată cu nici un utilizator',
    SHARE_KNOWLEDGE_BASE_USERNAME: 'Utilizatori partajați',
    SHARE_KNOWLEDGE_BASE_BUTTON_PLACEHOLDER: 'Selectează un utilizator..',
    SIMPLE_KNOWLEDGE_BASE_BUILDER_NAME_TABLE: 'Numele bazei de cunoștințe',
    SIMPLE_KNOWLEDGE_BASE_BUILDER_LANGUAGE_TABLE: 'Limba selectată',
    TITLE: 'Baze de cunoștințe'
  },
  SIMPLE_KNOWLEDGE_BASE_BUILDER_INTENT_EDITOR: {
    ADD: 'Adaugă intenție',
    ADVANCED_SEARCH: 'Căutare avansată',
    ANSWER: 'Ce răspunde asistentul',
    ANSWER_DESCRIPTION: 'Specifică cu ce va răspunde asistentul',
    ANSWER_TYPE: 'Răspuns',
    BACK: 'Înapoi',
    DELETE_CONFIRMATION_DESCRIPTION: 'Confirmă ștergerea răspunsului',
    DELETE_CONFIRMATION_NO: 'Nu',
    DELETE_CONFIRMATION_YES: 'Da',
    DESCRIPTION:
      'Configurează conversațiile automate între asistent și elev. Poți defini exemple pentru identificarea intenției elevului și configurarea automată a răspunsurilor. Pentru fiecare intenție va trebui să adaugi un set de exemple de antrenare folosind opțiunea "Config. exemple".',
    DESCRIPTION_NO_TABLE:
      'Configurează conversațiile automate între asistent și elev. Poți defini exemple pentru identificarea inteției elevului și configurarea automată a răspunsurilor. Pentru fiecare intenție va trebui să adaugi un set de exemple de antrenare. ',
    EDIT: 'Config. exemple',
    FILTER_PLACEHOLDER: 'Numele intenției',
    INTENT_ADVANCED_SEARCH: 'Căutare avansată',
    INTENT_NAME: 'Ce a întrebat elevul',
    INTENT_TYPE: 'Intenție',
    LIST_SAMPLES: 'Listă de exemple',
    LIST_SAMPLES_TOOLTIP:
      'Scrie aici texte similare cu ce ar putea scrie elevul în așa fel încât să fie utilizat ca set de antrenare pentru algoritmul de machine learning.',
    NAME: 'Ce a întrebat elevul',
    NAME_DESCRIPTION:
      'Specifică un identificator al întrebării pe care dorești să o definești',
    PERFORM_SEARCH: 'Caută',
    RESULT_TYPE: 'Tip de rezultat',
    RESULTS_PLACEHOLDER:
      'Nu există rezultate. Scrie numele intenției, exemplul sau răspunsul pentru a căuta',
    SAMPLE_TYPE: 'Exemplu',
    SAMPLES: 'Specifică un exemplu',
    SAMPLES_EMPTY: 'Atenție, nu sunt specificate exemple de antrenare',
    SEARCH_PLACEHOLDER: 'Numele intenției, exemplul sau răspunsul',
    TITLE: 'Editor răspunsuri'
  },
  // INTENT_EDITOR: {
  //   TITLE: 'Editor de intenții',
  //   NAME: 'Nume',
  //   SAMPLES: 'Samples',
  //   ANSWER: 'Răspuns'
  // },
  CLIENT_SIDE_ERRORS: {
    INVALID_FILE: 'Fișier invalid',
    INVALID_JSON_FILE: 'Fișierul JSON nu este formatat corect',
    POSITIVE_INTEGER: 'Trebuie sa fie un număr pozitiv',
    REQUIRED: 'Trebuie completat'
  },
  CLAIM: {
    ADD_OWN_STUDENT: 'Adăugare elev',
    STUDENT_ACCOUNT: 'Nume de utilizator elev',
    STUDENT_EMAIL: 'Adresa de mail înregistrată'
  },
  CREDIT: {
    EXISTING: 'Adaugă credit la un cont existent',
    NEW: 'Creează un cont nou'
  },
  DASHBOARD: {
    ADD_GROUP: 'Creează grupă',
    ADD_INSTANCE: 'Deschide o sesiune',
    CALENDAR: 'Programarea activităților',
    CHAT_GPT_AVAILABILITY_NOTICE: 'Integrarea cu serviciul ChatGPT va fi disponibilă începând cu data de 15 Martie 2023',
    CREATE_AN_ACCOUNT: 'Creează un cont nou',
    GROUPS: 'Grupe',
    NO_FLOW: 'Niciun flux selectat',
    NO_INSTANCE: 'Nicio sesiune deschisă',
    PATH_EDITOR: 'Editor conținut educațional',
    PROFILE: 'Profil',
    REGISTER: 'Înscriere',
    REGISTRATIONS: 'Înscrieri',
    REGISTER_TO_ACCESS: 'Vă rugăm să confirmați înscrierea la ediția curentă apăsând butonul de mai jos',
    SEE_YOUR_GROUPS: 'Comunitățile tale',
    SELECTED_FLOW: 'Fluxul selectat',
    USERS: 'Utilizatori'
  },
  EVENT_TYPES: {
    COURSE: 'Curs/Lecţie',
    GROUP: 'Cerc şcolar',
    HACKATHON: 'Hackathon educaţional',
    OTHERS: 'Altele',
    TEST: 'Test',
    TEST_WARNING_CAN_NOT_CHANGE: 'Atenție, nu se poate schimba fluxul de test pentru o activitate',
    TEST_WARNING_EVENT_TYPE: 'Atenție, fluxul selectat este de test, iar tipul evenimentului este',
    TEST_WARNING_IS_IN_TESTING: 'Atenție, fluxul de test selectat este vizibil în biblioteca publică dacă traiectoria de învățare este publicată. Acest fapt poate determina intrarea elevilor în flux și din bibliotecă, situație în care nu se înregistrează răspunsurile. Pentru a ascunde testul din biblioteca publică, selectează fluxul ca fiind "Accesibil doar pentru testare" din editorul de traiectorii.',
    TEST_WARNING_FLOW: 'Atenție, tipul evenimentului este test, iar fluxul selectat nu este de test',
    UNSPECIFIED: 'Nespecificat'
  },
  EVENT: {
    ACCESS_TOKEN: 'Cod de acces',
    ACTIVE_PARTICIPANTS: 'Participanți activi',
    ADD_COMMUNITY: 'Alocare comunitate',
    ADD_EXISTING_COMMUNITY: 'Alocare comunitate existentă',
    ADD_EVENT: 'Creare activitate',
    ADD_NEW_EVENT: 'Adaugă activitate',
    AGE: 'ani',
    AGE_RANGE: 'Categoria de vârstă',
    APPROVE_EVENT: 'Aprobă activitate',
    ASSIGN_EVENT: 'Alocă activitate',
    ASSIGN_EVENT_SUCCESSFUL: 'Activitate alocată!',
    CHANGE_FLOW: 'Schimbă fluxul activității',
    CLICK_FOR_ASYNCHRONOUS_FLOW: 'Click pe butonul de mai jos pentru a parcurge fluxul în afara activităților din calendar.',
    CODE: 'Cod de acces',
    CONFERENCE_LINK: 'Link spre conferința online (Meet, Zoom, Teams, etc)',
    CONFIRM_EVENT: 'Confirmă',
    CONTINUE_AS: 'Intră în eveniment ca',
    COORDINATOR: 'Coordonator',
    COPY_TOKEN_SUCCESS: 'Cod copiat',
    CREATE_EVENT: 'Creează un eveniment',
    DATE: 'În data de',
    DELETE_INSTANCE: 'Șterge',
    DESCRIPTION: 'Titlu activitate',
    DURATION: 'Durata',
    EVENTS: 'Activități',
    EDIT_EVENT_APPLY_ALL: 'Aplică schimbările la activitățile viitoare',
    EDIT_EVENT_INSTANCE: 'Editare activitate',
    ENABLE_TEST_ANSWER_VIEWING: 'Permiteți vizualizarea răspunsurilor corecte după corectare?',
    EVENT: 'Activitate',
    EVENT_CALENDAR: 'Calendar activități',
    EVENT_COMPLETE_NECESSARY_FIELDS: 'Vă rugăm să completați toate câmpurile obligatorii, cu valori valide!',
    EVENT_DELETE_AFTER: 'Acest eveniment și următoarele',
    EVENT_DELETE_ALL: 'Toate evenimentele',
    EVENT_DELETE_CURRENT: 'Acest eveniment',
    EVENT_DELETE_DIALOG_TITLE: 'Șterge evenimentul',
    EVENT_DETAILS: 'Detaliile activității',
    EVENT_DURATION_12HR: '12h',
    EVENT_DURATION_1D: '1 zi',
    EVENT_DURATION_1HR: '1h',
    EVENT_DURATION_1HR_30M: '1h 30min',
    EVENT_DURATION_2D: '2 zile',
    EVENT_DURATION_2HR: '2h',
    EVENT_DURATION_30M: '30min',
    EVENT_DURATION_3D: '3 zile',
    EVENT_DURATION_3HR: '3h',
    EVENT_DURATION_45M: '45min',
    EVENT_DURATION_4D: '4 zile',
    EVENT_DURATION_5D: '5 zile',
    EVENT_DURATION_5HR: '5h',
    EVENT_DURATION_6D: '6 zile',
    EVENT_DURATION_7D: '7 zile',
    EVENT_DURATION_2W: '2 săptămâni',
    EVENT_DURATION_4W: '4 săptămâni',
    EVENT_DURATION_9HR: '9h',
    EVENT_EXPIRED: 'Activitatea s-a terminat.',
    EVENT_FLOW: 'Fluxul',
    EVENT_FREQUENCY_DAILY: 'Zi',
    EVENT_FREQUENCY_MONTHLY: 'Luna',
    EVENT_FREQUENCY_WEEKLY: 'Săp.',
    EVENT_GROUP: 'Comunitate',
    EVENT_GROUP_ADD_PARTICIPANTS: 'Adaugă participanții la comunitate',
    EVENT_GROUP_ADD_ONLY_PERMANENT_PARTICIPANTS: 'Adaugă doar utilizatorii permanenți la comunitate',
    EVENT_GROUP_ADD_PARTICIPANTS_DESCRIPTION: 'Participanții la eveniment vor fi adăugați în comunitatea asociată',
    EVENT_GROUP_ADD_PARTICIPANTS_SUCCESS: 'Participanții au fost adăugați în comunitate',
    EVENT_HOST: 'Creat de ',
    EVENT_INTERFACE_EXPLANATION_1: 'În această interfață puteți adăuga clase de elevi, puteți genera conturi elevilor și crea comunități virtuale.',
    EVENT_INTERFACE_EXPLANATION_2: 'O comunitate virtuală reprezintă un grup de utilizatori care vor participa împreună la activitățile de învățare (hackathoane, cursuri, teste, cercuri stiintifice, etc). ',
    EVENT_MULTIPLE_DELETE_DIALOG_TITLE: 'Șterge evenimentul repetat',
    EVENT_NEEDS_DESCRIPTION: 'Activitatea trebuie să aibă o descriere!',
    EVENT_NEEDS_FLOW: 'Trebuie selectat un flux!',
    EVENT_NEEDS_LOCATION: 'Trebuie selectata o locație!',
    EVENT_NEEDS_NR_REPEATS: 'Trebuie specificat numărul de întâlniri!',
    EVENT_NEEDS_PARTICIPANTS: 'Trebuie specificat un număr de participanti!',
    EVENT_NEEDS_TRAINERS: 'Trebuie sa existe minim 1 trainer!',
    EVENT_TYPE_COURSE: 'Curs/Lecţie',
    EVENT_TYPE_GROUP: 'Cerc şcolar',
    EVENT_TYPE_HACKATHON: 'Hackathon educaţional',
    EVENT_TYPE_NONE: 'Altele',
    EVENT_TYPE_TEST: 'Test',
    FEEDBACK: 'Motivație',
    FINISHED: 'Terminat',
    GO_TO_DAY: 'Vizualizează ziua',
    GO_TO_EVENT: 'Vizualizează evenimentul',
    GROUP: 'Grupă asociată',
    HOUR: 'La ora',
    INCLUDE_PUBLIC_EVENTS: 'Include activitățile publice',
    INVITED: 'Invitaţi',
    INVITE_TRAINERS: 'Invită',
    INVITE_ENTER_EMAIL: 'Apasă Enter pentru a introduce una sau mai multe adrese de email',
    IN_PROGRESS: 'În derulare',
    IS_PUBLIC: 'Activitate publică?',
    LAST_EVENTS: 'Următoarele activități',
    LOCATION: 'Locație',
    MANAGE_PARTICIPANTS: 'Participanți',
    NOT_STARTED: 'Activitatea nu a început încă. Vă rugăm să reveniți!',
    NO_ACTIVE_PARTICIPANTS: 'Nu există participanți activi',
    NO_COMMUNITY: 'Nedefinit',
    NO_EVENTS: 'Nu există activități de afișat',
    NO_FLOW: 'Niciun flux',
    ONLINE_MEET: 'Video conferință asociată',
    PARTICIPANTS: 'Participanți',
    PARTICIPANT_EXPIRY: 'Participanții pot expira',
    PARTICIPATE: 'Participă',
    PAYMENT_MESSAGE: 'Costul fluxului pentru acestă activitate este: ',
    POINTS: ' puncte',
    REJECTED: 'Respins',
    REPEATABLE: 'Repetabil?',
    REQUESTED: 'Cerut',
    SCHEDULED: 'Programat',
    SELECT_FLOW: 'Flux',
    SELECT_FLUX: 'Selectați un flux pentru generarea de pachete SCORM',
    SELECT_LOCATION: 'Selectați o locație',
    SELECT_TYPE: 'Tip activitate',
    SEND_INVITES: 'Trimite invitațiile',
    SETTINGS_ADVANCED: 'Setări avansate',
    SHOW_CODE: 'Cod de acces',
    SHOW_CODE_SHORT: 'Cod',
    SHOW_CODE_TOOLTIP: 'Copiază codul de acces',
    COMMUNITY_AUTO_JOIN: 'Înrolează participanții automat în comunitate la intrarea în eveniment',
    ALLOW_ONLY_PERMANENT_USERS: 'Permite doar utilizatorilor definitivi să participe la eveniment',
    START_DATE: 'Data de început',
    START_HOUR: 'Ora de început',
    STATE: 'Starea activității',
    STOP_EVENT: 'Marchează evenimentul ca terminat',
    STOP_EVENT_EXPLANATION: 'Durata evenimentului va fi modificată astfel încât acesta va deveni terminat',
    STUDENT_NUMBER: 'Număr maxim de copii',
    TIMES_REPEATED: 'Număr de sesiuni',
    TOOLTIP_ACCEPT_EVENT: 'Confirmă',
    TOOLTIP_ADD_PARTICIPANTS: 'Adaugă participanți',
    TOOLTIP_CHOOSE_FLOW: 'Alocă lecție',
    TOOLTIP_COPY_TOKEN: 'Copiază link-ul',
    TOOLTIP_DELETE_EVENT: 'Șterge',
    TOOLTIP_EDIT_EVENT: 'Editează',
    TOOLTIP_INVITE: 'Invită',
    TOOLTIP_SEE_TOKEN: 'Vezi codul',
    TOOLTIP_STOP_EVENT: 'Marchează evenimentul ca terminat',
    TRAINER_NUMBER: 'Număr de traineri',
    TYPE_EVENT: 'Tip event',
    VALIDATE_ATTENDANCE: 'Validare prezență',
    WELCOME_TO_THE_GROUP: 'Bine ai venit în comunitatea'
  },
  FACETS: {
    PARENT: 'Părinte',
    SELLER: 'Coordonator',
    STUDENT: 'Student',
    TEACHER: 'Profesor',
    VOLUNTEER: 'Voluntar'
  },
  FLOW_EDITOR: {
    ASSISTANT: 'Asistent de învățare',
    ASSISTANT_FILTER_PLACEHOLDER: 'Asistent',
    DEFAULT_KNOWLEDGE_BASE: 'Baza de cunoștințe',
    DEFAULT_KNOWLEDGE_BASE_FILTER_PLACEHOLDER: 'Baza',
    DESCRIPTION: 'Denumire flux',
    DESCRIPTION_FILTER_PLACEHOLDER: 'Denumire',
    DESCRIPTION_SEARCH_PLACEHOLDER: 'Descrierea traiectoriei sau a fluxului',
    DESELECT_FLOW: 'Deselectează',
    END_AGE: 'Vârsta maximă',
    END_AGE_FILTER_PLACEHOLDER: 'Vârsta',
    FLOW_EDITOR: 'Listă de fluxuri',
    FLOW_EDITOR_INFO_SUMMARY: 'În secțiunea curentă sunt afișate fluxurile de învățare din traiectoria selectată',
    FLOW_TOTAL_COUNT: 'Număr total de fluxuri: ',
    GRADING_MODEL: 'Tip de barem',
    GRADING_MODEL_CUSTOM: 'Personalizat',
    GRADING_MODEL_CUSTOM_DATATIP: 'Întrebările au punctaj personalizat individual',
    GRADING_MODEL_DEFAULT: 'Standard',
    GRADING_MODEL_DEFAULT_DATATIP: 'Toate întrebările valorează 10 puncte',
    GRADING_MODEL_ENABLED_QUESTIONS: 'Întrebări incluse în test',
    GRADING_MODEL_MAX_POINTS: 'Punctaj maxim',
    GRADING_MODEL_REQUIREMENTS: 'Barem de corectare',
    GRADING_MODEL_REQUIREMENTS_ADD: 'Adaugă o cerință',
    GRADING_MODEL_REQUIREMENTS_CREATE: 'Creează barem',
    GRADING_MODEL_REQUIREMENTS_DELETE: 'Șterge baremul',
    GRADING_MODEL_REQUIREMENTS_DESCRIPTION: 'Descrierea cerinței',
    GRADING_MODEL_REQUIREMENTS_EDIT: 'Modifică baremul',
    GRADING_MODEL_REQUIREMENTS_EMPTY: 'Nu există nicio cerință. Punctajul se va acorda integral.',
    GRADING_MODEL_REQUIREMENTS_NOT_AVAILABLE: 'Indisponibil',
    GRADING_MODEL_REQUIREMENTS_REQUIREMENT: 'Cerință',
    GRADING_MODEL_REQUIREMENTS_SAVE_NEEDED: 'Este necesară salvarea manuală a modificărilor asupra baremului folosind butonul de mai jos',
    GRADING_MODEL_STANDARD: 'Toate întrebările se punctează, 10 puncte per răspuns corect',
    IS_QUESTION_ACTIVE: 'Întrebarea se punctează?',
    LANGUAGE: 'Limba',
    LANGUAGE_FILTER_PLACEHOLDER: 'Limbă',
    MOVE_DOWN: 'Mută fluxul mai jos',
    MOVE_UP: 'Mută fluxul mai sus',
    NO_DATA: 'Nu există fluxuri în această traiectorie',
    OWNED_FLOW_EDITOR_INFO_SUMMARY: 'În secțiunea curentă sunt afișate fluxurile de învățate din traiectoria selectată',
    POINTS: 'Puncte',
    QUESTION_TYPE: 'Tip de întrebare',
    RESOURCE_IMAGE_LOADER_DIALOG_HEADER: 'Alege URL-ul imaginii',
    RESOURCE_LINK_LOADER_DIALOG_HEADER: 'Alege URL-ul resursei',
    RESOURCE_LOADER_DIALOG_ENABLE_BUTTON: 'Resurse externe / CMIS',
    RESOURCE_LOADER_DIALOG_NO_RESOURCES: 'Nu a fost găsit niciun rezultat',
    RESOURCE_LOADER_DIALOG_SEARCH: 'Resursa căutată',
    RESOURCE_LOADER_DIALOG_SEARCH_MODE_CODE: 'Codul resursei',
    RESOURCE_LOADER_DIALOG_SEARCH_MODE_PLACEHOLDER: 'Modul de căutare',
    RESOURCE_LOADER_DIALOG_SEARCH_MODE_TITLE: 'Titlul resursei',
    RESOURCE_VIDEO_LOADER_DIALOG_HEADER: 'Alege URL-ul video-ului (YouTube)',
    RESOURCE_LOADER_DIALOG_THUMBNAIL: 'Thumbnail',
    RESOURCE_LOADER_DIALOG_SELECT_THUMBNAIL: 'Selectează un thumbnail pentru videoclip',
    SELECTED_FLOW: 'Flux selectat',
    SELECT_FLOW: 'Selectează',
    START_AGE: 'Vârsta minimă',
    START_AGE_FILTER_PLACEHOLDER: 'Vârsta',
    STEPS: 'Pași',
    TEST: 'Testează',
    THE_QUESTION: 'Întrebare',
    THE_STEP: 'Pas',
    WELCOME: 'Mesaj introductiv',
    WELCOME_FILTER_PLACEHOLDER: 'Mesaj'
  },
  FORM_TEMPLATE: {
    BACK: 'Întoarcere la utilizări',
    COLUMN_USER: 'Utilizator',
    COLUMN_FLOW: 'Flux',
    COLUMN_STEP: 'Pas',
    COLUMN_DATE: 'Data',
    DELETE_OPTION_TOOLTIP: 'Șterge opțiunea',
    DELETE_TOOLTIP: 'Șterge câmpul',
    DETAILS_TITLE: 'Detalii despre formular',
    DOWN_ARROW_TOOLTIP: 'Mută câmpul pe poziția următoare',
    DROPDOWN: '"Dropdown"',
    EDIT_OPTION_TOOLTIP: 'Modifică opțiunea',
    ELEMENT_LABEL: 'Etichetă',
    ELEMENT_UPDATED: 'Element actualizat',
    ENTRIES: 'Înregistrări',
    FIELD_UNDEFINED: 'Nu s-a răspuns',
    FILE_UPLOAD: 'Încărcare fișier',
    FILTER_PLACEHOLDER: 'Filtrează după nume',
    FORM_APPLY_ALL: 'Actualizează structura formularului in fluxurile existente',
    FORM_APPLY_ALL_TOOLTIP: 'Modificările formularului se vor aplica retroactiv',
    FORM_CONFIRM_DELETE: 'Confirmă ștergerea formularului',
    FORM_DATE: 'Data ultimei modificări a structurii formularului',
    FORM_EDITOR_INSTRUCTIONS: 'Folosește spațiul de mai jos pentru editarea formularului. Poți adăuga noi câmpuri făcând "drag&drop" din zona dreaptă.',
    FORM_FIELDS: 'Câmpuri pentru formulare',
    FORM_FLOW: 'Flux',
    FORM_INSTANCE_VIEWER: 'Utilizări ale formularului: ',
    FORM_INSTANCE_VIEWER_SUBTITLE: 'Aici poți vedea unde a fost utilizat formularul: ',
    FORM_PLACEHOLDER: 'Trage un câmp aici',
    FORM_QUESTION: 'Întrebare',
    FORM_STEP: 'Pas',
    FORM_SUBMISSION_VIEWER: 'Răspunsuri pentru formularul: ',
    FORM_VIEW: 'Vizualizează',
    FORM_VIEW_SUBMISSIONS: 'Vezi înregistrările',
    MAX_FIELDS_WARNING: 'Se recomandă folosirea a maxim 3 câmpuri pe un formular pentru menținerea unui grad de atenție optim',
    NAME: 'Nume formular',
    NO_FORM_SUBMISSIONS: 'Nu există răspunsuri încă.',
    PLACEHOLDER_ATTRIBUTE: 'Text afișat în câmp',
    SEE_DATA: 'Vezi date',
    SEND: 'Trimite',
    SUBMISSIONS: 'Înregistrări',
    TEXT_AREA: 'Text multilinie',
    TEXT_INPUT: 'Text scurt',
    THE_FIELD: 'Câmpul:',
    THE_FORM: 'Formularul: ',
    TITLE: 'Titlu formular',
    UPDATE_ELEMENT: 'Actualizează',
    UP_ARROW_TOOLTIP: 'Mută câmpul pe poziția anterioară'
  },
  GENERAL: {
    ACCESS: 'Accesează',
    ACCESS_FLOW: 'Accesează fluxul',
    ACCEPT: 'Acceptă',
    ACTIVATE: 'Activează',
    ACTIVITY: 'Activitate',
    ADD: 'Adaugă',
    ADD_BLANK_FLOW: 'Adaugă șablon de lecție cu 10 pași',
    ADD_CHILD: 'Adaugă un descendent',
    ADMIN: 'Admin',
    APPLE_DETECTED: 'Am detectat că folosești un dispozitiv iOS. Unele funcționalități precum vocea asistentului inteligent sunt implicit blocate, te rugăm să deblochezi opțiunea de autoplay.',
    ASSIGN: 'Asociază',
    ASSIGN_EVENT: 'Alocă unei activități',
    ASSIGN_EVENT_SUCCESS: 'Alocare efectuată cu succes',
    ASSOCIATED_STUDENTS: 'Elevi asociați',
    ASSOCIATE_WITH_TEACHER: 'Nu uita să te asociezi cu profesorii tăi din secțiunea Profil -> Asocieri Profesori.',
    AVAILABLE: 'disponibile',
    BACK: 'Înapoi',
    BOT_NAME: 'Ioana',
    CANCEL: 'Anulează',
    CHANGE_CODE: 'Selectează un alt eveniment',
    CLAIM: 'Asociază elev existent',
    CLOSE: 'Închide',
    CONFIGURE: 'Configurează',
    CONFIGURE_ACCOUNT: 'Configurare cont',
    CONFIRM: 'Confirmă',
    CONFIRMED: 'Confirmat?',
    CONTINUE: 'Continuă',
    CONTINUE_TO_ASSOCIATIONS: 'Mergi la Asocieri',
    CONTINUE_TYPE_DONE: 'Scrie gata să treci mai departe',
    COPY: 'Copiază',
    COPY_LINK: 'Copiază link-ul',
    CORRECT: 'Corect',
    CORRECT_QUESTION: 'Corect!',
    CORRECT_QUESTION_EN: 'Correct!',
    CREATED_EVENTS: 'Activități create',
    CREATED_FLOWS: 'Lecții create',
    CURRENT_FLOW: 'Lecția curentă',
    CUT: 'Taie',
    DELETE: 'Șterge',
    DESCRIPTION: 'Descriere',
    DOCUMENTATION: 'Documentație',
    DOWNLOAD: 'Descarcă',
    DOWNLOAD_NO_CONTENT: 'Formularul a fost trimis gol',
    DYNAMIC_FORM_FIELD_LABEL: 'Etichetă câmp',
    EDIT: 'Modifică',
    EMPTY_DATA: 'Nu există date',
    ERROR: 'Eroare',
    EVENT: 'Eveniment',
    EXPAND: 'Expandează',
    EXPORT: 'Exportă',
    EXPORT_IS_FETCHING: 'Se exportă...',
    EXPORT_FLOW: 'Exportă lecția',
    EXPORT_PATH: 'Exportă traiectoria',
    FILTER: 'Filtează',
    FILE_UPLOAD_EMPTY: 'Nu a fost încărcat niciun fișier',
    FILE_WAS_DOWNLOADED: 'Fișierul a fost descărcat',
    FILTER_BY_FLOW: 'Filtează după flux',
    FILTER_BY_NAME: 'Filtrează după utilizator',
    FINISHED_EVENTS: 'Activități terminate',
    FINISHED_FLOWS: 'Lecții parcurse',
    FINISHED_TESTS: 'Teste date',
    FLOW: 'Fluxul',
    FORM_FIELD_NO_ANSWER: 'Fără răspuns',
    GO_TO_DASHBOARD: 'Întoarce-te în Dashboard',
    GREETING: 'Bună ziua',
    HELPER: 'Apasă pentru a vizualiza informații despre conținut',
    I_UNDERSTAND: 'Am înțeles',
    IMPORT: 'Importă',
    IMPORT_FLOW: 'Importă o lecție',
    IMPORT_PATH: 'Importă o traiectorie',
    IN: 'în',
    INCORRECT: 'Greșit',
    INCORRECT_QUESTION: 'Greșit!',
    INCORRECT_QUESTION_EN: 'Wrong!',
    IS_DEFAULT: 'Este implicit',
    LETS_CONTINUE: 'Hai să continuăm.',
    KEY: 'Cheie',
    LOADING: 'Se încarcă...',
    LOGIN: 'Autentificare',
    LOGIN_WITH_CODE: 'Autentificare prin cod',
    LOGIN_EXTERNAL_WAIT: 'Se încearcă autentificarea externă. Vă rugăm ăsteptați!',
    LOGOUT: 'Ieșire',
    MAP_ERROR: 'Eroare la încărcarea hărții',
    MESSAGE: 'Mesaj',
    MONITORING: 'Monitorizare',
    NEW_VERSION_AVAILABLE: 'Nextlab are o nouă versiune!',
    NAME: 'Nume',
    NO: 'Nu',
    NO_FLUX_COVERED: 'Nu există fluxuri parcurse cu acest cont.',
    OPTION: 'Opțiunea',
    OR: 'Sau',
    PASTE: 'Lipește',
    PARENT: 'Părinte',
    PATH: 'Traiectoria',
    POINTS: 'puncte',
    PREVIEW: 'Previzualizează',
    PREVIEW_NOUN: 'Previzualizare',
    PRINT: 'Tipărește',
    REFRESH: 'Reîncarcă',
    REJECT: 'Respinge',
    RELOAD: 'Actualizează aplicația',
    RELOAD_APP_DESCRIPTION: 'Apasă butonul pentru a sincroniza aplicația web',
    RELOAD_TABLE: 'Reîncarcă tabelul',
    REORDER: 'Reordonează',
    REPORT_BUG: 'Raportează o problemă',
    REPORT: 'Raport',
    REVIEW: 'Evaluează',
    ROLE: 'Rol',
    RULES: 'Detalii și regulament',
    SAVE: 'Salvează',
    SEARCH: 'Caută',
    SEE_CALENDAR: 'vezi tot Calendarul',
    SEE_DETAILS: 'Vezi detalii',
    SEE_EDITOR: 'vezi toate Lecțiile',
    SEE_LEARNING_STATS: 'vezi tot Raportul',
    SEE_MORE_RESULTS: 'Vezi mai multe rezultate',
    SEE_RESULTS: 'vezi toate Rezultatele',
    SEE_STUDENTS: 'vezi toți Elevii',
    SELECT: 'Selectează',
    SELECTED_FILE: 'Fișier selectat',
    SELECTED_FILE_EMPTY: 'Niciun fișier selectat',
    SELECT_OPTION: 'Selectați o opțiune',
    SELLER: 'Seller',
    SELLER_MANAGER: 'Seller Manager',
    SEND: 'Trimite',
    SHOW_FORM: 'Afișează formularul',
    SHOW_FORM_INSTANCES: 'Afișează înregistrări',
    SHOW_LESS: 'Vezi mai puțin',
    SHOW_MORE: 'Vezi mai mult',
    SIGN_IN: 'Sign in',
    SUBMISSION_CONTENT:'Conținut încărcare',
    SUBMISSION_GRADING_ACCEPTED:'Corectarea încărcării a fost acceptată',
    SUBMISSION_GRADING_REJECTED:'Corectarea încărcarii a fost respinsă',
    SUBMISSION_GRADING: 'Corectarea încărcarii',
    START: 'Start',
    STEP: 'Pas',
    STUDENT: 'Elev',
    SYNC_PATHS: 'Sincronizare bibliotecă',
    TEACHER: 'Profesor',
    TEMPORARY_USER: 'utilizator temporar',
    TESTS_TAKEN: 'Teste parcurse',
    TEXT: 'Text',
    THANKS: 'Mulțumesc',
    THANKS_EN: 'Thank you!',
    TIMESTAMP: 'Data',
    TIMEZONE: 'Europe/Bucharest',
    TITLE: 'Titlu',
    TOTAL: 'Total',
    URL: 'URL',
    UPLOADED_FILE: 'Fișierul încărcat',
    VALUE: 'Valoare',
    UNLOCK_FLOW_CONFIRMATION: 'Nu ai deblocat acest flow.\nDeblocarea costă 1 punct.',
    UPDATE: 'Actualizează aplicația',
    UPLOAD: 'Încarcă',
    UPLOAD_COVER_IMAGE_FOR: 'Încarcă coperta pentru',
    UPLOAD_LIMIT: 'Maxim 10MB',
    USER_CLASS: 'Clasă',
    USE_THIS_ACCOUNT: 'Continuă cu ',
    VALIDATION: {
      ALPHANUMERIC_CODE: 'Codul poate fi alcătuit doar din litere și cifre',
      FIELD_AGE_INVALID_RANGE: 'Intervalul de varsta este incorect',
      FIELD_MAXIMUM_CHARACTERS: 'Acest câmp nu poate depăși 30 caractere.',
      FIELD_MAXIMUM_INPUT_CHARACTERS: 'Pentru acest câmp se recomandă a nu se depăși 300 de caractere.',
      INVALID_EMAIL: 'Email invalid',
      MINIMUM_ONE_ANSWER: 'Întrebarea trebuie să conțină cel putin o variantă de răspuns',
      MAXIMUM_ONE_CORRECT_ANSWER: 'Întrebarea nu poate conține mai mult de un răspuns corect',
      DATE: 'Câmpul trebuie să conțină o dată validă',
      DATE_INTERVAL: 'Intervalul de timp selectat este invalid',
      FIELD_REQUIRED: 'Acest câmp este obligatoriu!',
      BOTH_FIELDS_REQUIRED: 'Ambele câmpuri sunt obligatorii!',
      FIELD_TIME_DIFFERENCE: 'Timpul MAXIM trebuie să fie mai mare ca timpul MINIM',
      NO_NULL_EXAMPLES: 'Nu se pot adăuga exemple goale',
      VALIDATION_ERROR: 'Eroare de validare'
    },
    VIDEO: 'Video',
    VIDEO_PRESENTATION: 'Prezentare video',
    VIEW: 'Vizualizează',
    WELCOME_DIALOG_HEADER: 'Bun venit',
    WELCOME_DIALOG_MESSAGE_STUDENT: 'Vizitează secțiunea activități! Aici vei putea participa la cele mai interesante lecții online si hackathoane educaționale!',
    WELCOME_DIALOG_MESSAGE_TEACHER: 'Te rugăm sa asociezi conturile elevilor tăi. Poți face asta oferindu-le username-ul tău sau dacă le cunoști username-ul si e-mail-ul poți face asocierile din secțiunea Profil.',
    WRONG: 'Greșit',
    YES: 'Da',
    YOUR_ACCOUNTS: 'Conturile tale'
  },
  GROUP_LIST: {
    ADD_CLASS: 'Adaugă clasă',
    ADD_EVENT: 'Adaugă activitate',
    ADD_GROUP: 'Adaugă comunitate',
    ADD_GROUP_NEW: 'Creează o comunitate nouă',
    ADD_MEMBER: 'Adăugare',
    ADD_MEMBERS: 'Adaugă utilizatori existenți',
    ADD_STUDENTS: 'Adaugă elevi existenți',
    ADD_SUBGROUP: 'Adaugă subgrup',
    ASSOCIATE_EXISTING_STUDENTS: 'In cazul in care cunosti conturile elevilor tai ii poti adauga acum folosind butonul',
    ASSOCIATE_STUDENTS_REVERSE_1: 'Poti de asemenea sa le soliciti sa se asocieze cu contul tau',
    ASSOCIATE_STUDENTS_REVERSE_2: 'sau sa-i asociezi mai tarziu.',
    CHILD_EMAIL: 'Adresa de e-mail a copilului*',
    CITY: 'Oraș*',
    CLASS: 'Clasă*',
    CLASS_NAME: 'Numele clasei',
    CLASS_VOLUNTEER: 'Clasă (opțional)',
    COMMUNITIES: 'Comunităţi virtuale',
    COMMUNITY_NAME: 'Numele comunităţii',
    COMPETENCY_EXPLANATION: 'Puteți selecta o programă analitică pentru a vedea cum au răspuns elevii la întrebările relevante pentru dobândirea competențelor din cadrul acelei programe.',
    CONFIRM_MEMBERSHIP_DELETE: 'Confirmă eliminarea membrului din comunitate',
    CONFIRM_GROUP_DELETE: 'Confirmă ștergerea comunității',
    COORDINATOR: 'Coordonator',
    COUNTRY: 'Țară*',
    COUNTY: 'Județ*',
    CREATE_MEMBERS: 'Creează utilizatori noi',
    CREATE_STUDENTS: 'Creează elevi noi',
    CREATE_VOLUNTEERS: 'Creează cont voluntar',
    DELETE_CONFIRMATION_DESCRIPTION: 'Confirmă ștergerea comunități',
    DELETE_CONFIRMATION_NO: 'Nu',
    DELETE_CONFIRMATION_YES: 'Da',
    DELETE_GROUP: 'Şterge',
    DELETE_GROUP_MEMBERS: 'Ştergere membru',
    DESCRIPTION: 'Descriere',
    DETAILS_TITLE: 'Detalii despre comunitate',
    EDIT_CLASS: 'Editor clasă',
    EDIT_CLASS_TITLE: 'Editare clasă',
    EDIT_COMMUNITY: 'Editor comunitate',
    EDIT_COMPETENCIES: 'Competențe',
    EDIT_GROUP: 'Editează comunitatea',
    EDIT_GROUP_OPTIONS: 'Opţiuni editare',
    EDIT_GROUP_TITLE: 'Editare comunitate',
    EDIT_VIRTUAL_COMMUNITY: 'Editor communități virtuale',
    EMAIL: 'Adresa de e-mail*',
    EMAIL_VALID: 'Adresa de e-mail trebuie să fie validă',
    ERROR_NAME_REQUIRED: 'Numele este obligatoriu',
    EXCEL_IMPORT: 'Import Excel',
    EXCEL_IMPORT_DESCRIPTION: 'Utilizatorii vor fi creați (dacă numele de utilizator nu este specificat, în caz contrar se vor căuta utilizatori existenți), asociați cu profesorul și adăugați în comunitatea curentă',
    EXCEL_IMPORT_DESCRIPTION_2: 'Prima linie a fișierului excel este reprezentată de numele coloanelor. Ordinea acestora este: Nume de utilizator (opțional), Parola (opțional), Nume complet, Email, Telefon (opțional)',
    EXCEL_IMPORT_SAMPLE_FILE: 'Descarcă un exemplu',
    EXPORT: 'Exportă',
    EXPORT_COMMUNITY_TOOTLIP: 'Exportă o listă cu membrii comunității.',
    FILTER_PLACEHOLDER: 'Caută după nume',
    FULLNAME: 'Numele și prenumele*',
    GENERATE_INVITATION_LINK: 'Generează un link de înrolare',
    GENERATE_INVITATION_LINK_SUCCESS: 'Linkul de invitație a fost copiat',
    GROUP_DIALOG: 'grup',
    GROUP_FILTER_PLACEHOLDER: 'Caută după numele comunităţii',
    GROUP_LIST: 'Lista comunităţilor',
    INVITATIONS: 'Invitații',
    INVITATION_ACCEPTED: 'Invitația a fost acceptată',
    INVITATION_REJECTED: 'Invitația nu este validă',
    JOIN: 'Înscrie-te',
    JOIN_LATER: 'Închide',
    JOIN_EVENT_COMMUNITY: 'Înscrie-te în comunitatea asociată evenimentului',
    JOIN_COMMUNITY_CHOICE: 'Ai opțiunea să te înscrii în comunitatea',
    LINK: 'Link',
    LIST_CLASS_MEMBERS: 'Listă membri clasă',
    LIST_COMMUNITY_MEMBERS: 'Listă membri comunitate',
    MEMBERS: 'Membrii comunităţii',
    MEMBERS_DIALOG: 'Membri',
    MEMBER_FILTER_PLACEHOLDER: 'Caută după nume',
    NAME: 'Nume',
    NEW_CLASS: 'Clasă nouă',
    NEW_COMMUNITY: 'Comunitate nouă',
    NO_EVENT_AVAILABLE: 'Nu există activități programate',
    NO_MEMBER_CLASS: 'Această clasă nu are nici un membru. Poți adăuga membri folosind butoanele de mai jos.',
    NO_MEMBER_COMMUNITY: 'Această comunitate nu are nici un membru. Poți adăuga membri folosind butoanele de mai jos.',
    NO_MEMBER_RECORDS: 'Nu există membri in această comunitate',
    NO_SELECTED_GROUP_SHORT: 'Fără comunitate asociată',
    NO_SELECTED_GROUP: 'Fără comunitate asociată. Modifică activitatea pentru a asocia o comunitate.',
    PARENT_EMAIL: 'Adresa de e-mail a părintelui*',
    PASSWORD: 'Parola*',
    PHONE: 'Numărul de telefon (e.g. +40712345678)*',
    PHONE_VALID: 'Numărul de telefon trebuie să fie valid',
    RECENT_ASSOCIATIONS: 'Elevi asociați recent',
    REGISTER_MEMBER: 'Înregistrare',
    RETURN: 'Revenire',
    SAVE_CLASS: 'Salvare clasă',
    SAVE_COMMUNITY: 'Salvare comunitate',
    SCHOOL: 'Școala*',
    SCHOOL_VOLUNTEER: 'Școala (opțional)',
    SEE_GROUP_MEMBERS: 'Vizualizare membri',
    SELECT_USER_PLACEHOLDER: 'Selectați un utilizator',
    SELECT_GROUP_PLACEHOLDER: 'Selectați o comunitate',
    SKU: 'Codul sku',
    STUDENTS: 'Elevi',
    STUDENT_CLASS: 'Clasa elevului*',
    STUDENT_EMAIL: 'Adresa de e-mail a elevului/părintelui*',
    STUDENT_NAME: 'Numele și prenumele elevului*',
    STUDENT_SCHOOL: 'Școala elevului*',
    SUCCESS: 'Succes',
    TYPE: 'Tip',
    TYPE_ALL_USERS: 'Grup implicit',
    TYPE_CLASS: 'Clasă',
    TYPE_GENERIC_GROUP: 'Grup',
    TYPE_NEW_COMMUNITY: 'Tip',
    TYPE_OTHERS: 'Altele',
    TYPE_SCIENTIFIC_GATHERING: 'Cerc științific',
    USERNAME_WILL_BE_GENERATED: 'Va fi generat',
    USERS: 'Membri',
    USER_ADDED: 'Utilizatorul a fost adăugat',
    USER_LIST: 'Lista utilizatorilor'
  },
  INSPECTOR_DASHBOARD: {
    ACCESS_COUNT: 'Număr de accesări',
    ACCESS_STATS: 'Statistici de acces pentru:',
    CITY: 'Orașul',
    CITY_CHILD: 'Școala',
    CLASS: 'Clasa',
    CONCEPT_COVERAGE: 'Acoperirea conceptelor pentru: ',
    CONCEPT_STATS: 'Statistici de acoperire a conceptelor pentru: ',
    CONVERSATION_COUNT: 'Numărul de mesaje',
    CONVERSATION_STATS: 'Statistici conversaționale pentru: ',
    COVERAGE: 'Nivel de acoperire',
    COUNTY: 'Județul',
    COUNTY_CHILD: 'Orașul',
    GLOBAL_STATS: 'Statistici globale',
    SCHOOL: 'Școala',
    SCHOOL_CHILD: 'Clasa',
    SEARCH_ACCESS: 'Statistici de acces',
    SEARCH_CONCEPTS: 'Acoperirea conceptelor',
    SEARCH_USAGE: 'Statistici conversaționale',
    SEARCH_TYPE: 'Tip de statistici'
  },
  KNOWLEDGE_FIELD_BUILDER: {
    DESCRIPTION: 'Descriere',
    CODE: 'Cod',
    CONFIRM_DIALOG_HEADER: 'Confirmare',
    CONFIRM_DIALOG_CONTENT: 'Confirmați ștergerea materiei?',
    KNOWLEDGE_FIELD_LIST: 'Lista materiilor',
    KNOWLEDGE_MAP_VERSIONS: 'Versiuni hărți de cunoștințe (programe analitice)',
    KNOWLEDGE_MAP_VERSION_BUILDER: {
      ADD_DIALOG_HEADER: 'Adaugă o versiune',
      ADD_KNOWLEDGE_MAP: 'Creează o hartă de cunoștințe',
      CODE: 'Cod',
      CONFIRM_DIALOG_HEADER: 'Confirmare',
      CONFIRM_DIALOG_CONTENT: 'Confirmați ștergerea versiunii hărții de cunoștințe (programei analitice)?',
      COUNTRY: 'Țara',
      NORMATIVE_ACT: 'Act normativ',
      DESCRIPTION: 'Descriere',
      EDUCATIONAL_LEVEL: 'Nivelul educațional',
      KNOWLEDGE_MAP_BUILDER: {
        CODE: 'Cod',
        CONFIRM_DIALOG_HEADER: 'Confirmare',
        CONFIRM_DIALOG_CONTENT: 'Confirmați ștergerea hărții de cunoștințe (programei analitice)?',
        DESCRIPTION: 'Descriere',
        EXPORT: 'Exportă',
        KNOWLEDGE_MAP_EDITOR: 'Editor hartă de cunoștințe (programe analitice)',
        KNOWLEDGE_MAP_DIALOG: {
          CODE: 'Cod',
          DESCRIPTION: 'Descriere',
          UPDATE_DIALOG_HEADER: 'Modifică harta de cunoștințe (programa analitică)',
          ADD_DIALOG_HEADER: 'Adaugă o hartă de cunoștințe (programă analitică)'
        },
        KNOWLEDGE_MAP_LIST: 'Listă hărți de cunoștințe',
        RENDERED_DESCRIPTION: 'Descriere finală'
      },
      KNOWELDGE_MAPS: 'Hărți de cunoștințe (programe analitice)',
      KNOWLEDGE_MAP_PREVIEW_HEADER: 'Harta de cunoștințe',
      KNOWLEDGE_MAP_PREVIEW_CONTENT_DOMAINS: 'Domenii de conținut',
      KNOWLEDGE_MAP_PREVIEW_HEADER_GENERAL_COMPETENCIES: 'Competențe generale',
      KNOWLEDGE_MAP_VERSIONS_LIST: 'Listă versiuni hărți de cunoștințe (programe analitice)',
      NO_KNOWLEDGE_MAP: 'Nu a fost creată o hartă de cunoștințe',
      YEAR: 'Anul',
      UPDATE_DIALOG_HEADER: 'Modifică versiunea'
    },
    UPDATE_DIALOG_HEADER: 'Modifică materia',
    ADD_DIALOG_HEADER: 'Adaugă o materie'
  },
  KNOWLEDGE_MAP: {
    KNOWLEDGE_MAP_TITLE: 'Harta personală de cunoștințe',
    INCLUDE_IN_IMPORT: 'Include harta de cunoștințe',
    EXPORT_WITH_KM: 'Cu hartă de cunoștințe',
    EXPORT_WITHOUT_KM: 'Fără hartă de cunoștințe',
    NO_KNOWLEDGE_MAP: 'Nu există date pentru harta de cunoștințe'
  },
  KNOWLEDGE_MAP_EDITOR: {
    COMPETENCY_ELEMENT: {
      DESCRIPTION: 'Descriere',
      EXPLANATORY_LINK: 'Link explicativ',
      CODE: 'Cod'
    },
    COMPETENCY_ELEMENT_TITLE: 'Element de competență',
    COMPETENCIES: 'Competențe',
    CONTENT_DOMAINS: 'Domenii de conținut',
    CONTENT_DOMAIN_TITLE: 'Domeniu de conținut',
    CONTENT_DOMAIN: {
      DESCRIPTION: 'Descriere',
      CODE: 'Cod'
    },
    CONTENT_ITEM_TITLE: 'Element de conținut',
    CONTENT_ITEM: {
      DESCRIPTION: 'Descriere',
      CODE: 'Cod'
    },
    CONCEPT_TITLE: 'Concept',
    CONCEPT: {
      DESCRIPTION: 'Concept',
      RESOURCE_LINK: 'Link la resursă',
      RESOURCE_TYPE: 'Tip de resursă',
      EXISTING: 'Concept existent',
      NEW: 'Concept nou',
      CODE: 'Cod',
      THRESHOLD: 'Pragul de dificultate (valoare utilizată pentru raportul parcurgerii hărții de cunoștințe)'
    },
    EXPORT_EXCEL: 'Export Excel',
    EXPORT_JSON: 'Export JSON',
    GENERAL_COMPETENCY_TITLE: 'Competențe generale',
    GENERAL_COMPETENCY: {
      DESCRIPTION: 'Descriere',
      CODE: 'Cod'
    },
    KNOWLEDGE_MAP_PLACEHOLDER:
      'Dă click dreapta pentru a adăuga o hartă de învățare',
    KNOWLEDGE_MAP: {
      DESCRIPTION: 'Descriere',
      CODE: 'Cod'
    },
    KNOWLEDGE_MAP_TITLE: 'Hartă de cunoștințe',
    SPECIFIC_COMPETENCY: {
      DESCRIPTION: 'Descriere',
      CODE: 'Cod'
    },
    SPECIFIC_COMPETENCY_TITLE: 'Competență specifică'
  },
  KNOWLEDGE_MAP_METRICS: {
    CHART_HEADER: 'Acoperirea programei analitice',
    CHART_HEADER_SECOND: 'Utilizator',
    COVERAGE_VALUE: 'Valoare acoperire',
    COVERED_CONCEPT: 'Acest concept a fost suficient acoperit',
    DIALOG_HEADER: 'Hartă de cunoştinţe',
    FLOWS_SUGGESSTIONS: 'Click aici pentru a vedea fluxuri ce conțin acest concept',
    FLOWS_SUGGESSTIONS_PATH_DESCRIPTION: 'Descrierea traiectoriei',
    FLOWS_SUGGESSTIONS_FLOW_DESCRIPTION: 'Descrierea fluxului',
    FLOW_SUGGESTIONS_HEADER: 'Fluxuri neparcurse ce conțin conceptul',
    FLOWS_SUGGESSTIONS_NO_SUGGESTIONS: 'Nu există alte fluxuri pentru a aprofunda acest concept',
    FOOTNOTE_SECOND: 'Făcând click pe o celulă, automat acea celulă va deveni rădăcina graficului și va oferi o perspectiva asupra nodurilor inferioare',
    FOOTNOTE_FIRST: 'Selectați o hartă de cunoștințe, în urmă selecției va fi generat un grafic ce reflectă o perspectivă în ansamblu a metricii de parcurgere a hărții de cunoștințe selectate.',
    FOOTNOTE_FIFTH: 'Metrica de acoperire a unui concept este rezultatul agregării a fiecărei apariții a conceptului respectiv în pași',
    FOOTNOTE_HEADER: 'Notă de subsol',
    FOOTNOTE_THIRD:
      'Vizualizarea este reprezentarea grafică a metricii de acoperire a unei hărți de cunoștințe',
    FOOTNOTE_FORTH:
      'Culoarea fiecărei celule este rezultatul agregării metricii de acoperire de la nivelul conceptelor până la rădăcina, aceasta fiind reprezentată de prima celulă de la stânga la dreapta',
    LEGEND: {
      FIGURE_DESCRIPTION: 'Descriere',
      FIGURE_VALUE: 'Valoare acoperire',
      FIGURE_INDEX_FIRST: 'Figura 1',
      FIGURE_INDEX_SECOND: 'Figura 2',
      FIGURE_INDEX_THIRD: 'Figura 3',
      FIGURE_INDEX_FORTH: 'Figura 4',
      FIGURE_INDEX_FIFTH: 'Figura 5',
      HEADER: 'Legendă',
      LEGEND_INDICATION_FIRST: 'Roșu indică faptul că un concept / conținut / domeniu nu a fost acoperit',
      LEGEND_INDICATION_SECOND: 'Roșu-portocaliu reprezintă faptul că un concept / conținut / domeniu de conținut a fost slab acoperit',
      LEGEND_INDICATION_THIRD: 'Portocaliu înseamnă că un concept / conținut / domeniu de conținut a fost insuficient acoperit',
      LEGEND_INDICATION_FORTH: 'Galben reprezintă faptul că un concept / conținut / domeniu de conținut a fost acoperit la un nivel mediu',
      LEGEND_INDICATION_FIFTH: 'Verde reprezintă faptul că un concept / conținut / domeniu de conținut a fost acoperit la un nivel suficient',
      LEGEND_INDICATION_DESCRIPTION: 'Descrierea reprezintă numele programei analitice sau domeniului de conținut sau elementului de conținut sau conceptului',
      LEGEND_INDICATION_VALUE: 'Valoarea de acoperire reprezintă valoarea agregată a acoperirii de la nivelul descendenților direcți'
    },
    NO_DATA: 'Nu există date pentru a genera vizualizarea pentru programa analitică',
    PRINTABLE_KNOWLEDGE_MAP_METRICS: {
      SUMMARY_HEADER: 'Raportul NEXTLAB.TECH al parcurgerii hărții de cunoștințe (programei analitice)',
      STRUCTURE_HEADER: 'Structură',
      STRUCURE_INFO_COMPONENTS: 'Componentele raportului',
      STRUCTURE_INFO_FIRST: 'Raportul de acoperire a conținuturilor din cadrul programei analitice',
      STRUCTURE_INFO_SECOND: 'pentru elevul',
      STRUCTURE_INFO_THIRD: 'reflectă acoperirea domeniilor de conținut, elementelor de conținut și conceptelor în decursul parcurgerii de către elev a programei.',
      STRUCURE_INFO_COMPONENTS_FIRST: 'Scopul raportului',
      STRUCURE_INFO_COMPONENTS_SECOND: 'Fundamentul metricilor calculate',
      STRUCURE_INFO_COMPONENTS_THIRD: 'Harta de cunoștințe (programa analitică)',
      STRUCURE_INFO_COMPONENTS_FOURTH: 'Calcularea datelor la nivelul conceptelor',
      STRUCURE_INFO_COMPONENTS_FIFTH: 'Agregarea datelor',
      STRUCURE_INFO_COMPONENTS_SIXTH: 'Metrica desfășurată pentru fiecare nivel al hărții de cunoștințe',
      SCOPE_HEADER: 'Scop',
      SCOPE_INFO_FIRST: 'Harta de cunoștințe este construită pentru a reflecta o metrică de acoperire, reflectând faptul că elevul a parcurs conținutul acoperit de o programă analitică și a răspuns corect la întrebările de verificare conținute de lecțiile care sunt asociate programei respective.',
      SCOPE_INFO_SECOND: 'Acest raport își propune să ofere o perspectivă cu granularitate crescută asupra parcurgerii unei arii de conținut, permițând identificarea unor posibile zone problematice de conținut, care nu au fost acoperite sau pentru care acoperirea a fost doar formală.',
      FOUNDATION_HEADER: 'Fundamentul metricilor calculate',
      FOUNDATION_INFO_FIRST: 'Harta de cunoștințe este un raport care reprezintă detaliat acoperirea conceptelor din cadrul unei programe analitice. Raportul conține metrici de acoperire calculate la nivelul conceptelor, elementelor de conținut, domeniilor de conținut și hărții de cunoștințe în sine.',
      FOUNDATION_INFO_SECOND: 'Metrica de acoperire a conținutului ia în calcul faptul că elevul a parcurs domeniul/elementul de conținut prin integrarea parcurgerilor tuturor artefactelor educaționale care referă domeniul/elementul respectiv.',
      FOUNDATION_INFO_THIRD: 'La nivelul unui pas parcurs de elev, gradul de acoperire este calculat pe baza procentului de răspunsuri corecte la întrebările conținute de pasul respectiv. Scorul pentru pasul respectiv contribuie în mod egal la acoperirea tuturor conceptelor asociate pasului.',
      FOUNDATION_INFO_FOURTH: 'Acoperirea este calculată în raport cu un nivel standard asociat unui domeniu/element de conținut prin integrarea valorilor de acoperire obținute la nivelul conceptelor. Atunci când elevul obține o valoare a indicatorului care egalează sau depășește nivelul standard prestabilit, se consideră că acesta are un grad de acoperire de 100%.',
      KNOWLEDGE_MAP_HEADER: 'Harta de cunoștințe (programa analitică)',
      KNOWLEDGE_MAP_INFO_FIRST: 'Harta de cunoștințe reprezintă structura unei programe analitice într-o manieră ierarhică. O hartă de cunoștințe conține domenii de conținut. Un domeniu de conținut conține elemente de conținut. Un element de conținut înglobează concepte.',
      KNOWLEDGE_MAP_INFO_SECOND: 'Un element de conținut este un mijloc educațional prin care se urmărește formarea competențelor. Elementele de conținut înrudite sunt grupate în domenii de conținut. Un element de conținut include o serie de concepte, care sunt idei abstracte punctuale.',
      DATA_CALCULATION_HEADER: 'Calcularea datelor la nivelul conceptelor',
      DATA_CALCULATION_INFO: 'Atunci când un elev parcurge un pas din cadrul unei lecții și răspunde la întrebările din pasul respectiv, nextlab calculează numarul de răspunsuri corecte la întrebări în raport cu numărul total de întrebări. Acestea reprezintă un scor de corectitudine a parcurgerii pasului. Un pas poate avea mai multe concepte asociate. Scorul de acoperire calculat la nivelul unui pas va fi deci împărțit conceptelor asociate pasului (i.e. dacă un pas are asociat un singur concept, parcurgerea lui corectă va contribui mai mult la acoperirea conceptului decât în cazul în care pasul ar avea asociate două concepte).',
      DATA_AGREGATION: 'Agregarea datelor',
      DATA_AGREGATION_INFO: 'Calcularea indicatorilor la nivelul elementelor de conținut, domeniilor de conținut și hărții de cunoștințe în sine se face pornind de la indicatorii calculați la nivelul conceptelor. Un element de conținut va fi acoperit în măsura în care sunt acoperite toate conceptele pe care le conține. Un domeniu de conținut va fi acoperit în măsura în care sunt acoperite elementele de conținut pe care le conține. Harta de cunoștințe este o reprezentare globală a acoperirii tuturor domeniilor de conținut care sunt incluse în programa analitică respectivă.',
      SUMMARY_CONTENT: 'Metrica desfășurată pentru fiecare nivel al hărții de cunoștințe',
      SUMMARY_CONTENT_INFO: 'Harta de cunoștințe este reprezentată sub forma unui cuprins adnotat al programei analitice corespunzătoare. Indentarea reprezintă coborârea unui nivel în ierarhia de cunoștințe/concepte. Culoarea fiecărui element indică gradul lui de acoperire, iar valoarea necesară pentru acoperirea completă a unui element este reprezentată numeric.',
      SUMMARY_COCLUSION: 'Recomandări',
      KNOWLEDGE_MAP: 'Harta de cunoștințe',
      CONTENT_DOMAIN: 'Domeniul de conținut',
      CONTENT_ITEM: 'Elementul de conținut',
      CONCEPT: 'Conceptul',
      PRINT: 'Tipărește raportul',
      COVER_TITLE_FIRST: 'Hărți de cunoștințe',
      COVER_TITLE_SECOND: 'pentru',
      SUMMARY_CONCLUSION: {
        KNOWLEDGE_METRIC_LEVEL_WEAK: 'slab',
        KNOWLEDGE_METRIC_LEVEL_INSUFFICIENT: 'insuficient',
        KNOWLEDGE_METRIC_LEVEL_MEDIUM: 'mediu',
        KNOWLEDGE_METRIC_LEVEL_SUFFICIENT: 'suficient',
        STRONG_POINTS: 'Cele mai bune rezultate se află în cadrul domeniilor de conținut',
        WEAK_POINTS: 'Cele mai slabe rezultate se află în cadrul domeniilor de conținut',
        WEAK_POINTS_FLOWS_SUGGESTIONS: 'Ar trebui reparcurse cu prioritate domeniile la care scorul a fost mai mic. Pentru aprofundarea acestor concepte recomandăm parcurgerea următoarelor lecții',
        RECOMMENDATION_REDO: 'Recomandăm reparcurgerea lecțiilor',
        RECOMMENDATION_REDO_RESEARCH: 'Recomandăm reparcurgerea lecțiilor pentru aprofundarea conceptelor',
        RECOMMENDATION_REDO_OPTIONALLY: 'Recomandăm reparcurgerea lecțiilor la latitudinea elevului, pentru aprofundare.'
      }
    },
    OPEN_DIALOG_MESSAGE_FIRST: 'Selectați o programă analitică parcursă de elevul',
    OPEN_DIALOG_MESSAGE_SECOND: 'Va fi desenat un grafic ce va reprezenta o perspectivă în ansamblu a parcurgerii programei de către elev',
    OPEN_DIALOG_MESSAGE_THIRD: 'Fiecare culoare este asociată progresului la nivelul unui concept / conținut / domeniu de conținut pe care elevul l-a atins',
    SELECT_PLACEHOLDER: 'Selectează o programă analitică',
    TIMELINE_CURRENT_MONTH: 'Luna curentă',
    TIMELINE_LAST_THREE_MONTHS: 'Ultimele 3 luni',
    TIMELINE_LAST_SIX_MONTHS: 'Ultimele 6 luni',
    TIMELINE_LAST_YEAR: 'Ultimul an'
  },
  KNOWLEDGE_MAP_ASSETS_BUILDER: {
    ADD_DIALOG_HEADER: 'Adaugă o traducere',
    ADD_NEW_ASSET: 'Adaugă o nouă traducere',
    ASSET_KEY_FILTER_PLACEHOLDER: 'Codul traducerii',
    DELETE_CONFIRMATION_MESSAGE: 'Confirmă ștergerea traducerii.',
    EDIT_DIALOG_HEADER: 'Modifică traducerea',
    EMPTY_KM_ASSET_SUGGESTIONS: 'Nu există rezultate pentru textul căutat',
    HEADER: 'Traduceri hărți de cunoștințe',
    IMPORT: 'Importă traducerile',
    KEY: 'Cod',
    KEY_OR_DESCRIPTION: 'Codul sau descrierea',
    KM_ASSET_SUGGESTIONS: 'Rezultate',
    SEARCH_SHORTCODE: 'Adaugă codul unei traduceri',
    TYPE: 'Tip'
  },
  LEARNING_SUMMARY: {
    COVERAGE: 'Acoperire',
    DETAILS: 'Detalii',
    DYNAMIC_FORM: 'Formular dinamic',
    ENGAGEMENT: 'Implicare',
    FLOW: {
      FLOW_LIST: 'Lista fluxurilor',
      NO_DURATION: 'Durata fluxului invalidă',
      NOT_COVERED: 'Fluxul nu a fost parcurs',
      NUMBER_OF_CROSSED_FLOWS_FIRST: 'Număr de fluxuri parcurse',
      NUMBER_OF_CROSSED_FLOWS_SECOND: 'din',
      INFO_FIRST:
        'Graficul este o reprezentare a mediei metricilor calculate la nivel de PAS, aceste două metrici sunt ACOPERIREA și IMPLICAREA.',
      INFO_SECOND:
        'ACOPERIREA reprezintă câți pași din flux au fost parcurși, reprezentarea ei din grafic este cadranul din exterior colorat cu albastru.',
      INFO_THIRD:
        'IMPLICAREA este o reprezentare a efortului depus pentru a parcurge fluxul, acestă metrică este un rezultat al răspunsurilor și al timpului parcurs pe fiecare pas al fluxului. Este reprezentată pe cadranul interior al graficului, culoarea indicând nivelul metricii.',
      SHOW_UNCOVERED_FLOWS: 'Arată fluxuri neacoperite',
      SELECTED_FLOW_NO_STATS: 'Pentru fluxul selectat nu a fost generat raportul de învățare al lui',
      TITLE_FIRST:
        'Raportul de învățare la nivelul fluxurilor, din traiectoria ',
      TITLE_SECOND: ' pentru '
    },
    INFO: 'Informații',
    LAST_UPDATE: 'Ultima actualizare a raportului',
    MONITORING_TOOLTIP: 'Raport general de învățare',
    PATH: {
      INFO_FIRST:
        ' Graficul este o reprezentare a mediei metricilor calculate la nivel de FLUX, aceste două metrici sunt ACOPERIREA și IMPLICAREA.',
      INFO_SECOND:
        'ACOPERIREA reprezintă câte fluxuri din traiectorie au fost parcurse, reprezentarea ei din grafic este cadranul din exterior colorat cu albastru.',
      INFO_THIRD:
        'IMPLICAREA este o reprezentare a efortului depus pentru a parcurge un flux, acestă metrică este un rezultat al răspunsurilor și al timpului parcurs pe fiecare pas al unei lecții. Este reprezentată pe cadranul interior al graficului, culoarea indicând nivelul metricii.',
      NO_STATS:
        'nu a parcurs nici o traiectorie sau raportul nu a fost generat încă',
      PATH_LIST: 'Lista traiectoriilor',
      TITLE: 'Raportul de învățare la nivelul traiectoriilor pentru '
    },
    PRINT_SUMMARY: 'Tipărește rapoartele',
    PRINTABLE_PATH_SUMMARY: {
      PATH_KNOWLEDGE_MAP_INFO_FIRST: 'Traiectoria',
      PATH_KNOWLEDGE_MAP_INFO_SECOND: 'a fost construită pe baza domeniilor de conținut ale hărții de cunoștințe',
      PATH_KNOWLEDGE_MAP_INFO_THIRD: 'pentru nivelul de studii',
      PATH_KNOWLEDGE_MAP_INFO_FORTH: 'la materia',
      PATH_KNOWLEDGE_MAP_INFO_FIFTH: 'Harta de cunoștințe a fost construită pentru ciclul',
      PATH_KNOWLEDGE_MAP_INFO_SIXTH: 'pe baza',
      PATH_KNOWLEDGE_MAP_INFO_SEVENTH: 'din data de',
      EXPLANATION_FIRST: 'Arcul exterior al graficului, de culoare albastră, indică acoperirea conținutului educațional la nivel de traiectorie, calculată ca medie a indicatorilor de acoperire la nivelul fiecărei lecții parcurse de elev.',
      EXPLANATION_SECOND: 'Arcul interior al graficului indică metrica de implicarea la nivel de traiectorie, calculată ca medie a tuturor metricilor de implicare de la nivelul lecțiilor parcurse. Culoarea acestuia variază de la roșu la verde (10% - 100%) în funcție de valoarea implicării.',
      EXPLANATION_THIRD_LIST_HEADER: 'Variabilele în funcție de care este calculată metrică de implicare la nivelul pașilor sunt: ',
      EXPLANATION_THIRD_LIST_FIRST: 'Durata de parcurgere a unui pas',
      EXPLANATION_THIRD_LIST_SECOND: 'Corectitudinea răspunsurilor la întrebările din pașii ce conțin întrebări',
      PATH_HEADER: 'Raportul pentru traiectoria: '
    },
    PRINTABLE_STEP_SUMMARY: {
      AUTO_GRADED_NATURAL_LANGUAGE_ANSWER: 'Răspuns în limbaj natural corectat automat',
      STEPS_VIZUALIZATION_HEADER: 'Vizualizare asupra timpului parcurs pe fiecare pas',
      STEPS_VIZUALIZATION_HEADER_INFO: 'Acestă vizualizare reprezintă o prespectivă asupra timpului parcurs pe fiecare pas al fluxului de către elev',
      STEP_HEADER: 'Raportul pentru fluxul: ',
      EXPLANATION_FIRST: 'Arcul exterior al graficului, de culoare albastră, indică acoperirea conținutului educațional la nivel de lecție, calculată ca medie a indicatorilor de acoperire la nivelul fiecărui pas parcurs de elev.',
      EXPLANATION_SECOND: 'Arcul interior al graficului indică metrica de implicarea la nivel de lecție, calculată ca medie a tuturor metricilor de implicare de la nivelul pașilor parcurși. Culoarea acestuia variază de la roșu la verde (10% - 100%) în funcție de valoarea implicării.',
      EXPLANATION_THIRD_LIST_HEADER: 'Variabilele în funcție de care este calculată metrică de implicare la nivelul pașilor sunt: ',
      EXPLANATION_THIRD_LIST_FIRST: 'Durata de parcurgere a unui pas',
      EXPLANATION_THIRD_LIST_SECOND: 'Corectitudinea răspunsurilor la întrebările din pașii ce conțin întrebări'
    },
    PRINTABLE_SUMMARY: {
      SUMMARY_HEADER: 'Raportul de învățare NEXTLAB.TECH',
      SCOPE_HEADER: 'Scop',
      SCOPE_INFO_FIRST: 'Raportul de învățare prezintă o imagine de ansamblu asupra progresului elevului  în procesul de învățare.',
      SCOPE_INFO_SECOND: 'Raportul prezintă gradul de acoperire și gradul de implicare pentru toate traiectoriile parcurse de',
      FOUNDATION_HEADER: 'Fundament',
      FOUNDATION_INFO: 'Acest raport este construit pe baza unor metrici create de specialiști în educație, metrici destinate să reflecte gradul în care elevul a acoperit conținutul corespunzător materiilor pe care le studiază, dar și nivelul de implicare al elevului în procesul educațional. Metricile reflectate în raport sunt construite astfel încât să reflecte progresul elevului, nu greșelile acestuia.',
      ENGAGEMENT_METRIC_HEADER: 'Metrica de implicare',
      ENGAGEMENT_METRIC_INFO: 'În vreme ce una dintre premisele acumulării cunoștințelor și dobândirii competențelor specifice unei materii este parcurgerea conținutului educațional asociat, simpla participare nu este o garanție a eficienței procesului de învățare. O soluție la dificultatea de a estima eficiența reală a procesului de învățare este calcularea, alături de metrica tradițională de acoperire, a unei metrici de implicare. Această metrică reflectă cât de implicat a fost elevul în parcurgerea lecțiilor reflectând astfel indirect gradul de asimilare a cunoștințelor acoperite de conținutul educațional și gradul de dobândire a competențelor asociate acestuia. Indicatorul de implicare se calculează pentru fiecare pas parcurs și include ca factori timpul pe care elevul l-a petrecut parcurgând pasul și corectitudinea răspunsurilor acestuia la întrebările incluse în pas. Indicatorii obținuți la nivel de pas sunt integrați la nivelul fiecărei lecții și fiecărei traiectorii de învățare.',
      COVERAGE_METRIC_HEADER: 'Metrica de acoperire',
      COVERAGE_METRIC_INFO: 'Metrica de acoperire reflecta procentul  dintr-o lecție parcurs de elev. Aceasta metrică este agregată la nivelul unei traiectorii de învățare, oferind astfel o perspectivă de ansamblu asupra parcurgerii fiecărei materii.',
      STRUCTURE_HEADER: 'Structură',
      STRUCURE_INFO_COMPONENTS: 'Structura raportului:',
      STRUCURE_INFO_COMPONENTS_FIRST: 'Scopul raportului',
      STRUCURE_INFO_COMPONENTS_SECOND: 'Fundamentul metricilor calculate',
      STRUCURE_INFO_COMPONENTS_THIRD: 'Metrica de acoperire a conținutului educațional',
      STRUCURE_INFO_COMPONENTS_FORTH: 'Metrica de implicare în procesul educațional',
      STRUCURE_INFO_COMPONENTS_FIFTH: 'Raportul la nivelul fiecărei traiectorii de învățare',
      STRUCURE_INFO_COMPONENTS_INFO: 'În cadrul raportului de la nivelul traiectoriei se vor regăsi metricile create de specialiștii NEXTLAB.TECH. Acestea indică nivelul de cunoștințe dobândit de elev ca medie a tuturor lecțiilor parcurse din traiectoria de învățare respectivă, cât și procentajul de parcurgere a traiectoriei în sine, obținut prin integrarea rezultatelor la nivelul fiecărei lecții. Metricile la nivelul unei lecții se calculează pe baza analizei comportamentului elevului la fiecare pas al lecției.',
      CONTENT_HEADER: 'Conținutul raportului',
      STUDENT_INFO: 'Această secțiune conține informațiile legate de progresul elevului',
      FLOW_SUMMARY_HEADER: 'Raportul la nivelul fiecărui flux din traiectoria',
      EXPLANATION: 'Acest raport indică gradul de acoperire precum și gradul de implicare al elevilor pentru traiectorie/ succesiunea de lecții indicată',
      HEADER: 'Raportul la nivelul tuturor traiectoriilor pentru',
      LAST_UPDATE: 'Ultima actualizare a raportului',
      COVER_TITLE: 'Raportul de învățare pentru'
    },
    STEP: {
      STEP: 'Pasul',
      ANSWER_FEEDBACK: 'Feedback răspuns',
      ENGAGEMENT_METRIC: 'Coeficient de implicare ',
      FLOW_DURATION: 'Durata totală de parcurgere a fluxului: ',
      LEGEND_INDEX_FIRST: 'Figura 1:',
      LEGEND_INDEX_SECOND: 'Figura 2:',
      LEGEND_INDEX_THIRD: 'Figura 3:',
      LEGEND_INDEX_FOURTH: 'Figura 4:',
      LEGEND_TEXT_FIRST: 'Reprezintă timpul minim si maxim alocat parcurgerii unui pas',
      LEGEND_TEXT_SECOND: 'Banda de culoare roșie indica timpul petrecut în cadrul pasului, acesta fiind sub nivelul minim acceptat',
      LEGEND_TEXT_THIRD: 'Banda de culoare roșie indica timpul petrecut în cadrul pasului, acesta fiind peste nivelul maxim acceptat',
      LEGEND_TEXT_FOURTH: 'Banda de culoare verde indica timpul petrecut în cadrul pasului, acesta aflându-se între nivelul minim și maxim acceptat',
      OVERVIEW: {
        HEADER: 'Lista conversațiilor din fluxul',
        NO_ANSWER: 'Nu a fost dat un răspuns',
        NO_FEEDBACK: 'Feedback-ul nu a fost generat',
        NO_QUESTIONS: 'Pasul nu are întrebări',
        TABLE_HEADER_STEP_DESCRIPTION: 'Descrierea pasului',
        TABLE_HEADER_QUESTION_NUMBER: 'Întrebarea',
        TABLE_HEADER_QUESTION_DESCRIPTION: 'Descrierea întrebării',
        TABLE_HEADER_ANSWER: 'Răspuns',
        TABLE_HEADER_FEEDBACK_ANSWER: 'Feedback răspuns'
      },
      QUESTION: 'Întrebarea ',
      QUESTION_TEXT: 'Textul întrebării',
      STEP_TIMES: 'Vizualizare a timpilor parcurși pe fiecare pas',
      STEP_DETAILS: 'Vizualizarea detaliată a pașilor',
      TITLE_FIRST: 'Raportul de învățare la nivelul pașilor din fluxul ',
      TITLE_SECOND: ' al traiectoriei ',
      TITLE_THIRD: ' pentru ',
      UNCONFIGURED_STEPS: 'Pentru o vizualizare completă a timpilor parcurși pe fiecare pas este necesară setarea timpului minim și maxim pentru urmatorii pași ai fluxului'
    },
    NAVIGATION: {
      FLOW: 'Flux',
      PATH: 'Traiectorie'
    }
  },
  NETWORK_ERROR: {
    START: 'Conexiunea la rețea a fost întreruptă.',
    START_SECONDARY: 'Ne pare rău, roboțeii noștri nu au putut să răspundă cererii tale.',
    ACTION: 'Verifică conexiunea și mai încearcă o dată!',
    ACTION_SECONDARY: 'Dacă reîncarci aplicația vor mai încerca o data!'
  },
  NOTIFICATIONS: {
    INCOMPLETE_PROFILE_TITLE: 'Completare profil',
    INCOMPLETE_PROFILE_CONTENT:
      'Pentru a primi rezultatele la teste trebuie să completați în profil adresa de email și numărul de telefon.',
    NAVIGATE_PROFILE: 'Mergi la profil'
  },
  MAKERSPACE_OWNER_LIST: {
    DETAILS_TITLE: 'Detalii despre persoană',
    FILTER_PLACEHOLDER: 'Filtrează',
    MAKERSPACE_ADDRESS: 'Adresă',
    MAKERSPACE_NAME: 'Nume',
    NO_PASSWORD: 'Lasați câmpul necompletat pentru a păstra parola',
    USER_EMAIL: 'Email',
    USER_FULLNAME: 'Nume',
    USER_NAME: 'Utilizator',
    USER_PASSWORD: 'Parola',
    USER_PHONE: 'Telefon'
  },
  NX_FORMS_BUILDER: {
    EMAIL_INPUT: {
      HEADER_FIRST: 'Dacă nu aveți un cont introduceți adresa de email pentru a putea continua spre sesiunea de formulare',
      HEADER_SECOND: 'sau',
      HEADER_THIRD: 'Autentificați-va utilizând butonul "Sign in"',
      EMAIL: 'Email'
    },
    HEADER: 'Formulare Nextlab',
    FORM_SESSIONS_HEADER: 'Sesiuni',
    SESSIONS_LIST_HEADER: 'Listă sesiuni',
    FORM_TEMPLATES: 'Formulare',
    FORM_TEMPLATES_LIST: 'Listă formulare',
    NO_FORM_SESSIONS: 'Nu există sesiuni',
    NO_FORM_TEMPLATES: 'Nu există formulare',
    FORM_TEMPLATE: {
      TITLE: 'Titlu',
      NAME: 'Nume',
      DESCRIPTION: 'Descriere',
      CREATED_AT: 'Creat',
      FORM_APPLY_ALL_TOOLTIP: 'Modificările formularului se vor aplica retroactiv asupra tuturor sesiunilor de formulare',
      FORM_APPLY_ALL: 'Aplică modificările și asupra formularelor utilizate în sesiune de formulare (Utilizatorii nu vor mai putea ultimul răspuns în Sesiunea de formulare)'
    },
    FORM_SESSION: {
      TITLE: 'Titlu',
      NAME: 'Nume',
      DESCRIPTION: 'Descriere',
      CREATED_AT: 'Creat',
      ALLOW_ANONYMOUS_SUBMISSIONS: 'Permite răspunsuri anonime',
      STATE: 'Stare',
      STATE_VALUES: {
        HIDDEN: 'Ascuns',
        VISIBLE: 'Vizibil'
      },
      FORMS: 'Formulare',
      DATE_VALID_START: 'Valabil de la data',
      DATE_VALID_END: 'Valabil până la data',
      SESSION_AVAILABLE_DATES: 'Sesiunea este deschisă în perioada',
      EDIT_DIALOG_HEADER: 'Modifică sesiunea',
      ADD_DIALOG_HEADER: 'Adaugă sesiune',
      USER_NOT_LOGGED: 'Pentru a accesa sesiunea curentă este necesară autentificarea',
      FORM_SESSION_NOT_AVAILABLE: 'Sesiunea accesată nu este disponibilă',
      NO_ANWER_REGISTERED: 'Nu s-a înregistrat niciun răspuns',
      LAST_FORM_SUBMISSION: 'Ultimul răspuns salvat'
    },
    FORMS_SUBMISSIONS: {
      HEADER: 'Răspunsuri sesiune',
      ANSWER_DETAILS: {
        ANSWER: 'Răspuns',
        USER: 'Utilizator',
        USER_TYPE: 'Tipul utilizatorului',
        USER_TYPES: {
          ANONYMOUS: 'Neînregistrat',
          REGISTERED: 'Înregistrat'
        },
        ANSWERS_COUNT: 'Număr răspunsuri',
        ANSWERS: 'Răspunsuri',
        FORM_NAME: 'Formular',
        FORM_TITLE: 'Titlu formular',
        FORM_DESCRIPTION: 'Descriere formular',
        FORM_DATE: 'Data',
        FORM_DETAILS: 'Detalii formular'
      },
      NO_SUBMISSIONS: 'Nu au fost înregistrate răspunsuri',
      SUBMISSION_SENT: 'Răspunsul formularului a fost înregistrat'
    }
  },
  LOCATION_LIST: {
    DETAILS_TITLE: 'Detalii despre locație',
    LOCATION_DESCRIPTION: 'Descriere',
    LOCATION_WELCOME_MESSAGE: 'Mesaj de primire',
    LOCATION_LAT: 'Latitudine',
    LOCATION_LONG: 'Longitudine',
    FILTER_PLACEHOLDER: 'Filtrează',
    LOCATION_COORDINATES: 'Coordonate',
    OWNER: 'Makerspacer'
  },
  LOGIN: {
    BACK_TO_LOGIN: ' Înapoi la login',
    CONFIRM: 'Confirmare',
    CONTINUE_AS: 'Continuă ca',
    CONFIRM_PASSWORD: 'Confirmă parola',
    CHANGE_ACCOUNT: 'Schimbă contul',
    FULLNAME_PLACEHOLDER: 'Nume',
    GO_TO_LOGIN: 'Mergi la pagina de logare',
    INSERT_USERNAME: 'Introdu numele de utilizator',
    INSERT_PASSWORD: 'Introdu parola',
    LANDING_MESSAGE_FIRST:
      'Platformă cu lecții gratuite de informatică și robotică',
    LANDING_MESSAGE_FIRST_BNR:
      'Platforma cu lecții gratuite de educaţie financiară',
    LANDING_MESSAGE_SECOND: {
      EDULIB: 'pentru studiul de acasă',
      PITESTI: 'After School Fun&Stem, Pitești, România',
      ROBO: "pentru <a href='https://nextlab.tech/concurs/' target='_blank' rel='noopener noreferrer'> concursul național de robotică Nextlab.tech </a>",
      SIBIU: 'Swiss Web Business',
      STAGING: 'pentru studiul de acasă și pentru RoboHackatonul Nextlab.tech'
    },
    LANDING_MESSAGE_SECOND_BNR: 'pentru studiul de acasă',
    LANDING_MESSAGE_THIRD: 'Pentru lecții de clasa a V-a click',
    LANDING_MESSAGE_CONTEST_ANNOUNCEMENT:
      'Termenul de înscriere pentru RoboHackatonul Nextlab.tech este 30 iunie 2021',
    LANDING_MESSAGE_THIRD_BNR: 'Pentru lecții de clasa a VIII-a click',
    LOGIN: 'Intră',
    LOGIN_DETAILED: 'Intră în aplicație',
    NEW_PASSWORD_PLACEHOLDER: 'Parola nouă',
    NOT_YOUR_ACCOUNT: 'Nu ești',
    I_ALREADY_HAVE_AN_ACCOUNT: 'Am un cont existent',
    I_WANT_TO_USE_A_TEMPORARY_ACCOUNT: 'Vreau să folosesc un cont temporar',
    PASSWORD_PLACEHOLDER: 'Parola',
    PASSWORD_NO_MATCH_EXPLANATION:
      'Dacă nu primiți e-mail-ul verificați secțiunea SPAM din e-mail și asigurați-vă că ați introdus adresa si utilizatorul corect.',
    PASSWORD_RECOVERY_EMAIL_SENT:
      'Daca adresa de e-mail si numele de utilizator sunt corecte veți primi un e-mail în curând.',
    CODE_PLACEHOLDER: 'Codul de acces',
    RECOVER_CHANGE: 'Schimbă parola',
    RECOVER_PASSWORD: 'Mi-am uitat parola',
    RECOVER_PASSWORD_EXPLANATION:
      'Introduceți numele de utilizator și adresa de e-mail pentru a primi pe e-mail un link de resetare a parolei.',
    RECOVER_SEND: 'Trimite',
    RECOVER_USERS: 'Mi-am uitat utilizatorul',
    RECOVER_USERS_EXPLANATION:
      'Introduceți adresa de e-mail pentru a primi o listă cu utilizatorii asociați.',
    SESSION_EXPIRED: 'Sesiunea a expirat',
    USERNAME_PLACEHOLDER: 'Numele de utilizator',
    WELCOME_BACK: 'Bine ai revenit,',
    TEMPORARY_ACCOUNT_GROWL_SUMMARY: 'Contul tău este temporar',
    TEMPORARY_ACCOUNT_GROWL_DESCRIPTION: 'Configurează contul pentru a deveni permanent',
    TWO_FACTOR_DIALOG: {
      HEADER: 'Autentificare în doi pași',
      INPUT_PLACEHOLDER: 'Cod de autentificare',
      LOGIN: 'Intră',
      CODE_RECOVERY: 'Recuperează codul',
      CODE_RECOVERY_INFO_FIRST: 'În cazul pierderii codului de autentificare fă click',
      CODE_RECOVERY_INFO_SECOND: 'aici.'

    },
    CONFIGURE_TEMPORAR_ACCOUNT:
      'Acesta este un cont temporar',
    CONFIGURE_TEMPORARY_ACCOUNT_PERMANENT:
      'Pentru a definitiva contul, completează te rog următoarele câmpuri:',
    CONTINUE_WITH_TEMPORARY: 'Continuă cu un cont temporar',
    CONTINUE_NEW_ACCOUNT: 'Continuă cu un cont nou',
    USER_NOT_FOUND_FOR_EMAIL:
      'Nu a fost găsit niciun utilizator cu această adresă de e-mail!',
    USER_RECOVERY_EMAIL_SENT:
      'A fost trimis e-mail-ul de recuperare, acesta va sosi în câteva minute! Daca nu îl găsiți, cautați și în secțiunea SPAM.',
    WELCOME_MESSAGE: 'Bine ai venit pe platforma',
    WELCOME_MESSAGE_DESCRIPTION_COL_TABEL:
      'Alege tipul de cont cu care dorești să intri în activitate',
    WELCOME_MESSAGE_DESCRIPTION_COL_ADD:
      'Dacă nu îți găsești numele în listă, folosește una din opțiunile din dreapta pentru a intra în activitate',
    WELCOME_MESSAGE_DESCRIPTION_NO_TABLE:
      'Pentru a intra în această activitate va trebui să îți scrii numele în căsuța de mai jos pe care scrie "Nume și prenume"',
    WELCOME_MESSAGE_DESCRIPTION_PHONE:
      'Dacă nu îți găsești numele în listă, folosește una din opțiunile de mai sus pentru a intra în activitate',
    NAME_AND_SURNAME: 'Nume și prenume',
    TEMPORARY_ACCOUNT: 'Utilizator temporar',
    TEMPORARY_ACCOUNT_TOOLTIP: 'Utilizator temporar. Apasă și mergi la profil pentru a configura contul',
    COMMUNITIES: 'Comunitate',
    USE_EXISTING_ACCOUNT: 'Continuă cu un cont existent'
  },
  LOGIN_PROVIDER: {
    HEADER: 'Bine ai revenit!',
    LOGIN_MESSAGE: 'Conectează-te folosind unul dintre furnizorii de mai jos',
    CONTINUE_GOOGLE: 'Continuă cu Google',
    CONTINUE_APPLE: 'Continuă cu Apple',
    CONTINUE_FACEBOOK: 'Continuă cu Facebook',
    INVALID_ACCOUNT_CONTACT_SUPPORT: '⚠️ Contul tău nu este accesibil momentan. Te rugăm să contactezi serviciul nostru de suport! 📞👨‍💻',
    ACCOUNT_NOT_USEABLE: "🚫 Acest tip de cont nu poate fi folosit în aplicația mobilă. Te rugăm să accesezi interfața web! 🖥️📲",
    GRADER_PLACEHOLDER: 'Evaluatorul nu este încă disponibil în aplicația mobilă. Te rugăm să accesezi interfața web',
    LINKS: {
	    COOKIES_LINK: 'https://www.nextlab.tech/confidentialitate/',
	    COOKIES_TEXT: 'Politica de cookie-uri',
	    GDPR_LINK: 'https://www.nextlab.tech/confidentialitate/',
	    GDPR_TEXT: 'Protecția datelor',
	    TERMS_LINK: 'https://www.nextlab.tech/termeni-si-conditii/',
	    TERMS_TEXT: 'Termene și condiții',
    },
    LOGOUT: 'Folosește un alt cont',
    MOTO: 'Modul eficient de a lua note mari la Evaluarea Națională și olimpiadă!',
  },
  ADDITIONAL_KNOWLEDGE_BASE_EDITOR: {
    ADD_NEW_CONCEPT: 'Adaugă concept nou',
    ADD_NEW_ENTITY: 'Adaugă entitate nouă',
    ADD_NEW_SAMPLE: 'Adaugă exemplu',
    ADD_KNOWLEDGE: 'Adaugă cunoștințe',
    ADDITIONAL_KNOWLEDGE_BASE: 'Baza de cunoștințe',
    ADDITIONAL_KNOWLEDGE_BASES: 'Baze de cunoștințe',
    ADDITIONAL_KNOWLEDGE_BASE_ENTITY: 'Entitate concept',
    ADDITIONAL_KNOWLEDGE_BASE_UPPER_LIMIT: 'Max',
    ADDITIONAL_KNOWLEDGE_BASE_CHARACTERS: 'caractere',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_RESPONSE: 'Asistentul spune',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_EXPLANATION:
      'Intentiile simple permit asistentului sa clasifice textul introdus de utilizator, oferind raspunsuri. Ex:',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_EXAMPLE_1:
      'Copil: "Nu se învârt roțile"',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_EXAMPLE_2:
      'Asistent: "Lansează fluxul de verificare a motoarelor din traiectoria \'Asamblare robot\'. "',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_RECOGNITION_TEXT: 'Exemple',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY: 'Intentie simpla',
    ADDITIONAL_KNOWLEDGE_BASE_SIMPLE_ENTITY_TABLE_TITLE:
      'Intenții simple (utilizatori avansati)',
    ADDITIONAL_KNOWLEDGE_BASE_DESCRIPTION: 'Numele bazei',
    ADDITIONAL_KNOWLEDGE_BASE_INFORMATION:
      'O bază de cunoștințe reprezintă un set de concepte din programa școlară asociate cu definiții și resurse educaționale deschise sau materiale educaționale convenționale. Pe lângă bazele de cunoștinte implicite ale sistemului, cadrele didactice pot schimba definițiile și asocierile cu resurse educaționale. Aici poți configura răspunsurile pe care le dau asistenții virtuali la întrebări de forma "Ce este ...?". Bazele de cunoștinte reprezintă domenii sau subdomenii ale ariilor de învățare precum: Matematică clasa a-V-a, Fizică clasa a-VI-a, Informatică și TIC clasa a-V-a, Robotică, etc.',
    ADDITIONAL_KNOWLEDGE_BASE_ENTITIES: 'Entitățile și seturile de antrenare',
    ADDITIONAL_KNOWLEDGE_BASE_SAMPLES: 'Seturile de antrenare',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT: 'Concept',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT_EXAMPLE_1: 'Copil: "Ce este algoritmul?"',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT_EXAMPLE_2:
      'Asistentul: "Algoritmul este un set de pași...."',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPT_EXPLANATION:
      'Conceptele vor fi recunoscute de catre asistent in cadrul intrebarilor de tipul "Ce este ...?" pentru a fi explicate. Ex:',
    ADDITIONAL_KNOWLEDGE_BASE_NEW_CONCEPT: 'Concept_nou',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS: 'Conceptele bazei',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS_DEFINITIONS: 'Asistentul răspunde',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS_LINK: 'Link',
    ADDITIONAL_KNOWLEDGE_BASE_CONCEPTS_NOTES: 'Observații',
    ADDITIONAL_KNOWLEDGE_BASE_JSON_FORMAT: 'JSON',
    DETAILS_TITLE: 'Detaliile bazei de cunoștințe',
    FILTER_PLACEHOLDER: 'Filtrează după nume',
    INVALID_INPUT_DIALOG_HEADER: 'Valoare invalidă!',
    INVALID_INPUT_CONCEPT_NAME: 'Există deja un concept cu acest nume! \n',
    INVALID_INPUT_ENTITY_NAME: 'Există deja o entitate cu acest nume! \n',
    JSON_INVALID: 'Format invalid, respectați standardul JSON!',
    NEW_ENTITY: 'Entitate nouă',
    NEW_CONCEPT: 'Concept nou',
    NO_ADDITIONAL_KNOWLEDGE_BASES: 'Nu există baze de cunoștințe adiționale',
    TOOLTIP_VARIANT: 'Numele entității/intenției',
    TOOLTIP_DEFINITION: 'ex: Algoritmul este un set de pași...',
    TOOLTIP_LINK:
      'Link-ul către resursa educațională (videoclip, document pdf, etc)',
    TOOLTIP_NOTES:
      'Note explicative pentru resursa educațională (ex: Capitolul 2 al manualului, Minutul 3 al videoclipului, etc)',
    TOOLTIP_KEY: 'Numele intenției',
    TOOLTIP_CONCEPT_CHIPS:
      'Cuvinte cheie care ar putea fi folosite pentru a descrie entitatea (ex: robot, roboțel, robo, robotul, tecnicus, MIRO, etc)',
    TOOLTIP_SIMPLE_ENTITY_CHIPS:
      'Frazele specifice care trebuie sa declanseze raspunsul'
  },
  PATH_EDITOR: {
    PATH_TOTAL_COUNT: 'Număr total de traiectorii: ',
    PATH_EXPORT: 'Traiectorie',
    PATH_PREVIEW_IMAGE: 'Imaginile traiectoriei',
    TAG_PREVIEW_IMAGE: 'Imaginile etichetei',
    FLOW_PREVIEW_IMAGE: 'Imaginile fluxului',
    PATH_PREVIEW_SELECTED_IMAGE: 'Imaginea selectată',
    PATH_PREVIEW_REMOVE_SELECTED_IMAGE: 'Salvează modificările pentru a șterge coperta',
    PATH_PREVIEW_NO_SELECTED_IMAGE: 'Nu a fost selectată o imagine pentru copertă',
    PATH_PREVIEW_CURRENT_IMAGE: 'Imaginea curentă',
    PUBLIC_PATH: 'Traiectoria este publicată, starea este ireversibilă',
    FLOW_EXPORT: 'Lecție',
    ADVANCED_INTERFACE: 'Interfață avansată',
    PATH_CODE_IS_AVAILABLE: 'Codul este disponibil',
    PATH_CODE_IS_NOT_AVAILABLE: 'Codul este deja utilizat',
    ANSWER: {
      ANSWER_INDEX: 'Nr răspuns',
      IDEA: 'Explicație',
      IS_CORRECT: 'Este corect?',
      TEXT: 'Text variantă de răspuns',
      NEW_TEXT: 'Faceți click pentru a adăuga noul text.',
      NEW_IDEA: 'Faceți click pentru a adăuga o nouă explicație.',
      NEW_IS_CORRECT:
        'Faceți click pentru a indica corectitudinea răspunsului.',
      NEW_ANSWER: 'Adaugă variantă de răspuns',
      NO_NEW_ANSWERS: 'Întrebarea nu conține variante de răspuns noi',
      SAVE_ANSWER_EDIT: 'Salvează modificările'
    },
    COPY_LINK_SUCCESS: 'Link copiat',
    ANSWER_TITLE: 'Răspunsul',
    FILTER_PLACEHOLDER: 'Caută după descriere',
    FILTER_PATH_AND_FLOW_PLACEHOLDER: 'Caută după descrierea traiectoriei sau a fluxului',
    FILTER_KEYWORDS_PLACEHOLDER: 'Caută după cuvinte cheie',
    PRIVATE_LIBRARY_INFO_SUMMARY: 'În secțiunea curentă sunt afișate traiectoriile de învățare proprii și fluxurile conținute',
    PRIVATE_LIBRARY_INFO_DETAIL: 'Pentru accesul la mai multe opțiuni pe traiectorii și fluxuri, click dreapta pe acestea',
    PUBLIC_LIBRARY_INFO_SUMMARY: 'În secțiunea curentă sunt afișate traiectoriile de învățare disponibile și fluxurile conținute',
    PUBLIC_LIBRARY_INFO_DETAIL: 'Pentru accesul la mai multe opțiuni pe fluxuri, click dreapta pe acestea',
    PATH_EDITOR_INFO_SUMMARY: 'În secțiunea curentă sunt afișate traiectoriile de învățare, care conțin fluxuri de învățare',
    OWNED_PATH_EDITOR_INFO_SUMMARY: 'În secțiunea curentă sunt afișate traiectoriile proprii de învățare, care conțin fluxuri de învățare',
    FLOW: {
      ADDITIONAL_OERS: 'Lecturi suplimentare',
      ADDITIONAL_KNOWLEDGE_BASE_SELECTOR: 'Baze de cunoștințe adiționale',
      AGE_INTERVAL: 'Alege categoria de vârstă (de la 6 la 18 ani)',
      AGE_FROM: 'De la',
      AGE_TO: 'Până la',
      ASSISTANT_SELECTOR: 'Asistent de învățare (i.e. ioana, testrax)',
      AUTO_IMPROVED_KNOWLEDGE_BASE: 'Baza de cunoștințe care va fi îmbunătățită automat',
      CLONE_FLOW_AS_PRIVATE: 'Clonează fluxul într-o traiectorie privată existentă sau nou creată',
      CLONE_FLOW_AS_PRIVATE_CONTEXT_MENU: 'Clonează fluxul ',
      CLONE_FLOW_CREATE_PATH: 'Creează o traiectorie',
      CLONE_FLOW_SELECT_PATH: 'Selectează o traiectorie existentă',
      CLONED_FLOW_AS_PRIVATE: 'Fluxul a fost clonat cu succes',
      CLONED_FLOW_AS_PRIVATE_FAIL: 'Clonarea fluxului a eșuat',
      CONTENT_ITEMS: 'Elemente de conținut',
      DEFAULT_KNOWLEDGE_BASE: 'Baza de cunoștințe default',
      DEFAULT_ASSISTANT: 'Asistent de învățare',
      DESCRIPTION: 'Denumire flux',
      DELETE_CONFIRMATION: 'Confirmă ștergerea fluxului',
      HARDWARE_REQUIREMENTS: 'Cerințe hardware',
      HARDWARE_REQUIREMENTS_EMPTY: 'Fără cerințe hardware',
      HARDWARE_REQUIREMENTS_ENTER_INFO: 'Apasă enter pentru a definitiva o cerință hardware',
      ID_CODE: 'Cod de identificare',
      ID_CODE_FILTER_PLACEHOLDER: 'Cod de identificare',
      ID_CODE_TOOLTIP: 'Codul de identificare poate fi alcătuit doar din caractere alfanumerice și "."',
      FLOW_DIALOG_HEADER: 'Modifică fluxul',
      FLOW_DELETE_DIALOG_HEADER: 'Ștergere',
      KEYWORDS: 'Cuvinte cheie',
      KEYWORDS_ENTER_INFO: 'Apasă enter pentru a definitiva un cuvânt cheie',
      KNOWLEDGE_SELECTOR: 'Baza de cunoștințe (i.e. ioana-default, testrax-default)',
      LANGUAGE_SELECTOR: 'Limba (i.e. ro, en, es, fr)',
      PUBLIC_CHECKBOX: 'Disponibil public',
      SHARE: 'Partajează',
      SHARE_FLOW_SELECT_PLACEHOLDER: 'Selectează un utilizator..',
      SHARE_FLOW_DIALOG_HEADER: 'Partajează fluxul selectat',
      SHARE_FLOW_DIALOG_NO_SHARE: 'Fluxul nu a fost partajat cu nici un utilizator',
      SPECIFIC_COMPETENCIES: 'Competențe',
      USERNAME_SHARE: 'Utilizatori partajați',
      WELCOME: 'Mesaj introductiv'
    },
    FLOW_TITLE: 'Fluxul de învățare',
    FLOW_IN_TESTING: 'Accesibil doar pentru testare (nu va apărea în biblioteca publică a elevilor după publicare)',
    ID_CODE: 'Cod de identificare',
    ID_CODE_FILTER_PLACEHOLDER: 'Cod de identificare',
    INCLUDE_CLASSIFIER: 'Include clasificatorii',
    INCLUDE_FORM: 'Include formularele',
    INCLUDE_KNOWLEDGE_MAP_ASSETS_PATH: 'Atașează harta de cunoștințe la elementele traiectoriei',
    INCLUDE_KNOWLEDGE_MAP_ASSETS_FLOW: 'Atașează harta de cunoștințe la elementele fluxului',
    NO_DATA: 'Nu există fluxuri create.',
    NO_OWNED_PATHS_ONE: 'Nu există traiectorii personale',
    NO_OWNED_PATHS_TWO: ' Click ',
    NO_OWNED_PATHS_THREE: ' pentru a adăuga o traiectorie.',
    PATH: {
      ADVANCED_SEARCH: 'Căutare avansată',
      ADVANCED_SEARCH_EDIT: 'Editează',
      DESCRIPTION: 'Denumire traiectorie',
      DELETE_CONFIRMATION: 'Confirmă ștergerea traiectoriei',
      ID_CODE: 'Cod de identificare',
      NEW_SIMPLE_PATH: 'Adaugă o traiectorie',
      ID_CODE_TOOLTIP: 'Codul de identificare poate fi alcătuit doar din caractere alfanumerice și "."',
      PATH_DELETE_DIALOG_HEADER: 'Ștergere',
      PATH_DIALOG_HEADER: 'Modifică traiectoria'
    },
    PATH_PLACEHOLDER: 'Dă click dreapta pentru a adăuga un path',
    PATH_VALIDITY: 'Verificat pentru publicare?',
    PATH_IS_VISIBLE_IN_LANDING_PAGE: 'Vizibil în landing page',
    PATH_TITLE: 'Traiectoria de învățare',
    TAGS: 'Etichete',
    TAGS_SELECT: 'Etichete',
    LANGUAGE_SELECT: 'Limba',
    TAGS_SELECTED: 'Etichete selectate',
    KNOWLEDGE_MAP: 'Harta de cunoştinţe',
    CHOOSE_KNOWLEDGE_MAP: 'Alege harta de cunoştinţe',
    QUESTION: {
      SINGLE_CHOICE_INFO: {
        ONE_ANSWER_CONDITION: 'Întrebările de tip "Răspuns unic" trebuie să conțină cel putin un răspuns',
        ONE_CORRECT_ANSWER_CONDITION: 'Întrebările de tip "Răspuns unic" pot conține doar un răspuns corect',
        SAVE_ANSWER: 'Pentru a adăuga un răspuns, în urma completării câmpurilor obligatorii apasă pe butonul "Adaugă variantă de răspuns"',
        SAVE_QUESTION: 'Pentru a salva o întrebare cu tot cu răspunsuri în urma completării câmpurilor obligatorii, apasă pe butonul  "Adaugă întrebarea" ',
        EXPLANATION_FIELD: 'Câmpul "Explicație" din cadrul răspunsurilor reprezintă informația pe care elevul o primește atunci când a dat un răspuns greșit (nu este valabil în fluxurile marcate ca test)'
      },
      MULTIPLE_CHOICE_INFO: {
        ONE_ANSWER_CONDITION: 'Întrebările de tip "Răspuns multiplu" trebuie să conțină cel putin un răspuns',
        ONE_CORRECT_ANSWER_CONDITION: 'Întrebările de tip "Răspuns multiplu" pot conține mai mult de un răspuns corect',
        SAVE_ANSWER: 'Pentru a adăuga un răspuns, în urma completării câmpurilor obligatorii apasă pe butonul "Adaugă variantă de răspuns"',
        SAVE_QUESTION: 'Pentru a salva o întrebare cu tot cu răspunsuri în urma completării câmpurilor obligatorii, apasă pe butonul  "Adaugă întrebarea" ',
        EXPLANATION_FIELD: 'Câmpul "Explicație" din cadrul răspunsurilor reprezintă informația pe care elevul o primește atunci când a dat un răspuns greșit (nu este valabil în fluxurile marcate ca test)'
      },
      DYNAMIC_FORM_INFO: {
        SELECTED_DYNAMIC_FORM_CONDITION: 'Întrebările de tip "Formular dinamic" trebuie să conțină obligatoriu un formular selectat',
        SIMPLE_DYNAMIC_FORM: 'Pentru a crea un formular simplificat de tip text apasă pe butonul "Creează formular simplificat".',
        SIMPLE_DYNAMIC_FORM_SECOND: 'Se va afișa un câmp ce va reprezenta eticheta formularui de tip text.',
        SIMPLE_DYNAMIC_FORM_THIRD: 'În urma completării câmpului, apasă pe butonul "Adaugă formularul", acesta va salva noul formular simplificat și va completa automat câmpul de tip select corespunzător formularului.',
        SIMPLE_DYNAMIC_FORM_NAME: 'Numele formularului va fi sub forma "flow-<NUME FLUX>_<INDEX PAS>-step"',
        SAVE_QUESTION: 'Pentru a salva o întrebare în urma completării câmpurilor obligatorii, apasă pe butonul  "Adaugă întrebarea"'
      },
      CLASSIFIER_INFO: {
        SELECTED_CLASSIFIER_FORM_CONDITION: 'Întrebările de tip "Limbaj natural" trebuie să conțină obligatoriu un clasificator selectat',
        SIMPLE_CLASSIFIER: 'Pentru a crea un clasificator simplificat cu două clase (corect și incorect), apasă pe butonul "Creează formular simplificat.',
        SIMPLE_CLASSIFIER_SECOND: 'Se va afișa un câmp ce va reprezenta eticheta formularui de tip text.',
        SIMPLE_CLASSIFIER_THIRD: 'În urma completării celor două câmpuri corespunzatoare claselor "corect" (răspuns corect) și "incorect" (răspuns greșit) apasă pe butonul "Adaugă clasificatorul", acesta va salva noul clasificator simplificat și va completa automat câmpul de tip select corespunzător clasificatorului.',
        SIMPLE_CLASSIFIER_NAME: 'Numele clasificatorului va fi sub forma "flow-<NUME FLUX>_<INDEX PAS>-step"',
        SAVE_QUESTION: 'Pentru a salva o întrebare în urma completării câmpurilor obligatorii, apasă pe butonul  "Adaugă întrebarea"'
      },
      NO_QUESTIONS: 'Acest pas nu conține întrebări',
      CLASSIFIER: 'Clasificator',
      INVALID_CLASSIFIER: 'Intrebare invalidă, adăugați un clasificator',
      INVALID_DYNAMIC_FORM: 'Intrebare invalidă, adăugați un formular',
      DYNAMIC_FORM_DIALOG_HEADER: 'Modifică întrebarea de tip formular',
      NATURAL_LANGUAGE_DIALOG_HEADER: 'Modifică întrebarea de tip limbaj natural',
      FORM_TEMPLATE: 'Model formular',
      SELECTOR: 'Selector (testrax)',
      QUESTION_TYPE: 'Tip de întrebare',
      TEXT: 'Textul întrebării',
      ADD_QUESTION: 'Adaugă întrebarea',
      ANSWERS: 'Variante de răspuns',
      GRADING_INDICATIONS: 'Indicații pentru corectare',
      SINGLE_CHOICE: 'Răspuns unic',
      SINGLE_CHOICE_DIALOG_HEADER: 'Modifică întrebarea de tip răspuns unic',
      MULTIPLE_CHOICE: 'Răspuns multiplu',
      MULTIPLE_CHOICE_DIALOG_HEADER: 'Modifică întrebarea de tip răspuns multiplu',
      NATURAL_LANGUAGE: 'Limbaj natural',
      SIMPLIFIED_CLASSIFIER_CORRECT: 'Răspuns corect',
      SIMPLIFIED_CLASSIFIER_INCORRECT: 'Răspuns greșit',
      SIMPLIFIED_CLASSIFIER_CREATE: 'Creează clasificator simplificat',
      SIMPLIFIED_CLASSIFIER_SAVE: 'Adaugă clasificatorul',
      SIMPLIFIED_DYNAMIC_FORM_LABEL: 'Etichetă formular simplificat',
      SIMPLIFIED_DYNAMIC_FORM_CREATE: 'Creează formular simplificat',
      SIMPLIFIED_DYNAMIC_FORM_SAVE: 'Adaugă formularul',
      SIMPLIFIED_DYNAMIC_FORM_TITLE: 'Completați câmpul de mai jos',
      DYNAMIC_FORM: 'Formular dinamic',
      IMPORT_A_QUESTION: 'Importă o întrebare',
      IMPORT_A_QUESTION_FROM_QUESTION_BANK: 'Importă o întrebare dintr-o colecție de întrebări',
      DYNAMIC_QUESTION: 'Întrebare dinamică',
      QUESTION_BANK_RANDOM_QUESTION: 'Folosește o întrebare aleatorie dintr-o colecție de întrebări',
      SELECT_A_QUESTION_BANK: 'Alege o colecție de întrebări',
      ADD_SELECTED_QUESTION: 'Adaugă întrebarea selectată',
      ADD_RANDOM_QUESTION: 'Adaugă o întrebare aleatorie',
      QUESTION_ADDED: 'Întrebarea a fost adăugată',
      CHOOSE_QUESTION_OR_ADD_A_RANDOM_ONE: 'Alege o întrebare sau adaugă una aleatorie',
      ADD_DYNAMIC_QUESTION_FROM_THIS_COLLECTION: 'Adaugă o întrebare dinamică din această colecție',
      QUESTION_BANK_ADDED: 'Colecția de întrebări a fost adăugată în pasul curent',
      QUESTION_BANK_NAME: 'Numele colecției de întrebări',
      QUESTION_BANK_QUESTIONS_COUNT: 'Numărul de întrebări posibile'
    },
    QUESTION_TITLE: 'Întrebarea',
    SEE_FLOW_JSON: 'Vizualizează fluxul',
    SEE_PATH_JSON: 'Vizualizează traiectoria',
    STEP: {
      ADD_STEP_DIALOG_HEADER: 'Adaugă un pas',
      CONCEPTS: 'Concepte',
      CLOSE_STEP_EDITOR_MESSAGE: 'Pentru a salva modificările la nivelul pasului închide secțiunea "Editor de întrebări" ',
      DELETE_CONFIRMATION: 'Confirmă ștergerea pasului',
      DESCRIPTION: 'Textul pasului',
      HELP: 'Text de ajutor',
      HINT: 'Alte indicații',
      IS_COMPETENCY_RELEVANT_STEP: 'Pasul evaluează competența specifică fluxului?',
      IS_QUESTION_STEP: 'Conține întrebări?',
      IMAGE: 'Link la imagine',
      IMAGE_SOURCE: 'Sursa imaginii',
      KEY: 'Cheie de regăsire',
      MAX_TIME: 'Timp maxim',
      META: 'Metainformații (JSON)',
      MIN_TIME: 'Timp minim',
      NOT_SPECIFIED: 'Nu este specificat',
      STEP_DIALOG_HEADER: 'Modifică pasul',
      STEP_DELETE_DIALOG_HEADER: 'Ștergere',
      OER_LINK: 'Link către resursa educațională',
      SOLUTION: 'Soluție'
    },
    STEP_TITLE: 'Pasul de învățare',
    DESCRIPTION: 'Denumire traiectorie',
    DESCRIPTION_FILTER_PLACEHOLDER: 'Descriere',
    EXPORT: 'Exportă',
    FLOWS: 'Fluxuri',
    IS_SHARED: 'Partajat',
    IS_VERIFIED: 'Verificat pentru publicare',
    TEST_FLOW: 'Flux de test',
    VERIFIED: 'Verificat',
    PATH_EDITOR: 'Listă de traiectorii'
  },
  PACKAGE_EDITOR: {
    PACKAGE: {
      DESCRIPTION: 'Descriere',
      NAME: 'Nume'
    },
    PACKAGE_TITLE: 'Detalii despre pachet',
    SELECT_PATHS: 'Care traiectorii fac parte din pachet?'
  },
  RATING: {
    ADD_NEW_RATING: 'Adaugă o nouă evaluare',
    EDIT_EXISTENT_RATING: 'Actualizează evaluarea existentă',
    RATE: 'Evaluează',
    RATE_THE_ENTITY: 'Evaluează entitatea',
    RATE_THE_FLOW: 'Evaluează fluxul',
    RATING_1: 'Slab',
    RATING_2: 'Sub așteptări',
    RATING_3: 'Acceptabil',
    RATING_4: 'Bun',
    RATING_5: 'Excelent',
    RATING_ADDED: 'Evaluarea a fost adăugată',
    RATING_UPDATED: 'Evaluarea a fost actualizată',
    THE_AVERAGE_RATING: 'Evaluarea medie',
    THE_AVERAGE_RATING_OF_THE_FLOW: 'Evaluarea medie a fluxului'
  },
  REGISTER: {
    CHILD_REGISTERING_SELF: 'Sunt copil',
    GENERAL: {
      CHILD_NAME: 'Numele și prenumele copilului',
      CONFIRM_ACCOUNT: 'Confirmă email/telefon',
      CONFIRM_EMAIL: 'Codul de confirmare primit pe email',
      CONFIRM_PHONE: 'Codul de confirmare primit prin SMS',
      ESTIMATED_STUDENTS_NUMBER:
        'Numărul estimat de elevi pe care îi veți inscrie',
      FIELD_REQUIRED: 'Acest câmp este obligatoriu!',
      INSERT_EMAIL: 'Adresa de email (părinte/profesor)',
      PARENT_EMAIL: 'Adresa de email a părintelui',
      PARENT_PHONE: 'Numărul de telefon al părintelui (e.g. +40712345678)',
      PERFORM_CONFIRM: 'Confirmă',
      PERFORM_PARENT_REGISTER: 'Creează un cont de părinte',
      PERFORM_REGISTER: 'Înregistrează',
      PERFORM_STUDENT_REGISTER: 'Creează un cont de elev',
      PERFORM_TEACHER_REGISTER: 'Creează un cont de profesor',
      REGISTER_INTENT_PARENT: 'Înregistrare părinți',
      REGISTER_INTENT_STUDENT: 'Înregistrare elevi',
      REGISTER_INTENT_TEACHER: 'Înregistrare profesori',
      SAVE_AND_ENTER_APP: 'Salvează și intră în aplicație',
      TWO_FACTOR_AUTHENTICATION: 'Autentificare în doi pași',
      USER_CITY: 'Oraş',
      USER_CLASS: 'Clasă',
      USER_COUNTRY: 'Ţară',
      USER_COUNTY: 'Judeţ',
      USER_EMAIL: 'Adresa de email',
      USER_EMAIL_INVITED: 'Adresa de email',
      USER_NAME: 'Nume și prenume',
      USER_OLD_PASSWORD: 'Vechea parolă',
      USER_PASSWORD: 'Alege o parolă',
      USER_CONFIRM_PASSWORD: 'Confirmă parola',
      USER_PHONE: 'Numărul de telefon (e.g. +40712345678)',
      USER_PHONE_INVITED: 'Numărul de telefon',
      USER_PREFERRED_LANGUAGE: 'Limba preferată',
      USER_REGISTRATION: 'Înscriere elev',
      USER_SCHOOL: 'Şcoală',
      USER_USERNAME: 'Alege un nume de utilizator',
      USER_USERNAME_REGISTER: 'Nume de utilizator'
    },
    LOGIN: 'Vreau să intru în aplicație',
    PARENT: {
      CHILDREN: 'Copii',
      MY_DATA: 'Datele mele',
      PERFORM_CONFIRM: 'Confirmare'
    },
    PARENT_REGISTERING_CHILD: 'Sunt părinte',
    REGISTER_PARENT: 'Vreau să înregistrez un copil',
    REGISTER_SELLER: 'Vreau să organizez lecții',
    REGISTER_TRAINER: 'Vreau să fiu trainer',
    PARENT_REGISTRATION: 'Înscriere părinți',
    PARENT_REGISTRATION_FORM_DETAILS:
      'Creați un cont de părinte folosind formularul de mai jos',
    TEACHER_REGISTERING_CHILD: 'Sunt profesor',
    TEACHER_REGISTRATION: 'Înscriere profesori',
    TEACHER_REGISTRATION_FORM_DETAILS:
      'Creați un cont de profesor folosind formularul de mai jos',
    STUDENT_REGISTRATION: 'Înscriere elevi',
    STUDENT_REGISTRATION_FORM_DETAILS:
      'Creați un cont de elev folosind formularul de mai jos',
    TERMS_CONDITIONS: 'Termenii și Condițiile',
    TERMS_CONDITIONS_ONE: 'Sunt de acord cu ',
    TERMS_CONDITIONS_TWO: ' de utilizare a platformei',
    PRIVACY_COOKIES_ONE: 'Accept ',
    PRIVACY: 'Politica de Confidențialitate',
    COOKIES: 'Cookies',
    AND: ' și '
  },
  REPORTS: {
    ADD_TO_COMMUNITY: 'Adaugă la o clasă/comunitate',
    ADDITIONAL_OERS: 'Lecturi suplimentare',
    AGGREGATE: 'Pentru toate fluxurile: ',
    ASSOCIATE_STUDENTS: 'Adăugați elevii într-o clasă/comunitate',
    CREATE_CLASS: 'Creează o clasă nouă',
    CREATE_COMMUNITY: 'Creează o comunitate nouă',
    COMMUNITY_DESCRIPTION: 'Descrierea clasei',
    COMMUNITY_NAME: 'Numele clasei',
    CONFIRM_DEASSOCIATION: 'Confirmați dezasocierea?',
    DEASSOCIATE: 'Dezasociază',
    DEASSOCIATE_STUDENTS_CONFIRMATION: 'Dezasociați elevii selectați',
    DESCRIPTION_LEARNING_REPORT: 'Raport de învățare pentru:',
    DESCRIPTION_SIMPLE_TEST_REPORT: 'Status susținere teste',
    NO_STATS_FOR_USER: 'Nu există statistici pentru utilizatorul',
    QUESTIONS_CORRECT: 'Întrabări cu răspuns corect',
    CORRECT: 'Corecte',
    STARTED: 'Început',
    FINISHED: 'Terminat',
    FLOW: 'Pentru fluxul',
    INCORRECT: 'Incorecte',
    UNASWERED: 'Întrebări fără răspuns',
    LAST_DATE: 'Accesat ultima dată',
    LOADING: 'Se încarcă...',
    LEARNING_REPORT: 'Raport de învățare',
    NO_ANSWERS: 'La acest pas nu s-a răspuns la întrebări.',
    NO_GROUPS: 'Acest elev nu are comunități asociate',
    NO_QUESTIONS: 'Acest pas nu conține întrebări',
    NO_STATS: 'Nu există statistici pentru acest flux',
    NO_TESTS: 'Nu există teste',
    RECLAIM_NOTICE:
      'Pentru a monitoriza progresul elevilor va fi necesară reasocierea acestora!',
    REPORT_ANSWERS_PIECHART_TITLE: 'Structura răspunsurilor date',
    REPORT_EXPLANATION: 'Raportul indică ultimul răspuns la fiecare întrebare.',
    REPORT_FREQUENCY: 'Primește rapoarte',
    REPORT_FREQUENCY_WEEKLY: 'Săptămânal',
    REPORT_FREQUENCY_MONTHLY: 'Lunar',
    REPORT_FREQUENCY_NONE: 'Niciodată',
    REPORT_FORMS_SECTION_TITLE: 'Răspunsuri la formulare',
    REPORT_QUESTIONS_TIMELINE: 'Timpul petrecut la întrebări',
    REMOVE_FROM_GROUP: 'Șterge din clasă',
    SEARCH_FOR_COMMUNITY: 'Caută o comunitate',
    USERS_WILL_BE_ADDED_TO_COMMUNITY:
      'Utilizatorii vor fi adăugați in clasă/comunitatea',
    USER_ASSOCIATED_GROUPS: 'Clase atribuite',
    TO_THE_USER: 'utilizatorului',
    SECONDS: 'secunde',
    TIME_TO_COMPLETION: 'Rezolvarea întrebării a durat'
  },
  SERVER_MESSAGES: {
    ACCEPTED: 'Operația a fost făcută',
    CREATED: 'Entitatea a fost creată',
    ERR: 'Eroare',
    ERRORS: {
      ERR_ASSISTANT_CONTEXT_CHANGED: 'Sesiune conversațională invalidă!',
      ERR_MEDIA_ASSETS_IMAGE_WRONG_DIMENSIONS: 'Dimensiunile imaginii sunt incorecte',
      ERR_CLAIM_INCOMPLETE: 'Nu poți asocia un cont fără email și numele de utilizator',
      ERR_CODE_INVALID: 'Codul de activare este invalid',
      ERR_CODE_USED: 'Codul de activare a fost deja utilizat',
      ERR_COMPETENCY_ELEMENT_DUPLICATE_CODE: 'Există deja un element de competență cu acest cod',
      ERR_COMPETITION_REGISTRATION_EXISTS: 'Ești deja înscris',
      ERR_CONCEPT_DUPLICATE_CODE: 'Există deja un concept cu acest cod',
      ERR_CONTENT_DOMAIN_DUPLICATE_CODE: 'Există deja un domeniu de conținut cu acest cod',
      ERR_CONTENT_ITEM_DUPLICATE_CODE: 'Există deja un element conținut cu acest cod',
      ERR_DUPLICATE_ASSISTANT: 'Există deja un asistent cu acest nume',
      ERR_DUPLICATE_KNOWLEDGE_BASE: 'Există deja o bază de cunoștințe cu acest nume',
      ERR_DUPLICATE_USER: 'Există deja un cont cu acest utilizator',
      ERR_EVENT_FINISHED: 'Eventul nu mai este în desfășurare în prezent',
      ERR_EVENT_SCHEDULED: 'Eventul nu este în desfășurare în prezent',
      ERR_EXISTING_FLOW_ID_CODE: 'Codul de identificare al fluxului a fost deja utilizat',
      ERR_EXISTING_PATH_ID_CODE: 'Codul de identificare al traiectoriei a fost deja utilizat',
      ERR_EXISTING_MEDIA_ASSET_PURPOSE: 'Există deja un media asset pentru acest scop',
      ERR_EXISTS: 'Acest nume este deja utilizat',
      ERR_EXISTS_MANY: 'Unul dintre utilizatori există deja',
      ERR_FILE_UPLOAD_LIMIT_EXCEEDED: 'Fișierul ales este prea mare! Acesta nu trebuie sa depășească 10MB.',
      ERR_FLOW_PRIVATE: 'Nu ești autorizat să accesezi acest flux!',
      ERR_FORBIDDEN_SUBMISSION: 'Răspunsurile nu au putut fi înregistrate deoarece fluxul nu mai este valabil',
      ERR_GENERAL_COMPETENCY_DUPLICATE_CODE: 'Există deja o competență generală cu acest cod',
      ERR_INCORRECT_TOKEN: 'Încearcă să te reconectezi la aplicație',
      ERR_INSUFFICIENT_FUNDS: 'Puncte insuficiente',
      ERR_INVALID_2FA_TOKEN: 'Cod de autentificare în doi pași invalid',
      ERR_INVALID_EMAIL: 'Adresa de email este invalidă',
      ERR_INVALID_IMPORT_FLOW: 'Ai încercat să încarci o traiectorie, nu o lecție',
      ERR_INVALID_IMPORT_KNOWLEDGE_MAP: 'Harta de cunoștințe nu poate fi importată',
      ERR_INVALID_IMPORT_PATH: 'Ai încercat să încarci o lecție, nu o traiectorie',
      ERR_INVALID_PHONE: 'Numărul de telefon este invalid',
      ERR_INVALID_VALUE: 'Valoare invalidă!',
      ERR_JSON_INVALID: 'Structura invalida!',
      ERR_KNOWLEDGE_FIELD_DUPLICATE_CODE: 'Există deja o materie cu acest cod',
      ERR_KNOWLEDGE_MAP_DUPLICATE_CODE: 'Există deja o hartă de cunoștințe cu acest cod',
      ERR_KNOWLEDGE_MAP_VERSION_DUPLICATE_CODE: 'Există deja o versiune de hartă de cunoștințe cu acest cod',
      ERR_MATCH_TOKEN: 'Încearcă să te reconectezi la aplicație',
      ERR_MODEL_OVERLOADED: 'Modelul nu poate răspunde cererii momentan, reîncearcă mai târziu',
      ERR_NOT_AUTHORIZED: 'Nu ești autorizat să faci acestă operație',
      ERR_NOT_FOUND: 'Nu am găsit',
      ERR_NOT_IMPLEMENTED: 'Operația nu este încă implementată',
      ERR_NOT_OWNED_FLOW: 'Nu ai permisiunea pentru a efectua această operație pe flux',
      ERR_NO_ASSISTANT: 'Nu a fost selectat un asistent de învățare',
      ERR_NO_CLASSIFIER: 'Nu a fost selectat un clasificator',
      ERR_NO_CODE_PATH: 'Traiectoria ce conține acest flux nu are un cod de identificare',
      ERR_NO_CREDENTIALS: 'Credențialele sunt invalide',
      ERR_NO_EXISTING_PATH: 'Nu puteți adăuga un path deja existent',
      ERR_NO_FLOW_SELECTED: 'Niciun flux de învățare selectat',
      ERR_NO_FORM: 'Nu a fost selectat un formular',
      ERR_NO_KNOWLEDGEBASE: 'Nu a fost selectat o bază de cunoștințe',
      ERR_NO_LANGUAGE: 'Nu a fost selectată limba asistentului',
      ERR_ONLY_STUDENT_CLAIM: 'Se poate asocia doar un cont de student',
      ERR_OVER_CAPACITY: 'Numărul de utilizatori depășește numărul maxim',
      ERR_PASSWORD_RESET_TOKEN_EXPIRED: 'Link-ul de resetare a expirat sau nu este valid!',
      ERR_PHONE_LENGTH: 'Numărul de telefon trebuie să aibă cel puțin 10 caractere',
      ERR_REQUIRED_DURATION: 'Durata este obligatorie',
      ERR_REQUIRED_EMAIL: 'Adresa de email este obligatorie',
      ERR_REQUIRED_LOCATION: 'Locația este obligatorie',
      ERR_REQUIRED_PHONE: 'Numărul de telefon este obligatoriu',
      ERR_REQUIRED_STUDENT_CAPACITY: 'Numărul maxim de copii este obligatoriu',
      ERR_SERVER_ERROR: 'S-a întâmplat o eroare pe server',
      ERR_SPECIFIC_COMPETENCY_DUPLICATE_CODE: 'Există deja o competență specifică cu acest cod',
      ERR_TIME_EXPIRED: 'Timpul permis pentru această operație a expirat',
      ERR_TOKEN_EXPIRED: 'Sesiunea a expirat!',
      ERR_WRONG_CURRENT_PASSWORD: 'Parola curentă introdusă este greșită',
      ERR_WRONG_ID_CODE_FORMAT: 'Formatul codului este incorect'
    }
  },
  STEP_EDITOR: {
    STEP_EDITOR: 'Listă de pași',
    STEP_EDITOR_INFO: 'În secțiunea curentă se pot vizualiza și modifica pașii fluxului de învățare selectat',
    NO_DATA: 'Nu există pași pentru acest flux',
    DESCRIPTION: 'Textul pasului',
    DESCRIPTION_FILTER_PLACEHOLDER: 'Textul pasului',
    OER_LINK: 'OER link',
    OER_LINK_PLACEHOLDER: 'OER',
    HELP: 'Text de ajutor',
    HELP_FILTER_PLACEHOLDER: 'Ajutor',
    HINT: 'Sugestie',
    HINT_FILTER_PLACEHOLDER: 'Sugestie',
    QUESTION_EDITOR: 'Editor de întrebări',
    MOVE_UP: 'Mută pasul mai sus',
    MOVE_DOWN: 'Mută pasul mai jos',
    QUESTION_LIST: 'Lista întrebărilor existente',
    QUESTION_EDITOR_GUIDE: {
      TITLE: 'Instrucțiuni de utilizare al editorului de întrebări.',
      GENERAL_INIDCATION_ONE:
        'În editorul de întrebări fiecare coloană reprezintă un atribut al întrebării sau al răspunsului întrebării ce a fost extinse, iar rândurile reprezintă datele despre întrebare, respectiv despre răspuns.',
      GENERAL_INIDCATION_TWO:
        'Întrebările ce conțin răspunsuri au în dreptul coloanei " No." o săgeată, pentru vizualizarea sau modificarea răspunurilor unei întrebări se va face click pe săgeata corespunzătoare întrebării.',
      QUESTION_ACTIONS: {
        TITLE: 'Acțiuni asupra întrebărilor',
        ADD_QUESTION: {
          TITLE: 'Adăugarea unei întrebări',
          STEP_ONE:
            'Adăugarea unei întrebări se realizează prin a face click pe butonul PLUS din partea de jos a tabelului.',
          STEP_TWO:
            'Un formular va apărea ce va fi completat cu informațiile referitoare la întrebarea ce va fi adăugată, după completarea formularului se va face click pe butonul SALVEAZĂ.'
        },
        EDIT_QUESTION: {
          TITLE: 'Editarea unei întrebări existente',
          STEP_ONE:
            'Editarea unei întrebări existente se realizează printr-un click direct pe celula întrebării din tabel ce se dorește a fi modificată.',
          STEP_TWO:
            'După ce a fost realizat click-ul pe celulă va apărea un câmp de intrare ce se va completa cu noua informație.',
          STEP_THREE:
            'Salvarea modificării a întrebării va fi finalizată atunci când se va face click pe butonul ce conține discheta de pe rândul întrebării editate.'
        },
        DELETE_QUESTION: {
          TITLE: 'Ștergerea unei întrebări',
          STEP_ONE:
            'Ștergerea unei întrebări se realizează facând click pe butonul roșu de pe rândul corespunzător întrebării'
        }
      },
      ANSWER_ACTIONS: {
        TITLE: 'Acțiuni asupra răspunsurilor',
        ADD_ANSWER: {
          TITLE: 'Adăugarea unui răspuns',
          STEP_ONE:
            'Adăugarea unui răspuns se realizează prin a face click pe butonul PLUS din dreptul întrebării asupra căreia se dorește adăugarea răspunsului.',
          STEP_TWO:
            'În cadrul tabelului sub întrebarea aleasă va apărea un rând ce va conține mesajul "Click here to.." ',
          STEP_THREE:
            'După ce a fost apăsat butonul pentru adăugarea unui răspuns, modificarea informațiilor din tabel nu va fi posibilă.',
          STEP_FOUR:
            'Pentru a putea modifica informațiile din nou, este necesară completarea procesului de adăugare a unui răspuns sau abandonarea procesului prin apăsarea butonului MINUS de pe rândul ce va conține noua întrebare.',
          STEP_FIVE:
            'Pe fiecare celulă ce conține mesajul "Click here to.." se va face click, iar în locul mesajului va apărea un câmp pentru a introduce informația corespunzătoare coloanei în cadrul noului răspuns.',
          STEP_SIX:
            'După completarea câmpurilor cu informațiile necesare, procesul de adăugare a unui răspuns se va finaliza prin apăsarea butonului PLUS corespunzător din dreptul rândului ce conține noul răspuns.'
        },
        EDIT_ANSWER: {
          TITLE: 'Editarea unui răspuns existent',
          STEP_ONE:
            'Editarea unui răspuns existent se realizează printr-un click direct pe celula răspunsului din tabel ce se dorește a fi modificat.',
          STEP_TWO:
            'După ce a fost realizat click-ul pe celulă va apărea un câmp de intrare ce se va completa cu noua informație.',
          STEP_THREE:
            'Salvarea modificării răspunsului va fi finalizată atunci când se va face click pe butonul ce conține discheta de pe rândul răspunsului editat.'
        },
        DELETE_ANSWER: {
          TITLE: 'Ștergerea unui răspuns',
          STEP_ONE:
            'Ștergerea unui răspuns se realizează facând click pe butonul roșu de pe rândul corespunzător răspunsului'
        }
      }
    },
    QUESTION_PREVIEW_TABLE: {
      QUESTION_TYPE: 'Tip de întrebare',
      TEXT: 'Întrebare',
      ANSWERS: 'Răspunsuri/Formular/Clasificator',
      NO_ANSWERS: 'Nu au fost definite răspunsuri',
      NO_CLASSIFIER: 'Nu a fost selectat un clasificator',
      NO_DYNAMIC_FORM: 'Nu a fost selectat un formular',
      NO_PREDEFINED_ANSWERS: 'Nu are răspunsuri predefinite',
      NO_EXISTING_QUESTIONS: 'Pasul nu conține întrebări'
    },
    SOLUTION: 'Soluție',
    SOLUTION_FILTER_PLACEHOLDER: 'Soluție',
    IMAGE: 'Imagine',
    IS_COMPETENCY_RELEVANT_QUESTION: 'Întrebarea evaluează competența specifică fluxului',
    IS_COMPETENCY_RELEVANT_QUESTION_TRUE: 'Da',
    IS_COMPETENCY_RELEVANT_QUESTION_FALSE: 'Nu',
    IS_QUESTION_STEP: 'Pas cu întrebări',
    IS_QUESTION_STEP_TRUE: 'Da',
    IS_QUESTION_STEP_FALSE: 'Nu'
  },
  FLOW_STATS: {
    FLOW_STATS: 'Raport',
    FLOW_STATS_HEADING: 'Raportul parcurgerilor pentru fluxul',
    CORRECT_ANSWERS: 'Răspunsuri corecte',
    WRONG_ANSWERS: 'Răspunsuri greșite'
  },
  GROUP_STATS: {
    FOR_FLOW: 'Pentru fluxul',
    GROUP_STATS_TOOLTIP: 'Vizualizează rapoarte ale comunității pe baza unui flux',
    GROUP_COMPETENCIES: 'Vizualizează competențele',
    LEARNING_REPORT_FOR_COMMUNITY: 'Raportul comunității',
    FLOW_DESCRIPTION: 'Descrierea fluxului',
    PATH_DESCRIPTION: 'Descrierea traiectoriei'
  },
  EVENT_STATS: {
    ACTIVITY_REPORT: 'Raport de activitate',
    ANSWER_ACCURACY_MEAN: 'Precizia medie a răspunsurilor',
    CORRECT_ANSWERS_COUNT_STUDENT: 'Nr. răspunsuri corecte per elev',
    CORRECT_ANSWERS_COUNT_QUESTION: 'Nr. răspunsuri corecte per întrebare',
    CROSSINGS_PER_STUDENT_MEAN: 'Numărul mediu de accesări al pasului per student',
    CROSSINGS_STUDENTS_COUNT: 'Studenți care au accesat pasul',
    DURATION_MEAN: 'Timp mediu petrecut în pas (mm:ss)',
    DURATION_TOTAL_MEAN: 'Timp mediu petrecut în activitate (mm:ss)',
    ENGAGEMENT_METRIC_MEAN: 'Grad mediu de implicare',
    EVENT_DATE: 'Data activității',
    HEADER: 'Raportul activității',
    PARTICIPANTS: 'Participanți',
    PERIOD: 'Perioada',
    REPORT_SUMMATIVE: '',
    REPORT_DETAILED: '',
    EVENT_START_DATE: 'Data de început',
    STEP: 'Pas',
    STEP_DESCRIPTION: 'Descrierea pasului',
    STEPS: 'Pași',
    STUDENT_REPORTS: 'Rapoarte elevi',
    QUESTIONS_REPORT: 'Raportul întrebărilor',
    USERNAME: 'Utilizator'
  },
  STEP_STATS: {
    ANSWER: 'Răspuns',
    ATTEMPT_NUMBER: 'Încercarea',
    DATE: 'Data',
    DURATION: 'Durata parcurgerii',
    FIELD: 'Câmp',
    FIELD_SUBMISSION: 'Răspuns',
    FLOW: 'Fluxul',
    IN_PROGRESS: 'în derulare',
    INTERVAL_START: 'Inceput la',
    MINUTE: 'minut',
    MINUTES: 'minute',
    NO_ANSWER: 'Nu s-a înregistrat niciun răspuns',
    NO_STEP_QUESTIONS: 'Acest pas nu are întrebări',
    STEP_NO_GIVEN_ANSWERS: 'La acest pas nu a fost oferit niciun răspuns',
    QUESTION: 'Întrebarea',
    STEP: 'Pasul',
    STEP_STATS_TITLE: 'Raport de pas',
    TIME_INTERVAL: 'Interval de timp'
  },
  STUDENT_DASHBOARD: {
    ACCESSED_FROM_LIBRARY: 'Accesat din bibliotecă',
    CLICK_FOR_DEMO_FLOW: 'Apasă butonul următor pentru a accesa un flux demonstrativ',
    CREATED_BY: 'Creat de:',
    DATE: 'Data:',
    EVENT_TOKEN_FORM_JOIN: 'Intră',
    EVENT_TOKEN_FORM_PLACEHOLDER: 'Codul de acces (e.g: pelicanul-roz-523)',
    EVENT_TOKEN_FORM_SUBTITLE: 'Intră direct într-o activitate folosind codul de acces sau link-ul către acesta',
    EVENT_TOKEN_FORM_TITLE: 'Intră într-o activitate',
    FLOW_PREVIEW: 'Previzualizare flux',
    FLOW_TITLE: 'Titlul fluxului: ',
    INSTRUCTIONS: {
      FIFTH: '5. Robohackatonul Nextlab.tech',
      FIRST: '1. Parcurgerea lecțiilor',
      FOURTH: '4. Modificare date personale',
      SECOND: '2. Vizualizarea rapoartelor',
      THIRD: '3. Asocierea cu profesori'
    },
    INTRODUCTION: 'Introducere',
    LAST_FLOWS: 'Ultimele lecții accesate',
    LAST_FLOWS_EMPTY: 'Nu ai accesat nicio lecție încă',
    NO_QUESTIONS_STEP: 'Pasul nu conține întrebări.',
    ONE_QUESTIONS_STEP: 'Pasul conține o întrebare.',
    PLATFORM_INSTRUCTIONS: 'Instrucțiuni platformă',
    QUESTIONS: 'întrebări',
    RESUME_FLOW: 'Continuă de unde ai rămas',
    STEP_FORM: 'Pasul conține formularul',
    STEP_FORMS: 'Pasul conține formularele',
    STEP_NO_FORMS: 'Pasul nu conține formulare.',
    STEP_TAKEN: 'Parcurs',
    THE_STEP: 'Pasul',
    THE_STEP_CONTAINS: 'Pasul conține'
  },
  TAB_TITLES: {
    ADDITIONAL_KNOWLEDGE_BASE_EDITOR: 'Cunoștințe',
    ANNOUNCEMENT_BUILDER: 'Anunțuri',
    ASSISTANT_BUILDER: 'Editor de asistenți',
    CLASSIFIER_BUILDER: 'Clasificatori',
    COMPETITIONS: 'Competiții',
    FORM_TEMPLATE_ADMINISTRATION: 'Formulare',
    INSPECTOR_DASHBOARD: 'Statistici globale',
    SELLER_EVENT_ADMINISTRATION: 'Calendar',
    TRAINER_EVENT_ADMINISTRATION: 'Activitate - trainer',
    KNOWLEDGE_BASE_BUILDER: 'Editor de cunoștințe',
    KNOWLEDGE_MAP: 'Hartă de cunoștințe',
    KNOWLEDGE_MAP_ASSET_BUILDER: 'Traduceri harta de cunoștințe',
    KNOWLEDGE_MAP_EDITOR: 'Editor harta de cunoștințe',
    LEARNING_ASSISTANT: 'Asistent',
    LEARNING_PROGRESS: 'Monitorizare elevi',
    LEARNING_STATS: 'Raport de învățare',
    LEARNING_STEP_STATS: 'Răspunsurile elevului: ',
    LOCATION_EDITOR: 'Locații',
    MAKERSPACE_EVENT_ADMINISTRATION: 'Activitate - makerspace',
    NX_FORMS_BUILDER: 'Formulare Nextlab',
    OWNED_PATH_BUILDER: 'Editor traiectorii personale',
    PARENT_DASHBOARD: 'Dashboard părinte',
    PATH_BUILDER: 'Editor traiectorii (admin)',
    PRIMARY_DASHBOARD: 'Dashboard',
    PRIVATE_LIBRARY: 'Bibliotecă privată',
    PROFILE_EDITOR: 'Profil',
    SELLER_COMMUNITY_ADMINISTRATION: 'Comunităţi virtuale',
    STUDENT_DASHBOARD: 'Bibliotecă',
    TAG_DEFINITION_EDITOR: 'Taguri',
    TEACHER_DASHBOARD: 'Elevi',
    TEACHER_PATH_EDITOR: 'Editor de traiectorii',
    TEST_STATS: 'Raport teste',
    TRAINER_DASHBOARD: 'Dashboard trainer',
    ROBOHACKATON_USERS: 'Înscrieri 2021',
    SIMPLE_KNOWLEDGE_BASE_BUILDER: 'Cunoștințe',
    SCAFFOLD: '?!?',
    TEST_LIST_TEACHERS: 'Teste',
    TEST_LIST_STUDENTS: 'Rezultatele testelor',
    USER_EDITOR: 'Utilizatori',
    GLOBAL_UNHANDLED_QUESTIONS: 'Întrebări fără răspuns'
  },
  TAG_DEFINITION_LIST: {
    DOMAIN_TAG_VISIBILITY_IN_LANDING: 'Eticheta va fi vizibilă în pagina principală',
    DETAILS_TITLE: 'Detalii tag',
    TAG_CATEGORY: 'Categorie',
    TAG_CATEGORY_AGE: 'Vârstă',
    TAG_CATEGORY_DOMAIN: 'Domeniu',
    TAG_CATEGORY_LEVEL: 'Nivel',
    TAG_CATEGORY_OTHER: 'Alta',
    TAG_CONFIRM_DELETE: 'Confirmă ștergerea etichetei',
    TAG_DESCRIPTION: 'Descriere',
    TAG_FACETS: 'Aplicabil fațetelor',
    TAG_MODELS: 'Definiție',
    TAG_NAME: 'Numele etichetei',
    TAG_PRIORITY: 'Prioritate',
    TAG_TYPE: 'Tip'
  },
  TEACHER_DASHBOARD: {
    CHOOSE_FLOW: 'Alege fluxul de învăţare',
    CHOOSE_PATH: 'Alege o traiectorie de învăţare parcursă',
    DONT_UNDERSTAND: 'Ioana nu a înțeles',
    FILTER_PLACEHOLDER: 'Caută după utilizator',
    FLOWS_COUNT: 'Fluxuri de învățare',
    FUTURE_EVENTS: 'Activitățile următoare',
    INTENT: 'Intenția identificată',
    INTRO_ONE: 'Aici poți crea lecții proprii. Acestea pot fi atribuite unor activități de învățare. Implicit lecțiile nu sunt vizibile în biblioteca de pe platforma de învățare. Pentru a fi publicate în bibliotecă, traiectoriile trebuie aprobate de un administrator.',
    INTRO_TWO: 'Poți solicita acest lucru în secțiunea de suport: ',
    LAST_UPDATE: 'Ultima actualizare',
    NO_ASSOCIATED_STUDENTS_ONE: 'Nu există elevi asociați.',
    NO_ASSOCIATED_STUDENTS_TWO: 'Solicită elevilor să se asocieze contului tău folosind numele de utilizator.',
    NO_FUTURE_EVENTS: 'Nu există activități programate în perioada următoare',
    NO_PATHS: 'Nu există nicio traiectorie de învățare proprie',
    PATH: 'Traiectoria de învăţare',
    TEACHER_PATHS: 'Traiectorii personale',
    TESTS_TAKEN: 'Teste susținute'
  },
  TEACHER_LIST: {
    DETAILS_TITLE: 'Detalii despre profesor',
    FILTER_PLACEHOLDER: 'Filtrează',
    NO_PASSWORD: 'Lasați câmpul necompletat pentru a păstra parola',
    USER_EMAIL: 'Email',
    USER_FULLNAME: 'Nume',
    USER_NAME: 'Utilizator',
    USER_PASSWORD: 'Parola',
    USER_PHONE: 'Telefon'
  },
  TEST_LIST_TEACHERS: {
    ACCESS_TOKEN: 'Codul întâlnirii',
    ADJUST_FILTERS_OR_MAKE_TEST:
      'Creați un test folosind butonul albastru "Creează un test nou"!',
    ADVANCED_SEARCH: 'Căutare avansată',
    ALL_TESTS: 'Toate lucrările',
    ANSWERS: 'Răspunsurile',
    ANSWER: 'Răspuns',
    ANSWER_GIVEN: 'Răspunsul dat',
    ANSWER_CONSIDERED: 'Răspunsul este considerat',
    ANSWER_CONSIDERED_CORECT: 'Răspunsul este considerat : Corect',
    ANSWER_CONSIDERED_INCORECT: 'Răspunsul este considerat : Incorect',
    ANSWER_PICKED: 'Răspuns ales:',
    ARCHIVED: 'Arhivat',
    AUTOSAVE_NOTICE: 'Modificările efectuate se salvează automat!',
    BACK_TO_EVENT_LIST: 'Înapoi',
    CANNOT_GRADE_FINAL_SUMMARY:
      'Pentru a putea modifica lucrarea schimbați starea ei în "necorectată"',
    CANNOT_GRADE_DELETED_SUMMARY:
      'Pentru a putea modifica lucrarea schimbați starea ei în "necorectată"',
    CANNOT_GRADE_PUBLISHED_SUMMARY:
      'Lucrările publicate nu mai pot fi modificate.',
    CLASSIFIER: 'Clasificator',
    CLASSIFIER_LOADING: 'Se încarcă clasificatorul',
    CLASSIFIER_PREVIEW_SAMPLE: 'Exemplu',
    CLASSIFIER_PREVIEW_CLASS: 'Categorie',
    CLASSIFIER_TRAINING_SET: 'Setul de antrenare al clasificatorului',
    CONFIDENCE_LEVEL: 'cu o precizie de: ',
    COORDINATOR: 'Coordonator',
    CORRECT: 'Corect',
    CORRECTED_TESTS: 'Lucrări corectate',
    CURRENT_POINTS: 'Punctaj curent',
    DATE_ANSWERED: ' Răspuns dat în data de: ',
    DATE_EDIT_ANSWER: 'Răspunsul a fost editat ultima dată pe : ',
    DELETE: 'Ștergere',
    DELETED_TESTS: 'Lucrări șterse',
    DETAILS: 'Detalii',
    DETAILS_LAST_EDIT: 'Ultima editare a punctajul a avut loc la data de :',
    DISABLED_UNTIL_TEST_BEGINS:
      'Puteți monitoriza progresul dupa inceperea testului',
    DISABLED_UNTIL_TEST_FINISHED:
      'Puteți vizualiza rezultatele dupa finalizarea testului',
    DISABLED_UNTIL_TEST_GRADED:
      'Puteți vizualiza rezultatele dupa corectarea si publicarea testului',
    EVENT_ACCESS_TOKEN: 'Codul evenimentului',
    EVENT_DURATION: 'Durata testului',
    EVENT_DURATION_FILTER_PLACEHOLDER: 'Căutare durată test',
    EVENT_FILTER_PLACEHOLDER: 'Căutare cod întâlnire',
    EVENT_INSTANCE_FILTER: 'Teste',
    EVENT_NAME: 'Numele testului',
    EVENT_NAME_FILTER_PLACEHOLDER: 'Căutare nume test',
    EVENT_START_DATE: 'Data de început',
    EVENT_START_DATE_FILTER_PLACEHOLDER: 'Căutare dată începere',
    EXCEL_EXPORT_ALL: 'Exportă toate rapoartele',
    EXCEL_EXPORT_SELECTED: 'Exportă rapoarte selecții',
    EXPORT_FIELD_CITY: 'Oraş',
    EXPORT_FIELD_CLASS: 'Clasă',
    EXPORT_FIELD_COUNTY: 'Judeţ',
    EXPORT_FIELD_EMAIL: 'Email',
    EXPORT_FIELD_FULLNAME: 'Nume',
    EXPORT_FIELD_EDITEDSCORE: 'Puncte obținute',
    EXPORT_FIELD_SCHOOL: 'Şcoală',
    EXPORT_FIELD_USERNAME: 'Utilizator',
    EXPORT_SELECTED: 'Exportă utilizatorii selectați',
    EXPORT_TESTS: 'Se exportă din: ',
    FILTER_PLACEHOLDER: 'Numele elevului',
    FINALIZED: 'Finalizat',
    FOR_THE_TEST: 'pentru: ',
    FORM: 'Formular',
    FORM_TYPE: 'Tipul formularului',
    FOUND: 'Au fost găsite',
    GENERATE_SUMMARIES: 'Precorectare',
    GENERATE_SUMMARIES_CONFIRMATION:
      'Precorectarea automată poate dura mai multe minute, în funcție de numărul de lucrări si complexitatea lor',
    GENERATE_SUMMARIES_CONFIRMATION_2: 'Porniți precorectarea',
    GENERATION_ERROR: 'Recorectează',
    GENERATION_ERROR_CONFIRMATION:
      'A apărut o eroare în cadrul corectării automate a testelor, puteți reîncerca folosind butonul de mai jos',
    GENERATION_ERROR_CONFIRMATION_2:
      'Dacă eroarea persistă va rugăm sa contactați echipa de suport Nextlab (email: suport@nextlab.tech)',
    GO_TO: 'Selectează pagina',
    GO_TO_TOOLTIP: 'Apasă Enter pentru a selecta pagina',
    GRADING_PERMISSIONS: {
      ADD_NEW_GRADING_PERMISSION: 'Adaugă o permisiune de corectare',
      ANSWERS_TO_GRADE: 'Răspunsuri de corectat',
      CONFIRM_REVOKE: 'Confirmă revocarea către',
      END_INDEX: 'Indexul răspunsului de sfârșit',
      GRADING_ALLOCATION_DYNAMIC_FORMS: 'Alocă corectarea răspunsurilor de la formularele dinamice',
      GRADED_BY: 'Autorul ultimei modificări asupra corectării',
      GRANTED_BY: 'Partajat de',
      GRANTED_DATE: 'Data partajării',
      GRANT_PERMISSION: 'Alocă corectarea',
      INDEX_INTERVAL: 'Intervalul răspunsurilor de corectat',
      MAX_INDEX: 'Max',
      MAX_INDEX_EXPLANATION: 'Maximul nu poate depăși numărul de formulare de corectat',
      MIN_INDEX: 'Min',
      MIN_INDEX_EXPLANATION: 'Minim 1, iar indexul de sfârșit trebuie să fie mai mare sau egal cu indexul de start',
      NO_ANSWERS_TO_GRADE: 'Nu există răspunsuri de corectat',
      NO_DYNAMIC_FORM_QUESTIONS: 'Nu există întrebări de tip formular dinamic ce pot fi alocate pentru corectat',
      NO_GRANTED_GRADING_PERMISSIONS: 'Nu există încă permisiuni de corectare alocate',
      NO_SCORING_METAS: 'Nu se poate partaja corectarea, întrucât nu există formulare completate corespunzătoare acestei întrebări',
      NOT_GRADED: 'Automat',
      SCORING_META_COUNT: 'Numărul de răspunsuri ce pot fi corectate',
      SELECT_DYNAMIC_FORM_QUESTION: 'Selectează întrebarea tip formular dinamic a cărei corectare dorești să o partajezi',
      SHARED_GRADING: 'Corectare distribuită',
      SHARE_GRADING_PERMISSION: 'Partajează dreptul de corectare',
      START_GRADING: 'Corectează',
      START_INDEX: 'Indexul răspunsului de start',
      TEACHER_TO_WHOM_PERMISSION_IS_GRANTED: 'Profesorul către care s-a alocat corectarea'
    },
    GRADING_IN_PROGRESS: 'Se generează...',
    GRADING_IN_PROGRESS_EXPLANATION:
      'Lucrările sunt în curs de precorectare automată',
    GRADING_IN_PROGRESS_EXPLANATION_2: 'Vă rugăm așteptați',
    GRADING_IN_PROGRESS_ERROR: 'Doriți să resetați procesul de precorectare',
    GRADE_TEST: 'Corectează',
    HIDE_SIMILAR_ANSWER: 'Ascunde răspunsuri similare',
    IDENTIFIED_ANSWER_CLASS: 'Categoria identificată',
    IDENTIFIED_ANSWER_CLASS_STUDENT:
      'Răspunsul tău a fost clasificat si încadrat ca',
    INCORRECT: 'Incorect',
    INITIAL_POINTS: 'Punctaj inițial',
    INTERVAL_START: 'Începutul intervalului',
    INTERVAL_END: 'Sfârșitul intervalului',
    INTERVAL_START_DATE: 'De la',
    INTERVAL_END_DATE: 'Până la',
    INTRO_MESSAGE_ONE:
      'Te rugăm să te asociezi cu un profesor/profesorii cu care lucrezi. Poți face asta în secțiunea ',
    INTRO_MESSAGE_TWO:
      ', specificând numele de utilizator al cadrului didactic. În cazul în care lucrezi individual, această secțiune poate rămâne necompletată.',
    IS_GRADING_SIMPLIFIED: 'Corectare simplificată',
    LABEL: 'Etichetă',
    IS_ACCESSED: 'Accesat',
    IS_PUBLISHED: 'Este publicat',
    LAUNCH_A_TEST: 'Creează un test nou',
    LINK_VIDEO: 'Link către video : ',
    LIST_OF_DELETED_TESTS: 'Lista lucrărilor șterse',
    LIST_OF_FINISHED_TESTS: 'Lista lucrărilor corectate',
    LIST_OF_UNFINISHED_TESTS: 'Lista lucrărilor necorectate',
    LOADING: 'Se încarcă...',
    MARK_AS_GRADED: 'Finalizează corectarea',
    MAXIMUM_POINTS: 'Punctaj maxim',
    NAME_AND_SURNAME: 'Nume și prenume',
    MODEL_ANSWER: 'Răspunsul așteptat',
    MODEL_ANSWER_UNDEFINED: 'Nedefinit',
    MODIFY_VERDICT: 'Modifică',
    NATURAL_LANGUAGE: 'Limbaj natural',
    NATURAL_LANGUAGE_ANSWER_CLASS: 'Categorie',
    NATURAL_LANGUAGE_ANSWERS: 'Răspunsuri',
    NEXTLAB_VERDICT: 'Verdict final cu adnotări proprii',
    NO_ANSWER_TO_QUESTION: 'La acestă întrebare nu s-a răspuns',
    NO_EVENTS_TO_SHOW: 'Nu au fost găsite teste.',
    NO_FORM_ANSWER: 'La acest pas, care folosește formulare, nu s-a răspuns',
    NO_OBSERVATIONS: 'Fără observații',
    NO_PARTICIPANTS: 'Număr participanți',
    NO_PARTICIPANTS_FILTER_PLACEHOLDER: 'Căutare număr',
    NO_TESTS_TO_SHOW: 'Nu există rezultate pentru această întâlnire.',
    NO_TESTS: 'Nu există lucrări',
    NOT_CORRECTED_TESTS: 'Lucrări necorectate',
    NUMBER_TESTS_UNFINALIZED: 'Număr lucrări necorectate',
    NUMBER_TESTS_FINALIZED: 'Număr lucrări corectate',
    NUMBER_TESTS_DELETED: 'Număr lucrări șterse',
    OER: 'Resursă educațională',
    OBSERVATIONS: 'Observații :',
    OBTAINED_POINTS: 'Punctaj obținut',
    PARTIAL_CORRECT: 'Parțial corect',
    POINTS: 'Punctaj',
    POINTS_DONT_INCLUDE_FORMS:
      'punctajul nu include intrebarile de tip formular',
    PRINTABLE_TEST_SUMMARY: {
      SUMMARY_HEADER: 'Raportul de test NEXTLAB.TECH',
      STRUCTURE_HEADER: 'Structură',
      STRUCTURE_INFO_FIRST: 'Raportul de test pentru elevul',
      STRUCTURE_INFO_SECOND: 'reflectă rezultatele acestuia la testul',
      STRUCTURE_INFO_THIRD: 'din cadrul evenimentului',
      STRUCTURE_INFO_FORTH: 'din data de',
      STRUCURE_INFO_COMPONENTS: 'Raportul include următoarele secțiuni',
      STRUCURE_INFO_COMPONENTS_FIRST: 'Scopul raportului',
      STRUCURE_INFO_COMPONENTS_SECOND: 'Fundamentul testului',
      STRUCURE_INFO_COMPONENTS_THIRD: 'Testrax',
      STRUCURE_INFO_COMPONENTS_FORTH: 'Precorectarea răspunsurilor bazate pe clasificarea lor de algoritmi AI',
      STRUCURE_INFO_COMPONENTS_INFO: 'Raportul conține răspunsurile elevului la fiecare întrebare dintr-un flux și afișează corectitudinea fiecărui răspuns.',
      SCOPE_HEADER: 'Scopul raportului',
      SCOPE_INFO_FIRST: 'Prin raportul de test se poate vedea o descriere amănunțită a performanței unui elev la fiecare dintre întrebările care compun testul.',
      SCOPE_INFO_SECOND: 'Întrebările care pot fi incluse într-un test aparțin uneia din următoarele 4 categorii',
      SCOPE_INFO_THIRD: 'Întrebări grilă cu răspuns corect unic',
      SCOPE_INFO_FORTH: 'Întrebări grilă cu răspunsuri corecte multiple',
      SCOPE_INFO_FIFTH: 'Întrebări cu răspuns în limbaj natural',
      SCOPE_INFO_SIXTH: 'Formulare dinamice',
      FOUNDATION_HEADER: 'Fundamentul testului',
      FOUNDATION_INFO_FIRST: 'Conținutul testului este rezultatul aplicării expertizei autorului acestuia. Testul a fost administrat și corectat de creatorul evenimentului educațional în cadrul căruia a fost rulat. Susținerea testului a fost făcută cu suportul unui asistent de învățare specializat, numit Testrax.',
      FOUNDATION_INFO_SECOND: 'Pentru întrebările de tip  grilă cu răspuns corect unic și grilă cu răspunsuri corecte multiple corectarea s-a făcut de către platforma Nextlab.tech.',
      FOUNDATION_INFO_THIRD: 'Pentru întrebările cu răspuns în limbaj natural, corectarea s-a făcut cu ajutorul unor clasificatori dinamici antrenați pe bază motorului de corectare inclus în platforma Nextlab.tech.',
      FOUNDATION_INFO_FORTH: 'Întrebările care conțin formulare dinamice nu sunt corectate automat, ci sunt destinate să ofere organizatorului testului flexibilitatea de a corecta orice alt tip de răspuns dat de elev.',
      TESTRAX_HEADER: 'Testrax',
      TESTRAX_INFO_FIRST: 'Testrax este un asistent de învățare construit pentru a permite administrarea de teste unui număr mare de elevi. Testrax permite atât utilizarea unor întrebări clasice, cu răspunsuri corecte și greșite predefinite, cât și a unor întrebări care suportă răspunsuri în limbaj natural, la care elevul poate răspunde cu propriile cuvinte.',
      TESTRAX_INFO_SECOND: 'Utilizarea unor întrebări în limbaj natural are o serie de avantaje față de întrebările grilă, avantaje care derivă din mecanismul de precorectare a acestora, pe baza unor clasificatori antrenați dinamic.',
      AI_HEADER: 'Precorectarea răspunsurilor pe bază de AI',
      AI_INFO: 'Răspunsurile în limbaj natural date de elev sunt corectate cu ajutorul unor clasificatori pentru procesarea limbajului natural. Acești clasificatori sunt antrenați dinamic pe baza expertizei organizatorului testului. Atunci când acesta întâlnește un răspuns corect sau greșit, îl poate marca în interfața specializată de corectare a testelor. Clasificarea unui răspuns se aplică apoi tuturor elevilor participanți la test. Practic prin acest mecanism se asigură, pe lângă un proces de corectare mai facil, și omogenitatea procesului de corectare la nivelul participanților la test.',
      CONTENT_HEADER: 'Conținutul raportului',
      STUDENT_INFO: 'Această secțiune conține statisticile legate de corectitudinea răspunsurilor date de elev precum și întrebările care compun testul și detalierea răspunsurilor date la fiecare întrebare.',
      TEST_STATISTICS_HEADER: 'Statisticile elevului',
      TEST_STATISTICS_INFO_FIRST: 'În tabelul de mai jos sunt prezentate totalurile răspunsurilor corecte/greșite date de elev pe tipuri de întrebare.',
      TEST_STATISTICS_INFO_SECOND: 'Întrebările grilă cu răspuns unic și cele cu răspuns multiplu sunt reprezentate agregat, pe o singură linie.',
      TEST_STATISTICS_INFO_THRID: 'Pentru întrebările grilă cu răspuns unic, coloana Corect indică numărul de întrebări pentru care a fost selectată doar varianta corectă, iar coloana Greșit  indică numărul de întrebări pentru care a fost selectată una dintre variantele greșite. Coloana  Parțial corect nu este aplicabilă întrebărilor de tip grilă cu răspuns unic.',
      TEST_STATISTICS_INFO_FOURTH: 'Pentru întrebările grilă cu răspuns multiplu, coloana Corect indică numărul de întrebări pentru care au fost selectate toate variantele corecte, coloana Greșit  indică numărul de întrebări pentru care a fost selectată una dintre variantele greșite, iar coloana Parțial corect indică numărul de întrebări pentru care au fost selectate o parte din răspunsurile corecte, dar niciun răspuns greșit.',
      TEST_STATISTICS_INFO_FIFTH: 'Pentru întrebările de tip formular, coloanele Corect/Parțial corect/Greșit indică numărul de întrebări pe care organizatorul testului le-a marcat manual ca făcând parte din categoria de corectitudine a răspunsului respectiv.',
      TEST_STATISTICS_INFO_SIXTH: 'Pentru întrebările cu răspuns în limbaj natural,  coloanele Corect/Parțial corect/Greșit indică numărul de întrebări pe care organizatorul testului le-a marcat manual ca făcând parte din categoria de corectitudine a răspunsului respectiv, pe baza precorectării inițiale făcute de clasificatorul dinamic asociat întrebării.',
      DETAILED_ANSWERS_HEADER: 'Vizualizarea detaliată a răspunsurilor',
      DETAILED_ANSWERS_INFO: 'În această secțiune se poate vedea fiecare întrebare cu răspunsul care a fost dat de elev, momentul în care elevul a răspuns la întrebare și informația legată de corectitudinea răspunsului.',
      CHART_CAPTION: 'Vizualizare pentru numărul de întrebări corecte/greșite din total',
      FORM_ANSWER: 'Pentru a vedea răspunsul descărcați fișierul de pe platformă',
      DYNAMIC_FORM_ANSWER: 'Răspuns formular',
      COVER_TITLE: 'Raportul de test pentru',
      RECOMMENDED_READING: 'Lecturi recomandate',
      RECOMMENDED_READING_INFO: 'Lista reprezintă o colecție de resurse educaționale complementare lecției parurse în cadrul testului. Pentru aprofundarea conceptelor regăsite în test, recomandăm lecturarea acestor resurse.',
      OER: 'Resursă educațională'
    },
    PUBLISH: 'Publică',
    PUBLISH_TEST_RESULTS: 'Publică rezultatele lucrărilor',
    QUESTION_DESCRIPTION: 'Descrierea întrebării',
    QUESTION_TYPE: 'Tip întrebare',
    QUESTION_TYPE_CHOICE: 'Grilă',
    QUESTION_TYPE_FORM: 'Formular',
    QUESTION_TYPE_NATURAL_LANGUAGE: 'Limbaj natural',
    QUESTIONS_REPORT: 'Raportul sumativ al răspunsurilor',
    QUESTIONS_RESULT: 'Evaluarea răspunsurilor',
    RECOMMENDED_READING: 'Lecturi recomandate',
    RECOMMENDED_READING_INFO: 'Lista reprezintă o colecție de resurse educaționale complementare lecției parurse în cadrul testului. Pentru aprofundarea conceptelor regăsite în test, recomandăm lecturarea acestor resurse.',
    REGENERATE_TEST_SUMMARIES: 'Recorectare automată',
    REGENERATE_WARNING_DIALOG_HEADER: 'Atenție!',
    REGENERATE_WARNING_DIALOG_CONTENT_FIRST:
      'Ștergerea corecturilor va duce la reclasificarea răspunsurilor din testul curent, folosind algoritmul de machine learning existent',
    REGENERATE_WARNING_DIALOG_CONTENT_SECOND:
      'Veți fi trimis pe pagina anterioară și veți putea reintra în această interfață dupa finalizarea recorectării',
    REGRADE_TESTS: 'Recorectare automată',
    RESET_CLASSIFIERS: 'Resetează clasificatorii',
    RESET_CLASSIFIERS_DIALOG_HEADER: 'Atenție!',
    RESET_CLASSIFIERS_DIALOG_WARNING:
      'Resetarea clasificatorilor îi va aduce la forma inițială, iar toate lucrările din testul curent vor fi recorectate',
    RESET_CLASSIFIERS_DIALOG_WARNING_2:
      'Veți fi trimis pe pagina anterioară și veți putea reintra în această interfață dupa finalizarea recorectării',
    RESPONSE_IS: 'Răspunsul este:',
    SAVE: 'Salvează',
    SAVE_AND_FINALIZE: 'Salvează și finalizează',
    SCORING_META_UPDATES: 'Istoricul modificărilor corectării',
    SCORING_META_UPDATES_CONFIDENCE_LEVEL: 'Cu precizie de',
    SCORING_META_UPDATES_ADD: 'Exemplu nou',
    SCORING_META_UPDATES_AUTHOR: 'Autorul corectării',
    SCORING_META_UPDATES_UPDATE: 'Exemplu existent',
    SCORING_META_UPDATES_HIDE: 'Ascunde modificările corectării',
    SCORING_META_UPDATES_PREVIOUS_POINTS: 'Punctaj inițial',
    SCORING_META_UPDATES_PREVIOUS_REQUIREMENTS: 'Barem inițial',
    SCORING_META_UPDATES_REQUIREMENTS: 'Barem final',
    SCORING_META_UPDATES_POINTS: 'Punctaj final',
    SCORING_META_UPDATES_REASON: 'Cauzat de clasificarea exemplului',
    SCORING_META_UPDATES_REASON_2: 'Cauzat de modificarea în cadrul contestației',
    CHALLENGED_SCORING_META_UPDATE: 'Modificat în cadrul contestației',
    SEARCH: 'Caută',
    SEE_SCORING_META_UPDATES: 'Vezi modificările corectării',
    SEE_SIMILAR_ANSWERS: 'Vezi răspunsuri similare',
    SELECT: 'Selectează',
    SELECT_EVENT: 'Selectează test',
    SELECT_EVENT_INSTANCE: 'Selecteaza o întâlnire',
    SELECT_EXPORT_HEADER: 'Selectați de mai jos câmpurile care vor fi incluse în export',
    SELECT_EXPORT_FIELDS: 'Câmpuri',
    SELECT_TEST: 'Selecteză testul pe care vrei să îl vizualizezi :',
    SELECT_TIME_INTERVAL: 'Intervalul de timp în care s-a desfășurat testul',
    SIMILAR_ANSWERS: 'Răspunsuri similare',
    SIMILAR_ANSWERS_LOWERCASE: 'răspunsuri similare',
    SIMILARITY: 'Similitudine',
    SHOW_ONLY_ASSOCIATED_STUDENTS: 'Afișează doar elevii asociați',
    STATUS_QUESTIONS: 'Status întrebări',
    STEP_DESCRIPTION: 'Descrierea pasului',
    STUDENT: 'Elev:',
    TAB_DESCRIPTION_FINISHED_TESTS: 'Aici pot fi vizualizate testele corectate',
    TAB_DESCRIPTION_UNFINISHED_TESTS:
      'Aici pot fi vizualizate testele necorectate',
    TABPANEL_BACK: 'Întoarcere la lista de întâlniri.',
    TABPANEL_DELETED_TESTS: 'Lucrări șterse',
    TABPANEL_FINALIZED_TESTS: 'Lucrări corectate',
    TABPANEL_NO_DELETED_TESTS:
      'Nu există lucrări șterse pentru această întâlnire',
    TABPANEL_UNFINALIZED_TESTS: 'Lucrări necorectate',
    TABPANEL_NO_UNFINALIZED_TESTS:
      'Nu există lucrări necorectate pentru această întâlnire',
    TABPANEL_NO_FINALIZED_TESTS:
      'Nu există lucrări corectate pentru această întâlnire',
    TEST_CHALLENGING: {
      ADD_FEEDBACK: 'Adaugă o explicație pentru soluționarea contestației',
      EMPTY_QUESTIONS: 'Nu există întrebări ce pot fi contestate',
      FINALIZE_TEST_TO_RESOLVE_THE_CHALLENGE: 'Contestația se va soluționa la finalizarea corectării. Ulterior, este necesară publicarea lucrării pentru ca studentul să poată vizualiza rezultatul.',
      FINAL_SCORE: 'Punctajul după contestație',
      INITIAL_SCORE: 'Punctajul înainte de contestație',
      INIT_TEST_CHALLENGING: 'Contestă rezultatul lucrării',
      INVALID_TEST_CHALLENGE_INTERVAL: 'Intervalul de timp al contestațiilor este invalid',
      IS_CHALLENGED: 'Este contestat',
      MIN_1_QUESTION: 'Selectează minim o întrebare',
      QUESTION_IS_CHALLENGED: 'Întrebarea este contestată',
      SELECT_QUESTIONS: 'Selectează întrebările care vor face obiectul contestației (minim 1)',
      STUDENT_FEEDBACK: 'Motivul contestației',
      TEACHER_FEEDBACK: 'Explicația soluționării contestației',
      TEST_CHALLENGE_END_DATE: 'Data de sfârșit a contestațiilor',
      TEST_CHALLENGE_IS_PENDING: 'Rezultatul testului este contestat. Contestația este în curs de soluționare. Numărul întrebărilor contestate: ',
      TEST_CHALLENGE_IS_RESOLVED: 'Contestația a fost soluționată',
      TEST_CHALLENGE_START_DATE: 'Data de început a contestațiilor',
      TEST_CHALLENGING_FEEDBACK: 'Descrie motivul contestației',
      TEST_CHALLENGING_SOLUTION_FEEDBACK: 'Descrie soluționarea contestației'
    },
    TEST_DATE: 'Data testului',
    TEST_EXPORT_FORMAT: 'Formatul exportului',
    TEST_EXPORT_FORMAT_EXCEL: 'Excel',
    TEST_EXPORT_FORMAT_JSON: 'JSON',
    TEST_FLOW: 'Fluxul de test',
    TEST_FLOW_FILTER_PLACEHOLDER: 'Căutare fluxul de test',
    TEST_LIST: 'Teste',
    TEST_LIST_DESCRIPTION: 'Aici poți să vezi rezultatele testelor organizate.',
    TEST_LIST_HEADER: 'Lista lucrărilor din testul: ',
    TEST_LIST_HEADER_ACCESS_TOKEN: 'Codul de acces al testului: ',
    TEST_LIST_HEADER_DATE: 'Data testului: ',
    TEST_LIST_HEADER_DURATION: 'Durata testului: ',
    TEST_LIST_HEADER_DURATION_MINUTES: 'minute',
    TEST_LIST_HEADER_FLOW: 'Fluxul testului: ',
    TEST_LIST_RECORRECT: 'Recorectare lucrări selectate',
    TEST_LIST_RESTAURATION: 'Restaurare lucrări selectate',
    TEST_LIST_SAVE_AND_FINALIZE: 'Finalizează lucrări selectate',
    TEST_LIST_STUDENTS_EXPLANATION:
      'Testele sunt corectate cu ajutorul unui algoritm de machine learning, rezultatele vor fi disponibile conform calendarului anunțat de coordonator.',
    TEST_NOT_PUBLISHABLE:
      'Lucrările nu pot fi publicate deoarece au mai rămas lucrări necorectate.',
    TEST_PUBLISHABLE:
      'Lucrările publicate sunt vizibile în mod individual pentru utilizatorii care au dat testul. Publicați lucrările?',
    TEST_UNPUBLISHABLE:
      'Lucrările retrase nu permit vizualizarea rezultatelor de către utilizatori. Retrageți lucrările?',
    TEST_RESULTS: 'Situație întrebări',
    TEST_STATUS: 'Starea testului',
    TEST_STATUS_DELETED: 'Șters',
    TEST_STATUS_FILTER_PLACEHOLDER: 'Căutare stare test',
    TEST_STATUS_FINISHED: 'Finalizat',
    TEST_STATUS_GRADING: 'În corectare',
    TEST_STATUS_GRADED: 'Corectat',
    TEST_STATUS_GRADED_ACTION: 'Vezi rezultatele',
    TEST_STATUS_SCHEDULED: 'Programat',
    TEST_STATUS_IN_PROGRESS: 'În derulare',
    TEST_STATISTICS: 'Statistici test',
    THE_STEP: 'Pasul',
    THE_QUESTION: 'Întrebarea',
    TOTAL: 'Total',
    TOTAL_CORECT_ANSWERS: 'Total grile corecte',
    TOTAL_FORMS_SUBMITTED: 'Total formulare completate',
    TOTAL_INCORECT_ANSWERS: 'Total grile incorecte',
    TOTAL_NATURAL_LANGUAGE_QUESTIONS: 'Întrebari cu răspuns în limbaj natural',
    TOTAL_PARTIAL_ANSWERS: 'Total grile parțial corecte',
    TOTAL_POINTS_EARNED: 'Total puncte obținute : ',
    TOTAL_POINTS: 'Total puncte :',
    TOTAL_POINTS_AUTOMATED_GRADING: 'Punctaj din corectare automată',
    TOTAL_POINTS_MODIFIED: 'Punctaj final',
    TOTAL_STEPS_TEST: 'Total pași : ',
    TOTAL_QUESTIONS_TEST: 'Întrebări',
    TOTAL_UNASWERED_QUESTIONS:
      'Total întrebări la care nu s-a înregistrat nici un răspuns : ',
    USER: 'Utilizator',
    UNDEFINED: 'Nedefinit',
    UNDELETE: 'Restaurare',
    UNPUBLISH: 'Retrage',
    UNPUBLISH_TEST_RESULTS: 'Retrage rezultatele lucrărilor',
    USERNAME: 'Utilizator',
    VERIFYING: 'Se verifică...',
    VIEW: 'Vizualizează',
    VIEW_TEST: 'Vezi testul'
  },
  USER_ANNOUNCEMENTS: {
    HEADER: 'Anunțuri',
    NO_ANNOUNCEMENTS: 'Nu există anunțuri publice',
    COMPLET_ANNOUNCEMENT: 'Vezi anunțul complet',
    ALL_ANNOUNCEMENTS: 'Vezi toate anunțurile'
  },
  USER_LIST: {
    ALL_MEMBERS: 'Toți membrii',
    ASSOCIATED_STUDENTS: 'Elevi asociaţi',
    ASSOCIATED_TEACHERS: 'Profesori asociaţi',
    ASSOCIATED_USERS: 'Utilizatori asociaţi',
    ASSOCIATE_TEACHERS_EXPLANATION: 'Asociază-te cu profesorii tăi căutându-i în selectorul de mai jos.',
    ASSOCIATE_TEACHERS_PLACEHOLDER: 'Nume de utilizator',
    ASSOCIATION_SUCCESSFUL: 'Asociere efectuată cu succes!',
    BILLING_MAKERSPACE: 'Makerspace original',
    COMPETENCY_MAP: 'Competențe',
    CONFIRM_DELETE: 'Confirmă ștergerea utilizatorului',
    CONVERSATION_HISTORY: 'Istoric conversaţii',
    CURRENT_MAKERSPACE: 'Makerspace curent',
    DEASSOCIATION_SUCCESSFUL: 'Profesorul a fost dezasociat!',
    DELETE_ASSOCIATION_CONFIRMATION_DESCRIPTION: 'Confirmă dezasocierea de profesorul',
    DETAILS_TITLE: 'Detalii despre student',
    EMAIL_FILTER_PLACEHOLDER: 'Email căutat',
    EMPTY_USER_LIST: 'Nu există utilizatori',
    FACETS: 'Fațete',
    FACET_FILTER_PLACEHOLDER: 'Fațeta căutată',
    FILTER_PLACEHOLDER: 'Căutare după numele de utilizator',
    FULLNAME_FILTER_PLACEHOLDER: 'Nume căutat',
    GROUP: 'Grupa',
    GROUPS: 'Comunități',
    IMPORT_EXCEL_SAMPLE: 'Descarcă un exemplu',
    KNOWLEDGE_MAP: 'Hartă de cunoştinţe',
    LEARNING_REPORT: 'Raport de învăţare',
    MAKERSPACES: 'Makerspace-uri',
    MEMBERS_COUNT: 'Număr total de membri',
    MEMBERS_NO_DATA: 'Nu există membri',
    NO_ASSOCIATED_TEACHERS: 'Momentan nu ești asociat cu niciun profesor!',
    NO_PASSWORD: 'Lasați câmpul necompletat pentru a păstra parola',
    PAYMENT_TYPE: 'Tip abonament',
    PHONE_FILTER_PLACEHOLDER: 'Telefonul căutat',
    TEST_STATUS: 'Status susținere teste',
    TYPE: 'Tip',
    USERNAME_FILTER_PLACEHOLDER: 'Utilizator căutat',
    USER_CITY: 'Oraş',
    USER_CITY_FILTER_PLACEHOLDER: 'Caută orașul',
    USER_CLASS: 'Clasa declarată',
    USER_COUNTRY: 'Tară',
    USER_COUNTY: 'Judeţ',
    USER_COUNTY_FILTER_PLACEHOLDER: 'Caută judeţul',
    USER_CURRENT_STEP: 'Pasul curent',
    USER_EMAIL: 'Email',
    USER_FACETS: 'Alege fațete',
    USER_FULLNAME: 'Nume',
    USER_LEARNING_STATS: 'Raport de învățare',
    USER_NAME: 'Utilizator',
    USER_PARENT: 'Asociază părinţi',
    USER_PASSWORD: 'Parola',
    USER_PASSWORD_CONFIRMATION: 'Confirmarea parolei',
    USER_PASSWORD_CONFIRMATION_VALIDATION: 'Parolele trebuie să fie identice',
    USER_PHONE: 'Telefon',
    USER_ROLE: 'Rol',
    USER_SCHOOL: 'Şcoală',
    USER_SCHOOL_FILTER_PLACEHOLDER: 'Caută școala',
    USER_STATUS: 'Status',
    USER_STEPS_TAKEN: 'Pași parcurși',
    USER_TEACHER: 'Asocieri profesori',
    USER_USERNAME: 'Utilizator',
    USER_USERNAME_FILTER_PLACEHOLDER: 'Utilizator căutat',
    WALLET: 'Portofel electronic',
    WALLET_BALANCE: 'Puncte',
    WALLET_EXPIRY: 'Data de expirare'
  },
  WORKING_GROUP_LIST: {
    ACTIVATE_FLOW: 'Activează un flux',
    ADD_FLOW_TO_GROUP: 'Adaugă un flux la grupă',
    DESCRIPTION: 'Descriere',
    CLASSROOM: 'Locație',
    GROUP: 'Grupa',
    DETAILS_TITLE: 'Detalii despre grupă',
    SELECT_FLOW: 'Selectează un flux',
    SELECT_PATH: 'Selectează o traiectorie',
    IMPORT_STUDENTS: 'Importă studenți'
  },
  PROFILE_LIST: {
    ACCOUNT_DETAILS: 'Detalii cont',
    ADVANCED_SETTINGS: 'Setări avansate',
    ASSOCIATIONS: 'Asocieri Profesori/Părinţi',
    COMMUNITIES_ASSOCIATIONS: 'Asocieri comunități',
    COMMUNITIES_ASSOCIATIONS_DESCRIPTION: 'În tabelul de mai jos poți vedea din ce comunități faci parte:',
    COMMUNITIES_ASSOCIATIONS_TITLE: 'Comunitățile tale',
    COMMUNITY_DESCRIPTION: 'Descriere comunitate',
    COMMUNITY_LINK: 'Link comunitate',
    COMMUNITY_NAME: 'Nume comunitate',
    COMMUNITY_OWNER_NAME: 'Profesor',
    COMMUNITY_TIMESTAMP_ADDED: 'Dată adăugare',
    MODIFY_PASSWORD: 'Modificare parolă',
    MODIFY_SUCCESS_GROWL: 'Modificat!',
    NO_COMMUNITY: 'Aici vei vedea lista de comunități din care faci parte după ce vei fi adăugat de profesorii tăi.',
    NO_REPORTS: 'În versiunea gratuită a aplicației raportările automate sunt inactive',
    PARENT: 'parent',
    PASSWORD_CHANGED_SUCCESS: 'Parolă schimbată',
    PASSWORD_CHANGE_CONFIRMATION: 'Ești sigur că vrei să îți schimbi parola?',
    PASSWORD_CONFIRMATION: 'Confirmă parola',
    PASSWORD_CURRENT: 'Parola curentă',
    PASSWORD_LENGTH_ERROR: 'Parola este prea scurtă!',
    PASSWORD_MATCH_ERROR: 'Parolele trebuie să fie egale',
    RELOAD_APP: 'Actualizare aplicație',
    REPORTS_FREQUENCY: 'Frecvenţă rapoarte',
    STUDENT: 'student',
    STUDENT_ASSOCIATIONS: 'Asocieri Elevi',
    STUDENT_ASSOCIATIONS_NO_USERS_ASSOCIATED: 'Nu ai niciun elev asociat. Asociază-te cu elevii tăi folosind numele lor de utilizator si adresa lor de email. Alternativ le poți cere acestora sa se asocieze cu tine folosind numele tău de utilizator.',
    TEACHER: 'teacher',
    TWO_FACTOR_AUTHENTICATION: {
      ACTIVATE_TWO_FACTOR_AUTHENTICATION: 'Activează autentificarea în doi pași',
      ACTIVE: 'Activată',
      DEACTIVATE_TWO_FACTOR_AUTHENTICATION: 'Dezactivează autentificarea în doi pași',
      INACTIVE: 'Dezactivată',
      TWO_FACTOR_AUTHENTICATION_MANAGER_INSTRUCTIONS: 'Pentru a activa sau dezactiva autentificarea în doi pași dați click pe butonul de mai jos',
      TWO_FACTOR_AUTHENTICATION_STATUS: 'Autentificarea în doi pași este'
    },
    TWO_FACTOR_AUTHENTICATION_MANAGER: 'Autentificare în doi pași',
    TWO_FACTOR_QR_CODE_DISPLAY: {
      ACTIVATION_DIALOG_HEADER: 'Activează autentificarea în doi pași',
      ACTIVATION_INFO_FIRST: 'Pentru a activa autentificarea în doi pași urmăriți cu atenție instrucțiunile de mai jos:',
      ACTIVATION_INFO_SECOND: 'Pentru generarea codului de acces vei avea nevoie de aplicația Microsoft Authenticator ',
      ACTIVATION_INFO_THIRD: 'Aplicația va genera câte un cod nou la fiecare 30 de secunde, timp în care codul este valabil pentru accesarea platformei.',
      AND: 'și',
      AVAILABLE_ON: 'disponibilă pe',
      DEACTIVATION_DIALOG_HEADER: 'Dezactivează autentificarea în doi pași',
      DEACTIVATION_INFO: 'Pentru a confirma dezactivarea autentificării în doi pași este necesară parola contului și codul generat de aplicația de autentificare folosită',
      CONFIRM_TWO_FACTOR_AUTHENTICATION_ACTIVATION: 'Confirmă activarea autentificării în doi pași',
      CONFIRM_TWO_FACTOR_AUTHENTICATION_DEACTIVATION: 'Confirmă dezactivarea autentificării în doi pași',
      CONFIRM_BUTTON: 'Confirmă',
      GENERATED_CODE: 'Codul generat de aplicație',
      PASSWORD: 'Parola',
      STEP_LIST_HEADER: 'Utilizarea aplicației Microsoft Authenticator:',
      STEP_LIST_FIRST: 'Vom naviga la secțiunea "Verified Ids"',
      STEP_LIST_SECOND: 'Vom apăsa pe butonul "Scan QR code"',
      STEP_LIST_THIRD: 'Utilizând camera telefonului vom scana codul QR, iar aplicația va genera codul de acces',
      STEP_LIST_FORTH: 'Pentru a confirma configurarea corectă a aplicației de autentificare și a activa autentificarea în doi pași vă rugăm sa introduceți codul generat de aplicație în căsuța de mai jos și să apăsați butonul',
      STEP_LIST_FIFTH: 'Atenție la valabilitatea codului, acesta este valabil doar 30 de secunde de la generare!'
    }
  },
  LEARNING_PROGRESS: {
    ANSWER_COPIED: 'Răspunsul a fost copiat',
    COMPETENCIES_EVALUATION: {
      COMPETENCIES_EVALUATION: 'Evaluarea competențelor',
      COMPETENCY_EXPLANATION: 'Puteți valida răspunsurile elevului pentru a marca o competență ca fiind dobândită.',
      REGENERATE_EXPLANATION: 'Dacă ați adăugat competențe la flux după ce elevii au început parcurgerea acestuia, va trebui să apăsați butonul "Generează elemente de competență" pentru a putea valida competențele acestora.',
      CREATE_MISSING_COMPETENCY_ELEMENTS: 'Generează elemente de competență',
      CREATE_MISSING_COMPETENCY_ELEMENTS_TOOLTIP: 'Apăsați aici pentru a actualiza structura tabelului.',
      HAS_COMPETENCY: 'A dobândit competența',
      MARK_COMPETENCY: 'Confirmă competența',
      NO_COMPETENCIES_IN_FLOW: 'Acest flux nu are întrebări care evaluează competențe',
      NO_COMPETENCIES_IN_GROUP: 'Pentru programa  selectată, nu există competențe de evaluat în această comunitate ',
      NO_DATA_TO_SHOW: 'Nu există date de afișat',
      NO_PREVIEW: 'Răspunsul nu poate fi previzualizat',
      SEE_ACTIVITY: 'Vezi activitatea elevului'
    },
    INTERVALS: {
      TODAY: 'Astăzi',
      LAST_7_DAYS: 'Ultimele 7 zile',
      LAST_30_DAYS: 'Ultimele 30 zile',
      LAST_90_DAYS: 'Ultimele 3 luni',
      LAST_180_DAYS: 'Ultimele 6 luni',
      LAST_365_DAYS: 'Ultimul an',
      CUSTOM: 'Alege un interval'
    },
    EVENT: 'Activitate',
    FLOW: 'Flux',
    DELAY: 'Întârziere',
    DELAYED: 'Întârziat',
    DATE: 'Data',
    DURATION: 'Durata',
    START_HOUR: 'Ora început',
    SEARCH_EVENT: 'Accesează direct activitatea folosind codul sau link-ul activității',
    SEARCH_EVENT_SUBMIT: 'Accesează',
    SEARCH_INPUT_PLACEHOLDER: 'Codul sau link-ul activității',
    VIEW_RECENT_EVENTS: 'Vizualizează activitățile recente',
    MONITORIZATION: 'Monitorizare',
    NOT_STARTED: 'Neînceput',
    RESTARTED: 'Reînceput',
    STARTED: 'În curs',
    FINISHED: 'Parcurs',
    CONFIRM_DELETE_PARTICIPANT: 'Confirmă ștergerea participantului',
    DELETE_CONFIRMATION_YES: 'Da',
    DELETE_CONFIRMATION_NO: 'Nu',
    UNANSWERED_QUESTIONS: {
      ADD: 'Adaugă',
      ADD_ANOMALY_TO_INTENT: 'Adăugați ca exemplu de antrenare pentru',
      ADD_ANOMALY_TO_INTENT_HEADER: 'Adăugați ca exemplu de antrenare',
      ADD_QUESTION: 'Adaugă',
      ADD_NEW_QUESTION: 'Adaugă întrebare nouă',
      ADVANCED_INTERFACE: 'Interfață avansată',
      ASISTANT: 'Asistent',
      ASSISTANT_ANSWER: 'Răspunsul asistentului corectat',
      ASSISTANT_ANSWER_PLACEHOLDER: 'Adaugă răspunsul asistentului corectat',
      ASSISTANT_RESPONSE: 'Răspunsul asistentului',
      ASSISTANT_WILL_REPLY_TO: 'Asistentul va răspunde la',
      COPY_RESPONSE: 'Copiază răspunsul asistentului',
      CURRENT_EVENT_HEADER: 'Lista întrebărilor de la evenimentul curent la care asistentul nu a știut să răspundă',
      CURRENT_EVENT_INDICATION: 'Evenimentul curent',
      DEFAULT_SELECTED_ADDITIONAL_KNOWLEDGEBASE: 'Baza de cunoștințe selectată (prima bază de cunoștințe adițională atașată fluxului) implicit pentru a adăuga răspunsurile este',
      EDIT_RESPONSE: 'Adaugă ca exemplu de antrenare la o întrebare existentă',
      EXAMPLES: 'Exemple',
      EVENT_INSTANCE: 'Codul evenimentului',
      FLOW_DESCRIPTION: 'Flux',
      FLOW_REPORT_UNANSWERED_QUESTIONS: 'Întrebări la care asistentul nu a  răspuns',
      GENERATE_ANSWER: 'Generează răspuns',
      GENERATED_BY: 'Generat de..',
      GLOBAL_UNHANDLED_QUESTIONS_INTENT_DIALOG_HEADER: 'Adaugă întrebare nouă',
      GLOBAL_UNHANDLED_QUESTIONS_INTENT_DIALOG_EDITOR_MANUAL: 'Editare manuală',
      GLOBAL_UNHANDLED_QUESTIONS_INTENT_DIALOG_EDITOR_GPT: 'Generare cu ChatGPT',
      HANDLED_BY: 'Rezolvată de..',
      INTENT_NAME: 'Nume',
      INTENT_ANSWER: 'Răspunsul asistentului',
      INTENT_TOTAL_COUNT: 'Număr total de răspunsuri',
      KNOWLEDGE_BASE: 'Baza de cunoștințe',
      KNOWLEDGE_BASE_CREATION: 'Creează o nouă bază de cunoștințe',
      KNOWLEDGE_BASE_PLACEHOLDER: 'Selectează baza de cunoștințe',
      KNOWLEDGE_BASES_ADDED: 'Baza de cunoștințe a fost adăugată',
      KNOWLEDGE_BASES_ASSIGN: 'Asociază o bază de cunoștințe fluxului',
      KNOWLEDGE_BASES_CREATE_AND_ASSIGN: 'Creează și asociază',
      KNOWLEDGE_BASES_OWNED: 'Alege o bază de cunoștințe proprie',
      MULTIPLE_EVENTS_HEADER: 'Lista întrebărilor de la toate evenimentele bazate pe fluxul evenimentului curent la care asistentul nu a știut să răspundă',
      MULTIPLE_EVENTS_INDICATION: 'Toate evenimentele (desfășurate pe fluxul curent)',
      NO_INTENTS: 'Baza selectată nu conținte intenții simple',
      NO_KNOWLEDGE_BASES: 'Nu sunt disponibile baze de cunoștințe publice',
      NO_KNOWLEDGE_BASE_PERMISSION:
        'Răspunul nu poate fi adăugat deoarece baza de cunoștințe nu vă apartine și nici nu a fost partajată',
      NO_QUESTIONS:
        'Nu există nici o întrebare la care asistentul nu a știut să răspundă',
      NOT_SOLVED: 'Nu a fost tratată',
      PENDING: 'În așteptare',
      REJECTED: 'Respinsă',
      REJECT_QUESTION: 'Respinge',
      REJECT_QUESTION_EXPLANATION: 'Respinge întrebarea',
      RESET_ANSWER: 'Resetează răspunsul la starea inițială',
      QUESTIONS_TOTAL_COUNT: 'Număr total de întrebări',
      QUESTION: 'Exemplu de întrebare',
      QUESTIONS: 'Întrebări',
      SELECT_A_KNOWLEDGE_BASE: 'Selectați o bază de cunoștințe pentru a adăuga răspunsuri',
      SOLVED: 'Rezolvată',
      STATUS: 'Status',
      STEP_INDEX: 'Indexul pasului',
      TYPE: 'Generată de..',
      UNANSWERED_QUESTIONS: 'Întrebări fără răspuns',
      USERNAME: 'Utilizator',
      USER_REQUEST: 'Cererea utilizatorului',
      WITH_ANSWER: 'cu răspunsul'
    },
    FORM_SUBMISSIONS: {
      FORM_SUBMISSION: 'Formular',
      FORM_SUBMISSIONS: 'Formulare',
      LABEL: 'Etichetă',
      SELECT_PLACEHOLDER: 'Selectează un formular',
      NO_FILE: 'Formularul a fost trimis fără un fișier atașat',
      NO_FORM_SUBMISSIONS_FOR_EVENT: 'Nu s-au completat formulare pentru acestă activitate',
      NO_FORM_SUBMISSIONS:
        'Nu a avut loc niciun form submission pe formularul selectat',
      USER_COLUMN_HEADER: 'Utilizator',
      LABEL_VALUE: 'Valoarea etichetei',
      TIMESTAMP_COLUMN_HEADER: 'Data',
      FILE_UPLOADED: 'Fișierul a fost încărcat',
      FILE_NOT_UPLOADED: 'Fișierul nu a fost încărcat'
    }
  },
  LEARNING_ASSISTANT: {
    ANSWERS_SAVE_IS_DISABLED: 'Răspunsurile nu se salvează',
    ANSWERS_SAVED_AUTOMATICALLY_LABEL: 'Salvare automată activată',
    ANSWERS_SAVED_MANUALLY_LABEL: 'Salvare automată dezactivată',
    ANSWERS_SAVED_AUTOMATICALLY: 'Poți vizualiza și salva răspunsurile folosind butonul "Răspunsurile tale".',
    ANSWERS_SAVED_MANUALLY_WARNING: 'Atenție, există o salvare manuală a răspunsurilor pe care o poți actualiza folosind butonul "Răspunsurile tale".',
    ANSWERS_SAVED_AUTOMATICALLY_WARNING: 'Răspunsurile au fost salvate automat.',
    ADDED_ON_DATE: 'Data adăugării',
    AUTOSAVE_EXPLANATORY_NOTE:
      'Progresul tău și datele introduse se salvează automat',
    AUTOSAVE_NOTICE: 'Modificările efectuate se salvează automat în cazul în care NU se salvează manual răspunsurile!',
    CLASSIC_VIEW: 'Chatbot',
    CLICK: 'Apasă ',
    END_FLOW: 'Închide',
    EXPLANATORY_NOTE: 'Tastează "gata" ca să treci mai departe',
    EXTERNAL_ANSWER_DISCLAIMER: 'Răspunsurile generate pot fi incorecte uneori. Dacă răspunsul este incorect apasă butonul următor',
    FINISH_TEST: 'Salvează răspunsurile',
    FINISH_TEST_MANUAL: 'Salvează manual răspunsurile',
    FINISH_TEST_AUTO: 'Activează salvarea automată a răspunsurilor',
    FINISH_TEST_AUTO_EXPLANATION: 'Răspunsurile sunt salvate în forma finală automat, după fiecare modificare',
    FINISH_TEST_AUTO_EXPLANATION_FUTURE: 'Răspunsurile vor fi salvate în forma finală automat, după fiecare modificare',
    FINISH_TEST_EXPLANATION: 'Răspunsurile vor fi salvate în forma finală',
    FINISH_TEST_FULFILLED: 'Răspunsurile au fost salvate cu succes',
    FINISH_TEST_REJECTED: 'Răspunsurile nu au putut fi salvate',
    FINISHED_TEST_TIMESTAMP: 'Răspunsurile finale au fost salvate manual la',
    FINISHED_TEST_TIMESTAMP_WARNING: 'Atenție, salvarea automată este dezactivată. Orice modificare ulterioară a răspunsurilor finale necesită salvarea manuală sau activarea salvării automate',
    FINISHED_TEST_TIMESTAMP_WARNING_FUTURE: 'Atenție, salvarea automată va fi dezactivată. Orice modificare ulterioară a răspunsurilor finale necesită salvarea manuală sau activarea salvării automate',
    FLOW_CONTEXT_CHANGED_HEADER: 'Atenție!',
    FLOW_CONTEXT_CHANGED_1: 'A fost deschisă o altă conversație cu asistentul virtual în alt tab de browser, sau pe alt dispozitiv!',
    FLOW_CONTEXT_CHANGED_2: 'Puteți avea doar o conversație activă cu asistentul virtual. Pentru a reporni dialogul reîmprospătați pagina curentă.',
    FOR_DEMO_FLUX: 'pentru un flux demonstrativ.',
    FOR_LIBRARY: ' pentru secțiunea Bibliotecă.',
    HERE: ' aici ',
    HIDE_MESSAGES: 'Ascunde mesajele',
    IMAGE_SOURCE: 'Sursa imaginii',
    KNOWLEDGE_BASE: 'Baza de cunoștințe',
    KNOWLEDGE_BASE_NOT_FOUND: 'Neidentificată',
    KNOWLEDGE_BASE_PREVIEW_EXPLANATION: 'Întrebările tale se înregistrează doar în cadrul evenimentelor',
    KNOWLEDGE_BASES: 'Bazele de cunoștințe',
    KNOWLEDGE_BASE_AVAILABLE: 'În acest flux asistentul virtual știe să răspundă la următoarele',
    LOADING: 'Se încarcă...',
    MAX_CHARACTER_RECOMMENDATION: 'Numărul maxim de caractere indicat este o recomandare și poate fi depășit',
    NAVIGATOR_EXPLANATION: 'Dă click pe semnul ">" din dreptul pasului dorit pentru a reveni la acesta și a suprascrie răspunsul anterior, se va lua în considerare ultimul răspuns.',
    NAME: 'Nume',
    NEXT_ARROW_TOOLTIP: 'Treci mai departe',
    NO_KNOWLEDGE_BASE: 'Fără',
    NO_MONITORING_STATS: 'Parcurgerea unui flux în afara unui eveniment nu este vizibilă în secțiunea de monitorizare a unui profesor',
    NO_OWN_QUESTIONS: 'Nu au fost găsite întrebări puse de tine pe acest flux',
    NO_OWN_QUESTIONS_EXPLANATION: 'Pune o întrebare în cadrul unui eveniment, sau vezi întrebările din bazele de cunoștințe existente',
    NO_PREVIOUS_ANSWERS: 'Nu s-au găsit răspunsuri anterioare.',
    NO_SELECTED_FLOW: 'Fără flux de învățare selectat.',
    OWN_QUESTIONS_AVAILABLE: 'În acest flux ai pus următoarele întrebari',
    PREVIOUS_ANSWERS: 'Răspunsurile tale curente',
    QUESTION: 'Întrebare',
    QUESTION_ANSWER_NONE: 'Fără răspuns',
    QUESTION_ANSWER_PENDING: 'Neadăugat',
    QUESTION_ANSWER_REJECTED: 'Respinsă',
    QUESTION_DETAILS: 'Detaliile întrebării',
    SEARCH: 'Caută',
    SEND_QUESTION: 'Vezi răspunsul',
    SHOW_MESSAGES: 'Arată mesajele',
    SHOW_OWN_QUESTIONS_ONLY: 'Întrebările tale',
    SHOW_ALL_QUESTIONS: 'Toate întrebările',
    SLIDE_VIEW: 'Slide',
    SHOW_ANSWERS: 'Răspunsurile tale',
    STEP_DETAILS: 'Detaliile pasului',
    THE_ANSWER: 'Răspunsul',
    THE_EVENT: 'Activitatea',
    THE_FLOW: 'Fluxul',
    THE_STEP: 'Pasul ',
    TOKEN_EXPIRED_TITLE: 'Te-ai autentificat în alt browser',
    TOKEN_EXPIRED_CONTENT: 'Încearcă să te reconectezi la aplicație',
    THE_QUESTION: 'Întrebarea',
    UNLINKED_TEST_DIALOG_HEADER: 'Atenție!',
    UNLINKED_TEST_DIALOG_TEXT: 'Ați accesat un flux de test în afara unei activități de testare. Răspunsurile date nu se vor înregistra sau lua în considerare!',
    UNLINKED_TEST_DIALOG_TEXT_2: 'Pentru a accesa o sesiune de testare accesați link-ul corespunzător sau folosiți fereastra activitățile mele din secțiunea Dashboard!',
    WRONG_CONTEXT: 'Dacă răspunsul nu se potrivește contextului curent, apasă butonul următor'
  },
  LIBRARY: {
    EMPTY_PRIVATE_LIBRARY: 'Nu există nicio traiectorie definită',
    FLOW_PREVIEW: {
      ANSWER_TYPE: 'Tipul răspunsului',
      COVER_TITLE: 'Conținutul lecției ',
      DROPDOWN: 'Formular de tip "Select", răspundeți la acest formular alegând un răspuns',
      FILE_UPLOAD: 'Formular de tip "File upload", pentru a răspunde la acest formular parcurgeți lecția pe platformă',
      FORM_FIELD: 'Câmpul',
      FORM_TITLE: 'Denumirea formularului',
      INPUT_TEXT: 'Formular de tip "Input text", răspundeți la acest formular în limbaj natural',
      MULTIPLE_CHOICE: 'Răspunsuri (Unul sau mai multe răspunsuri corecte)',
      NATURAL_LANGUAGE: 'Răspuns în limbaj natural',
      QUESTION: 'Întrebare',
      QUESTION_BANK: 'Colecție de întrebări',
      QUESTION_BANK_TYPE: 'Tipul întrebărilor din colecție',
      QUESTIONS: 'Întrebări',
      SINGLE_CHOICE: 'Răspunsuri (Un singur răspuns corect)',
      STEP_DESCRIPTION: 'Descrierea pasului'
    }
  },
  PUBLIC: {
    AGE_CATEGORIES: 'Categorii de vârstă',
    ASCENDING: 'Ascendent',
    CHOOSE_A_FLOW: 'Alege un flux de învățare',
    CHOOSE_A_FLOW_ACTION: 'Alege o acțiune asupra fluxului',
    CHOOSE_A_LEARNING_PATH: 'Alege o traiectorie de învățare',
    CONTENT_IMAGE: 'Imagine de conținut',
    COVER_IMAGE: 'Imagine de copertă',
    DESCENDING: 'Descendent',
    DESCRIPTION: 'Descriere',
    EXISTING_IMAGES: 'Imagini existente',
    EXPLORE_A_SUBJECT: 'Explorează un subiect',
    EXPLORE_PUBLIC_PATHS: 'Explorează traiectoriile de învățare',
    FILTERS: 'Filtre',
    FLOW: 'Flux',
    FLOWS: 'fluxuri',
    FLOWS_COUNT: 'Număr de fluxuri conținute',
    IMAGE_DISPLAY_ORDER: 'Afișată în',
    IMAGE_DISPLAY_ORDER_2: 'Ordinea de afișare a imaginii',
    IMAGE_NAME: 'Denumirea imaginii',
    IMAGE_USE_CASE: 'Utilizarea imaginii',
    LANDING_MESSAGE: 'Platformă de învățare adaptivă pentru joburile viitorului',
    LANGUAGE: 'Limbă',
    LEARNING_FLOW: 'Flux de învățare',
    LEARNING_FLOWS: 'Fluxuri de învățare',
    LEARNING_PATH: 'Traiectorie de învățare',
    LEARNING_PATH_GOALS: 'Obiectivele traiectoriei de învățare',
    PATH: 'Traiectorie',
    PATH_CONTENT: 'Conținut',
    SEARCH_INPUT: {
      EMPTY_RESULTS: 'Nu există rezultate pentru această căutare',
      EMPTY_RESULTS_CLEAR_FILTERS: 'Resetează filtrele',
      SEARCH_EVENTS_SECTION: 'Evenimente din calendar',
      SEARCH_FLOWS_SECTION: 'Lecții',
      SEARCH_IN_LIBRARY: 'Caută în bibliotecă',
      SEARCH_PATHS_SECTION: 'Traiectorii',
      SEARCH_TAGS_SECTION: 'Etichete'
    },
    SEE_ALL_PUBLIC_PATHS: 'Vezi toate lecțiile',
    SELECTED_IMAGE: 'Imaginea selectată',
    SELECTED_IMAGES_FOR_UPLOAD: 'Imagini selectate pentru upload',
    SELECT_IMAGE_FOR_UPLOAD: 'Selectează o imagine de încărcat',
    SORT: 'Sortare',
    THE_FLOW: 'Fluxul',
    THIS_COURSE_INCLUDES: 'Acestă traiectorie include',
    TYPE: 'Tip',
    YEARS: 'Ani'
  },
  QUESTION_BANK: {
    ADD_QUESTION_BANK: 'Adaugă o colecție de întrebări',
    ANSWERS: 'Răspunsuri',
    CONFIRM_DELETE: 'Confirmă ștergerea colecției de întrebări',
    CONFIRM_QUESTION_DELETE: 'Confirmă ștergerea întrebării',
    CORRECT: 'Corect',
    DESCRIPTION: 'Descriere',
    DYNAMIC_FORM: 'Formular dinamic',
    EDIT_QUESTION_BANK: 'Modifică o colecție de întrebări',
    GENERATE_A_TEST: 'Generează un flux de test',
    GENERATE_A_TEST_BASED_ON_QUESTION_BANK: 'Generează un flux de test pe baza unei colecțiii de întrebări',
    GENERATE_THE_TEST: 'Generează testul',
    IMPORT_FROM_FLOW: 'Importă din flux',
    IMPORT_FROM_TEXT_FILE: 'Importă din fișier text',
    IMPORT_FROM_TEXT_FILE_CLEAR: 'Resetează',
    IMPORT_FROM_TEXT_FILE_INCORRECT_FORMAT: 'Fișierul nu respectă formatul de import',
    IMPORT_FROM_TEXT_FILE_NO_QUESTIONS: 'Nu există întrebări valide în fișierul selectat',
    IMPORT_QUESTIONS: 'Importă întrebări în colecție',
    IMPORT_QUESTIONS_FROM_FLOW: 'Importă întrebări dintr-un flux',
    IMPORT_QUESTIONS_INFO: 'Întrebările vor fi importate în colecția de întrebări curentă',
    IMPORT_QUESTIONS_INFO_2: 'Vor fi preluate doar întrebările de tipul',
    IMPORT_SOURCE: 'Sursa importului',
    INCORRECT: 'Incorect',
    MULTIPLE_CHOICE: 'Răspuns multiplu',
    NAME: 'Nume',
    NATURAL_LANGUAGE: 'Limbaj natural',
    NATURAL_LANGUAGE_IMPORT_WARNING: 'Sunt afișate doar întrebările tip limbaj natural care au clasificatori',
    NO_QUESTIONS: 'Nu există întrebări în această colecție',
    NO_QUESTION_BANKS: 'Nu există colecții de întrebări',
    QUESTIONS: 'Întrebări',
    QUESTIONS_PREVIEW: 'Previzualizarea întrebărilor posibile',
    QUESTION_BANK: 'Colecție de întrebări',
    QUESTION_BANKS: 'Colecții de întrebări',
    QUESTION_TEXT: 'Textul întrebării',
    QUESTION_TYPE: 'Tipul întrebării',
    SELECT_A_FLOW: 'Alege un flux',
    SINGLE_CHOICE: 'Răspuns unic',
    TEST_DESCRIPTION: 'Descrierea testului',
    TEST_QUESTIONS_COUNT: 'Numărul de întrebări din test',
    TEST_QUESTIONS_COUNT_MAX: 'Maxim',
    TXT_EXPORT: 'Export în format text',
    TYPE: 'Tipul întrebărilor din colecție'
  },
  STUDENT_FUTURE_EVENTS: {
    FUTURE_EVENTS: 'Activitățile viitoare',
    NO_FUTURE_EVENTS: 'Momentan nu ai întâlniri/activități asociate.',
    INTRO_MESSAGE_ONE: 'Vizitează secțiunea ',
    INTRO_MESSAGE_TWO:
      ' pentru a parcurge lecțiile prin studiu individual. Pe măsură ce vei fi invitat la întălniri de învățare, vei vedea aici link-urile și codurile de acces. De asemenea, vei primi notificări prin email. Dacă nu ai specificat o adresă de email te rugăm să accesezi secțunea ',
    INTRO_MESSAGE_THREE: ' unde poți completa informațiile de contact.'
  },
  ROBOHACKATON_USERS: {
    EXPORT_ALL: 'Exportă toți utilizatorii',
    EXPORT_TEACHERS: 'Exportă profesorii',
    EXPORT_STUDENTS: 'Exporta studenții',
    START_DATE: 'Dată început',
    END_DATE: 'Dată final',
    DESCRIPTION: 'Utilizatori Robohackaton Nextlab.tech 2021',
    FROM: 'De la',
    TO: 'Până la'
  },
  SELLER_DASHBOARD: {
    ASSOCIATED_PRODUCTS: 'Produse asociate',
    ASSIGN_SELLER: 'Asociază seller',
    COMMUNITIES: 'Comunități',
    NEW_SALES: 'Vânzări noi',
    ORDERS: 'Comenzi',
    ORDER_ID: 'Id',
    ORDER_ID_FILTER_PLACEHOLDER: 'Id-ul căutat',
    ORDER_DATE: 'Data',
    ORDER_DATE_FILTER_PLACEHOLDER: 'Data căutată',
    PRODUCT: 'Produs',
    PRODUCTS: 'Produse',
    PRODUCT_ID: 'Id-ul produsului',
    PRODUCT_ID_FILTER_PLACEHOLDER: 'Id-ul căutat',
    PRODUCT_DESCRIPTION: 'Descrierea produsului',
    PRODUCT_DESCRIPTION_FILTER_PLACEHOLDER: 'Produsul căutat',
    SALES: 'Vânzări',
    SELLERS: 'Selleri',
    SKU: 'sku',
    SKU_CODES: 'Coduri SKU',
    SKU_FILTER_PLACEHOLDER: 'sku-ul căutat'
  },
  SUBSCRIPTIONS: {
    ALREADY_ASSIGNED_NOTE: "Acest abonament a fost deja atribuit. Dacă doriți să îl modificați, vă rugăm să contactați suportul.",
    CORRECTORS_ALLOCATION: 'Alocarea Corectorilor',
    CORRECTOR_EMAIL: "Email-ul Corectorului",
    EMAIL_SPECIFICATION_WARNING: "Dacă nu specificați un email, un corector va fi alocat automat.",
    ENTER_IMAGE_LINK: "Introduceți linkul imaginii",
    ENTER_NUMBER: "Introduceți numărul",
    EXCEEDS_QUOTA: "Obiectivul depășește limita maximă permisă de planul dvs. de abonament.",
    FINISH_ASSIGNATION: "Finalizați atribuirea",
    KNOWLEDGE_MAP: 'Harta de cunoștințe:',
    KNOWLEDGE_MAP_SELECTION: 'Selectați harta de cunoștințe:',
    NUMBER_OF_PAGES: "Numărul de pagini",
    NUMERIC_FIELD: "Acest câmp trebuie să fie un număr",
    PAGES: "pagini",
    REQUIRED_FIELD: "Specificați obiectivul sau numărul de pagini",
    SUBSCRIPTION_PLAN: 'plan',
    SUBSCRIPTION_PLAN_PREFIX: ' plan pentru ',
    SUBSCRIPTION_ASSIGNMENT: 'Atribuire Abonament',
    SUBSCRIPTION_EMAIL: "Email-ul Studentului",
    SUBSCRIPTION_STATUS: 'Stare',
    SUBSCRIPTION_USER: 'Utilizator',
    SUBSCRIPTIONS: 'ABONAMENTE',
    TROPHY_IMAGE_ALT: "Imagine Obiectiv",
    TROPHY_ROAD_DESCRIPTION: 'Descriere',
    TROPHY_ROAD_HEADER: 'Obiective'
  },
  TEST_SCANNING: {
    GALLERY_TITLE: 'GALERIE',
    NO_PICTURES: 'Folosește pictograma cu camera foto pentru a adăuga poze',
    PRESS_FOR_PICTURE: 'Apăsă pe imagine pentru a captura o poză.'
  },
  TESTRAX_V2: {
    ACTIVITY_FEED: 'Activitate',
    ALLOCATED_FOR: 'a fost alocat',
    CAMERA: 'Cameră',
    CLICK_FOR_TEST: 'Accesează',
    CONFIRM_EXERCISE_REQUEST: 'Confirmă',
    DEMAND_EXERCISE_SET: 'Solicită exerciții',
    EVENT_TYPE: {
      GRADING_REJECTED:'Evaluare respinsă',
      SUBMISSION_UPLOADED: 'Imagini încărcate',
      TEST_ASSIGNED: 'Testul',
      TROPHY_ROAD_ITEM_COMPLETE:'Condiții îndeplinite',
      SUBMISSION_GRADED:'Încărcare evaluată',
      SUBMISSION_SENT_FOR_REGRADING:'Încărcare trimisă pentru reevaluare'
    },
    EXERCISE:'Exercițiul',
    EXERCISE_SET_SUBJECT: 'Subiect set de exerciții',
    EXTERNAL_GRADER_MESSAGE: 'Această trimitere a fost evaluată de un evaluator extern. Doriți să o acceptați?',
    FOR_OBJECTIVE: 'pentru obiectivul',
    GALLERY_TITLE: 'GALERIE',
    SELECT_IMAGES: 'Selectează imagini',
    NO_IMAGES_SELECTED: 'Nicio imagine selectată',
    KNOWELDGE_MAPS_REPORTS: 'Hărți de cunoștințe',
    HISTORY: 'Istoric',
    HISTORY_EMPTY: 'Nu ai încărcat încă documente',
    HOME_TITLE: 'TESTRAX',
    IMAGES: 'Imagini',
    IMAGES_SENT_SUCCESS: 'SUCCES',
    IMAGES_SENT: 'Imaginile au fost trimise pentru analiză!',
    KNOWLEDGE_MAP_REPORT: {
      HEADER: 'Raport hartă de cunoștințe',
      NO_DATA: 'Date indisponibile',
      KNOWLEDGE_MAP: 'Hartă de cunoștințe',
      CONTENT_DOMAIN: 'Domeniu de conținut',
      CONTENT_ITEM: 'Element de conținut'
    },
    NO_ACTIVITY: 'Activitatea ta va apărea aici',
    NO_ACTIVITY_PARENT: 'Activitatea elevului va apărea aici',
    NO_PICTURES: 'Apasă pe pictograma camerei pentru a deschide camera',
    NO_GRADING_ITEMS_MESSAGE: 'Pagina nu conține exerciții',
    NO_SUBSCRIPTION: 'Acest cont nu este activat în sistem, vă rugăm să ne contactați pentru asistență la adresa de email',
    NO_ACTIVE_SUBSCRIPTIONS: 'Acest cont nu este activat în sistem, vă rugăm să ne contactați pentru asistență la adresa de email',
    NO_UPLOADED_PICTURES: 'Nu ai încărcat nicio poză!',
    NO_SUBJECTS: 'A apărut o eroare cu abonamentul dvs., vă rugăm să ne contactați pentru asistență la adresa de email',
    NO_SWOT_DATA: 'Sunt necesare mai multe date pentru analiza SWOT',
    NO_THUMBNAILS_AVAILABLE: 'Miniaturi indisponibile pentru imagini',
    NO_TROPHY_ROAD_ITEM_FOUND: 'Niciun obiectiv găsit',
    OBJECTIVE: 'Obiectiv',
    PRESS_FOR_PICTURE: 'Atinge fluxul camerei pentru a salva o imagine în galerie',
    PRINT_TEST: 'Printează Test',
    PRINT_MESSAGE: 'Poți printa testul utilizând butonul de tipărire de la sfârșitul paginii.',
    PROFILE: 'Profil',
    POINTS: 'puncte',
    SCAN_HISTORY: 'Istoric scanări',
    SCAN_HISTORY_EXPLANATION: 'Aici puteți vedea ultimele scanări trimise și previzualiza imaginile salvate',
    SELECT_FILE: 'Selectează fișiere',
    SEND: 'Trimite imagini',
    SEND_ANSWERS: 'Trimite rezolvarea',
    SETTINGS: {
      HEADER: 'Setări',
      SELECT_LANGUAGE: 'Selectează limba',
      LANGUAGE: 'Limba',
      LANGUAGE_RO: 'Română',
      LANGUAGE_EN: 'English',
      LANGUAGE_CHANGED_SUCCESS: 'Limba a fost schimbată cu succes !',
    },
    SOLVED_EXERCISES: 'Exerciții rezolvate',
    SOLUTION: 'Rezolvare',
    SUBJECT: 'pentru',
    SUBMISSION_EDITOR_TITLE: 'Trimite la evaluator',
    SUBMISSION_EDITOR_OBJECTIVE: 'Încarci pentru obiectivul',
    SUBMISSION_GRADING_TYPE: 'A corectat profesorul exercițiile?',
    SUBMISSION_GRADING_TYPES: {
      GRADED: 'Corectat',
      UNGRADED: 'Necorectat'
    },
    SUBMISSION_STATUS_TYPE: {
      CORRECT: "CORECT",
      INCORRECT: "INCORECT",
      PARTIALLY_CORRECT: "PARȚIAL CORECT",
      NOT_ATTEMPTED: "NEÎNCERCAT",
      UNGRADED: "NEEVALUAT"
    },
    SUBMISSION_TYPE: 'Tip de încărcare',
    SUBMISSION_TYPES: {
      EXERCISE_SET: 'Exerciții',
      HOMEWORK: 'Temă',
      SIMULATION: 'Simulare de examen',
      STATIC_TEST: 'Test',
      USER_TEST: 'Test'
    },
    STATISTICS:{
      HEADER: 'Statistici',
      TOTAL_PAGES: 'Total pagini încărcate',
      PAGES: 'Pagini',
      UPLOADED_PAGES: 'Pagini încărcate'
    },
    STUDENT_FIRST_NAME: 'Prenume',
    STUDENT_GRADE: 'Clasă',
    STUDENT_LAST_NAME: 'Nume',
    SWOT_ALL_ELEMENTS: 'Elemente de conținut în ordinea acoperirii',
    SWOT_ANALYSIS: 'Analiză SWOT',
    SWOT_OPPORTUNITIES: 'Oportunități',
    SWOT_STRENGTHS: 'Puncte tari',
    SWOT_THREATS: 'Amenințări',
    SWOT_WEAKNESSES: 'Puncte slabe',
    SWOT_NOT_ENOUGH_DATA: 'Sunt necesare mai multe date pentru o analiză completă',
    STUDENT_UPLOADED: 'Ai încărcat:',
    TEST_INSTRUCTIONS: 'Indicații',
    TEST_KNOWLEDGE_MAP: 'Materie',
    TROPHY_ROAD: 'Obiective',
    TROPHY_ROAD_COMPLETED: 'a îndeplinit un nou obiectiv!',
    TROPHY_ROAD_ITEM_DETAILS: 'Detalii obiectiv',
    TROPHY_ROAD_COMPLETED_MESSAGE:'Felicitări! Ai îndeplinit acest obiectiv!',
    TROPHY_ROAD_UPLOAD_MESSAGE: 'Încarcă pagini pentru a progresa!',
    TROPHY_ROAD_UNLOCKED_MESSAGE: 'Continuă progresul pentru a debloca acest obiectiv!',
    TROPHY_ROAD_UNLOCKED_ACTIVITY_MESSAGE: 'Obiectivul nu a fost încă deblocat!',
    UPLOAD_LIMIT_EXCEEDED_WARNING: 'Nu mai poți încărca deoarece ai depășit dimensiunea maximă de încărcare!',
    UPLOAD_SOLUTIONS_INSTRUCTIONS_1: 'Rezolvă exercițiile pe hârtie și fotografiază soluțiile.',
    UPLOAD_SOLUTIONS_INSTRUCTIONS_2: 'Încarcă soluțiile folosind aplicația TESTRAX.',
    UNGRADABLE_PAGE_MESSAGE: 'Pagina nu a fost inclusă în corectare',
    UNSOLVED_EXERCISES: 'Exerciții nerezolvate',
    USER_PROFILE: {
      SETTINGS: 'Setări',
      STATISTICS: 'Statistici'
    },
    OBSERVATIONS: 'Observații'
  },
  THREE_D_MODELS: {
    ADD_MODEL: 'Adaugă un model 3D',
    CODE: 'Cod de identificare',
    CONFIRM_DELETE: 'Confirmă ștergerea modelului',
    THREE_D_MODELS: 'Modele 3D',
    THREE_D_MODEL: 'Model 3D',
    UPDATE_MODEL: 'Adaugă un model 3D',
    VIEW: 'Vizualizarea modelului',
    VIEW_MODEL: 'Vizualizează modelul 3D',
    URL: 'URL model',
    UPLOAD_META: 'Selectează fișierul cu metadate'
  },
  TRANSCRIPTION_PACKS: {
    ADD_HEADER: 'Adaugă un pachet de transcrieri',
    ADD_MAP_HEADER: 'Adaugă o transcriere fonetică',
    CONFIRM_DELETE: 'Confirmă ștergerea pachetului de transcrieri',
    DE: 'Germană',
    EN: 'Engleză',
    FR: 'Franceză',
    GLOBAL_TRANSCRIPTION: 'Transcriere fonetică globală',
    GLOBAL_TRANSCRIPTION_PLACEHOLDER: 'Alege o transcriere fonetică globală',
    KEY: 'Expresie',
    KEY_PLACEHOLDER: 'Caută expresia',
    LANGUAGE: 'Limbă',
    MAPPINGS_COUNT: 'Număr transcrieri',
    OWNED_TRANSCRIPTION: 'Transcriere fonetică proprie',
    OWNED_TRANSCRIPTION_PLACEHOLDER: 'Alege o transcriere fonetică proprie',
    RO: 'Română',
    TITLE: 'Denumire pachet transcrieri',
    TRANSCRIPTION_PACKS: 'Transcrieri fonetice',
    UPDATE_HEADER: 'Modifică un pachet de transcrieri',
    VALUE: 'Transcriere',
    VALUE_FILTER_PLACEHOLDER: 'Caută transcrierea'
  },
  VIDEO_ANALYTICS: {
    ACTIONS: 'Acțiuni',
    TOTAL_WATCHING_TIME: 'Timpul total petrecut în video',
    VIDEO_ANALYTICS: 'Analiza videoului',
    WATCHED_SEGMENTS: 'Vizualizarea pe segmente',
    WATCHING_TIME: 'Timpul acoperit din video',
    WATCHING_TIME_PERCENT: 'Procentul de acoperire a videoului'
  }
}

export default assets
