import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import useAssets from '../../../../hooks/useAssets'

const TrophyRoadItem = ({
  item, index, isEditing, onEditClick, onSaveClick, editLink, editConditionValue,
  handleConditionChange, handleLinkChange, validator, pagesQuota
}) => {
  const assets = useAssets()

  return (
    <div className='trophy-road-generator-item'>
      <div className='trophy-road-generator-item-header'>
        {isEditing ? (
          <>
            <label htmlFor={`condition-${index}`}>{assets.SUBSCRIPTIONS.NUMBER_OF_PAGES}</label>
            <InputText
              id={`condition-${index}`}
              value={editConditionValue}
              onChange={handleConditionChange}
              placeholder={assets.SUBSCRIPTIONS.ENTER_NUMBER}
              className='trophy-road-generator-edit-condition-number'
            />
            {validator.current.message(`condition-${index}`, editConditionValue, `required|numeric|exceedsQuota:${pagesQuota}`)}
          </>
        ) : (
          <>
            <div className='trophy-road-generator-condition'>
              {item.condition} {assets.SUBSCRIPTIONS.PAGES}
            </div>
            <div className='trophy-road-generator-description'>
              {item.description}
            </div>
          </>
        )}
        {!isEditing && (
          <i className='pi pi-pencil trophy-road-generator-edit-icon' onClick={onEditClick} title={assets.GENERAL.EDIT} />
        )}
      </div>
      <div className='trophy-road-generator-image'>
        {isEditing ? (
          <>
            <InputText
              value={editLink}
              onChange={handleLinkChange}
              placeholder={assets.SUBSCRIPTIONS.ENTER_IMAGE_LINK}
              className='trophy-road-generator-edit-image'
            />
            <Button label={assets.GENERAL.SAVE} onClick={onSaveClick} className='trophy-road-generator-save-button' />
          </>
        ) : item.link ? (
          <div className='trophy-road-generator-image-container'>
            <img src={item.link} alt={assets.SUBSCRIPTIONS.TROPHY_IMAGE_ALT} />
          </div>
        ) : (
          <div className='trophy-road-generator-placeholder'>
            <i className='pi pi-image trophy-road-generator-plus-icon' />
          </div>
        )}
      </div>
    </div>
  )
}

export default TrophyRoadItem
