/**
 * @module tokenReducer
 * @description state manipulation for the token validity
 * @category reducers
 */
const INITIAL_STATE = {
  isTokenValid: false,
  error: null,
  fetching: false,
  fetched: false,
  count: 0
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CHECK_TOKEN_VALIDITY_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'CHECK_TOKEN_VALIDITY_FULFILLED':
      return { ...state, isTokenValid: action.payload.data.isTokenValid, count: action.payload.data.count, error: null, fetched: true, fetching: false }
    case 'CHECK_TOKEN_VALIDITY_REJECTED':
      return { ...state, error: action.payload.response.data, fetching: false, fetched: false }
    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}
