// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suspense-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;

}

.suspense-loading .logo {
  max-width: 200px; /* Adjust the size as needed */
  animation: pulsate 4s infinite;
  transition: opacity 0.5s ease-in-out;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 0.05;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  75% {
    transform: scale(1.1);
    opacity: 1;
  }


  100% {
    transform: scale(1);
    opacity: 0.05;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/App/SuspenseLoading/SuspenseLoading.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;;AAE3B;;AAEA;EACE,gBAAgB,EAAE,8BAA8B;EAChD,8BAA8B;EAC9B,oCAAoC;AACtC;;AAEA;EACE;IACE,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;;EAGA;IACE,mBAAmB;IACnB,aAAa;EACf;AACF","sourcesContent":[".suspense-loading {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background-color: #ffffff;\n\n}\n\n.suspense-loading .logo {\n  max-width: 200px; /* Adjust the size as needed */\n  animation: pulsate 4s infinite;\n  transition: opacity 0.5s ease-in-out;\n}\n\n@keyframes pulsate {\n  0% {\n    transform: scale(1);\n    opacity: 0.05;\n  }\n\n  50% {\n    transform: scale(1.1);\n    opacity: 1;\n  }\n\n  75% {\n    transform: scale(1.1);\n    opacity: 1;\n  }\n\n\n  100% {\n    transform: scale(1);\n    opacity: 0.05;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
