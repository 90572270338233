// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.middle{
  position: fixed;
  left: 50%;
  top: 50%;
  opacity: 1 !important;
  transform: translate(-50%, -50%);
}

.loader-message {
  position: fixed;
  /* left: 47%; */
  left: 47%;
  top: 56%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  /* color: #116FBF; */
  color: var(--primary-color);
  font-weight: bolder;
  /* height: 20vh; */
}

.top-level {
  z-index:99999
}`, "",{"version":3,"sources":["webpack://./src/components/LoadingDisplay/LoadingDisplay.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,QAAQ;EACR,qBAAqB;EACrB,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,eAAe;EACf,SAAS;EACT,QAAQ;EACR,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,uBAAuB;EACvB,oBAAoB;EACpB,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE;AACF","sourcesContent":[".middle{\n  position: fixed;\n  left: 50%;\n  top: 50%;\n  opacity: 1 !important;\n  transform: translate(-50%, -50%);\n}\n\n.loader-message {\n  position: fixed;\n  /* left: 47%; */\n  left: 47%;\n  top: 56%;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: flex-start;\n  justify-content: center;\n  /* color: #116FBF; */\n  color: var(--primary-color);\n  font-weight: bolder;\n  /* height: 20vh; */\n}\n\n.top-level {\n  z-index:99999\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
