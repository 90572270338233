/**
 * @module testActions
 * @description actions for tests
 * @category actions
 */

import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const GET_TEST_CONTENT = 'GET_TEST_CONTENT'
export const DEMAND_EXERCISES = 'DEMAND_EXERCISES'

export function demandExercises(userKnowledgeMapId, trophyRoadItemId) {
  const token = store.getState().loginUser.user.token
  return {
    type: DEMAND_EXERCISES,
      payload: axios.get(`${SERVER}/student-api/trophy-road-items/${trophyRoadItemId}/user-knowledge-maps/${userKnowledgeMapId}/exercise-set`, {
        headers: {
          Authorization: token
        }
    }) 
  }
}

export function getTestContent(testId) {
  const token = store.getState().loginUser.user.token

  return {
      type: GET_TEST_CONTENT,
      payload: axios.get(`${SERVER}/student-api/tests/${testId}/content`,{
        headers: {
          Authorization: token
        }
    })     
  }
}