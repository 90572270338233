import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { knowledgeMapMetricsActions } from 'actions'
import NotificationArea from 'components/NotificationArea'
import useAssets from 'hooks/useAssets'
import './StudentSwot.css'

const ownedSubscriptionsSelector = state => state.ownedSubscriptions.subscriptions
const flattenedKnowledgeMapMetricsSelector = state => state.knowledgeMapMetrics.flattenedKnowledgeMapMetrics

function StudentSwot () {
  const { subscriptionId } = useParams()
  const dispatch = useDispatch()
  const assets = useAssets()


  const ownedSubscriptions = useSelector(ownedSubscriptionsSelector , shallowEqual)
  const flattenedKnowledgeMapMetrics = useSelector(flattenedKnowledgeMapMetricsSelector, shallowEqual)

  const selectedSubscription = ownedSubscriptions.find(sub => sub._id === subscriptionId)
  const studentId = selectedSubscription.forUser[0]?._id
  const studentFullName = selectedSubscription.forUser[0]?.fullName

  const highestCoverageElements = flattenedKnowledgeMapMetrics.slice(0, 5)
  const lowestCoverageElements = flattenedKnowledgeMapMetrics.slice(-5)

  const filteredHighestCoverageElements = highestCoverageElements.filter(e => e.contentItemHasActivity)
  const filteredLowestCoverageElements = lowestCoverageElements.filter(e => e.contentItemHasActivity)

  const coverageElementsSortedByROI = flattenedKnowledgeMapMetrics.sort((a, b) => {
    return a.contentItemActivityROI - b.contentItemActivityROI
  })

  const highestROIElements = coverageElementsSortedByROI.slice(0, 5)
  const lowestROIElements = coverageElementsSortedByROI.slice(-5)

  const filteredHighestROIElements = highestROIElements.filter(e => e.contentItemHasActivity)
  const filteredLowestROIElements = lowestROIElements.filter(e => e.contentItemHasActivity)

  const hiddenElementsLabel = () => {
    return (
      <div>
        <span>{assets.TESTRAX_V2.SWOT_NOT_ENOUGH_DATA}</span>
      </div>
    )
  }

  const swotElementTemplate = (elementData, sectionColor) => (
    <div className="swot-item">
      <div className="swot-vertical-line" style={{ backgroundColor: sectionColor }}></div>
      <span>{elementData.contentItemDescription}</span>
    </div>
  )  
  const activityElementCount = flattenedKnowledgeMapMetrics.reduce((acc, c) => acc + c.contentItemHasActivity ? 1 : 0, 0)


  const strengthsSectionTemplate = (
    <div className='swot-strengths-section'>
      <span className='swot-section-label background-strengths'>{assets.TESTRAX_V2.SWOT_STRENGTHS}</span>
      <div className='swot-strengths-list-container'>
        {
        filteredHighestCoverageElements.map(e => swotElementTemplate(e, 'var(--swot-strengths-color)'))
        }
        {
        filteredHighestCoverageElements.length !== highestCoverageElements.length && 
        hiddenElementsLabel()
        }
      </div>
    </div>
  )
  
  const weaknessesSectionTemplate = (
    <div className='swot-weaknesses-section'>
      <span className='swot-section-label background-weaknesses'>{assets.TESTRAX_V2.SWOT_WEAKNESSES}</span>
      <div className='swot-weaknesses-list-container'>
        {
        filteredLowestCoverageElements.map(e => swotElementTemplate(e, 'var(--swot-weaknesses-color)'))
        }
        {
        filteredLowestCoverageElements.length !== lowestCoverageElements.length && 
        hiddenElementsLabel()
        }
      </div>
    </div>
  )
  
  const opportunitiesSectionTemplate = (
    <div className='swot-opportunities-section'>
      <span className='swot-section-label background-opportunities'>{assets.TESTRAX_V2.SWOT_OPPORTUNITIES}</span>
      <div className='swot-opportunities-list-container'>
        {
         filteredHighestROIElements.map(e => swotElementTemplate(e, 'var(--swot-opportunities-color)'))
        }
        {
          filteredHighestROIElements.length !== highestROIElements.length && 
          hiddenElementsLabel()
        }
      </div>
    </div>
  )
  
  const threatsSectionTemplate = (
    <div className='swot-threats-section'>
      <span className='swot-section-label background-threats'>{assets.TESTRAX_V2.SWOT_THREATS}</span>
      <div className='swot-threats-list-container'>
        {
         filteredLowestROIElements.map(e => swotElementTemplate(e, 'var(--swot-threats-color)'))
        }
        {
         filteredLowestROIElements.length !== lowestROIElements.length && 
         hiddenElementsLabel()
         }
      </div>
    </div>
  )
  

  useEffect(() => {
    dispatch(knowledgeMapMetricsActions.getStudentSwot(studentId))
  }, [studentId])

  return (
    <>
      <NotificationArea defaultText={`${assets.TESTRAX_V2.SWOT_ANALYSIS.toUpperCase()}: ${studentFullName.toUpperCase()}`} />
      <div className='student-swot'>
        { flattenedKnowledgeMapMetrics.length > 0 && 
          <>
            {strengthsSectionTemplate}
            {weaknessesSectionTemplate}
            {opportunitiesSectionTemplate}
            {threatsSectionTemplate}
          </>
        }
       { (flattenedKnowledgeMapMetrics.length === 0 || activityElementCount === 0) &&
          <div>
            <div className='no-swot-div'>
                <span>{assets.TESTRAX_V2.NO_SWOT_DATA}</span>
              </div>
          </div>
        }
      </div>
    </>
  )
}

export default StudentSwot
