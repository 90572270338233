// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera-container video{
   object-fit: cover;
   object-position: center;
   width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Camera/Camera.css"],"names":[],"mappings":"AAAA;GACG,iBAAiB;GACjB,uBAAuB;GACvB,WAAW;AACd","sourcesContent":[".camera-container video{\n   object-fit: cover;\n   object-position: center;\n   width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
