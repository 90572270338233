/* global localStorage */
import './App.css'
import React, { Component, Suspense } from 'react'

// import * as serviceWorker from 'serviceWorker'
import axios from 'axios'
import debounce from 'lodash/debounce'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'

import { Dialog } from 'primereact/dialog'
import { Messages } from 'primereact/messages'
import { Button } from 'primereact/button'
import logoMobile from 'assets/logo_mobile.webp'
import logo from 'assets/updated_logo-slogan-color.webp'

import { platformInfoActions, appModeActions } from 'actions'
import { SERVER } from 'config/global'
import CatchDotInUrl from 'components/CatchDotInUrl/CatchDotInUrl'
import SuspenseLoading from './SuspenseLoading/SuspenseLoading'

import LoadingSpinnerDisplay from '../LoadingSpinnerDisplay/LoadingSpinnerDisplay'
import ErrorDisplay from 'components/ErrorDisplay'
import LoadingDisplay from 'components/LoadingDisplay'
import NetworkErrorDisplay from 'components/NetworkErrorDisplay'
import ProtectedRoute from 'components/ProtectedRoute'
import LoginForm from 'components/LoginForm/LoginForm.js'
// import LanguageSelector from 'components/LanguageSelector'
import { Helmet } from 'react-helmet'
// import SocketConnectionManager from 'components/SocketConnectionManager/SocketConnectionManager'
import LoginProvider from '../LoginProvider'
import LoginAuthorization from '../LoginAuthorization/LoginAuthorization'
// import UserLoginConfirmation from 'components/UserLoginConfirmation'
import LanguageSelector from 'components/Settings/LanguageSelector'
import StudentDashboard from 'components/StudentDashboard'
import ParentDashboard from 'components/ParentDashboard'
import FirebaseNotif from 'components/FirebaseNotif'
import ToastManager from 'components/ToastManager'
import FacetPicker from 'components/FacetPicker'
import UserProfile from 'components/UserProfile'
import Settings from 'components/Settings'
import Statistics from 'components/Statistics'

const ErrorNotFound = React.lazy(() => import('components/ErrorNotFound'))

const mapStateToProps = function (state) {
  return {
    language: state.language.lang,
    assets: state.language.assets,
    user: state.loginUser.user
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    actions: bindActionCreators({
      savePlatformInfo: platformInfoActions.addPlatformInfo,
      switchAppToMobileMode: appModeActions.switchToMobileMode,
      switchAppToDefaultMode: appModeActions.switchToDefaultMode
    }, dispatch)
  }
}

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      newVersionAvailable: false,
      waitingWorker: {},
      user: null,
      lastLoggedUser: false,
      displayInfoDialog: false,
      isMobile: true,
      platform: window.localStorage.getItem('PLATFORM')
    }

    window.addEventListener('resize', debounce(() => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }, 40))

    // let isFirstLoad = true

    this.onServiceWorkerUpdate = (registration) => {
      localStorage.removeItem('tx-global-state')
      console.warn('APP UPDATE AVAILABLE. RELOAD!')
      this.setState({
        waitingWorker: registration && registration.waiting,
        newVersionAvailable: true
      })
    }

    this.handleUpdateServiceWorker = () => {
      const { waitingWorker } = this.state
      waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' })
      this.setState({ newVersionAvailable: false })
      window.location.reload()
    }

    this.setMessagesRef = (ref) => {
      this.messages = ref
    }

    this.reloadTemplate = (
      <div className='p-grid p-fluid p-align-center'>
        <div className='p-col-6'>
          {this.props.assets.GENERAL.NEW_VERSION_AVAILABLE}
        </div>
        <div className='p-col-6 p-align-right'>
          <Button label={this.props.assets.GENERAL.RELOAD} onClick={this.handleUpdateServiceWorker} className='p-button-success' />
        </div>
      </div>
    )

    this.handleCloseInfoDialog = () => {
      this.setState({ displayInfoDialog: false })
    }

    this.savePlatformInfo = async () => {
      const platform = navigator.platform
      const screenResolution = {
        width: parseInt(window.screen.width),
        height: parseInt(window.screen.height)
      }
      await this.props.actions.savePlatformInfo(platform, screenResolution)
    }

    this.hardRefresh = () => {
      if ('caches' in window) {
        window.caches.keys().then((names) => {
          names.forEach(async (name) => {
            await window.caches.delete(name)
          })
        })
        window.location.reload()
      }
    }

    this.checkAppVersion = async () => {
      try {
        const response = await axios.get(`${SERVER}/version/app-version`)
        const latestVersion = response.data.appVersion
        const localVersion = localStorage.getItem('appVersion')
        if (!localVersion) {
          localStorage.setItem('appVersion', latestVersion)
          this.hardRefresh()
        } else {
          if (this.semverGreaterThan(latestVersion, localVersion)) {
            localStorage.setItem('appVersion', latestVersion)
            this.hardRefresh()
          }
        }
      } catch(e) {
        console.warn(e)
      }
    }

    this.semverGreaterThan = (versionA, versionB) => {
      const versionsA = versionA.split(/\./g)
      const versionsB = versionB.split(/\./g)

      while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift())
        const b = Number(versionsB.shift())

        if (a === b) continue
        return a > b || isNaN(b)
      }
      return false
    }

    this.appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.appHeight)
    this.appHeight()

    this.checkAppVersion()

    if (navigator.platform.includes('iPhone') || navigator.platform.includes('iPad') || navigator.platform.includes('iPod')) {
      this.setState({
        displayInfoDialog: true
      })
    }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setState({
        isMobile: true
      })
      this.props.actions.switchAppToMobileMode()
    } else {
      this.props.actions.switchAppToDefaultMode()
    }

    this.savePlatformInfo()
    this.setState({
      user: this.props.user
    })
    // if (localStorage.getItem('tx-global-state') && JSON.parse(localStorage.getItem('tx-global-state')).loginUser && JSON.parse(localStorage.getItem('tx-global-state')).loginUser.lastLoggedUser !== null) {
    //   this.setState({
    //     lastLoggedUser: true
    //   })
    // }
  }

  componentDidUpdate (oldProps) {
    const { newVersionAvailable } = this.state
    if (newVersionAvailable) {
      const messages = [{
        severity: 'info',
        sticky: true,
        detail: this.reloadTemplate
      }]
      this.messages.show(messages)
    }

    if (this.props !== oldProps) {
      if (this.props.user !== oldProps.user) {
        this.setState({
          user: this.props.user
        })
      }
      this.savePlatformInfo()
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.props.actions.switchAppToMobileMode()
    } else {
      this.props.actions.switchAppToDefaultMode()
    }
  }


  componentWillUnmount () {
    window.removeEventListener('resize', this.appHeight)
  }

  render () {
    const { isMobile } = this.state
    const { assets } = this.props

    const infoDialogFooter = (
      <div>
        <Button label='Ok' icon='pi pi-check' onClick={(this.handleCloseInfoDialog)} className='p-button-text' />
      </div>
    )

    return (
      <Suspense fallback={<SuspenseLoading />}>
        {/* <SocketConnectionManager /> */}
        <Helmet>
          {/* <link
            href='https://fonts.cdnfonts.com/css/futura?styles=65266&display=swap'
            rel='stylesheet'
          /> */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet" />
        </Helmet>
        <div className='app'>
          <div className='p-grid not-shown'>
            <div className='p-col-10 p-lg-10 p-md-10 p-sm-8 p-offset-1 p-lg-offset-1 p-md-offset-1 p-sm-offset-2'>
              <Messages
                ref={this.setMessagesRef}
                life={10000}
              />
            </div>
          </div>
          <LoadingSpinnerDisplay />
          <LoadingDisplay />
          <ErrorDisplay />
          <CatchDotInUrl />

          {/* {this.state.displayInfoDialog &&
            <div className='info-dialog' style={{ position: 'relative', zIndex: '99999' }}>
              <Dialog header='Info' visible={this.state.displayInfoDialog} modal style={{ width: '350px' }} footer={infoDialogFooter} onHide={this.handleCloseInfoDialog}>
                <div className='confirmation-content'>
                  <i className='pi pi-exclamation-triangle' style={{ fontSize: '1.5rem' }} />
                  <span style={{ marginTop: '4vh' }}>{assets.GENERAL.APPLE_DETECTED}</span>
                </div>
              </Dialog>
            </div>} */}

          <NetworkErrorDisplay>

            {this.state.platform === 'mobile' && <FirebaseNotif />}
            <Router>
              <ToastManager />
              <div style={{ paddingTop: 0 }} className={`p-col-12 ${isMobile ? 'no-padding' : ''}`}>
                <Routes>
                  <Route exact path='/' element={<LoginProvider />} />
                  <Route exact path='/login' element={<LoginProvider />} />
                  <Route exact path='/login-classic' element={<LoginForm />} />
                  <Route exact path='/complete-login/:code' element={<LoginAuthorization />} />
                  {/* <Route path='/dashboard/:state?/:stateParams?' element={<ProtectedRoute />} /> */}
                  <Route path='/facet-picker' element={<ProtectedRoute element={<FacetPicker />} />} />
                  <Route path='/student-dashboard/*' element={<ProtectedRoute element={<StudentDashboard />} />} />
                  <Route path='/parent-dashboard/*' element={<ProtectedRoute element={<ParentDashboard />} />} />
                  <Route path='/user-profile' element={<UserProfile/>}/>
                  <Route path='/user-profile/settings' element={<Settings/>}/>
                  <Route path='/user-profile/settings/language' element={<LanguageSelector/>}/>
                  <Route path='/user-profile/statistics' element={<Statistics/>}/>
                  <Route component={ErrorNotFound} />
                </Routes>
              </div>
            </Router>
          </NetworkErrorDisplay>
        </div>
      </Suspense>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
