import { stateActions } from 'actions'

const tokenGuardMiddleware = store => next => action => {
  if (action.type.indexOf('_REJECTED') && action.payload?.response?.data.message === 'ERR_INCORRECT_TOKEN') {
    store.dispatch(stateActions.resetTokenState())
  }
  return next(action)
}

export default tokenGuardMiddleware
