/**
 * @module networkErrorReducer
 * @description state manipulation for network errors
 * @category reducers
 */
const INITIAL_STATE = {
  networkErrorOccured: false
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_NETWORK_ERROR':
      return { ...state, networkErrorOccured: true }
    case 'UNSET_NETWORK_ERROR':
    case 'RESET':
      return INITIAL_STATE
    default:
      return state
  }
}
