import React from 'react'
import { useSelector } from 'react-redux'

/**
 * @description selects user from state and returns it
 * @returns assets state
 */
const useUser = () => {
  const user = useSelector(state => state.loginUser.user)
  return user
}

export default useUser
