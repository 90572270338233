import './TrophyRoadViewer.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import TestraxLogoV1 from 'assets/icons/testrax_v1.webp'
import { subscriptionActions, trophyRoadActions } from '../../actions'
import previewPlaceholder from 'assets/icons/gift-svgrepo-com.svg'
import PointsViewer from './PointsViewer'

import iconMedal from 'assets/icons/icon-medal.svg'
import iconStar from 'assets/icons/icon-star.svg'
import iconTrophy from 'assets/icons/icon-trophy.svg'
import iconGift from 'assets/icons/gift-svgrepo-com.svg'

const iconMapping = {
  'icon-star': iconStar,
  'icon-medal': iconMedal,
  'icon-trophy': iconTrophy,
  'gift-svgrepo-com': iconGift
}

const assetSelector = state => state.language.assets
const userSelector = state => state.loginUser.user
const subscriptionIdSelector = state => state.subscription.subscriptionId
const subscriptionStatusSelector = state => state.subscription.status
const subscriptionPageStatsSelector = state => state.subscription.subscriptionPageStats
const trophyRoadItemsSelector = state => state.trophyRoad.trophyRoadItems
const trophyRoadIdSelector = state => state.trophyRoad.trophyRoadId

const TrophyRoadViewer = () => {
  const assets = useSelector(assetSelector, shallowEqual)
  const user = useSelector(userSelector, shallowEqual)
  const subscriptionId = useSelector(subscriptionIdSelector, shallowEqual)
  const subscriptionStatus = useSelector(subscriptionStatusSelector, shallowEqual)
  const subscriptionPageStats = useSelector(subscriptionPageStatsSelector, shallowEqual)
  const trophyRoadItems = useSelector(trophyRoadItemsSelector, shallowEqual)
  const trophyRoadId = useSelector(trophyRoadIdSelector, shallowEqual)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(subscriptionActions.getActiveSubscription())
  }, [user])

  useEffect(() => {
    if (subscriptionId && subscriptionStatus === 'ACTIVE') {
      dispatch(trophyRoadActions.getTrophyRoad(subscriptionId))
    }
  }, [subscriptionId, subscriptionStatus])

  const handleItemClick = (trophyroaditemId, isUnlocked) => {
    if (!isUnlocked) return;
    navigate(`/student-dashboard/trophyroads/${trophyRoadId}/trophyroaditems/${trophyroaditemId}`)
  }

  const navigateToTestContent = (testId, trophyRoadItemId) => {
    if (trophyRoadId && trophyRoadItemId) {
      navigate(`/student-dashboard/trophyroads/${trophyRoadId}/trophyroaditems/${trophyRoadItemId}/tests/${testId}/content`)
    } else {
      navigate(`/student-dashboard/tests/${testId}/content`)
    }
  }

  return (
    <div className='trophy-road-viewer'>
      {
        (!subscriptionId || subscriptionStatus !== 'ACTIVE') &&
        <div className='no-subscription-container'>
          <div className='no-subscription-logo'>
            <img src={TestraxLogoV1} />
          </div>
          <div className='no-subscription-div'>
            <span>{assets.TESTRAX_V2.NO_SUBSCRIPTION}: <a href={`mailto:${process.env.SUPPORT_EMAIL || 'contact@nextlab.tech'}`}>{process.env.SUPPORT_EMAIL || 'contact@nextlab.tech'}</a></span>
          </div>
        </div>
      }
      <PointsViewer current={trophyRoadItems.filter(e => e.isCompleted && e.points).reduce((a, e) => a + e.points, 0)} total={trophyRoadItems.filter(e => e.points).reduce((a, e) => a + e.points, 0)} />
      <div className='trophy-road'>
        {
          trophyRoadItems && trophyRoadItems.length > 0 &&
          trophyRoadItems.map((e, i) =>
            {
              return (
                <div key={`trophy-road-item-${i}`}>
                  <div className={`trophy-road-item ${e.isCompleted ? 'completed' : (i === 0 || trophyRoadItems[i-1].isCompleted ? 'in-progress' : 'not-completed')}`}>
                  <div className="preview" onClick={() => handleItemClick(e._id, e.isUnlocked)}>
                    <img src={e.link || iconMapping[e.icon] || previewPlaceholder} alt=""/>
                  </div>
                  <div className="trophy-road-item-description">
                    {`${e.description}`}
                    {
                      e.testAllocations.length > 0 &&
                      (
                        <div onClick={() => navigateToTestContent(e.testAllocations[e.testAllocations.length - 1].test, e._id)}><span className='test-link'>{assets.TESTRAX_V2.CLICK_FOR_TEST}</span></div>
                      )
                    }
                    {
                      (e.points && e.points > 0) ?
                      (
                        <div className='trophy-road-item-points'>
                          <span>{`(${e.points} ${assets.TESTRAX_V2.POINTS})`}</span>
                        </div>
                      ) : null
                    }
                  </div>
                </div>
                {
                  trophyRoadItems.length - 1 > i &&
                  <div className="trophy-interstitial" key={`inter-${i}`}></div>
                }
                </div>
              )
            }
          )
        }
      </div>
    </div>
  )
}

export default TrophyRoadViewer
