// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submission-content-container {
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-rows: var(--notification-area-height) 1fr;
  grid-template-areas: 
    "notification-area"
    "content-area";
}

.submission-content-container .notification-area {
  grid-area: notification-area;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--notification-area-height); 
  background-color: var(--secondary-background-color);
  color: var(--primary-foreground-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.submission-content-container .content-area {
  grid-area: content-area;
  /* margin-top: var(--notification-area-height);  */
  background-color: var(--default-area-background-color);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 3vw 2vw;
  gap: 2vw;
}

.submission-content-container .picture-container {
  width: 47vw;
  aspect-ratio: 1 / 1;  
  overflow: hidden;
  border: 1px solid var(--secondary-color);
  background-position: center center;
  background-size: cover;
  box-shadow: 0px 0px 5px 1px slategray;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/SubmissionContent/SubmissionContent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,YAAY;EACZ,uDAAuD;EACvD;;kBAEgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,uCAAuC;EACvC,mDAAmD;EACnD,sCAAsC;EACtC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,kDAAkD;EAClD,sDAAsD;EACtD,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,2BAA2B;EAC3B,yBAAyB;EACzB,gBAAgB;EAChB,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,wCAAwC;EACxC,kCAAkC;EAClC,sBAAsB;EACtB,qCAAqC;EACrC,iBAAiB;AACnB","sourcesContent":[".submission-content-container {\n  display: grid;\n  height: 100vh;\n  width: 100vw;\n  grid-template-rows: var(--notification-area-height) 1fr;\n  grid-template-areas: \n    \"notification-area\"\n    \"content-area\";\n}\n\n.submission-content-container .notification-area {\n  grid-area: notification-area;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: var(--notification-area-height); \n  background-color: var(--secondary-background-color);\n  color: var(--primary-foreground-color);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: bold;\n}\n\n.submission-content-container .content-area {\n  grid-area: content-area;\n  /* margin-top: var(--notification-area-height);  */\n  background-color: var(--default-area-background-color);\n  overflow-y: auto;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  align-content: flex-start;\n  padding: 3vw 2vw;\n  gap: 2vw;\n}\n\n.submission-content-container .picture-container {\n  width: 47vw;\n  aspect-ratio: 1 / 1;  \n  overflow: hidden;\n  border: 1px solid var(--secondary-color);\n  background-position: center center;\n  background-size: cover;\n  box-shadow: 0px 0px 5px 1px slategray;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
