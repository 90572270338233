import './FacetPicker.css'
import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Navigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'

import InvalidAccountLogout from '../InvalidAccountLogout/InvalidAccountLogout'
import NotificationArea from '../NotificationArea/NotificationArea'

import { facetsActions } from '../../actions'

import useAssets from 'hooks/useAssets'

const loginUserSelector = (state) => state.loginUser.user
const FacetPicker = () => {
  const dispatch = useDispatch()
  const user = useSelector(loginUserSelector, shallowEqual)
  const assets = useAssets()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && user.facets.length !== 0) {
      if (user.facets.includes('student')) {
        navigate('/student-dashboard')
      }
      if (user.facets.includes('parent')) {
        navigate('/parent-dashboard')
      }
      if (user.facets.includes('grader')) {
        navigate('/login')
      }
    }
  }, [user])

  const handlePickStudentFacet = () => {
    dispatch(facetsActions.pickUserFacet(user._id, 'student'))
  }

  const handlePickParentFacet = () => {
    dispatch(facetsActions.pickUserFacet(user._id, 'parent'))
  }

  return (
    <>
    <NotificationArea defaultText={assets.TESTRAX_V2.HOME_TITLE.toUpperCase()} />
    <div className='facet-picker-container'>
    <div className='facet-picker-content'>
        <Card title={assets.FACETS_PICKER.HEADER}>
          <div className='buttons-container'>
            <Button label={assets.FACETS.STUDENT} onClick={handlePickStudentFacet} />
            <Button label={assets.FACETS.PARENT} onClick={handlePickParentFacet} />
          </div>
        </Card>
      </div>
    </div>
    </>
  )
}

export default FacetPicker
