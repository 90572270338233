import React from 'react'
import './TotalSizeDisplay.css'
import { MAX_UPLOAD_SIZE_MB } from 'config/global'
import useAssets from 'hooks/useAssets'

const MAX_SIZE_BYTES = MAX_UPLOAD_SIZE_MB * 1024 * 1024

const formatBytesToMB = (bytes) => {
  return (bytes / (1024 * 1024)).toFixed(2) 
}

const TotalSizeDisplay = ({ totalSize }) => {
  const percentage = Math.min((totalSize / MAX_SIZE_BYTES) * 100, 100)
  const roundedPercentage = Number(percentage.toFixed(0))
  const assets = useAssets()

  const getProgressBarColor = (percentage) => {
    if (percentage < 50) return 'var(--primary-background-color)'
    if (percentage < 75) return 'var(--secondary-background-color)'
    return 'var(--upload-limit-color)'
  }

  const progressBarStyle = {
    width: `${roundedPercentage}%`,
    backgroundColor: getProgressBarColor(roundedPercentage),
    height: '100%',
    transition: 'width 0.3s ease, background-color 0.3s ease'
  }

  return (
    <div className='total-size-display'>
      <div className='size-text'>
        {assets.TESTRAX_V2.STUDENT_UPLOADED} {formatBytesToMB(totalSize)} MB / {MAX_UPLOAD_SIZE_MB} MB
      </div>
      <div className='progress-bar'>
        <div className='progress-bar-value' style={progressBarStyle} />
        <span className='progress-label'>{roundedPercentage}%</span>
      </div>
    </div>
  )
}

export default TotalSizeDisplay
