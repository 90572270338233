// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-selector-container {
    height: calc(var(--app-height) * 0.84);
    overflow-y: auto;
  }
  
  .language-selector-list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .language-selector-item {
    display: flex;
    align-items: center;
    padding: 0.75em 1.5em; 
    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);
    width: 100% ;
    max-width: 40rem;
    margin: 0 auto;
    height: 4em;
    box-sizing: border-box;
    cursor: pointer;
  }
  
  .language-selector-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .language-selector-flag {
    width: 2em; 
    height: auto;
    margin-right: 1em; 
    border-radius: 0.25em;
  }
  
  .language-selector-label {
    font-size: 1rem;
    font-weight: 500;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Settings/LanguageSelector/LanguageSelector.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,+CAA+C;IAC/C,YAAY;IACZ,gBAAgB;IAChB,cAAc;IACd,WAAW;IACX,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,qCAAqC;EACvC;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,iBAAiB;IACjB,qBAAqB;EACvB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".language-selector-container {\n    height: calc(var(--app-height) * 0.84);\n    overflow-y: auto;\n  }\n  \n  .language-selector-list {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n  }\n  \n  .language-selector-item {\n    display: flex;\n    align-items: center;\n    padding: 0.75em 1.5em; \n    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);\n    width: 100% ;\n    max-width: 40rem;\n    margin: 0 auto;\n    height: 4em;\n    box-sizing: border-box;\n    cursor: pointer;\n  }\n  \n  .language-selector-item:hover {\n    background-color: rgba(0, 0, 0, 0.05);\n  }\n  \n  .language-selector-flag {\n    width: 2em; \n    height: auto;\n    margin-right: 1em; \n    border-radius: 0.25em;\n  }\n  \n  .language-selector-label {\n    font-size: 1rem;\n    font-weight: 500;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
