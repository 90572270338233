// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.network-error-display {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.network-error-display .robot {
    border-radius: 50%;
    max-height: 230px;
    max-width: 230px;
    padding: 30px;
  }`, "",{"version":3,"sources":["webpack://./src/components/ErrorBoundary/ErrorBoundary.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;EACf","sourcesContent":[".network-error-display {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  height: 70vh;\n}\n\n.network-error-display .robot {\n    border-radius: 50%;\n    max-height: 230px;\n    max-width: 230px;\n    padding: 30px;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
