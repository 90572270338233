// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.facet-picker-container .facet-picker-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.facet-picker-container .facet-picker-content .p-card {
    width: 25em;
}

.facet-picker-container .p-card .p-card-content .buttons-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
    align-items: center;
    justify-content: center;
}

.facet-picker-container .p-card .p-card-content .buttons-container .p-button {
    width: 15em;
    background-color: var(--primary-background-color);
    border-color: var(--primary-background-color);
    color: white;
}

.facet-picker-container .p-card .p-card-content .buttons-container .p-button:hover,
.facet-picker-container .p-card .p-card-content .buttons-container .p-button:active {
    background-color: #2d33b3;
    border-color: #2d33b3;
}

.facet-picker-container .facet-picker-content .p-card .p-card-title {
    text-align: center;
    font-size: 1.4rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/FacetPicker/FacetPicker.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,iDAAiD;IACjD,6CAA6C;IAC7C,YAAY;AAChB;;AAEA;;IAEI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".facet-picker-container .facet-picker-content {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 90vh;\n}\n\n.facet-picker-container .facet-picker-content .p-card {\n    width: 25em;\n}\n\n.facet-picker-container .p-card .p-card-content .buttons-container {\n    display: flex;\n    flex-direction: column;\n    row-gap: 0.5em;\n    align-items: center;\n    justify-content: center;\n}\n\n.facet-picker-container .p-card .p-card-content .buttons-container .p-button {\n    width: 15em;\n    background-color: var(--primary-background-color);\n    border-color: var(--primary-background-color);\n    color: white;\n}\n\n.facet-picker-container .p-card .p-card-content .buttons-container .p-button:hover,\n.facet-picker-container .p-card .p-card-content .buttons-container .p-button:active {\n    background-color: #2d33b3;\n    border-color: #2d33b3;\n}\n\n.facet-picker-container .facet-picker-content .p-card .p-card-title {\n    text-align: center;\n    font-size: 1.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
