import './KnowledgeMapReports.css'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import KnowledgeMapVisualization from '../KnowledgeMapVisualization/KnowledgeMapVisualization'
import NotificationArea from '../NotificationArea'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { knowledgeMapMetricsActions } from 'actions'
import useAssets from '../../hooks/useAssets'

const knowledgeMapMetricsSelector = state => state.knowledgeMapMetrics.knowledgeMapsMetrics
function KnowledgeMapReports() {
  const knowledgeMapMetrics = useSelector(knowledgeMapMetricsSelector, shallowEqual)

  const assets = useAssets()
  const params = useParams()
  const dispatch = useDispatch()

  const { studentId } = params


  useEffect(() => {
    if (studentId) {
      dispatch(knowledgeMapMetricsActions.getStudentKnowledgeMapMetrics(studentId))
    }
  }, [studentId])

  return (
    <div className='knowledge-map-reports-container'>
      <NotificationArea defaultText={assets.TESTRAX_V2.KNOWLEDGE_MAP_REPORT.HEADER.toUpperCase()} />
      <div className='knowledge-map-reports-visualization-container'>
        <KnowledgeMapVisualization knowledgeMapMetrics={knowledgeMapMetrics} />
      </div>
    </div>
  )
}

export default KnowledgeMapReports
