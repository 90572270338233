
import './KnowledgeMapVisualization.css'
import React, { useEffect, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Card } from 'primereact/card'
import { scaleThreshold } from 'd3'
import useAssets from '../../hooks/useAssets'

const KnowledgeMapVisualization = (props) => {
  const { knowledgeMapMetrics } = props

  const [knowledgeMapsCollapsed, setKnowledgeMapsCollapsed] = useState({})
  const [contentDomainsCollapsed, setContentDomainsCollapsed] = useState({})
  const [keysReady, setKeysReady] = useState(false)

  useEffect(() => {
    if (knowledgeMapMetrics) {
      const knowledgeMapsKeys = {}
      const contentDomainsKeys = {}
      for (const knowledgeMap of knowledgeMapMetrics) {
        const { contentDomainsMetrics } = knowledgeMap
        knowledgeMapsKeys[knowledgeMap._id] = false
        for (const contentDomain of contentDomainsMetrics) {
          contentDomainsKeys[contentDomain.contentDomainId] = false
        }
      }
      setKnowledgeMapsCollapsed(knowledgeMapsKeys)
      setContentDomainsCollapsed(contentDomainsKeys)
      setKeysReady(true)
    }
  }, [knowledgeMapMetrics])

  const updateKnowledgeMapKey = (key) => {
    setKnowledgeMapsCollapsed({
      ...knowledgeMapsCollapsed,
      [key]: !knowledgeMapsCollapsed[key] 
    })
  }
  
  const updateContentDomainKey = (key) => {
    setContentDomainsCollapsed({
      ...contentDomainsCollapsed,
      [key]: !contentDomainsCollapsed[key] 
    })
  }

  const assets = useAssets()

  const red = '#FF0D0D'
  const orangeRed = '#FF4E11'
  const orange = '#FF8E15'
  const yellow = '#FAB733'
  const lime = '#ACB334'
  const green = '#69B34C'

  const getColor = (node) => {
    // console.warn(node)
    const colors = [red, orangeRed, orange, yellow, lime, green]
    const colorsDomain = [0, 0.3, 0.5, 0.7, 0.9, 1]

    const color = scaleThreshold()
      .domain(colorsDomain)
      .range(colors)
    if (node.childrenKnowledgeMetricSum) {
      return color(node.childrenKnowledgeMetricSum)
    } else {
      return color(node.knowledgeMetric)
    }
  }
  return (
    <div className='knowledge-map-visualization-container'>
      {knowledgeMapMetrics && keysReady && knowledgeMapMetrics.length > 0
        ? <div className='summary-content-container'>
          {
                     knowledgeMapMetrics.map(knowledgeMapMetric =>
                       <div key={knowledgeMapMetric._id} className='knowledge-map-container'>
                         <div onClick={() => updateKnowledgeMapKey(knowledgeMapMetric._id)} style={{ backgroundColor: `${getColor(knowledgeMapMetric)}` }} className='knowledge-map-details-container'>
                           <i className={knowledgeMapsCollapsed[knowledgeMapMetric._id] ? 'pi pi-angle-down' : 'pi pi-angle-right'} style={{ fontSize: '1rem' }}></i>
                           <div className='knowledge-map-details'>{`${knowledgeMapMetric.description}(${knowledgeMapMetric.code})`}</div>
                           <div className='knowledge-metric-details'>{`${Math.floor(knowledgeMapMetric.knowledgeMetric * 100)}%`}</div>
                         </div>
                         <Collapse theme={{ content: 'content-domains-container' }} isOpened={knowledgeMapsCollapsed[knowledgeMapMetric._id]} className='content-domains-container'>
                           {
                                knowledgeMapMetric.contentDomainsMetrics.map(contentDomainMetric =>
                                  <div key={contentDomainMetric.contentDomainId}>
                                    <div onClick={() => updateContentDomainKey(contentDomainMetric.contentDomainId)} style={{ backgroundColor: `${getColor(contentDomainMetric)}` }} className='content-domain-details-container'>
                                      <i className={contentDomainsCollapsed[contentDomainMetric._id] ? 'pi pi-angle-down' : 'pi pi-angle-right'} style={{ fontSize: '1rem' }}></i>
                                      <div className='content-domain-details'>{`${contentDomainMetric.description}(${contentDomainMetric.code})`}</div>
                                      <div className='knowledge-metric-details'>{`${Math.floor(contentDomainMetric.knowledgeMetric * 100)}%`}</div>
                                    </div>
                                    <Collapse theme={{ content: 'content-items-container' }} isOpened={contentDomainsCollapsed[contentDomainMetric.contentDomainId]} >
                                      {
                                                contentDomainMetric.contentItemMetrics?.map(contentItemMetric =>
                                                  <div key={contentItemMetric.contentItemId}>
                                                    <div style={{ backgroundColor: `${getColor(contentItemMetric)}` }} className='content-item-details-container'>
                                                      <div className='content-item-details'>{`${contentItemMetric.description}(${contentItemMetric.code})`}</div>
                                                      <div className='knowledge-metric-details'>{`${Math.floor(contentItemMetric.knowledgeMetric * 100)}%`}</div>
                                                    </div>
                                                  </div>
                                                )
                                            }
                                    </Collapse>
                                  </div>)
                            }
                         </Collapse>
                       </div>)
}
        </div>
        : <div>{assets.TESTRAX_V2.KNOWLEDGE_MAP_REPORT.NO_DATA}</div>}
    </div>
  )
}

export default KnowledgeMapVisualization
