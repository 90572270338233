import { SERVER } from '../config/global'
import axios from 'axios'
import store from 'stores/store'

/**
 * @module facetsActions
 * @description actions for choosing user facets
 * @category actions
 */

export const PICK_USER_FACETS = 'PICK_USER_FACETS'

/**
 * @description Pick user facet
 * @action switchToMobileMode
 * @dispatches PICK_USER_FACETS
 */
export function pickUserFacet (userId, facet) {
  const token = store.getState().loginUser.user.token

  return {
    type: PICK_USER_FACETS,
    payload: async () => {
      return await axios.put(`${SERVER}/user-api/users/${userId}/facet`, { facet }, { headers: { Authorization: token } })
    }
  }
}
