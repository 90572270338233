import './Subscriptions.css'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import IconCircleArrow from 'assets/icons/icon-circle-arrow.svg'
import TestraxLogoV1 from 'assets/icons/testrax_v1.webp'
import IconPlusCircle from 'assets/icons/icon-plus-circle.svg'
import useAssets from '../../hooks/useAssets'
import NotificationArea from 'components/NotificationArea'
import BurgerMenu from 'components/BurgerMenu'
import { subscriptionActions } from 'actions'
import IconProfile from 'assets/icons/icon-profile.svg'


const Subscriptions = () => {
  const assets = useAssets()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.loginUser.user)
  const subscriptions = useSelector((state) => state.ownedSubscriptions.subscriptions)

  const sortedSubscriptions = subscriptions.sort((a, b) => {
    if (a.status === 'ACTIVE' && b.status === 'ACTIVE') {
      const aUsername = a.forUser[0].username
      const bUsername = b.forUser[0].username
      return aUsername.localeCompare(bUsername)
    }
    return a.status === 'ACTIVE' ? -1 : 1
  })

  const menuModel = [
    {
      url: '/user-profile',
      label: assets.TESTRAX_V2.PROFILE,
      icon: IconProfile,
      alt: 'icon-profile',
    },
  ]
  
  useEffect(() => {
    if (user) {
      dispatch(subscriptionActions.getOwnedSubscriptions(user._id))
    }
  }, [user, dispatch])

  const handleActiveSubscriptionClick = (subscription) => {
    navigate(`/parent-dashboard/subscriptions/${subscription._id}`)
  }

  const handlePendingSubscriptionClick = (subscription) => {
    navigate(`/parent-dashboard/subscriptions/${subscription._id}/assignment`)
  }

  return (
    <div className="subscriptions">
      <NotificationArea defaultText={assets.SUBSCRIPTIONS.SUBSCRIPTIONS.toUpperCase()} />
      <div className="subscription-list-container">
        {sortedSubscriptions.length === 0 && (
          <div className='no-subscriptions-container'>
            <div className='no-subscriptions-logo'>
              <img src={TestraxLogoV1}/>
            </div>
            <div className="no-subscriptions-message">
              {assets.TESTRAX_V2.NO_ACTIVE_SUBSCRIPTIONS}: <a href={`mailto:${process.env.SUPPORT_EMAIL || 'contact@nextlab.tech'}`}>{process.env.SUPPORT_EMAIL || 'contact@nextlab.tech'}</a>
            </div>
          </div>
        )}
        {sortedSubscriptions.length !== 0 && (
          <div className="subscription-list">
            {sortedSubscriptions.map((subscription) =>
              subscription.status === 'ACTIVE' ? (
                <div
                  key={subscription._id}
                  className="subscription-item active"
                  onClick={() => handleActiveSubscriptionClick(subscription)}
                >
                  <div className="content">
                    <div className="username">{subscription?.forUser[0]?.username}</div>
                  </div>
                  <div className="ops">
                    <img src={IconCircleArrow} alt="icon-circle-arrow" />
                  </div>
                </div>
              ) : (
                <div
                  key={subscription._id}
                  className="subscription-item pending"
                  onClick={() => handlePendingSubscriptionClick(subscription)}
                >
                  <div className="content">
                    <div className="test-name">{subscription.subscriptionPlan[0].highStakesTestDefinition[0].description}</div>
                    <div className="plan-type">{subscription.subscriptionPlan[0].description.toUpperCase()}</div>
                  </div>
                  <div className="ops">
                    <img src={IconPlusCircle} alt="icon-plus-circle" />
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
      <BurgerMenu menuItems={menuModel} />
    </div>
  )  
}

export default Subscriptions
