import React, { useEffect, useRef } from 'react'
import './TestContent.css'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { testActions } from 'actions'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ContentRenderer from 'components/ContentRenderer'
import NotificationArea from 'components/NotificationArea'
import useAssets from '../../hooks/useAssets'
// import { useReactToPrint } from 'react-to-print'
// import TestPrintContainer from 'components/TestPrintContainer'
import { Button } from 'primereact/button'
import { generateTestPDF } from '../../lib/pdf-lib'

const testSelector = (state) => state.test.test
const trophyRoadItemsSelector = (state) => state.trophyRoad.trophyRoadItems

const TestContent = () => {
  const { testId, trophyRoadId, trophyroaditemId } = useParams()
  const dispatch = useDispatch()
  const printerRef = useRef(null)
  const assets = useAssets()
  const navigate = useNavigate()

  useEffect(() => {
    if (testId) {
      dispatch(testActions.getTestContent(testId))
    }
  }, [testId])

  const test = useSelector(testSelector, shallowEqual)
  const trophyRoadItems = useSelector(trophyRoadItemsSelector, shallowEqual)

  const trophyRoadItem = trophyRoadItems?.find(item => item._id === trophyroaditemId)

  // const printTestContent = useReactToPrint({
  //   content: () => printerRef.current
  // })

  const navigateToCamera = () => {
    navigate(
      `/student-dashboard/trophyroads/${trophyRoadId}/trophyroaditems/${trophyroaditemId}/camera?knowledgeMap=${test.knowledgeMap._id}&eventType=${'TEST'}`,
      { state: { eventType: 'TEST', testId: testId } }
    )
  }

  const downloadPDF = async () => {
    try {
      await generateTestPDF(test)
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }

  const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1)

  return (
    <div className='test-content'>
      <NotificationArea defaultText={assets.TESTRAX_V2.HOME_TITLE.toUpperCase()} />
      <div className='test-content-container'>
        <h1>{test?.title}</h1>
        {trophyRoadItem && (
          <p>{assets.TESTRAX_V2.OBJECTIVE}: <em>{trophyRoadItem.description}</em></p>
        )}
        {test?.knowledgeMap?.description && (
          <p>{assets.TESTRAX_V2.TEST_KNOWLEDGE_MAP}: <em>{test.knowledgeMap.description}</em></p>
        )}
        <p className='instructions-label'><strong>{assets.TESTRAX_V2.TEST_INSTRUCTIONS}: </strong></p>

        <ul className='test-meta'>
          <li>{assets.TESTRAX_V2.PRINT_MESSAGE}</li>
          <li>{assets.TESTRAX_V2.UPLOAD_SOLUTIONS_INSTRUCTIONS_1}</li>
          <li>{assets.TESTRAX_V2.UPLOAD_SOLUTIONS_INSTRUCTIONS_2}</li>
        </ul>

        <div className='test-items'>
          {test?.content?.map((item, index) => (
            <div key={item._id} className='test-item'>
              <h3>{assets.TESTRAX_V2.EXERCISE} {index + 1}</h3>
              <ContentRenderer content={item.content} />
            </div>
          ))}
        </div>

        <Button onClick={() => downloadPDF()} className='print-button'>
         {assets.TESTRAX_V2.PRINT_TEST}
        </Button>

        { trophyRoadId && !trophyRoadItem?.isCompleted &&
          <Button onClick={navigateToCamera} className='answer-button'>
            {assets.TESTRAX_V2.SEND_ANSWERS}
          </Button>
        }

      </div>
      {/* <div style={{ visibility: 'hidden', position: 'absolute', height: 0, width: '100vw' }}>
        <TestPrintContainer ref={printerRef} test={test} trophyroaditemId={trophyroaditemId}/>
      </div> */}
    </div>
  )
}

export default TestContent
