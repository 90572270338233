import React from 'react'
import './UserProfile.css'
import NotificationArea from 'components/NotificationArea'
import { useNavigate } from 'react-router-dom'
import useAssets from 'hooks/useAssets'

const UserProfile = () => {
  const navigate = useNavigate()
  const assets = useAssets()

  const handleSettingsNavigation = () => {
    navigate('/user-profile/settings')
  }

  const handleStatisticsNavigation = () => {
    navigate('/user-profile/statistics')
  }

  return (
    <div className="user-profile-container">
      <NotificationArea defaultText={assets.TESTRAX_V2.PROFILE.toUpperCase()} />
      <div className="user-profile-list">
        <div
          className="user-profile-item"
          onClick={handleSettingsNavigation}
        >
          <span className="user-profile-label">
            <i className="user-profile-icon-left pi pi-cog"></i>
            {assets.TESTRAX_V2.USER_PROFILE.SETTINGS}
          </span>
          <button
            className="user-profile-navigate-button"
            onClick={handleSettingsNavigation}
          >
            <i className="user-profile-icon pi pi-angle-right"></i>
          </button>
        </div>
        {/* <div
          className="user-profile-item"
          onClick={handleStatisticsNavigation}
        >
          <span className="user-profile-label">
            <i className="user-profile-icon-left pi pi-chart-line"></i>
            {assets.TESTRAX_V2.USER_PROFILE.STATISTICS}
          </span>
          <button
            className="user-profile-navigate-button"
            onClick={handleStatisticsNavigation}
          >
            <i className="user-profile-icon pi pi-angle-right"></i>
          </button>
        </div> */}
      </div>
    </div>
  )
}

export default UserProfile
