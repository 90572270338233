
import './NotificationArea.css'
import React, { useEffect, useState} from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import HelpOverlay from './HelpOverlay'
import IconLogout from 'assets/icons/shutdown-svgrepo-com.svg'
import iconHelp from 'assets/icons/question-svgrepo-com.svg'
import { loginActions, helpOverlayActions } from 'actions'

const shouldShowHelpOverlaySelector = state => state.helpOverlay.shouldShow

function NotificationArea (props) {
  const { defaultText, notificationText } = props
 	// const hasBeenHidden = localStorage.getItem('hasBeenHidden')

  const shouldShowHelpOverlay = useSelector(shouldShowHelpOverlaySelector, shallowEqual)

  // const [ helpShown, setHelpShown ] = useState(!shouldShowHelpOverlay)
  // const [isManualToggle, setIsManualToggle] = useState(false)
  const [ activeNotificationText, setActiveNotificationText ] = useState('')
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(loginActions.logout())
  }

  useEffect(() => {
    if (notificationText) {
      setActiveNotificationText(notificationText)
    }
  }, [ notificationText ])

  function handleNotificationAnimationEnd () {
    setActiveNotificationText('')
  }

  const showHelp = () => {
  	// setHelpShown(true)
    // setIsManualToggle(true)
    dispatch(helpOverlayActions.showHelp())
  }

  const handleHelpHide = () => {
  	// setHelpShown(false)
    // setIsManualToggle(false)
    dispatch(helpOverlayActions.hideHelp())
    // localStorage.setItem('hasBeenHidden', true)
  }

  return (
    <div className='notification-area'>
    	<HelpOverlay shouldShow={shouldShowHelpOverlay} isManualToggle={shouldShowHelpOverlay} onHide={handleHelpHide} />
      <div className='help' onClick={showHelp}>
        <img src={iconHelp} alt='HELP'/>
      </div>
      <div className='title' onAnimationEnd={ handleNotificationAnimationEnd }>
        {
          activeNotificationText ? (
            <div className='ticker-container'>
                <div className='ticker-tape'>
                  {defaultText}
                </div>
                <div className='ticker-tape'>
                  {activeNotificationText}
                </div>
                <div className='ticker-tape'>
                  {defaultText}
                </div>
            </div>
          ) :
          <div className='default-text'>
            {defaultText}
          </div>
        }
      </div>
      <div className='logout' onClick={ handleLogout }>
        <img src={IconLogout} alt='icon-logout' />
      </div>
    </div>
  )
}

export default NotificationArea
