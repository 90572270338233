// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-manager {
  display: none;
  position: fixed;
  left: 4%;
  width: 92%;
  height: 10%;
  border-radius: 0.5em;
  z-index: 999999;
}

.toast-background-color-positive {
  background-color: rgba(80, 238, 93, 0.8);
}

.toast-background-color-neutral {
  background-color: rgba(227, 238, 80, 0.8);
}

.toast-background-color-negative {
  background-color: rgba(238, 80, 80, 0.8);
}

.toast-content-span {
  padding: 1em;
  font-weight: bold;
  position: fixed;
}

.toast-position-top {
  top: 10%
}

.toast-position-bottom {
  bottom: 10%;
}`, "",{"version":3,"sources":["webpack://./src/components/ToastManager/ToastManager.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,QAAQ;EACR,UAAU;EACV,WAAW;EACX,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE;AACF;;AAEA;EACE,WAAW;AACb","sourcesContent":[".toast-manager {\n  display: none;\n  position: fixed;\n  left: 4%;\n  width: 92%;\n  height: 10%;\n  border-radius: 0.5em;\n  z-index: 999999;\n}\n\n.toast-background-color-positive {\n  background-color: rgba(80, 238, 93, 0.8);\n}\n\n.toast-background-color-neutral {\n  background-color: rgba(227, 238, 80, 0.8);\n}\n\n.toast-background-color-negative {\n  background-color: rgba(238, 80, 80, 0.8);\n}\n\n.toast-content-span {\n  padding: 1em;\n  font-weight: bold;\n  position: fixed;\n}\n\n.toast-position-top {\n  top: 10%\n}\n\n.toast-position-bottom {\n  bottom: 10%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
