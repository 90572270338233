import React from 'react'
import './SuspenseLoading.css'

import logo from 'assets/updated_logo-slogan-color.webp'

const SuspenseLoading = () => {
  return (
    <div className='suspense-loading'>
      <img
        src={logo}
        alt='Logo'
        className='logo'
      />
    </div>
  )
}

export default SuspenseLoading
