// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-student-dashboard ul.p-tabview-nav li {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
}

.primary-student-dashboard ul.p-tabview-nav li a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary-student-dashboard ul .p-tabview-ink-bar {
  background-color: var(--primary-color);
}

.primary-student-dashboard .p-tabview-panels {
  padding: 0px;
  /* position: fixed; */
}

.primary-student-dashboard {
  position: relative;
  padding-top: calc(var(--notification-area-height) + var(--tab-header-height)); 
}

.primary-student-dashboard .notification-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.primary-student-dashboard .p-tabview-nav-container {
  position: fixed;
  top: var(--notification-area-height); 
  left: 0;
  width: 100%;
  z-index: 999; 
}
`, "",{"version":3,"sources":["webpack://./src/components/PrimaryStudentDashboard/PrimaryStudentDashboard.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,6EAA6E;AAC/E;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;AACf;;AAEA;EACE,eAAe;EACf,oCAAoC;EACpC,OAAO;EACP,WAAW;EACX,YAAY;AACd","sourcesContent":[".primary-student-dashboard ul.p-tabview-nav li {\n  width: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-grow: 2;\n}\n\n.primary-student-dashboard ul.p-tabview-nav li a {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.primary-student-dashboard ul .p-tabview-ink-bar {\n  background-color: var(--primary-color);\n}\n\n.primary-student-dashboard .p-tabview-panels {\n  padding: 0px;\n  /* position: fixed; */\n}\n\n.primary-student-dashboard {\n  position: relative;\n  padding-top: calc(var(--notification-area-height) + var(--tab-header-height)); \n}\n\n.primary-student-dashboard .notification-area {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  z-index: 1000;\n}\n\n.primary-student-dashboard .p-tabview-nav-container {\n  position: fixed;\n  top: var(--notification-area-height); \n  left: 0;\n  width: 100%;\n  z-index: 999; \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
