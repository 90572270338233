
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { galleryActions, licensedSubjectsActions, trophyRoadActions, toastActions } from 'actions'
import './Gallery.css'
import { MAX_UPLOAD_SIZE_MB } from 'config/global'
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useQuery from 'hooks/useQuery' 
import TotalSizeDisplay from 'components/TotalSizeDisplay'
import BurgerMenu from 'components/BurgerMenu'
import NotificationArea from 'components/NotificationArea'
import IconFolder from 'assets/icons/icon-folder.svg'
import IconSend from 'assets/icons/icon-send.svg'
import IconCamera from 'assets/icons/icon-camera.svg'
import IconTrash from 'assets/icons/icon-trash.svg'

const MAX_SIZE_BYTES = MAX_UPLOAD_SIZE_MB * 1024 * 1024

const assetSelector = state => state.language.assets
const picturesSelector = state => state.gallery.pictures
const totalSizeSelector = state => state.gallery.totalSize
const trophyRoadItemsSelector = state => state.trophyRoad.trophyRoadItems
const subscriptionIdSelector = state => state.subscription.subscriptionId

function Gallery(props) {
  const { trophyroadId, trophyroaditemId } = useParams()
  const [platformSelect, setPlatformSelect] = useState('')
  const [zoomedImage, setZoomedImage] = useState(null)
  
  const browserFileInputRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()  
  const knowledgeMap = query.get("knowledgeMap")
  const eventType = query.get("eventType")

  const photoUploadRef = useRef(null)

  const assets = useSelector(assetSelector, shallowEqual)
  const pictures = useSelector(picturesSelector, shallowEqual)
  const totalSize = useSelector(totalSizeSelector, shallowEqual) 
  const trophyRoadItems = useSelector(trophyRoadItemsSelector, shallowEqual)
  const subscriptionId = useSelector(subscriptionIdSelector, shallowEqual)

  useEffect(() => {
    const platform = window.localStorage.getItem('PLATFORM')
    console.warn('Platform ' + platform)
    setPlatformSelect(platform)

    if (trophyroadId) {
      dispatch(trophyRoadActions.getTrophyRoad(subscriptionId))
      // console.warn('Trophy Road Items:', trophyRoadItems)
    }
  }, [])

  useEffect(() => {
    if (trophyroaditemId && trophyRoadItems.length > 0) {
      const currentItem = trophyRoadItems.find(item => item._id === trophyroaditemId)
    }
  }, [trophyroaditemId, trophyRoadItems])

  const handleBrowserTriggerUpload = () => {
    browserFileInputRef.current.click()
  }

  const getBase64URI = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = function(event) {
        resolve(event.target.result)
      }
      
      reader.onerror = function(error) {
        reject(error)
      }
      
      reader.readAsDataURL(file)
    })
  }

  const handleNavigation = (basePath) => {
    let queryParams = []
  
    if (knowledgeMap) {
      queryParams.push(`knowledgeMap=${knowledgeMap}`)
    }
  
    if (eventType) {
      queryParams.push(`eventType=${eventType}`)
    }
  
    const queryString = queryParams.length ? `?${queryParams.join("&")}` : ""
    const fullPath = `${basePath}${queryString}`
  
    return fullPath
  }
  

  const handleBrowserSelectImage = async (evt) => {
    try {
      const files = evt.target.files
      const totalSizeOfFiles = Array.from(files).reduce((total, file) => total + file.size * (4 / 3), 0)
      const currentTotalSize = totalSize 
      if (currentTotalSize + totalSizeOfFiles > MAX_SIZE_BYTES) {
        dispatch(
          toastActions.pushNotification({
            text: assets.TESTRAX_V2.UPLOAD_LIMIT_EXCEEDED_WARNING,
            navigationTarget: null,
            position: 'BOTTOM',
            color: 'NEGATIVE',
            life: 3000,
          })
        )
        return
      }
  
      for (const file of files) {
        const dataURI = await getBase64URI(file)
        dispatch(galleryActions.addPicture(dataURI))
      }
  
        navigate(handleNavigation(trophyroaditemId 
        ? `/student-dashboard/trophyroads/${trophyroadId}/trophyroaditems/${trophyroaditemId}/gallery` 
        : '/student-dashboard/gallery'))

        } catch (e) {
      console.warn(e)
    }
  }
  
  const uint8ArrayToBase64 = (uint8Array, mediaType) => {
    const metadata = `data:${mediaType};base64,`
    let binary = '' 
    const len = uint8Array.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(uint8Array[i])
    }
    return metadata + window.btoa(binary)
  }

  const handleMobileSelectImage = async () => {
    try {
      const chooserObject = window.chooser || chooser;
      const files = await chooserObject.getFile()
  
      const totalSizeOfFiles = files.reduce((total, file) => total + file.data.byteLength * (4 / 3), 0)
      const currentTotalSize = totalSize
      if (currentTotalSize + totalSizeOfFiles > MAX_SIZE_BYTES) {
        dispatch(
          toastActions.pushNotification({
            text: assets.TESTRAX_V2.UPLOAD_LIMIT_EXCEEDED_WARNING,
            navigationTarget: null,
            position: 'BOTTOM',
            color: 'NEGATIVE',
            life: 3000,
          })
        )
        return
      }
  
      if (files && files.length > 0) {
        for (const file of files) {
          const base64 = uint8ArrayToBase64(file.data, file.mediaType)
          dispatch(galleryActions.addPicture(base64))
        }
        
        navigate(handleNavigation(trophyroaditemId 
          ? `/student-dashboard/trophyroads/${trophyroadId}/trophyroaditems/${trophyroaditemId}/gallery` 
          : '/student-dashboard/gallery'))

          }
    } catch (e) {
      console.warn(e)
    }
  }

  const handleImageClick = (image) => {
    setZoomedImage(image)
  }

  const closeZoom = () => {
    setZoomedImage(null)
  }

  const handleDeleteImage = (index) => {
    dispatch(galleryActions.removePicture(index)) 
  }

  const menuModel = [{
      url: handleNavigation(trophyroaditemId ? `/student-dashboard/trophyroads/${trophyroadId}/trophyroaditems/${trophyroaditemId}/submission-editor` : '/student-dashboard/submission-editor'),
      label: assets.TESTRAX_V2.SEND,
      icon: IconSend,
      alt: 'icon-send'
  },{
      url: handleNavigation(trophyroaditemId ? `/student-dashboard/trophyroads/${trophyroadId}/trophyroaditems/${trophyroaditemId}/camera` : '/student-dashboard/camera'),
      label: assets.TESTRAX_V2.CAMERA,
      icon: IconCamera,
      alt: 'icon-camera'
  }, {
    action:  platformSelect === 'browser' ? () => handleBrowserTriggerUpload() : () => handleMobileSelectImage(),
      label: assets.TESTRAX_V2.SELECT_FILE,
      icon: IconFolder,
      alt: 'icon-folder'
  }]

  return (
    <>
    <div className='gallery-container'>
      <NotificationArea defaultText={assets.TESTRAX_V2.GALLERY_TITLE.toUpperCase()} />
      <TotalSizeDisplay totalSize={totalSize} />
      {trophyroaditemId && (
    <div className='trophyroad-info'>
      <p>
        {assets.TESTRAX_V2.OBJECTIVE} : {' '}
        {
          trophyRoadItems.length > 0 && trophyRoadItems.find(item => item._id === trophyroaditemId)?.learningObjectiveDescription || ''
        }
      </p>
    </div>
  )}
      <div className='content-area'>
        {pictures.length > 0 ? (
          pictures.map((image, index) => (
            <div
              key={index}
              className='picture-container'
              style={{ backgroundImage: `url(${image})` }}
              onClick={() => handleImageClick(image)}
            >
              <div
                className='delete-btn'
                onClick={(e) => {
                  e.stopPropagation()
                  handleDeleteImage(index)
                }}
              >
                <img src={IconTrash} alt='icon-trash' />
              </div>
            </div>
          ))
        ) : (
            <div className="no-pictures-message">
            {assets.TESTRAX_V2.NO_UPLOADED_PICTURES}
            </div>
        )}
      </div>
      <BurgerMenu menuItems={menuModel} />
      <input
        type='file'
        accept='image/*'
        multiple
        onChange={platformSelect === 'browser' ? handleBrowserSelectImage : handleMobileSelectImage}
        style={{ display: 'none' }}
        ref={browserFileInputRef}
        onClick={(event) => {
          event.target.value = null
        }}
      />
        {/* <input
          type='file'
          accept='image/*'
          onChange={handleBrowserSelectImage}
          style={{ display: 'none' }}
          ref={browserFileInputRef}
          onClick={(event) => { event.target.value = null }}
        /> */}
      {zoomedImage && (
          <div className="fullscreen-overlay" onClick={closeZoom}>
          <button
              className="exit-button"
            onClick={(e) => {
              e.stopPropagation()
              closeZoom()
            }}
          >
            ✕
          </button>
            <img src={zoomedImage} alt="Zoomed" className="fullscreen-image" />
        </div>
      )}
    </div>
    </>
  )
}

export default Gallery
