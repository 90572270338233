import { licensedSubjectsActions, testActions } from 'actions'
import React, { useState, useEffect } from 'react'
import './ExerciseDemandForm.css'
import NotificationArea from 'components/NotificationArea'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { useNavigate } from 'react-router-dom'

const licensedSubjectsSelector = state => state.licensedSubjects.licensedSubjects
const isFetchingLicensedSubjectsSelecor = state => state.licensedSubjects.fetching
const fetchedLicensedSubjectsSelector = state => state.licensedSubjects.fetched
const isFetchingTestSelector = state => state.test.fetching
const isFetchedTestSelector = state => state.test.fetched
const testIdSelector = state => state.test.testId
const assetSelector = state => state.language.assets
const trophyRoadItemsSelector = state => state.trophyRoad.trophyRoadItems

function ExerciseDemandForm() {
  const assets = useSelector(assetSelector, shallowEqual)
  const licensedSubjects = useSelector(licensedSubjectsSelector, shallowEqual)
  const isFetchingLicensedSubjects = useSelector(isFetchingLicensedSubjectsSelecor, shallowEqual)
  const fetchedLicensedSubjects = useSelector(fetchedLicensedSubjectsSelector, shallowEqual)
  const isFetchingTest = useSelector(isFetchingTestSelector, shallowEqual)
  const isFetchedTest = useSelector(isFetchedTestSelector, shallowEqual)
  const testId = useSelector(testIdSelector, shallowEqual)
  const trophyRoadItems = useSelector(trophyRoadItemsSelector, shallowEqual)
  const currentTrophyRoadItem = trophyRoadItems?.find(item => !item.isCompleted)
  const isExerciseRequestDisabled = currentTrophyRoadItem?.condition?.type !== 'VALID_PAGES'

  const [subject, setSubject] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  if (!(licensedSubjects && licensedSubjects.length > 0) && !isFetchingLicensedSubjects && !fetchedLicensedSubjects) {
    dispatch(licensedSubjectsActions.getLicensedSubjects())
  }

  useEffect(() => {
    if (!subject && licensedSubjects.length > 0) {
      setSubject(licensedSubjects[0])
    }
  }, [licensedSubjects])

  useEffect(() => {
    if (!isFetchingTest && isFetchedTest && testId) {
      navigate(`/student-dashboard/tests/${testId}/content`)
    }
  }, [isFetchingTest, isFetchedTest, testId])

  return (
    <div className='exercise-demand-form-container'>
      <NotificationArea defaultText={assets.TESTRAX_V2.DEMAND_EXERCISE_SET.toUpperCase()} />
      <div className='exercise-demand-form'>
        <span>{assets.TESTRAX_V2.EXERCISE_SET_SUBJECT}:</span>
        {licensedSubjects?.length > 0 ? (
          <div className='subject-selector'>
            {licensedSubjects.map((option) => (
              <div key={option.value} className="accordion-option-container" onClick={() => setSubject(option)}>
                <RadioButton inputId={option.value} name="accordion-option subject-options" value={option} onChange={(e) => setSubject(e.value)} checked={option.value === subject?.value} />
                <label htmlFor={option.value} className="ml-2">{option.label}</label>
              </div>
            ))}
          </div>
        ) : (
          <span>{assets.TESTRAX_V2.SUBJECTS_NOT_AVAILABLE}</span>
        )}
        {licensedSubjects.length === 0 && !isFetchingLicensedSubjects && (
          <div>
            <span>{assets.TESTRAX_V2.NO_SUBJECTS}: {process.env.SUPPORT_EMAIL || 'contact@nextlab.tech'}</span>
          </div>
        )}
        <div className='button-div'>
          <Button className='confirm-button' label={assets.TESTRAX_V2.CONFIRM_EXERCISE_REQUEST} disabled={isExerciseRequestDisabled} onClick={() => dispatch(testActions.demandExercises(subject.value, currentTrophyRoadItem._id))} />
        </div>
      </div>
    </div>
  )
}

export default ExerciseDemandForm
