// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.knowledge-map-reports-container .knowledge-map-reports-visualization-container {
   display: flex; 
   justify-content: center;
   margin-top: 5%;
}`, "",{"version":3,"sources":["webpack://./src/components/KnowledgeMapReports/KnowledgeMapReports.css"],"names":[],"mappings":"AAAA;GACG,aAAa;GACb,uBAAuB;GACvB,cAAc;AACjB","sourcesContent":[".knowledge-map-reports-container .knowledge-map-reports-visualization-container {\n   display: flex; \n   justify-content: center;\n   margin-top: 5%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
