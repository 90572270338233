/**
 * @module trophyRoadActions
 * @description actions for trophyRoad
 * @category actions
 */
import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const GET_TROPHY_ROAD = 'GET_TROPHY_ROAD'

/**
 * @description GET trophy road
 * @action getTrophyRoad
 * @dispatches GET_TROPHY_ROAD
 */
export function getTrophyRoad (subscriptionId) {
    const user = store.getState().loginUser.user
    const facet = store.getState().loginUser.user.facets.includes('parent') ? 'parent' : 'student'
    return {
      type: GET_TROPHY_ROAD,
      payload: axios.get(`${SERVER}/${facet}-api/subscriptions/${subscriptionId}/trophy-road`, { headers: { Authorization: user.token } })
    }
  }