import React from 'react'
import './Settings.css'
import NotificationArea from 'components/NotificationArea'
import { useNavigate } from 'react-router-dom'
import useAssets from 'hooks/useAssets'

const Settings = () => {
  const navigate = useNavigate()
  const assets = useAssets()

  const handleLanguageNavigation = () => {
    navigate('language')
  }

  return (
    <div className='settings-container'>
      <NotificationArea defaultText={assets.TESTRAX_V2.SETTINGS.HEADER.toUpperCase()} />
      <div className='settings-list'>
        <div className='settings-item' onClick={handleLanguageNavigation} >
          <span className='settings-label'>
            <i className='settings-icon-left pi pi-globe'></i>
            {assets.TESTRAX_V2.SETTINGS.LANGUAGE}
          </span>
          <button
            className='settings-navigate-button'
            onClick={handleLanguageNavigation}
          >
            <i className='settings-icon pi pi-angle-right'></i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Settings
