
import React from 'react'
import { useSelector } from 'react-redux'

/**
 * @description selects assets from state and returns them
 * @returns assets state
 */
const useAssets = () => {
  const assets = useSelector(state => state.language.assets)
  return assets
}

export default useAssets
