import './LoginForm.css'
import React from 'react'

import LoginClassic from 'components/LoginClassic'
import { useLocation } from 'react-router-dom'

const LoginForm = (props) => {
  const location = useLocation()

  return (
    <div className='login-form-page'>
      <div className='page-login-cards-container'>
        <div className='login-classic-container'>
          <LoginClassic username={location?.state?.user || ''} destination={location?.state?.destination} />
        </div>
      </div>
    </div>
  )
}

export default LoginForm
