import { Navigate, Route, Routes } from 'react-router-dom'
import PrimaryStudentDashboard from '../PrimaryStudentDashboard'
import './StudentDashboard.css'
import Cam from 'components/Cam'
import SubmissionEditor from 'components/SubmissionEditor'
import ImageUpload from 'components/ImageUpload'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MAX_UPLOAD_SIZE_MB } from 'config/global'
import Gallery from 'components/Gallery'
import SubmissionContent from 'components/SubmissionContent' 
import SubmissionGrading from 'components/SubmissionGrading'; 
import TrophyRoadItemDetails from '../TrophyRoadItemDetails'
import ExerciseDemandForm from 'components/ExerciseDemandForm'
import TrophyRoadItemCompleted from 'components/TrophyRoadItemCompleted'
import TrophyRoadItemActivityFeed from 'components/TrophyRoadItemActivityFeed'
import TestContent from 'components/TestContent'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { galleryActions, toastActions } from '../../actions'
import useAssets from 'hooks/useAssets'

const MAX_SIZE_BYTES = MAX_UPLOAD_SIZE_MB * 1024 * 1024
const userSelector = state => state.loginUser.user

function StudentDashboard() { 
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const assets = useAssets()
  const totalSize = useSelector((state) => state.gallery.totalSize, shallowEqual)

  const handleAddPicture = (picture, trophyroadId = null, trophyroaditemId = null, knowledgeMap = null, eventType = null) => {
    const pictureSize = picture.length
    if (totalSize + pictureSize > MAX_SIZE_BYTES) {
      const galleryPath = trophyroadId && trophyroaditemId ? `/student-dashboard/trophyroads/${trophyroadId}/trophyroaditems/${trophyroaditemId}/gallery?knowledgeMap=${knowledgeMap}&eventType=${eventType}` : `/student-dashboard/gallery?knowledgeMap=${knowledgeMap}&eventType=${eventType}`
      navigate(galleryPath)
  
      dispatch(
        toastActions.pushNotification({
          text: assets.TESTRAX_V2.UPLOAD_LIMIT_EXCEEDED_WARNING,
          navigationTarget: null,
          position: 'BOTTOM',
          color: 'NEGATIVE',
          life: 3000
        })
      )
      return
    }
  
    dispatch(galleryActions.addPicture(picture))
  }

  const handleNavigation = (basePath, knowledgeMap, eventType) => {
    let queryParams = [];
    if (knowledgeMap) queryParams.push(`knowledgeMap=${knowledgeMap}`)
    if (eventType) queryParams.push(`eventType=${eventType}`)
    return `${basePath}${queryParams.length ? `?${queryParams.join('&')}` : ''}`
  }

  const user = useSelector(userSelector, shallowEqual)

  if (user.facets.includes('parent')) {
    return <Navigate to={{ pathname: '/parent-dashboard' }} />
  } else {
    return (
      <Routes>
      <Route path="camera/*" element={<Cam onPhotoCapture={(picture, { knowledgeMap, eventType }) => handleAddPicture(picture, null, null, knowledgeMap, eventType)} onDone={({ knowledgeMap, eventType }) => navigate(handleNavigation('/student-dashboard/gallery', knowledgeMap, eventType))} />} />
      <Route path="trophyroads/:trophyroadId/trophyroaditems/:trophyroaditemId/camera" element={<Cam onPhotoCapture={(picture, { trophyroadId, trophyroaditemId, knowledgeMap, eventType }) => handleAddPicture(picture, trophyroadId, trophyroaditemId, knowledgeMap, eventType)} onDone={({ trophyroadId, trophyroaditemId, knowledgeMap, eventType }) => navigate(handleNavigation(`/student-dashboard/trophyroads/${trophyroadId}/trophyroaditems/${trophyroaditemId}/gallery`, knowledgeMap, eventType))} />} />
      <Route path="gallery/*" element={<Gallery />} />
      <Route path="trophyroads/:trophyroadId/trophyroaditems/:trophyroaditemId/gallery" element={<Gallery />} />
      <Route path="submission-editor/*" element={<SubmissionEditor />} />
      <Route path="trophyroads/:trophyroadId/trophyroaditems/:trophyroaditemId/submission-editor" element={<SubmissionEditor />} />
      <Route path="files/*" element={<ImageUpload />} />
      <Route path="submissions/:submissionId" element={<SubmissionContent />} /> 
      <Route path="/trophyroads/:trophyRoadId/trophyroaditems/:trophyroaditemId/tests/:testId/content" element={<TestContent />} />
      <Route path="tests/:testId/content" element={<TestContent />} /> 
      <Route path="submission-gradings/:submissionGradingId" element={<SubmissionGrading />} /> 
      <Route path="subscriptions/:subscriptionId/trophyroads/:trophyroadId/trophyroaditems/:trophyroaditemId" element={<TrophyRoadItemDetails/>}/>
      <Route path='subscriptions/:subscriptionId/trophyroads/:trophyroadId/completedtrophyroaditems/:trophyroaditemId' element={<TrophyRoadItemCompleted />} />
      <Route path="trophyroads/:trophyroadId/trophyroaditems/:trophyroaditemId" element={<TrophyRoadItemActivityFeed/>}/>
      <Route path='exercise-request-form' element={<ExerciseDemandForm />} />
      <Route exact index element={<PrimaryStudentDashboard />} />
    </Routes>
    )
  }
}

export default StudentDashboard
