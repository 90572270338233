/**
 * @module platformInfoActions
 * @description actions for platform info operations
 * @category actions
 */

export const SAVE_PLATFORM_INFO = 'SAVE_PLATFORM_INFO'

export function addPlatformInfo (platform, screenResolution) {
  return {
    type: SAVE_PLATFORM_INFO,
    payload: {
      platform: platform,
      screenResolution: screenResolution
    }
  }
}
