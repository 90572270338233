/**
 * @module sidebarActions
 * @description actions for sidebar
 * @category actions
 */

export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR'

export const setIsSidebarCollapsed = (value) => {
  return {
    type: COLLAPSE_SIDEBAR,
    payload: value
  }
}
