
import React, { useState, useEffect, useRef } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'

import './PrimaryStudentDashboard.css'
import TrophyRoadViewer from 'components/TrophyRoadViewer'
import StudentActivityFeed from 'components/StudentActivityFeed'
import { shallowEqual, useSelector } from 'react-redux'
import { toastActions } from '../../actions'
import { MAX_UPLOAD_SIZE_MB } from 'config/global'
import BurgerMenu from 'components/BurgerMenu'
import NotificationArea from 'components/NotificationArea'
import IconCamera from 'assets/icons/icon-camera.svg'
import IconFolder from 'assets/icons/icon-folder.svg'
import IconPlus from 'assets/icons/icon-plus-circle.svg'
import IconProfile from 'assets/icons/icon-profile.svg'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { galleryActions } from 'actions'

const MAX_SIZE_BYTES = MAX_UPLOAD_SIZE_MB * 1024 * 1024

const assetSelector = state => state.language.assets
// const picturesSelector = state => state.gallery.pictures
const totalSizeSelector = state => state.gallery.totalSize
const trophyRoadItemsSelector = state => state.trophyRoad.trophyRoadItems

const PrimaryStudentDashboard = () => {
  const [platformSelect ,setPlatformSelect] = useState('')
  const [activeIndex, setActiveIndex] = useState(0)

  const assets = useSelector(assetSelector, shallowEqual)
  const totalSize = useSelector(totalSizeSelector, shallowEqual)
  const trophyRoadItems = useSelector(trophyRoadItemsSelector, shallowEqual)

  const browserFileInputRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isExerciseRequestDisabled = () => {
    if (!trophyRoadItems || trophyRoadItems.length === 0) return false
    
    const currentItem = trophyRoadItems.find(item => !item.isCompleted)
    if (!currentItem || !currentItem.condition || typeof currentItem.condition !== 'object') return false
  
    const type = currentItem.condition.type
    return type !== 'VALID_PAGES'
  }

  const menuModel = [{
    url: '/student-dashboard/camera',
    label: assets.TESTRAX_V2.CAMERA,
    icon: IconCamera,
    alt: 'icon-camera'
  }, {
    action: () => platformSelect === 'browser' ? handleBrowserTriggerUpload() : handleMobileSelectImage(),
    label: assets.TESTRAX_V2.SELECT_FILE,
    icon: IconFolder,
    alt: 'icon-folder'
  }, {
    url: '/student-dashboard/exercise-request-form',
    label: assets.TESTRAX_V2.DEMAND_EXERCISE_SET,
    icon: IconPlus,
    alt: 'icon-plus',
    disabled: isExerciseRequestDisabled()
  }, {
    url: '/user-profile',
    label: assets.TESTRAX_V2.PROFILE,
    icon: IconProfile,
    alt: 'icon-profile'
  }
]

  useEffect(() => {
    const platform = window.localStorage.getItem('PLATFORM')
    const lastSelectedTab = window.localStorage.getItem('student-dashboard-last-selected-tab')
    if (lastSelectedTab) {
      setActiveIndex(Number(lastSelectedTab))
      window.localStorage.removeItem('student-dashboard-last-selected-tab')
    }
    setPlatformSelect(platform)
  }, [])

  const handleBrowserTriggerUpload = () => {
    browserFileInputRef.current.click()
  }

  const getBase64URI = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = function(event) {
          resolve(event.target.result)
      }

      reader.onerror = function(error) {
          reject(error)
      }

      reader.readAsDataURL(file)
    })
  }

  const handleBrowserSelectImage = async (evt) => {
    try {
      const files = evt.target.files

      const totalSizeOfFiles = Array.from(files).reduce((total, file) => total + file.size * (4 / 3), 0)
      const currentTotalSize = totalSize

      if (currentTotalSize + totalSizeOfFiles > MAX_SIZE_BYTES) {
        dispatch(
          toastActions.pushNotification({
            text: assets.TESTRAX_V2.UPLOAD_LIMIT_EXCEEDED_WARNING,
            navigationTarget: null,
            position: 'BOTTOM',
            color: 'NEGATIVE',
            life: 3000,
          })
        )
        return
      }

      for (const file of files) {
        const dataURI = await getBase64URI(file)
        dispatch(galleryActions.addPicture(dataURI))
      }

      navigate('/student-dashboard/gallery')
    } catch (e) {
      console.warn(e)
    }
  }


  const uint8ArrayToBase64 = (uint8Array, mediaType) => {
    const metadata = `data:${mediaType};base64,`
    let binary = ''
    const len = uint8Array.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(uint8Array[i])
    }
    return metadata + window.btoa(binary)
  }

  const handleMobileSelectImage = async () => {
    try {
      const chooserObject = window.chooser || chooser;
      const files = await chooserObject.getFile()

      const totalSizeOfFiles = files.reduce((total, file) => total + file.data.byteLength * (4 / 3), 0)
      const currentTotalSize = totalSize

      if (currentTotalSize + totalSizeOfFiles > MAX_SIZE_BYTES) {
        dispatch(
          toastActions.pushNotification({
            text: assets.TESTRAX_V2.UPLOAD_LIMIT_EXCEEDED_WARNING,
            navigationTarget: null,
            position: 'BOTTOM',
            color: 'NEGATIVE',
            life: 3000,
          })
        )
        return
      }

      if (files && files.length > 0) {
        for (const file of files) {
          const base64 = uint8ArrayToBase64(file.data, file.mediaType)
          dispatch(galleryActions.addPicture(base64))
        }
        navigate('/student-dashboard/gallery')
      }
    } catch (e) {
      console.warn(e)
    }
  }

  const handleOnTabChange = (e) => {
    setActiveIndex(e.index)
    window.localStorage.setItem('student-dashboard-last-selected-tab', e.index)
  }

  return (
    <div className='primary-student-dashboard'>
      <NotificationArea defaultText={assets.TESTRAX_V2.HOME_TITLE.toUpperCase()} />

      <TabView activeIndex={activeIndex} onTabChange={(e) => handleOnTabChange(e) } className='nav-tabview'>
        <TabPanel header={assets.TESTRAX_V2.TROPHY_ROAD} >
          <TrophyRoadViewer />
        </TabPanel>
        <TabPanel header={assets.TESTRAX_V2.ACTIVITY_FEED} >
           <StudentActivityFeed />
        </TabPanel>
      </TabView>
      <BurgerMenu menuItems={menuModel} />
      <input
        type='file'
        accept='image/*'
        multiple
        onChange={platformSelect === 'browser' ? handleBrowserSelectImage : handleMobileSelectImage}
        style={{ display: 'none' }}
        ref={browserFileInputRef}
        onClick={(event) => { event.target.value = null }}
      />
    </div>
  )
}

export default PrimaryStudentDashboard
