import './TrophyRoadItemActivityFeed.css'
import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { activityActions, galleryActions, toastActions } from '../../actions'
import TestraxLogoV1 from 'assets/icons/testrax_v1.webp'
import StudentTestingEvent from 'components/StudentActivityFeed/StudentTestingEvent'
import NotificationArea from 'components/NotificationArea'
import BurgerMenu from 'components/BurgerMenu'
import IconCamera from 'assets/icons/icon-camera.svg'
import IconFolder from 'assets/icons/icon-folder.svg'
import { MAX_UPLOAD_SIZE_MB } from 'config/global'
import previewPlaceholder from 'assets/icons/gift-svgrepo-com.svg'
import iconMedal from 'assets/icons/icon-medal.svg'
import iconStar from 'assets/icons/icon-star.svg'
import iconTrophy from 'assets/icons/icon-trophy.svg'
import iconGift from 'assets/icons/gift-svgrepo-com.svg'

const iconMapping = {
  'icon-star': iconStar,
  'icon-medal': iconMedal,
  'icon-trophy': iconTrophy,
  'gift-svgrepo-com': iconGift
}

const MAX_SIZE_BYTES = MAX_UPLOAD_SIZE_MB * 1024 * 1024

const assetSelector = state => state.language.assets
const activitySelector = state => state.trophyRoadItemActivity.trophyRoadItemFeedItems
const activityCountSelector = state => state.trophyRoadItemActivity.count
const fetchingSelector = state => state.trophyRoadItemActivity.fetching
const userSelector = state => state.loginUser.user
const totalSizeSelector = state => state.gallery.totalSize
const trophyRoadSelector = state => state.trophyRoad.trophyRoadItems

const TrophyRoadItemActivityFeed = () => {
  const dispatch = useDispatch()
  const lastInfiniScrollElement = useRef()
  const browserFileInputRef = useRef()
  const { trophyroadId: trid, trophyroaditemId: triid } = useParams()
  const navigate = useNavigate()

  const assets = useSelector(assetSelector, shallowEqual)
  const activity = useSelector(activitySelector, shallowEqual)
  const activityCount = useSelector(activityCountSelector, shallowEqual)
  const fetching = useSelector(fetchingSelector, shallowEqual)
  const user = useSelector(userSelector, shallowEqual)
  const totalSize = useSelector(totalSizeSelector, shallowEqual)
  const trophyRoad = useSelector(trophyRoadSelector, shallowEqual)

  const [currentPage, setCurrentPage] = useState(0)
  const [platformSelect, setPlatformSelect] = useState('')

  useEffect(() => {
    dispatch(activityActions.resetTrophyRoadItemActivity())
    dispatch(activityActions.getTrophyRoadItemActivity(trid, triid, 0))

    const platform = window.localStorage.getItem('PLATFORM')
    setPlatformSelect(platform)
  }, [user, trid, triid])

  const handleNext = () => {
    dispatch(activityActions.getTrophyRoadItemActivity(trid, triid, currentPage + 1))
    setCurrentPage(currentPage + 1)
  }

  const checkVisible = (elm) => {
    const rect = elm.getBoundingClientRect()
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
  }

  const infiniScrollHandler = () => {
    if (!fetching && checkVisible(lastInfiniScrollElement.current) && activity.length < activityCount) {
      handleNext()
    }
  }

  const renderTrophyRoadItem = () => {
    const selectedItem = trophyRoad.find(item => item._id === triid)
    if (!selectedItem) return null

    return (
      <div className="trophy-road-item-content" key={`trophy-road-item-${selectedItem.position}`}>
        <div className="trophy-road-instruction">
        {!selectedItem.isUnlocked 
          ? assets.TESTRAX_V2.TROPHY_ROAD_UNLOCKED_MESSAGE
          : selectedItem.isCompleted
            ? assets.TESTRAX_V2.TROPHY_ROAD_COMPLETED_MESSAGE
            : assets.TESTRAX_V2.TROPHY_ROAD_UPLOAD_MESSAGE}
        </div>
        <div className={`trophy-road-item ${
          selectedItem.isCompleted 
            ? 'completed' 
            : selectedItem.isUnlocked 
              ? 'in-progress' 
              : 'not-completed'
        }`}
      >          <div className="preview">
            <img src={selectedItem.link || iconMapping[selectedItem.icon] || previewPlaceholder} alt=""/>
          </div>
          {/* <div className="trophy-road-item-description">
            {selectedItem.description}
          </div> */}
        </div>
      </div>
    )
  }

  const getEventColor = (eventType) => {
    switch (eventType) {
      case 'SUBMISSION_UPLOADED':
        return 'var(--grading-event-color-submission-uploded)'
      case 'SUBMISSION_GRADED':
        return 'var(--grading-event-color-submission-graded)'
       case 'TEST_ASSIGNED':
        return 'var(--grading-event-color-test-assigned)'
      case 'TROPHY_ROAD_ITEM_COMPLETE':
        return 'var(--grading-event-color-trophy-road-item-complete)'
      default:
        return ''
    }
  }

  const getBase64URI = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = function (event) {
        resolve(event.target.result)
      }
      reader.onerror = function (error) {
        reject(error)
      }
      reader.readAsDataURL(file)
    })
  }

  const handleBrowserSelectImage = async (evt) => {
    try {
      const files = evt.target.files

      const totalSizeOfFiles = Array.from(files).reduce((total, file) => total + file.size * (4 / 3), 0)
      const currentTotalSize = totalSize
      if (currentTotalSize + totalSizeOfFiles > MAX_SIZE_BYTES) {
        dispatch(
          toastActions.pushNotification({
            text: assets.TESTRAX_V2.UPLOAD_LIMIT_EXCEEDED_WARNING,
            navigationTarget: null,
            position: 'BOTTOM',
            color: 'NEGATIVE',
            life: 3000
          })
        )
        return
      }

      for (const file of files) {
        const dataURI = await getBase64URI(file)
        dispatch(galleryActions.addPicture(dataURI))
      }

      // navigate(`/student-dashboard/trophyroads/${trid}/trophyroaditems/${triid}/gallery`)
      handleGalleryNavigation()
    } catch (e) {
      console.warn(e)
    }
  }

  const uint8ArrayToBase64 = (uint8Array, mediaType) => {
    const metadata = `data:${mediaType};base64,`
    let binary = ''
    const len = uint8Array.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(uint8Array[i])
    }
    return metadata + window.btoa(binary)
  }

  const handleMobileSelectImage = async () => {
    try {
      const chooserObject = window.chooser || chooser
      const files = await chooserObject.getFile()

      const totalSizeOfFiles = files.reduce((total, file) => total + file.data.byteLength * (4 / 3), 0)
      const currentTotalSize = totalSize

      if (currentTotalSize + totalSizeOfFiles > MAX_SIZE_BYTES) {
        dispatch(
          toastActions.pushNotification({
            text: assets.TESTRAX_V2.UPLOAD_LIMIT_EXCEEDED_WARNING,
            navigationTarget: null,
            position: 'BOTTOM',
            color: 'NEGATIVE',
            life: 3000
          })
        )
        return
      }

      if (files && files.length > 0) {
        for (const file of files) {
          const base64 = uint8ArrayToBase64(file.data, file.mediaType)
          dispatch(galleryActions.addPicture(base64))
        }
        // navigate(`/student-dashboard/trophyroads/${trid}/trophyroaditems/${triid}/gallery`)
        handleGalleryNavigation()
      }
    } catch (e) {
      console.warn(e)
    }
  }

  const handleBrowserTriggerUpload = () => {
    browserFileInputRef.current.click()
  }

  const handleCameraNavigation = () => {
    const selectedItem = trophyRoad.find(item => item._id === triid);
    
    if (!selectedItem) return;
  
    const eventType = ['STATIC_TEST', 'SIMULATION', 'EXERCISE_SETS'].includes(selectedItem.condition?.type)
      ? 'TEST_ASSIGNED'
      : '';
  
    const path = `/student-dashboard/trophyroads/${trid}/trophyroaditems/${triid}/camera${eventType ? `?eventType=${eventType}` : ''}`;
  
    navigate(path)
  }

  const handleGalleryNavigation = () => {
    const selectedItem = trophyRoad.find(item => item._id === triid);
    
    if (!selectedItem) return;
  
    const eventType = ['STATIC_TEST', 'SIMULATION', 'EXERCISE_SETS'].includes(selectedItem.condition?.type)
      ? 'TEST_ASSIGNED'
      : '';
  
    const path = `/student-dashboard/trophyroads/${trid}/trophyroaditems/${triid}/gallery${eventType ? `?eventType=${eventType}` : ''}`;
  
    navigate(path)
  }
  
  const menuModel = [
    {
      action: handleCameraNavigation,
      label: assets.TESTRAX_V2.CAMERA,
      icon: IconCamera,
      alt: 'icon-camera'
    },
    {
      action: () => (platformSelect === 'browser' ? handleBrowserTriggerUpload() : handleMobileSelectImage()),
      label: assets.TESTRAX_V2.SELECT_FILE,
      icon: IconFolder,
      alt: 'icon-folder'
    }
  ]

  return (
    <div className='trophy-road-item-activity-feed-container'>
    <div className='trophy-road-item-activity-feed' onScroll={infiniScrollHandler}>
      <NotificationArea defaultText={trophyRoad.find(item => item._id === triid)?.learningObjectiveDescription.toUpperCase() || 'TROPHY ROAD ITEM'} />
      {activity.length > 0 &&
        activity.map((e, i) => {
          const isLastElement = i === activity.length - 1
          return (
            <div key={i} ref={isLastElement ? lastInfiniScrollElement : null} className={`trophy-road-activity-item ${i % 2 === 0 ? 'even' : 'odd'}`}>
              <StudentTestingEvent 
                item={e} 
                background={getEventColor(e.eventType, i)} 
                itemNumber={activityCount - i} 
              />
            </div>
          )
        })
      }
      {activity.length === 0 && (
        <div className='no-activity-container'>
          <div className='no-activity-logo'>
            <img src={TestraxLogoV1} />
          </div>
          <div className='no-trophy-road-activity-div'>
            <span> {trophyRoad.find(item => item._id === triid)?.isUnlocked 
          ? assets.TESTRAX_V2.NO_ACTIVITY 
          : assets.TESTRAX_V2.TROPHY_ROAD_UNLOCKED_ACTIVITY_MESSAGE}</span>
          </div>
        </div>
      )}
      <input
        type='file'
        accept='image/*'
        multiple
        onChange={platformSelect === 'browser' ? handleBrowserSelectImage : handleMobileSelectImage}
        style={{ display: 'none' }}
        ref={browserFileInputRef}
        onClick={(event) => { event.target.value = null }}
      />
    </div>
    <div className='trophy-road-item-container'>
        {renderTrophyRoadItem()}
      </div>
      {trophyRoad.find(item => item._id === triid)?.isUnlocked && !trophyRoad.find(item => item._id === triid)?.isCompleted && <BurgerMenu menuItems={menuModel} />}
    </div>
  )
}

export default TrophyRoadItemActivityFeed
