
import './StudentActivityFeed.css'
import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { activityActions } from '../../actions'
import TestraxLogoV1 from 'assets/icons/testrax_v1.webp'
import StudentTestingEvent from './StudentTestingEvent'

const assetSelector = state => state.language.assets
const activitySelector = state => state.activity.activityFeedItems
const activityCountSelector = state => state.activity.count
const fetchingSelector = state => state.activity.fetching
const userSelector = state => state.loginUser.user

const StudentActivityFeed = () => {

  const dispatch = useDispatch()
  const lastInfiniScrollElement = useRef()

  const assets = useSelector(assetSelector, shallowEqual)
  const activity = useSelector(activitySelector, shallowEqual)
  const activityCount = useSelector(activityCountSelector, shallowEqual)
  const fetching = useSelector(fetchingSelector, shallowEqual)
  const user = useSelector(userSelector, shallowEqual)

  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    dispatch(activityActions.resetActivity())
    dispatch(activityActions.getActivity(0))
  }, [user])

  const handleNext = () => {
    dispatch(activityActions.getActivity(currentPage + 1))
    setCurrentPage(currentPage + 1)
  }

  const checkVisible = (elm) => {
    const rect = elm.getBoundingClientRect()
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
  }

  const infiniScrollHandler = () => {
    if (!fetching && checkVisible(lastInfiniScrollElement.current) && activity.length < activityCount) {
      handleNext()
    }
  }
  
  const getEventColor = (eventType) => {
    switch (eventType) {
      case 'SUBMISSION_UPLOADED':
        return 'var(--grading-event-color-submission-uploded)'
      case 'SUBMISSION_GRADED':
        return 'var(--grading-event-color-submission-graded)'
      case 'TEST_ASSIGNED':
        return 'var(--grading-event-color-test-assigned)'
      case 'TROPHY_ROAD_ITEM_COMPLETE':
        return 'var(--grading-event-color-trophy-road-item-complete)'
      default:
        return ''
    }
  }

  return (
    <div className='student-activity-feed' onScroll={infiniScrollHandler}>
      {activity.length > 0 &&
        activity.map((e, i) => {
          const isLastElement = i === activity.length - 1
          return (
            <div key={i} ref={isLastElement ? lastInfiniScrollElement : null} className={`student-activity-item ${i % 2 === 0 ? 'even' : 'odd'}`}>
              <StudentTestingEvent 
                item={e} 
                background={getEventColor(e.eventType, i)} 
                itemNumber={activityCount - i} 
              />
            </div>
          )
        })
      }
      {activity.length === 0 && (
        <div className='no-activity-container'>
          <div className='no-activity-logo'>
            <img src={TestraxLogoV1} />
          </div>
          <div className='no-student-activity-div'>
            <span>{assets.TESTRAX_V2.NO_ACTIVITY}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default StudentActivityFeed
