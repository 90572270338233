import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

/**
 * @module firebaseNotifActions
 * @description actions for firebase notifications
 * @category actions
 */

export const REGISTER_FCM_TOKEN = 'REGISTER_FCM_TOKEN'

/**
 * @description Register FCM token for user
 * @action registerFCMToken
 * @dispatches REGISTER_FCM_TOKEN
 */
export function registerFCMToken (userId, fcmToken) {
  const token = store.getState().loginUser.user.token
  console.warn({ userId, fcmToken })

  return {
    type: REGISTER_FCM_TOKEN,
    payload: axios.put(`${SERVER}/user-api/users/${userId}/fcm`, { fcmToken }, { headers: { Authorization: token } })
  }
}