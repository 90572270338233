import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js/auto'
import useAssets from 'hooks/useAssets'
import { useSelector } from 'react-redux'

const calendarLocaleSelector = (state) => state.language.calendarLocale

const MonthlyPageChart = ({ monthlyPageCounts }) => {
  const assets = useAssets()
  const chartRef = useRef(null)

  const calendarLocale = useSelector(calendarLocaleSelector)

  useEffect(() => {
    if (!chartRef.current) return

    const labels = monthlyPageCounts.map(
      (entry) =>
        `${new Date(entry.year, entry.month - 1).toLocaleString(calendarLocale, {
          month: 'short',
        })} ${entry.year}`
    )
    const data = monthlyPageCounts.map((entry) => entry.totalImageCount)

    const chart = new Chart(chartRef.current, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: '',
            data,
            backgroundColor: '#ff4929',
            borderColor: '#ff4929',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        layout: {
          padding: {
            left: 8,
            right: 8,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${assets.TESTRAX_V2.STATISTICS.PAGES} ${context.raw}`
              },
            },
            titleFont: {
              weight: 'bold',
              color: 'black',
            },
            bodyFont: {
              weight: 'bold',
              color: 'black',
            },
          },
        },
        scales: {
          x: {
            title: {
              display: false,
            },
            ticks: {
              callback: function (value, index) {
                return labels[index]
              },
              maxRotation: 45,
              minRotation: 45,
              font: {
                weight: 'bold',
              },
              color: 'black',
            },
            grid: {
              display: false,
            },
          },
          y: {
            title: {
              display: true,
              text: `${assets.TESTRAX_V2.STATISTICS.UPLOADED_PAGES}`,
              padding: {
                bottom: 8,
              },
              font: {
                weight: 'bold',
                size: 12,
              },
              color: 'black',
            },
            beginAtZero: true,
            grid: {
              display: true,
            },
            ticks: {
              precision: 0, 
              font: {
                weight: 'bold',
              },
              color: 'black',
            },
          },
        },
      },
    })

    return () => {
      chart.destroy()
    }
  }, [monthlyPageCounts])

  return <canvas className='statistics-canvas' ref={chartRef} />
}

export default MonthlyPageChart
