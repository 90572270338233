/**
 * @module galleryReducer
 * @description state manipulation for gallery content
 * @category reducers
 */
const INITIAL_STATE = {
  pictures: [],
  sentPictures: false,
  totalSize: 0,
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_PICTURE':
      return { ...state, pictures: addPicture(state.pictures, action.payload), totalSize: calculateTotalSize([...state.pictures, action.payload]) }
    case 'REMOVE_PICTURE':
      return { ...state, pictures: removePicture(state.pictures, action.payload), totalSize: calculateTotalSize(removePicture(state.pictures, action.payload)) }
    case 'SUBMIT_PICTURES_FULFILLED':
      return { ...state, pictures: [], sentPictures: true, totalSize: 0 }
    case 'RESET_PICTURE_SEND_STATUS':
      return { ...state, sentPictures: false }
    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}

const addPicture = (currentPictures, newPicture) => {
  const pics = [...currentPictures]
  pics.push(newPicture)
  return pics
}

const removePicture = (pictures, pictureIndex) => {
  const pics = pictures.toSpliced(pictureIndex, 1)
  return pics
}

const calculateTotalSize = (pictures) => {
  return pictures.reduce((total, picture) => total + picture.length, 0)
}

