/**
 * @module testScanActions
 * @description actions for test scan
 * @category actions
 */
import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const SUBMIT_PICTURES = 'SUBMIT_PICTURES'
export const GET_TEST_SCANS = 'GET_TEST_SCANS'

/**
 * @description POST pictures for scan
 * @action submitPictures
 * @dispatches SUBMIT_PICTURES
 */
export function submitPictures (pictures, userKnowledgeMapId, submissionType, submissionGradingType, selectedTrophyRoadItem, observations) {
  const user = store.getState().loginUser.user
  return {
    type: SUBMIT_PICTURES,
    payload: axios.post(`${SERVER}/student-api/user-knowledge-maps/${userKnowledgeMapId}/submissions`, { pictures: pictures, submissionType: submissionType, submissionGradingType: submissionGradingType, selectedTrophyRoadItem: selectedTrophyRoadItem, observations: observations }, { headers: { Authorization: user.token } })
  }
}

/**
 * @description GET owned test scans
 * @action getOwnedTestScans
 * @dispatches GET_TEST_SCANS
 */
export function getOwnedTestScans () {
  const user = store.getState().loginUser.user
  return {
    type: GET_TEST_SCANS,
    payload: axios.get(`${SERVER}/student-api/users/${user._id}/test-scans`, { headers: { Authorization: user.token } })
  }
}
