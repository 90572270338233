import { galleryActions, licensedSubjectsActions, testScanActions, trophyRoadActions } from 'actions'
import './SubmissionEditor.css'
import React, { useState, useRef, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { InputTextarea } from 'primereact/inputtextarea'
import { RadioButton } from 'primereact/radiobutton'
import NotificationArea from 'components/NotificationArea'
import { toastActions } from '../../actions'
import useQuery from 'hooks/useQuery' 

const licensedSubjectsSelector = state => state.licensedSubjects.licensedSubjects
const trophyRoadSelector = state => state.trophyRoad.trophyRoadItems
const subscriptionIdSelector = state => state.subscription.subscriptionId
const isFetchingLicensedSubjectsSelector = state => state.licensedSubjects.fetching
const fetchedLicensedSubjectsSelector = state => state.licensedSubjects.fetched
const sentPicturesSelector = state => state.gallery.sentPictures
const assetSelector = state => state.language.assets
const picturesSelector = state => state.gallery.pictures
const isFetchingPicturesSubmissionSelector = state => state.testScan.fetching

function SubmissionEditor () {
  const { trophyroadId, trophyroaditemId } = useParams()

  const [currentNotification, setCurrentNotification] = useState('')
  const assets = useSelector(assetSelector, shallowEqual)
  const licensedSubjects = useSelector(licensedSubjectsSelector, shallowEqual)
  const trophyRoad = useSelector(trophyRoadSelector, shallowEqual)
  const subscriptionId = useSelector(subscriptionIdSelector, shallowEqual)
  const isFetchingLicensedSubjects = useSelector(isFetchingLicensedSubjectsSelector, shallowEqual)
  const fetchedLicensedSubjects = useSelector(fetchedLicensedSubjectsSelector, shallowEqual)
  const pictures = useSelector(picturesSelector, shallowEqual)
  const sentPictures = useSelector(sentPicturesSelector, shallowEqual)
  const isFetchingPicturesSubmission = useSelector(isFetchingPicturesSubmissionSelector, shallowEqual)
  const selectableSubmissionTypes = ['HOMEWORK', 'USER_TEST']
  const submissionGradingTypes = ['UNGRADED', 'GRADED']
  const query = useQuery()  
  const knowledgeMap = query.get("knowledgeMap")
  const eventType = query.get("eventType")

  const [subject, setSubject] = useState('')
  const [submissionType, setSubmissionType] = useState(eventType === 'TEST' ? 'USER_TEST' : selectableSubmissionTypes[0])
  const [submissionGradingType, setSubmissionGradingType] = useState(submissionGradingTypes[0])
  const [selectedTrophyRoadItem, setSelectedTrophyRoadItem] = useState(null) 
  const [observations, setObservations] = useState('')
  const [activeIndex, setActiveIndex] = useState(null)
  const unlockedTrophyRoadItems = trophyRoad.filter(item => item.isUnlocked === true && item.isCompleted === false)
  
  useEffect(() => {
    if (unlockedTrophyRoadItems.length === 1 && unlockedTrophyRoadItems[0].testAllocations?.length > 0) {
      setSubmissionType('USER_TEST')
    }
  }, [trophyRoad])

  useEffect(() => {
    if (unlockedTrophyRoadItems.length === 1) {
      setSelectedTrophyRoadItem(unlockedTrophyRoadItems[0])
    }
  }, [unlockedTrophyRoadItems])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  if (!(licensedSubjects && licensedSubjects.length > 0) && !isFetchingLicensedSubjects && !fetchedLicensedSubjects) {
    dispatch(licensedSubjectsActions.getLicensedSubjects())
  }

  useEffect(() => {
    if (licensedSubjects.length === 1) {
      setSubject(licensedSubjects[0])
    } else if (!subject && licensedSubjects.length > 0) {
      setSubject(licensedSubjects[0])
    }
  
    if (subscriptionId) {
      dispatch(trophyRoadActions.getTrophyRoad(subscriptionId))
    }
   }, [licensedSubjects, subscriptionId])

   useEffect(() => {
    if (!fetchedLicensedSubjects) return
  
    if (knowledgeMap || licensedSubjects.length === 1) {  
      if (eventType !== 'TEST') {
        setActiveIndex(1)
      } else if (submissionGradingType) {
        setActiveIndex(2)
      } else if (unlockedTrophyRoadItems.length !== 1) {
        setActiveIndex(3)
      }
    } else {
      setActiveIndex(0)
    }
  }, [licensedSubjects, knowledgeMap, eventType, trophyRoad, fetchedLicensedSubjects, submissionGradingType])  

  useEffect(() => {
    if (trophyroadId && trophyroaditemId && trophyRoad.length > 0) {
      const item = trophyRoad.find((item) => item._id === trophyroaditemId)
      if (item) {
        setSelectedTrophyRoadItem(item)
      }
    }
  }, [trophyroadId, trophyroaditemId, trophyRoad])

  useEffect(() => {
    if (pictures.length === 0 && sentPictures) {
      dispatch(toastActions.pushNotification({
        text: assets.TESTRAX_V2.IMAGES_SENT,
        navigationTarget: null,
        position: 'BOTTOM',
        color: 'POSITIVE',
        life: 3000
      }))
      setCurrentNotification(assets.TESTRAX_V2.IMAGES_SENT)
      handleSuccessfulSubmission()
    }
  }, [pictures])

  useEffect(() => {
    if (knowledgeMap && licensedSubjects.length > 0) {
      const matchedSubject = licensedSubjects.find(subj => subj.knowledgeMapId === knowledgeMap)
      if (matchedSubject) {
        setSubject(matchedSubject)
      }
    } else if (!subject && licensedSubjects.length > 0) {
      setSubject(licensedSubjects[0])
    }
  }, [knowledgeMap, licensedSubjects])


  const handleSubmitPictures = () => {
    if (pictures.length > 0 && licensedSubjects.length > 0) {
      dispatch(testScanActions.submitPictures(pictures, subject.value, submissionType, submissionGradingType, selectedTrophyRoadItem._id, observations))
    }
  }

  const handleSuccessfulSubmission = () => {
    dispatch(galleryActions.resetPicturesSendStatus())
    navigate('/student-dashboard')
  }

  return (
    <div className='submission-editor'>
      {
        (licensedSubjects.length > 0) &&
          <>
          <NotificationArea defaultText={assets.TESTRAX_V2.SUBMISSION_EDITOR_TITLE.toUpperCase()} notificationText={currentNotification} />
          <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className='submission-options-accordion'>
            <AccordionTab key='subject-selector-accordion-tab' header={`${assets.TESTRAX_V2.SUBJECT}: ${subject ? subject.label : assets.GENERAL.SELECT}`} disabled={knowledgeMap !== null || licensedSubjects.length === 1}>
              {
                licensedSubjects?.length > 0
                  ? (
                    <div className='subject-selector'>
                      {
                        licensedSubjects.map(option => {
                          return (
                            <div key={option.value} className="accordion-option-container" onClick={() => setSubject(option)}>
                              <RadioButton inputId={option.value} name="accordion-option subject-options" value={option} onChange={(e) => setSubject(e.value)} checked={option.value === subject?.value} disabled={knowledgeMap !== null}/>
                              <label htmlFor={option.value} className="ml-2">{option.label}</label>
                            </div>
                          )
                        })
                      }
                    </div>)
                  : (
                    <span>{assets.TESTRAX_V2.SUBJECTS_NOT_AVAILABLE}</span>)
              }          
            </AccordionTab>
            <AccordionTab key='submission-type-selector-accordion-tab' header={`${assets.TESTRAX_V2.SUBMISSION_TYPE}: ${assets.TESTRAX_V2.SUBMISSION_TYPES[submissionType]}`} disabled={eventType === 'TEST'}>
              <div className='submission-type-selector'>
                {selectableSubmissionTypes.map(option => (
                  <div key={option} className="accordion-option-container" onClick={() => setSubmissionType(option)}>
                    <RadioButton inputId={option} name="accordion-option submission-type-option" value={option} onChange={(e) => setSubmissionType(e.value)} checked={option === submissionType} disabled={eventType === 'TEST'}/>
                    <label htmlFor={option} className="ml-2">{assets.TESTRAX_V2.SUBMISSION_TYPES[option]}</label>
                  </div>
                ))}
              </div>
            </AccordionTab>
            <AccordionTab key='submission-grading-type-selector-accordion-tab' header={`${assets.TESTRAX_V2.SUBMISSION_GRADING_TYPE}: ${submissionGradingType ? assets.TESTRAX_V2.SUBMISSION_GRADING_TYPES[submissionGradingType] : assets.GENERAL.SELECT}`}>
              <div className='submission-grading-type-selector'>
                {
                  submissionGradingTypes.map(option => {
                    return (
                      <div key={option} className="accordion-option-container" onClick={() => setSubmissionGradingType(option)}>
                        <RadioButton inputId={option} name="accordion-option submission-grading-type-option" value={option} onChange={(e) => setSubmissionGradingType(e.value)} checked={option === submissionGradingType} />
                        <label htmlFor={option} className="ml-2">{assets.TESTRAX_V2.SUBMISSION_GRADING_TYPES[option]}</label>
                      </div>
                    )
                  })
                }
              </div>         
            </AccordionTab>
            {
            (!trophyroadId && !trophyroaditemId) && (
            trophyRoad && trophyRoad.length > 0 && (
            <AccordionTab key="trophy-road-accordion-tab"  header={`${assets.TESTRAX_V2.TROPHY_ROAD}: ${ selectedTrophyRoadItem ? selectedTrophyRoadItem.learningObjectiveDescription : assets.GENERAL.SELECT }`} disabled={unlockedTrophyRoadItems.length === 1}>
              <div className="subject-selector"> 
                {trophyRoad.filter(e => e.isUnlocked === true && e.isCompleted === false).map((item) => (
                  <div
                    key={item._id}
                    className="accordion-option-container" 
                  >
                    <RadioButton
                       inputId={item._id}
                       name="trophy-road-item"
                       value={item}
                       onChange={(e) => {setSelectedTrophyRoadItem(e.value)}}
                       checked={selectedTrophyRoadItem?._id === item._id}
                    />
                    <label htmlFor={item._id} className="ml-2">
                      {item.learningObjectiveDescription}
                    </label>
                  </div>
                ))}
              </div>
            </AccordionTab>
        )
      )}
          </Accordion>
          {trophyroadId && trophyroaditemId && (
          <div className="upload-info">
            <p>{`${assets.TESTRAX_V2.SUBMISSION_EDITOR_OBJECTIVE}: ${selectedTrophyRoadItem?.learningObjectiveDescription || assets.GENERAL.LOADING}`}</p>
          </div> )}
          <div className='observations-container'>
            <span className='observations-title'>{assets.TESTRAX_V2.OBSERVATIONS}</span>
            <InputTextarea value={observations} onChange={(e) => setObservations(e.target.value)} placeholder={assets.TESTRAX_V2.OBSERVATIONS_PLACEHOLDER} className='observations-input' />
          </div>
          <div className='submission-send-button-container'>
          <button className='submission-send-button' onClick={handleSubmitPictures} disabled={pictures.length < 1 || !subject || !submissionType || !submissionGradingType || !selectedTrophyRoadItem || isFetchingPicturesSubmission}>
            {assets.TESTRAX_V2.SEND}</button></div>
          </>
      }
      {
        (licensedSubjects.length === 0 && !isFetchingLicensedSubjects) &&
          <div>
            <span>{assets.TESTRAX_V2.NO_SUBJECTS}: <a href={`mailto:${process.env.SUPPORT_EMAIL || 'contact@nextlab.tech'}`}>{process.env.SUPPORT_EMAIL || 'contact@nextlab.tech'}</a></span>
          </div>
      }
    </div>
  )
}

export default SubmissionEditor
