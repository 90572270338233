/**
 * @module helpOverlayReducer
 * @description state manipulation for help overlay
 * @category reducers
 */
const INITIAL_STATE = {
  shouldShow: false
}
    
export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOGIN_FULFILLED':
    case 'SET_INITIAL_STATE_FULFILLED':
    case 'MODIFY_PROFILE_FULFILLED':
    case 'MODIFY_PASSWORD_FULFILLED':
    case 'CONFIRM_TWO_FACTOR_AUTHENTICATION_FULFILLED':
    case 'DEACTIVATE_TWO_FACTOR_AUTHENTICATION_FULFILLED':
    case 'COMPLETE_OAUTH2_LOGIN_FULFILLED':
    case 'REGISTER_FCM_TOKEN_FULFILLED':
    case 'PICK_USER_FACETS_FULFILLED':
    case 'PUT_SHOW_PLATFORM_INSTRUCTIONS_FULFILLED':
      return { ...state, shouldShow: !action.payload.data.hideInstructions, error: null, fetched: true, fetching: false }
    case 'SHOW_HELP':
      return { ...state, shouldShow: true }
    case 'HIDE_HELP':
      return { ...state, shouldShow: false }
    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}