/**
 * @module paymentFlowReducer
 * @description state manipulation for the selected payment flow
 * @category reducers
 */
const INITIAL_STATE = {
  paymentFlow: null,
  index: 0,
  facets: []
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SELECT_PAYMENT_FLOW':
      return { ...state, paymentFlow: action.payload }
    case 'RESET':
    case 'RESET_PAYMENT_FLOW':
      return INITIAL_STATE
    default:
      return state
  }
}
