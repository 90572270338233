/**
 * @module appModeActions
 * @description actions for switching app-specific toggles (like display mode, etc)
 * @category actions
 */

export const SWITCH_TO_MOBILE_MODE = 'SWITCH_TO_MOBILE_MODE'
export const SWITCH_TO_DEFAULT_MODE = 'SWITCH_TO_DEFAULT_MODE'

/**
 * @description Switch app to mobile mode
 * @action switchToMobileMode
 * @dispatches SWITCH_TO_MOBILE_MODE
 */
export function switchToMobileMode (userId, code) {
  return {
    type: SWITCH_TO_MOBILE_MODE,
    payload: null
  }
}

/**
 * @description Switch app to default mode
 * @action switchToDefaultMode
 * @dispatches SWITCH_TO_DEFAULT_MODE
 */
export function switchToDefaultMode (userId, code) {
  return {
    type: SWITCH_TO_DEFAULT_MODE,
    payload: null
  }
}
