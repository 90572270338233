/**
 * @module subscriptionReducer
 * @description state manipulation for subscriptions
 * @category reducers
 */
const INITIAL_STATE = {
    subscriptionId: null,
    subscriptionStatus: null,
    subscriptionPageStats: 0
  }
  
  export default function reducer (state = INITIAL_STATE, action) {
    switch (action.type) {
      case 'GET_ACTIVE_SUBSCRIPTION_PENDING':
        return { ...state, error: null, fetching: true, fetched: false }
      case 'GET_ACTIVE_SUBSCRIPTION_FULFILLED':
        return { ...state, subscriptionId: action.payload.data.subscription._id, status: action.payload.data.subscription.status, subscriptionPageStats: action.payload.data.subscription.pageCount, error: null, fetching: false, fetched: true }
      case 'GET_ACTIVE_SUBSCRIPTION_REJECTED':
        return { ...state, error: action.payload.response.data, fetching: false, fetched: false }
      case 'RESET':
        return INITIAL_STATE
      default:
        break
    }
    return state
  }
