/**
 * @module licensedSubjectsActions
 * @description actions for licensed subjects
 * @category actions
 */
import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const GET_LICENSED_SUBJECTS = 'GET_LICENSED_SUBJECTS'

/**
 * @description GET licensed subjects
 * @action getLicensedSubjects
 * @dispatches GET_LICENSED_SUBJECTS
 */
export function getLicensedSubjects () {
  const user = store.getState().loginUser.user
  return {
    type: GET_LICENSED_SUBJECTS,
    payload: axios.get(`${SERVER}/student-api/user-knowledge-maps`, { headers: { Authorization: user.token } })
  }
}
