// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.student-activity-feed {
  height: calc(var(--app-height) * 0.84);
  overflow-y: auto;
  position: relative;
}

.student-activity-item.even {
  background-color: var(--even-background-color);
}

.student-activity-item.odd {
  background-color: var(--odd-background-color);
}

.student-activity-feed .no-student-activity-div {
  margin: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold; 
  text-align: center;
}

.student-activity-feed .no-activity-logo {
  text-align: center;
}

.student-activity-feed .no-activity-logo img {
  height: 5em;
}

.student-activity-feed .no-activity-container {
  margin-top: 7em;
}`, "",{"version":3,"sources":["webpack://./src/components/StudentActivityFeed/StudentActivityFeed.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".student-activity-feed {\n  height: calc(var(--app-height) * 0.84);\n  overflow-y: auto;\n  position: relative;\n}\n\n.student-activity-item.even {\n  background-color: var(--even-background-color);\n}\n\n.student-activity-item.odd {\n  background-color: var(--odd-background-color);\n}\n\n.student-activity-feed .no-student-activity-div {\n  margin: 1.5em;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: bold; \n  text-align: center;\n}\n\n.student-activity-feed .no-activity-logo {\n  text-align: center;\n}\n\n.student-activity-feed .no-activity-logo img {\n  height: 5em;\n}\n\n.student-activity-feed .no-activity-container {\n  margin-top: 7em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
