// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile-container {
    position: relative;
    padding-top: var(--notification-area-height);
    height: calc(var(--app-height) * 0.84);
    overflow-y: auto;
}

.user-profile-container .notification-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}
  
.user-profile-list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.user-profile-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75em;
    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
    height: 4em;
}

.user-profile-label {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    padding-left: 1em;
}

.user-profile-icon-left {
    margin-right: 1em;
    font-size: 1.25rem;
    color: var(--primary-background-color);
}

.user-profile-navigate-button {
    font-size: 1.25rem;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
}

.user-profile-icon {
    font-size: 1.25rem;
    color: var(--primary-background-color);
}

`, "",{"version":3,"sources":["webpack://./src/components/UserProfile/UserProfile.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,4CAA4C;IAC5C,sCAAsC;IACtC,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,+CAA+C;IAC/C,WAAW;IACX,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,sCAAsC;AAC1C","sourcesContent":[".user-profile-container {\n    position: relative;\n    padding-top: var(--notification-area-height);\n    height: calc(var(--app-height) * 0.84);\n    overflow-y: auto;\n}\n\n.user-profile-container .notification-area {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    z-index: 1000;\n}\n  \n.user-profile-list {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.user-profile-item {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0.75em;\n    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);\n    width: 100%;\n    box-sizing: border-box;\n    height: 4em;\n}\n\n.user-profile-label {\n    display: flex;\n    align-items: center;\n    font-size: 1rem;\n    font-weight: 500;\n    padding-left: 1em;\n}\n\n.user-profile-icon-left {\n    margin-right: 1em;\n    font-size: 1.25rem;\n    color: var(--primary-background-color);\n}\n\n.user-profile-navigate-button {\n    font-size: 1.25rem;\n    background: none;\n    border: none;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-right: 1em;\n}\n\n.user-profile-icon {\n    font-size: 1.25rem;\n    color: var(--primary-background-color);\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
