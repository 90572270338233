import './BurgerMenu.css'
import { useNavigate } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'

function BurgerMenu (props) {
  const { menuItems } = props

  const navigate = useNavigate()
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)
  const menuRef = useRef(null)

  const handleAction = (item) => {
    if (item.disabled) return
    if (item.action && typeof item.action === 'function') {
      item.action()
    } else if (item.url && typeof item.url === 'string') {
      navigate(item.url)
    }
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuExpanded(false)
    }
  }

  useEffect(() => {
    if (isMenuExpanded) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isMenuExpanded])

  return (
    <div className='burger-menu' ref={menuRef}>
      <div className='menu-expanded'>
        {menuItems.map((item, index) => {
          if (index === 0) {
            return (
              <div
                className='menu-item'
                onClick={() => handleAction(item)}
                key={item.label}
              >
                <span className={isMenuExpanded ? 'opened label' : 'closed label'}>
                  {item.label}
                </span>
                <span className={isMenuExpanded ? 'micro-opened pin symbol' : 'micro-closed pin symbol'}>
                  <img src={item.icon} alt={item.alt} className='icon' />
                </span>
              </div>
            )
          } else {
            return (
              <div
                className={`${isMenuExpanded ? 'opened menu-item' : 'closed menu-item'} ${item.disabled ? 'disabled' : ''}`}
                key={item.label}
                onClick={() => handleAction(item)}
              >
                <span className='label'>{item.label}</span>
                <span className='pin symbol'>
                  <img src={item.icon} alt={item.alt} className='icon' />
                </span>
              </div>
            )
          }
        })}
      </div>
      <div
        className='menu-anchor pin symbol'
        onClick={() => setIsMenuExpanded(!isMenuExpanded)}
      >
        ☰
      </div>
    </div>
  )
}

export default BurgerMenu
