import React from 'react';
import './AuthorizedImage.css';

class AuthorizedImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnailContent: '',
      imageContent: '',
      isZoomOpen: false
    };
  }

  componentDidMount() {
    this.loadImages();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src || prevProps.thumbnail !== this.props.thumbnail) {
      this.loadImages();
    }
  }

  loadImages = () => {
    if (this.props.src && this.props.thumbnail) {
      Promise.all([
        fetch(this.props.src, {
          method: 'GET',
          headers: { Authorization: `${this.props.authorizationToken}` }
        }),
        fetch(this.props.thumbnail, {
          method: 'GET',
          headers: { Authorization: `${this.props.authorizationToken}` }
        })
      ])
        .then(([imageResponse, thumbnailResponse]) => {
          if (!imageResponse.ok || !thumbnailResponse.ok) {
            throw new Error('Failed to load images');
          }
          return Promise.all([imageResponse.blob(), thumbnailResponse.blob()]);
        })
        .then(([imageBlob, thumbnailBlob]) => {
          this.setState({
            imageContent: URL.createObjectURL(imageBlob),
            thumbnailContent: URL.createObjectURL(thumbnailBlob)
          });
        })
        .catch((err) => {
          this.setState({ thumbnailContent: '', imageContent: '' });
          console.error('Image loading failed:', err);
        });
    }
  };

  toggleZoom = () => {
    this.setState((prevState) => ({ isZoomOpen: !prevState.isZoomOpen }));
  };

  render() {
    const { isZoomOpen, thumbnailContent, imageContent } = this.state;
    return (
      <div className={`authorized-image`}>
        {thumbnailContent ? (
          <div>
            <img
              src={thumbnailContent}
              alt="Thumbnail"
              onClick={this.toggleZoom}
              className={`thumbnail-image ${this.props.isGradeable ? '' : 'ungradeable'}`}
              />
            {
              !this.props.isGradeable &&
              (
                <div className="image-overlay">
                  <div className="line"></div>
                  <div className="line"></div>
                </div>
              )
            }
          </div>
        ) : (
          'Loading...'
        )}
        {isZoomOpen && (
          <div className="fullscreen-overlay" onClick={(e) => e.stopPropagation()}>
            <button className="exit-button" onClick={this.toggleZoom}>✕</button>
            <img
              src={imageContent}
              alt="Zoomed"
              className="fullscreen-image"
            />
          </div>
        )}
      </div>
    );
  }
}

export default AuthorizedImage;
