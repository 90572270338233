const INITIAL_STATE = {
    test: null,
    testId: null,
    error: null,
    fetching: false,
    fetched: false
}

const testReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case 'GET_TEST_CONTENT_PENDING':
        case 'DEMAND_EXERCISES_PENDING':
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: null
            }
        case 'GET_TEST_CONTENT_FULFILLED':
            return {
                ...state,
                test: action.payload.data,
                error: null,
                fetching: false,
                fetched: true
            }
        case 'DEMAND_EXERCISES_FULFILLED':
            return {
                ...state,
                testId: action.payload.data.testId,
                error: null,
                fetching: false,
                fetched: true
            }
        case 'GET_TEST_CONTENT_REJECTED':
            return {
                ...state,
                error: action.payload.response.data,
                fetching: false,
                fetched: false
            }
        case 'DEMAND_EXERCISES_REJECTED':
            return {
                ...state,
                error: action.payload.response.data,
                fetching: false,
                fetched: false
            }
        case 'RESET':
            return INITIAL_STATE
        default:
            break
        
    }
    return state
}

export default testReducer