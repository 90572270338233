import React, { Component } from 'react'

import './ErrorDisplay.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// import assets from 'assets'

import { Toast } from 'primereact/toast'
import { toastActions } from '../../actions'

const mapStateToProps = function (state) {
  return {
    errors: [
      state.loginUser.error,
      state.ownedSubscriptions.error
    ],
    language: state.language.lang,
    assets: state.language.assets
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    actions: bindActionCreators({
      pushToastNotification: toastActions.pushNotification
    }, dispatch)
  }
}

class ErrorDisplay extends Component {
  constructor (props) {
    super(props)

    this.state = {
      alerts: []
    }

    this.setGrowlRef = (ref) => {
      this.growl = ref
    }

    this.mapErrorSummary = (error) => {
      const assets = this.props.assets
      const errorSummary = error.message
      if (errorSummary in assets.SERVER_MESSAGES.ERRORS && assets.SERVER_MESSAGES.ERRORS[errorSummary]) {
        let errorMessage = assets.SERVER_MESSAGES.ERRORS[errorSummary]
        if (errorSummary === 'ERR_EXISTS_MANY') {
          errorMessage += ': ' + error.usernames.join(',')
        }
        return errorMessage
      } else {
        return assets.SERVER_MESSAGES.ERRORS.ERR
      }
    }
  }

  componentDidUpdate (oldProps) {
    if (this.props !== oldProps && this.props.errors.length > 0 && this.props.errors !== oldProps.errors) {
      const { errors } = this.props
      const actualErrors = Array.from(new Set(errors.filter(e => (e !== null) && oldProps.errors.indexOf(e) === -1)))
      const messages = actualErrors.map(e => {
        return {
          severity: 'error',
          summary: this.mapErrorSummary(e)
        }
      })
      if (actualErrors.length > 0) {
        // this.growl.show(messages)
        for (const message of messages) {
          this.props.actions.pushToastNotification({
            text: message.summary,
            navigationTarget: null,
            position: 'BOTTOM',
            color: 'NEGATIVE',
            life: 3000
          })
        }
      }
    }
  }

  render () {
    return (
      <></>
      // <Toast
      //   ref={this.setGrowlRef}
      //   life={5000}
      // />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDisplay)
