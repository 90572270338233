/**
 * @module submissionsActions
 * @description actions for submissions
 * @category actions
 */
import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const GET_SUBMISSION_PICTURES = 'GET_SUBMISSION_PICTURES'
export const GET_SUBMISSION_GRADING = 'GET_SUBMISSION_GRADING'
export const UPDATE_SUBMISSION_GRADING_STATUS = 'UPDATE_SUBMISSION_GRADING_STATUS'

export function getSubmissionPictures(submissionId) {
  const token = store.getState().loginUser.user.token;

  return {
    type: GET_SUBMISSION_PICTURES,
    payload: axios.get(`${SERVER}/media-api/submission/${submissionId}/images`, {
      headers: {
        Authorization: token
      }
    })
  };
}

export function getSubmissionGrading (submissionGradingId){
  const token = store.getState().loginUser.user.token
  const facet = store.getState().loginUser.user.facets.includes('parent') ? 'parent' : 'student'

  return {
    type: GET_SUBMISSION_GRADING,
    payload: axios.get(`${SERVER}/${facet}-api/submission-gradings/${submissionGradingId}`,{
      headers: {
        Authorization: token
      }
    })
  }
}

export function updateSubmissionGradingStatus(studentId, userKnowledgeMapId, submissionGradingId, status) {
  const token = store.getState().loginUser.user.token;
  const facet = store.getState().loginUser.user.facets.includes('parent') ? 'parent' : 'teacher'

  return {
    type: UPDATE_SUBMISSION_GRADING_STATUS,
    payload: axios.put(`${SERVER}/${facet}-api/students/${studentId}/user-knowledge-maps/${userKnowledgeMapId}/submission-gradings/${submissionGradingId}`, 
      { status }, 
      {
        headers: {
          Authorization: token
        }
      }
    )
  };

  }