// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent-activity-feed {
  position: relative;
  padding-top: var(--notification-area-height);
  height: calc(var(--app-height) * 0.84);
  overflow-y: auto;
}

.parent-activity-feed .notification-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.parent-activity-item.even {
  background-color: var(--even-background-color);
}

.parent-activity-item.odd {
  background-color: var(--odd-background-color);
}

.no-activity-parent-div {
  margin: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold; 
  text-align: center;
}

.parent-activity-feed .no-activity-parent-logo {
  text-align: center;
}

.parent-activity-feed .no-activity-parent-logo img {
  height: 5em;
}

.parent-activity-feed .no-activity-parent-container {
  margin-top: 7em;
}


`, "",{"version":3,"sources":["webpack://./src/components/ParentActivityFeed/ParentActivityFeed.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,4CAA4C;EAC5C,sCAAsC;EACtC,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;AACf;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".parent-activity-feed {\n  position: relative;\n  padding-top: var(--notification-area-height);\n  height: calc(var(--app-height) * 0.84);\n  overflow-y: auto;\n}\n\n.parent-activity-feed .notification-area {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  z-index: 1000;\n}\n\n.parent-activity-item.even {\n  background-color: var(--even-background-color);\n}\n\n.parent-activity-item.odd {\n  background-color: var(--odd-background-color);\n}\n\n.no-activity-parent-div {\n  margin: 1.5em;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: bold; \n  text-align: center;\n}\n\n.parent-activity-feed .no-activity-parent-logo {\n  text-align: center;\n}\n\n.parent-activity-feed .no-activity-parent-logo img {\n  height: 5em;\n}\n\n.parent-activity-feed .no-activity-parent-container {\n  margin-top: 7em;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
