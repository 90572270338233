/**
 * @module networkErrorActions
 * @description actions for handling network errors
 * @category actions
 */

export const SET_NETWORK_ERROR = 'SET_NETWORK_ERROR'
export const UNSET_NETWORK_ERROR = 'UNSET_NETWORK_ERROR'

export function setNetworkError () {
  return {
    type: SET_NETWORK_ERROR
  }
}

export function unsetNetworkError () {
  return {
    type: UNSET_NETWORK_ERROR
  }
}
