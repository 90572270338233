import React, { Component } from 'react'

import './LoadingDisplay.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import { TailSpin } from 'react-loader-spinner'

const mapStateToProps = function (state) {
  return {
    isLoading: state.loginUser.fetching,
    language: state.language.lang,
    isNetworkError: state.networkError.networkErrorOccured,
    assets: state.language.assets
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    actions: bindActionCreators({
    }, dispatch)
  }
}

class LoadingDisplay extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false
    }
  }

  componentDidUpdate (oldProps) {
    if (this.props !== oldProps) {
      if (this.props.isLoading !== oldProps.isLoading) {
        this.setState({
          isLoading: this.props.isLoading
        })
      }

      if (this.props.isNetworkError) {
        const loadingSpinner = document.getElementById('app-spinner')
        if (loadingSpinner) {
          loadingSpinner.style.setProperty('display', 'none')
        }
      }
    }
  }

  render () {
    const { isLoading } = this.state
    return (
      <>
        {
          isLoading
            ? (
              <>
                <div id='app-spinner'>
                  {/* <TailSpin className='middle default-loader top-level' color='var(--primary-color)' height={50} width={50} /> */}
                </div>
              </>)
            : ''
        }
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingDisplay)
