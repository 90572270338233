// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.total-size-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  text-align: center;
  width: 100%;
  height: 6vh;
  gap: 0.4em;
}

.total-size-display .size-text {
  font-size: 0.975em;
  font-weight: bold;
}

.progress-bar {
  width: 90%;
  height: 1.4em;
  background-color: #e0e0e0; 
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.progress-bar-value {
  height: 100%;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.progress-label {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  color: white;
  font-size: 0.875em;
  font-weight: bold;
  z-index: 1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5); 
}
`, "",{"version":3,"sources":["webpack://./src/components/TotalSizeDisplay/TotalSizeDisplay.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,uDAAuD;AACzD;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;EACV,yCAAyC;AAC3C","sourcesContent":[".total-size-display {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: 1em;\n  text-align: center;\n  width: 100%;\n  height: 6vh;\n  gap: 0.4em;\n}\n\n.total-size-display .size-text {\n  font-size: 0.975em;\n  font-weight: bold;\n}\n\n.progress-bar {\n  width: 90%;\n  height: 1.4em;\n  background-color: #e0e0e0; \n  border-radius: 5px;\n  overflow: hidden;\n  position: relative;\n}\n\n.progress-bar-value {\n  height: 100%;\n  border-radius: 5px;\n  position: absolute;\n  top: 0;\n  left: 0;\n  transition: width 0.3s ease, background-color 0.3s ease;\n}\n\n.progress-label {\n  position: absolute;\n  top: 50%; \n  left: 50%; \n  transform: translate(-50%, -50%); \n  color: white;\n  font-size: 0.875em;\n  font-weight: bold;\n  z-index: 1;\n  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5); \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
