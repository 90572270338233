const INITIAL_STATE = {
    submissionGrading: null,
    error: null,
    fetching: false,
    fetched: false
}

const submissionGradingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case 'GET_SUBMISSION_GRADING_PENDING':
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: null
            }
        case 'GET_SUBMISSION_GRADING_FULFILLED':
            return {
                ...state,
                submissionGrading: action.payload.data,
                error: null,
                fetching: false,
                fetched: true
            }
        case 'GET_SUBMISSION_GRADING_REJECTED':
            return {
                ...state,
                error: action.payload.response.data,
                fetching: false,
                fetched: false
            }
        case 'RESET':
            return INITIAL_STATE
        default:
            break
        
    }
    return state
}

export default submissionGradingReducer