import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import NotificationArea from 'components/NotificationArea'
import { useDispatch, useSelector } from 'react-redux'
import { trophyRoadActions } from '../../actions'
import './TrophyRoadItemDetails.css'
import useAssets from 'hooks/useAssets'

const ownedSubscriptionsSelector = state => state.ownedSubscriptions.subscriptions

const TrophyRoadItemDetails = () => {
  const { subscriptionId, trophyroaditemId } = useParams()
  const dispatch = useDispatch()
  const assets = useAssets()

  const { trophyRoadItems, fetching, error } = useSelector(state => state.trophyRoad)
  const ownedSubscriptions = useSelector(ownedSubscriptionsSelector)
  const selectedSubscription = ownedSubscriptions.find(sub => sub._id === subscriptionId)
  // const studentFullName = selectedSubscription?.forUser[0]?.fullName

  const trophyRoadItem = trophyRoadItems.find(item => item._id === trophyroaditemId)

  useEffect(() => {
    dispatch(trophyRoadActions.getTrophyRoad(subscriptionId))
  }, [subscriptionId, dispatch])


  if (!trophyRoadItem) {
    return <div>{assets.TESTRAX_V2.NO_TROPHY_ROAD_ITEM_FOUND}</div>
  }

  return (
    <div className="trophy-road-details-container">
    <NotificationArea defaultText={assets.TESTRAX_V2.TROPHY_ROAD_ITEM_DETAILS.toUpperCase()} />
    <div className="trophy-road-details">
      <div className="trophy-road-details__item">
        <div className="trophy-road-details__header">
          <div className="trophy-road-details__description">
            {trophyRoadItem.description} 
          </div>
        </div>
        <div className="trophy-road-details__image">
          {trophyRoadItem.link ? (
              <div className="trophy-road-details__image-container">
              <img src={trophyRoadItem.link} alt="Trophy" />
            </div>
          ) : (
              <div className="trophy-road-details__placeholder">
              <i className="pi pi-image plus-icon" />
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
  )
}

export default TrophyRoadItemDetails
