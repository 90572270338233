import './HelpOverlay.css'
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import logoHelp from 'assets/help-screen/logo.webp'
import mainHelp from 'assets/help-screen/main.webp'
import bgHelp from 'assets/help-screen/bg.webp'
import TrailChevronUp from 'assets/help-screen/trail-chevron-up.svg'
import { Checkbox } from 'primereact/checkbox'
import useUser from '../../../hooks/useUser'
import { profileActions } from '../../../actions'

const assetSelector = state => state.language.assets

function HelpOverlay (props) {
	const { shouldShow,isManualToggle, onHide } = props
	const [ isOverlayHidden, setIsOverlayHidden ] = useState(false)
	const assets = useSelector(assetSelector, shallowEqual)
	const anchorRef = useRef(null)
	const TOUCH_THRESHOLD = 50

	const dispatch = useDispatch()
	const user = useUser()

	useEffect(() => {
		setIsOverlayHidden(false)
	}, [ shouldShow ])

	const handleScrollClick = (evt) => {
		setIsOverlayHidden(true)
		localStorage.setItem('hasBeenHidden', true)
		onHide()
	}

	const handleTouchStart = (evt) => {
		anchorRef.current = evt.touches[0].clientY
	}

	const handleTouchMove = (evt) => {
		const touchAnchor = anchorRef.current
		if (touchAnchor && (touchAnchor - event.touches[0].clientY > TOUCH_THRESHOLD)) {
			setIsOverlayHidden(true)
			// localStorage.setItem('hasBeenHidden', true)
			onHide()
      anchorRef.current = null
    }
	}

	const getRandomPosition = () => {
	  // const isLeft = Math.random() < 0.5 // 50% chance to be left or right
	  // const randomX = isLeft
	  //   ? Math.floor(Math.random() * 30) // Between 0 and 5vw
	  //   : 70 + Math.floor(Math.random() * 15) // Between 75 and 90vw
	  // const randomY = Math.floor(Math.random() * 80) + 10 // Between 60 and 80vh
	  return {
	    // top: `${randomY}vh`,
	    // left: `${randomX}vw`,
			transform: `rotate(${Math.floor(Math.random() * 360)}deg) scale(${Math.random() + 1.2})`,
			opacity: Math.random() * 0.5 + 0.4
	  }
	}

	return (
		<div className={ shouldShow && (isManualToggle || !user?.hideInstructions) ? (isOverlayHidden ? 'help-overlay help-overlay-hidden' : 'help-overlay') : 'help-overlay help-overlay-not-displayed' } onTouchStart={ handleTouchStart } onTouchMove={ handleTouchMove } >
			<div className='help-overlay-content'>
				<div className='help-overlay-bg'>
					{
						[0, 1, 2, 3, 4, 5, 6, 7].map((e, i) =>
							<img src={bgHelp} alt='logo' key={i} className={`bg-element element-${i}` } style={getRandomPosition()}/>
						)
					}
				</div>
				<div className='help-overlay-header'>
					{assets.HELP_OVERLAY.TITLE}
				</div>
				<div className='help-overlay-header-image'>
					<img src={logoHelp} alt='logo' />
				</div>
				<div className='help-overlay-main-image'>
					<img src={mainHelp} alt='logo' />
				</div>
				<div className='help-overlay-body'>
						{
							Object.values(assets.HELP_OVERLAY.DETAILED_INSTRUCTIONS).map((e, i) =>
								<div key={i}>
									{e}
								</div>
							)
						}
				</div>

				<div className='help-overlay-footer'>
			    <div className='footer-content'>
			      <div className='checkbox-container'>
				      <Checkbox checked={user?.hideInstructions} onChange={() => dispatch(profileActions.putUserPlatformInstructions({ userId: user?._id }))} />
              <span>{assets.HELP_OVERLAY.DO_NOT_SHOW_AGAIN}</span>
			      </div>
				    <a href={assets.HELP_OVERLAY.INSTRUCTIONS_LINK_URL} target='_blank' rel='noopener noreferrer'>
			       	{assets.HELP_OVERLAY.INSTRUCTIONS_LINK_TEXT}
				    </a>
			    </div>
		    </div>

				<div className={ isOverlayHidden ? 'help-scroll-action help-scroll-action-hidden' : 'help-scroll-action' } onClick={ handleScrollClick }>
   				<img src={TrailChevronUp} alt='swipe' />
				</div>
			</div>
		</div>
	)
}

export default HelpOverlay
