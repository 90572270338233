const INITIAL_STATE = {
  pictures: [], 
  count: 0,
  error: null,
  fetching: false,
  fetched: false
};

const submissionPicturesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_SUBMISSION_PICTURES_PENDING':
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      };
    case 'GET_SUBMISSION_PICTURES_FULFILLED':
      return {
        ...state,
        pictures: Array.isArray(action.payload.data) ? action.payload.data : [], 
        error: null,
        fetching: false,
        fetched: true
      };
    case 'GET_SUBMISSION_PICTURES_REJECTED':
      return {
        ...state,
        error: action.payload.response.data,
        fetching: false,
        fetched: false
      };

    case 'RESET':
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default submissionPicturesReducer;
