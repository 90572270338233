import './ParentTestingEvent.css'
import React, { useRef } from 'react'
import { SplitButton } from 'primereact/splitbutton'
import { useNavigate, useParams } from 'react-router-dom'
import useAssets from 'hooks/useAssets'
import dayjs from 'dayjs'
import { shallowEqual, useSelector } from 'react-redux'

const ownedSubscriptionsSelector = state => state.ownedSubscriptions.subscriptions

const ParentTestingEvent = ({ item, background, itemNumber}) => {
  const { subscriptionId } = useParams()
  const navigate = useNavigate()
  const assets = useAssets()
  const ownedSubscriptions = useSelector(ownedSubscriptionsSelector, shallowEqual)
  const splitButtonRef = useRef(null)

  const selectedSubscription = ownedSubscriptions.find(sub => sub._id === subscriptionId)
  const userKnowledgeMap = selectedSubscription?.userKnowledgeMaps?.find(
    ukm => ukm.user === item.student && ukm.knowledgeMap._id === item.knowledgeMapId
  )

  const menuItems = () => {
    switch (item.eventType) {
      case 'SUBMISSION_UPLOADED':
        return [
          {
            label: assets.GENERAL.VIEW,
            icon: 'pi pi-eye',
            command: () => navigate(`/parent-dashboard/submissions/${item.submission}`, { state: { item } })
          }
        ]
      case 'SUBMISSION_GRADED':
        return [
          {
            label: assets.GENERAL.REVIEW,
            icon: 'pi pi-file',
            command: () => navigate(
              `/parent-dashboard/submissions/${item.submission}/user-knowledge-maps/${userKnowledgeMap._id}/submission-gradings/${item.submissionGrading}`,
              { state: { item } }
            )
          }
        ]
      case 'TEST_ASSIGNED':
        return [
          {
            label: assets.GENERAL.VIEW,
            icon: 'pi pi-eye',
            command: () => navigate(`/parent-dashboard/tests/${item.testId}/content`, { state: { item } })
          }
        ]
      case 'TROPHY_ROAD_ITEM_COMPLETE':
        return [
          {
            label: assets.TESTRAX_V2.TROPHY_ROAD,
            icon: 'pi pi-chart-line',
            command: () => navigate(
              `/parent-dashboard/subscriptions/${subscriptionId}/trophyroads/${item.trophyRoad}/trophyroaditems/${item.trophyRoadItem}`,
              { state: { item } }
            )
          }
        ]
      default:
        return []
    }
  }

  const items = menuItems()
  const isDisabled = items.length === 0

  const handleEventClick = (event) => {
    if (event.isTrusted === false) {
      return
    }

    if (items.length === 1) {
      items[0].command()
    } else if (splitButtonRef.current && typeof splitButtonRef.current.show === 'function') {
      const dropdownButton = splitButtonRef.current.getElement().querySelector(
        '.p-splitbutton-menubutton.p-button.p-component.p-button-icon-only'
      )
      if (dropdownButton) {
        const syntheticEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
        dropdownButton.dispatchEvent(syntheticEvent)
      }
    }
  }

  const activityTemplate = (item) => {
    return (
      <div className='activity-item-title'>
        <span className='event-description'>
          {assets.TESTRAX_V2.EVENT_TYPE[item.eventType]}
        </span>
        {
          item.eventType === 'TEST_ASSIGNED' &&
            <span> "{item.testTitle}" {assets.TESTRAX_V2.ALLOCATED_FOR}</span>
        }
        <span className='description-separator'>{assets.TESTRAX_V2.FOR_OBJECTIVE}</span>
        <span>"{item.trophyRoadItemDescription}"</span>
      </div>
    )
  }

  return (
    <div
      className='parent-testing-event-container'
      onClick={handleEventClick}
    >
      <div
        className='line-marker'
        style={{ backgroundColor: background }}
      />
      <div className='item-number-column'> 
        <span className='item-number'>#{itemNumber}</span>
      </div>
      <div className='activity-content'>
        {activityTemplate(item)}
        <div className='timestamp'>
          {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
        </div>
      </div>
      <div className='activity-controls'>
        {!isDisabled && items.length > 1 && (
          <SplitButton
            ref={splitButtonRef}
            icon='pi pi-ellipsis-v'
            model={items}
            className='p-button-secondary'
            dropdownIcon='pi pi-ellipsis-v'
          />
        )}
      </div>
    </div>
  )
}

export default ParentTestingEvent
