// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistics-container {
  height: calc(var(--app-height) * 0.84);
  overflow-y: auto;
}

.statistics-content {
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
}

.statistics-content p {
  font-size: 1.2em;
  font-weight: 500;
  margin: 0;
  text-align: left; 
  width: 100%; 
}

.statistics-canvas {
  max-width: 100%;
  height: 60vh; 
  margin: 1em 1em;
}
`, "",{"version":3,"sources":["webpack://./src/components/Statistics/Statistics.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,SAAS;EACT,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".statistics-container {\n  height: calc(var(--app-height) * 0.84);\n  overflow-y: auto;\n}\n\n.statistics-content {\n  margin-top: 3em;\n  display: flex;\n  flex-direction: column;\n  gap: 2em;\n  align-items: center;\n}\n\n.statistics-content p {\n  font-size: 1.2em;\n  font-weight: 500;\n  margin: 0;\n  text-align: left; \n  width: 100%; \n}\n\n.statistics-canvas {\n  max-width: 100%;\n  height: 60vh; \n  margin: 1em 1em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
