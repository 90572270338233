/**
 * @module profileActoins
 * @description actions for user profile
 * @category actions
 */

import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const PUT_SHOW_PLATFORM_INSTRUCTIONS = 'PUT_SHOW_PLATFORM_INSTRUCTIONS'

export function putUserPlatformInstructions({ userId }) {
  const token = store.getState().loginUser.user.token
  return {
    type: PUT_SHOW_PLATFORM_INSTRUCTIONS,
    payload: axios.put(`${SERVER}/user-api/users/${userId}/instructions`, {}, {
        headers: {
          Authorization: token
        }
    })
  }
}
