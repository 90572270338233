
import './ToastManager.css'
import React, { useEffect, useRef, useState} from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toastActions } from 'actions'
import { useNavigate } from 'react-router-dom';
const notificationQueueSelector = state => state.toast.notificationQueue
const currentNotificationSelector = state => state.toast.currentNotification

function ToastManager () {

  const notificationQueue = useSelector(notificationQueueSelector, shallowEqual)
  const currentNotification = useSelector(currentNotificationSelector, shallowEqual)

  const [currentToastText, setCurrentToastText] = useState('This is a DEMO notification toast')
  const [currentToastColorOption, setCurrentToastColorOption] = useState('POSITIVE')
  const [currentToastLife, setCurrentToastLife] = useState('3000')
  const [currentToastNavigationTarget, setCurrentToastNavigationTarget] = useState(null)
  const [currentToastPosition, setCurrentToastPosition] = useState('TOP')
  const dispatch = useDispatch()
  const toastRef = useRef()
  const navigate = useNavigate()
  

  const validColorOptions = [
    'POSITIVE',
    'NEUTRAL',
    'NEGATIVE'
  ]

  const validToastPositions = [
    'TOP',
    'BOTTOM'
  ]

  const setColor = (color) => {
    if (validColorOptions.includes(color)) {
      setCurrentToastColorOption(color)
    }
  }

  const handleShowToast = () => {
    if (toastRef.current) {
      toastRef.current.style.display = 'block'
      setTimeout(handleHideToast, currentToastLife) //replace with animations and stuff
    }
  }

  const handleHideToast = () => {
    if (toastRef.current) {
      toastRef.current.style.display = 'none'
      dispatch(toastActions.popNotification())
    }
  }

  useEffect(() => {
    if (!currentNotification) {
      dispatch(toastActions.popNotification())
    }
  }, [notificationQueue])

  useEffect(() => {
    if (currentNotification) {
      setCurrentToastText(currentNotification.text)
      setCurrentToastNavigationTarget(currentNotification.navigationTarget)
      setCurrentToastPosition(currentNotification.position)
      setColor(currentNotification.color)
      setCurrentToastLife(currentNotification.life)
      handleShowToast()
    }
  }, [currentNotification])

  const handleOnClick = () => {
    if (currentToastNavigationTarget) {
      navigate(currentToastNavigationTarget)
    }
  }

  return (
    <div ref={toastRef} onClick={handleOnClick} className={`toast-manager toast-background-color-${currentToastColorOption.toLowerCase()} toast-position-${currentToastPosition.toLowerCase()}`}>
      <span className='toast-content-span' >{currentToastText}</span>
    </div>
  )
}

export default ToastManager